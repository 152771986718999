
<div class="content_box">
  <h2>Edit Resident</h2>

  <div class="register-page">
    <div class="add-swimming-pool-form">
        <div class="row">

    

            <div class="col-lg-6">
                <div class="input-field-box">
                    <label>Resident First Name<span class="required">*</span></label>
                    <input [(ngModel)]="fName" type="text" class="input-field">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="input-field-box">
                    <label>Resident Last Name<span class="required">*</span></label>
                    <input [(ngModel)]="lName" type="text" class="input-field">
                </div>
            </div>
           

          
            
            
              <div class="col-lg-6">
                <div class="input-field-box">
                    <label>Country<span class="required">*</span></label>
                    <select class="input-field" [(ngModel)]="country" (ngModelChange)="OnChangeCountry(country)" disabled>
                        <option *ngFor="let list of countryList" [value]="list.id">{{list.name}}</option>
                       
                    </select>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="input-field-box">
                    <label>State<span class="required">*</span></label>
                    <select class="input-field" [(ngModel)]="state" (ngModelChange)="OnChangeState(state)" disabled>
                        <option *ngIf="!stateList || stateList.length === 0" value="no-state-found">No state found</option>
                        <option *ngFor="let list of stateList" [value]="list.id">{{list.name}}</option>
                    </select>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="input-field-box">
                    <label>City<span class="required">*</span></label>
                    <select class="input-field" [(ngModel)]="city" (ngModelChange)="onChangeCity(city)" disabled>
                        <ng-container *ngIf="cityList && cityList.length > 0; else noCityFound">
                            <option *ngFor="let list of cityList" [value]="list.id">{{list.name}}</option>
                        </ng-container>
                        <ng-template #noCityFound>
                            <option value="no-city-found">No city Found</option>
                        </ng-template>
                    </select>
                </div>
            </div>
              <div class="col-lg-6">
                <div class="input-field-box">
                    <label>Property Name<span class="required">*</span></label>
                    <select class="input-field" disabled [(ngModel)]="condoName" (ngModelChange)="onChangeCondoName(condoName)">
                        <option *ngFor="let list of consoNameList" [value]="list._id">{{list.property_name}},{{list.property_address}},{{list.postal_code}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="input-field-box">
                    <label>Suite Number</label>
                    <input [(ngModel)]="suiteNo" type="text" class="input-field">
                </div>
            </div>
              <div class="col-lg-6">
                <div class="input-field-box">
                    <label>Resident Address<span class="required">*</span></label>
                    <input [(ngModel)]="address" type="text" class="input-field">
                </div>
            </div>
            
            <div class="col-lg-6">
                <div class="input-field-box">
                    <label>Postal Code</label>
                    <input [(ngModel)]="postalCode" type="text" class="input-field">
                </div>
            </div>


            <div class="col-lg-6">
                <div class="input-field-box">
                    <label>Phone Number<span class="required">*</span></label>
                    <!-- <div class="input-field"> -->
                        <!-- <input [(ngModel)]="country_code" class="countrycode" readonly/>
                        <input type="number" onwheel="this.blur()" [(ngModel)]="cellNumber" class="phn"/>
                        </div> -->
                    <!-- <input [(ngModel)]="cellNumber" type="number" class="input-field">
                    <p *ngIf="cellNumberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->
                    <!-- <input type="text" class="input-field" style="width: 150%;" ng2TelInput
                            [ng2TelInputOptions]="{initialCountry: 'ca'}" (hasError)="hasError($event)"
                            (ng2TelOutput)="getNumber($event)" (intlTelInputObject)="telInputObject($event)"
                            [(ngModel)]="cellNumber" (countryChange)="onCountryChange($event)" /> -->
                            <!-- <p *ngIf="cellNumberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->
                            
                <!-- </div> -->
                <input id="Phone" class="form-control" type="text" minlength="10" maxlength="15" onwheel="this.blur()" >
            </div>
        </div>


            <div class="col-lg-6">
              <div class="input-field-box">
                  <label>Whatsapp Number</label>
                  <!-- <div class="input-field"> -->
                    <!-- <input [(ngModel)]="homeCountryCode" class="countrycode" readonly />
                              <input type="number" onwheel="this.blur()" [(ngModel)]="homeno" class="phn"/></div> -->
                  <!-- <input [(ngModel)]="homeno" type="number" class="input-field"> -->
                  <!-- <input type="text" class="input-field" style="width: 150%;" ng2TelInput
                  [ng2TelInputOptions]="{initialCountry: 'ca'}" (hasError)="hasError($event)"
                  (ng2TelOutput)="getNumber($event)" (intlTelInputObject)="telInputObject($event)"
                  [(ngModel)]="homeno" (countryChange)="onCountryChange1($event)" /> -->
                  <!-- <p *ngIf="homenoValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->
              <!-- </div>  -->
              <input id="whatsapp" class="form-control" ype="text" minlength="10" maxlength="15" onwheel="this.blur()" >
          </div>
        </div>

          <div class="col-lg-6">
            <div class="input-field-box">
                <label>Email<span class="required">*</span></label>
                <input [(ngModel)]="email" type="email" class="input-field" readonly/>
            </div>
        </div>


          <!-- <div class="col-lg-12">
            <div class="input-field-box">
                <label>Are you an adult?</label>
                <div class="birthday">
                    <select class="input-field" [(ngModel)]="bDate" (ngModelChange)="onChangeDate(bDate)">
                        <option [ngValue]="null" [disabled]="true">Date</option>
                        <option *ngFor="let item of birthDate" [value]="item.date">{{item.date}}</option>
                </select>

                <select class="input-field" [(ngModel)]="bMonth" (ngModelChange)="onChangeMonth(bMonth)">
                    <option [ngValue]="null" [disabled]="true">Month</option>
                    <option *ngFor="let item of birthMonth" [value]="item.month">{{item.month}}</option>
                </select>

                <select class="input-field" [(ngModel)]="bYear" (ngModelChange)="onChangeYear(bYear)">
                    <option [ngValue]="null" [disabled]="true">Year</option>
                    <option *ngFor="let item of birthYear" [value]="item.year">{{item.year}}</option>
                </select>
                </div>
            </div>
        </div> -->

        <div class="col-lg-6">
            <div class="input-field-box">
                <label>Are You An Adult?</label>
                <select class="input-field" [(ngModel)]="adult_status" (ngModelChange)="onAdult(adult_status)">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>
        </div>


        <div class="col-lg-6">
            <div class="input-field-box">
                <label>Gender</label>
                <select class="input-field" [(ngModel)]="gender" (ngModelChange)="onChangeGender(gender)">
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                </select>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="input-field-box">
                <label>Resident Status<span class="required">*</span></label>
                <select class="input-field" [(ngModel)]="residentStatus" (ngModelChange)="onChangeResidentStatus(residentStatus)">
                    <option value="Owner resident">Owner resident</option>
                    <option value="Family member">Family member</option>
                    <option value="Offsite Owner">Offsite Owner</option>
                    <option value="Tenant">Tenant</option>
                </select>
            </div>
        </div> 
       

            <div class="col-lg-12">
            <h2 class="page-sub-title">Emergency Contact</h2>
              </div>

            <div class="col-lg-12">
                <div class="input-field-box">
                    <label>Name</label>
                    <input [(ngModel)]="eName" type="text" class="input-field">
                </div>
            </div>


            <div class="col-lg-6">
                <div class="input-field-box">
                    <label>Contact Number</label>
                    <!-- <div class="input-field"> -->
                        
                        <!-- <input [(ngModel)]="contactCountryCode" class="contactcountrycode" readonly/>
                                  <input type="number" onwheel="this.blur()"[(ngModel)]="eCellNo" class="contactphn"
                              /></div> -->
                    <!-- <input [(ngModel)]="eCellNo" type="number" class="input-field"> -->
                    <!-- <input type="text" class="input-field" style="width: 318%;" ng2TelInput
                    [ng2TelInputOptions]="{initialCountry: 'ca'}" (hasError)="hasError($event)"
                    (ng2TelOutput)="getNumber($event)" (intlTelInputObject)="telInputObject($event)"
                    [(ngModel)]="eCellNo" (countryChange)="onCountryChange2($event)" /> -->
                    <!-- <p *ngIf="eCellNoValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->
                <!-- </div> -->
                <input id="emergency" class="form-control" type="text" minlength="10" maxlength="15" onwheel="this.blur()" >
            </div>
        </div>
            
            <div class="col-lg-6">
                <div class="input-field-box">
                    <label>Email</label>
                    <input [(ngModel)]="eEmail" type="email" class="input-field">
                </div>
            </div>
           

             <div class="col-lg-6">
                <div class="input-field-box">
                    <label>Relationship</label>
                    <div class="relation_inner">
                    <div class="relation">
                        <input type="radio" name="relation" [checked]="eReletation == 'family'" ng-model="eReletation" (click)="onChangeReletation('family')">
                        <span>Family</span>
                    </div>
                     <div class="relation">
                        <input type="radio" name="relation" [checked]="eReletation == 'friend'" ng-model="eReletation" (click)="onChangeReletation('friend')">
                        <span>Friend</span>
                    </div>
                    </div>
                </div>
            </div>

          

           <div class="col-lg-12">
               <div class="button-set">
                  <button class="default-btn" (click)="onUpdate();">Update</button>
                </div>
           </div>
         
        </div>
    </div>
</div>
  </div>


