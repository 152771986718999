import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WebserviceService } from './webservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Admin';
  pageurlLink: any = '';
  user_extension: any;
  UserData: any;

  constructor(private router: Router,
    public webservice: WebserviceService,
    private route: ActivatedRoute,) {
      webservice.headerfooter.subscribe((data) => {
        console.log(data);
        this.user_extension = data;
      });

    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData);
    if (this.UserData != null) {
      this.user_extension = undefined;
      this.router.navigateByUrl('/main/dashboard');
    } else {
      this.router.navigateByUrl('/admin-login/admin');
    }
  }

  ngOnInit(): void {

  }
}
