<div class="content_box">
  <h2>Edit Resident</h2>
  <div class="inpurwraper">
    <input
      [(ngModel)]="subject"
      type="text"
      class="form-control"
      placeholder="Subject"
    />
  </div>

  <div class="inpurwraper">
    <input
      [(ngModel)]="memo"
      type="text"
      class="form-control"
      placeholder="Memo"
    />
  </div>

  <div class="inpurwraper">
    <input
      [(ngModel)]="date"
      type="text"
      class="form-control"
      [matDatepicker]="picker"
      placeholder="Choose a Date"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </div>

  <div class="inpbtn_wrap">
    <button
      type="button"
      class="btn defbtn"
      *ngIf="date != '' && memo != '' && subject != ''"
      type="submit"
      class="btn defbtn"
      (click)="onAdd();"
    >
    Save
    </button>

    <button
      type="button"
      class="btn defbtn"
      *ngIf="date == '' || memo == '' || subject == ''"
      [disabled]="date == '' || memo == '' || subject == ''"
      type="submit"
      class="btn defbtn"
      style="cursor: no-drop;"
    >
      Save
    </button>
  </div>
</div>
