import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';

@Component({
  selector: 'app-vendor-profile',
  templateUrl: './vendor-profile.component.html',
  styleUrls: ['./vendor-profile.component.scss'],
})
export class VendorProfileComponent implements OnInit {
  country: any = '';
  city: any = '';
  state: any = '';
  name: any = '';
  email: any = '';
  Password: any = '';
  cPassword: any = '';
  condoName: any = '';
  countryList: any = [];
  stateList: any = [];
  citylist: any = [];
  UserData: any;
  profileId: any;
  consoNameList: any = [];
  phn_no: any;
  business_name: any = '';
  business_address: any = '';
  business_phone_number: any = '';
  business_phone_number_country_code: any = '';
  business_alternate_phone_number: any = '';
  business_alternate_phone_number_country_code: any = '';
  business_email: any = '';
  vendor_alternate_phone_number: any = '';
  vendor_alternate_phone_number_country_code: any = '';
  suite_no: any = '';
  postal_code: any = '';
  country_code: any = '';
  property_name:any = '';

  constructor(
    public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    this.route.params.subscribe((params) => {
      this.profileId = params['profile_id'];
      console.log('profile' + this.profileId);
      this.onVendorDetails();
    });
  }
  onVendorDetails() {
    
    this.spinner.show();
    this.webservice.adminGetData('vendorById/' + this.profileId).then(
      (result: any) => {
        console.log(result);
        if (result.status == 1) {

          this.name = result.data.name;
          this.email = result.data.email;
          this.country = result.data.country_id;
          this.state = result.data.state_id;
          console.log("state====",this.state)
          this.city = result.data.city_id;
          this.condoName = result.data.property_id;
          this.phn_no = result.data.contact_no;
          this.business_name = result.data.business_name;
          this.business_address = result.data.business_address;
          this.business_phone_number = result.data.business_phone_number;
          this.business_phone_number_country_code =
            result.data.business_phone_number_country_code;
          this.business_alternate_phone_number =
            result.data.business_alternate_phone_number;
          this.business_alternate_phone_number_country_code =
            result.data.business_alternate_phone_number_country_code;
          this.business_email = result.data.business_email;
          this.vendor_alternate_phone_number =
            result.data.vendor_alternate_phone_number;
          this.vendor_alternate_phone_number_country_code =
            result.data.vendor_alternate_phone_number_country_code;
          this.suite_no = result.data.suite_no;
          this.postal_code = result.data.postal_code;
          this.country_code = result.data.country_code;
          this.property_name =result.data.property_name;
          console.log(this.state);
          console.log(this.city);
          console.log(this.country);
          this.ongetAllCountry();
          this.allStateList();
          this.allCityList();
          this.onAllProperty();
        } else {
          console.log(result.message);
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        console.log(err);
      }
    );
  }

  allStateList() {
    this.webservice.adminGetData('stateList/' + this.country).then(
      (result: any) => {
        console.log(result);
        if (result.status == 1) {
          this.stateList = result.data;
        } else {
          console.log(result.msg);
        }
      },
      (err) => {
        this.spinner.hide();
        console.log(err);
      }
    );
  }

  allCityList() {
    this.webservice.adminGetData('cityList/' + this.state).then(
      (result: any) => {
        console.log(result);
        if (result.status == 1) {
          this.citylist = result.data;
        } else {
          console.log(result.msg);
        }
      },
      (err) => {
        this.spinner.hide();
        console.log(err);
      }
    );
  }

  ongetAllCountry() {
    this.spinner.show();
    this.webservice.adminGetData('countryList').then(
      (result: any) => {
        console.log(result);
        if (result.status == 1) {
          this.countryList = result.data;
        } else {
          console.log(result.msg);
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        console.log(err);
      }
    );
  }

  onAllProperty() {
    this.webservice.adminGetData('propertyListByCity/' + this.city).then(
      (result: any) => {
        console.log(result);
        if (result.status == 1) {
          this.consoNameList = result.data;
        } else {
          console.log(result.msg);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
