import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manageresidents',
  templateUrl: './manageresidents.component.html',
  styleUrls: ['./manageresidents.component.scss']
})
export class ManageresidentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
