<div class="content_box">
  <h2>Property List</h2>
  <div style="margin-bottom: 10px;">
    <mdb-icon fas icon="search" aria-hidden="true"></mdb-icon>
    <input style="min-height: 40px;" class="form-control form-control-sm w-60 srchIcon" type="text" 
      placeholder="Search by Country, State, City, Property building by Name & Address" aria-label="Search" mdbInput
      [(ngModel)]="search" (ngModelChange)="onSearch(search)">
  </div>
  <!-- <table mat-table class="mat-elevation-z8">
    <tr>
      <th>Sl#</th>
      <th>Property Name</th>
      <th>Email</th>
      <th>Contact Phone No</th>
      <th>Address</th>
      <th>Action</th>
    </tr>
    <tr *ngFor="let property of propertyList | paginate:{itemsPerPage: tableSize, currentPage: page, totalItems:count}; let i=index"  >
      <td>{{ (page - 1) * tableSize + i +1 }}</td>
      <td>{{property.property_name}}</td>
      <td>{{property.property_email}}</td>
      <td>{{property.property_country_code}} {{property.property_contact_no}}</td>
      <td>{{property.property_address}}</td>
      <td><i style="cursor: pointer;" class="material-icons"
          [routerLink]="['./property-edit/'+property._id]">create</i>
           <i style="padding-left:10%" class="btn btn-outline-secondary" mwlConfirmationPopover [popoverTitle]="popoverTitle"
          [popoverMessage]="popoverMessage" placement="left" (confirm)="onDelete(property._id)"
          (cancel)="cancelClicked = true" style="cursor: pointer;" class="material-icons" >delete</i></td>
    </tr>
  </table> -->

  <div class="responsive_table">
    <table mat-table class="mat-elevation-z8">
      <thead>
      <tr>
        <th>Sl#</th>
        <th>Property Name</th>
        <th>Email</th>
        <th>Contact Phone No</th>
        <th>Address</th>
        <th>Action</th>
        <!-- <th>Action</th> -->
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let property of propertyList | paginate:{itemsPerPage: tableSize, currentPage: page, totalItems:count}; let i=index"  >
        <td data-label="Sl#">{{ (page - 1) * tableSize + i +1 }}</td>
        <td data-label="Property Name">{{property.property_name}}</td>
        <td data-label="Email">{{property.property_email}}</td>
        <td data-label="Contact Phone No">{{property.property_country_code}} {{property.property_contact_no}}</td>
        <td data-label="Address">{{property.property_address}}</td>
        <!-- <td>icon</td> -->
        <td  data-label="Action">
          <div class="d-flex floatr">
            <i style="cursor: pointer;" class="material-icons"
            [routerLink]="['./property-edit/'+property._id]">create</i>
             <i style="padding-left:10%" class="btn btn-outline-secondary" mwlConfirmationPopover [popoverTitle]="popoverTitle"
            [popoverMessage]="popoverMessage" placement="left" (confirm)="onDelete(property._id)"
            (cancel)="cancelClicked = true" style="cursor: pointer;" class="material-icons" >delete</i>
          </div>
          </td>
      </tr>
      </tbody>
    </table>
  </div>
<div class="justify-content-end d-flex">
  <pagination-controls previousLabel="Prev" nextLabel="Next" maxSize="5" (pageChange)="onTableDataChange($event) " ></pagination-controls>
</div>

</div>
<div *ngIf="propertyList.length == 0" class="Nodatafound">No result found!</div>
<div class="btn btn-primary btn-round default_btn mat-raised-button" [routerLink]="['./property-add']"
  style="float:right">
  <i class="material-icons float-icon">add</i>
</div>