import { Component, OnInit } from '@angular/core';
import { WebserviceService } from 'src/app/webservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
declare var intlTelInput: any;
@Component({
  selector: 'app-editproperty',
  templateUrl: './editproperty.component.html',
  styleUrls: ['./editproperty.component.scss']
})
export class EditpropertyComponent implements OnInit {
  public edit_property: any;

  propertyName: any = '';
  propertyAddress: any = '';
  propertyContact: any = '';
  email: any;
  propertyDetailsData: any;
  emailValidation: boolean = false;
  propertyContactValidation: boolean = false;
  data: any;
  UserData: any;
  lat: any = '';
  long: any = '';
  postalCode:any='';

  title = 'rou'; 
  //Local Variable defined 
  formattedaddress=" "; 
  options={ 
    componentRestrictions:{ 
      // country:["AU"] 
    } 
  } 

  country: any = '';
  city: any = '';
  state: any = '';
  countryList: any = [];
  stateList: any = [];
  citylist: any = [];
  countryCode: '';
  property_country_code: any='';
  property_management_company: any = '';

  phone: any;
  shortcountrycode:any;
  
  public AddressChange(address: any) { 
    //setting address from API to local variable 
     this.formattedaddress=address.formatted_address 
     this.propertyAddress = this.formattedaddress;
     this.lat = address.geometry.location.lat();
     this.long = address.geometry.location.lng();
     console.log(this.lat,this.long);
  } 

  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) { 
      setTimeout(() => {
        this.getcountry();
      },500);
    }

  ngOnInit(): void {
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    this.route.params.subscribe(params => {
      this.edit_property = params['property_id'];
      console.log('eeeeeeeeeee'+this.edit_property);
    });
    
    setTimeout(() => {
      this.phone = intlTelInput(document.querySelector("#Phone"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });
     

    },100);
    
    this.getUserType();
    this.propertyDetails();


}
getcountry(){
  this.webservice.getcountry().subscribe((res:any)=>{  
    //this.ipAddress=res.ip;  
    var short_code= res.countryCode
    this.shortcountrycode = (short_code).toLowerCase();
    console.log('country===========',this.shortcountrycode)
  }); 
}

  hasError(obj) {
    console.log(obj);
  }

  onCountryChanges(obj) {
    console.log(obj);
    this.property_country_code = obj.dialCode
    console.log(this.property_country_code );
  }
  getNumber(obj) {
    console.log(obj);
  }

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry('ca');
  }

  onCountryChange(val) {

    this.state = ''; // Reset state
    this.city = '';  // Reset city
    this.country = val;
    if(this.country != '') {
      this.webservice.adminGetData('stateList/'+this.country).then((result: any) => {
        console.log(result)
        if(result.status == 1) {
          this.stateList = result.data;
        } else {
          console.log(result.msg)
        }
  
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }
  }

  onStateChange(val) {
    this.city = '';  // Reset city
    this.state = val;
    if(this.state != '') {
      this.webservice.adminGetData('cityList/'+this.state).then((result: any) => {
        console.log(result)
        if(result.status == 1) {
          this.citylist = result.data;
        } else {
          console.log(result.msg)
        }
  
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

  onCityChange(val) {
    this.city = val;
  }

  ongetAllCountry() {
    this.webservice.adminGetData('countryList').then((result: any) => {
      console.log(result)
      if(result.status == 1) {
        this.countryList = result.data;
      } else {
        console.log(result.msg)
      }

    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  allStateList() {
    this.webservice.adminGetData('stateList/'+this.country).then((result: any) => {
      console.log(result)
      if(result.status == 1) {
        this.stateList = result.data;
      } else {
        console.log(result.msg)
      }

    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  allCityList() {
    this.webservice.adminGetData('cityList/'+this.state).then((result: any) => {
      console.log(result)
      if(result.status == 1) {
        this.citylist = result.data;
      } else {
        console.log(result.msg)
      }

    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

  }

  getUserType() {
    this.webservice.adminGetData('userTypes').then((result) => {
      console.log("userTypes: ", result);


    }, (err) => {
      console.log(err);
    });

  }

  propertyDetails() {
    this.spinner.show();
    this.webservice.adminGetData('propertyById/'+this.edit_property).then((result) => {
      console.log("propertyById: ", result);
      this.propertyDetailsData = result;
      if (this.propertyDetailsData.status == 1) {
        this.propertyAddress = this.propertyDetailsData.data.property_address;
        this.propertyName = this.propertyDetailsData.data.property_name;
        this.propertyContact = this.propertyDetailsData.data.property_contact_no;
        this.email = this.propertyDetailsData.data.property_email;
        this.lat = this.propertyDetailsData.data.latitude;
        this.long = this.propertyDetailsData.data.longitude;
        this.country = this.propertyDetailsData.data.country_id;
        this.state = this.propertyDetailsData.data.state_id;
        this.city = this.propertyDetailsData.data.city_id;
        this.postalCode = this.propertyDetailsData.data.postal_code;
        this.property_management_company = this.propertyDetailsData.data.property_management_company;
        this.property_country_code= this.propertyDetailsData.data.property_country_code;
        this.phone.setNumber(this.propertyDetailsData.data.property_country_code+this.propertyDetailsData.data.property_contact_no);

        this.ongetAllCountry();
        this.allStateList();
        this.allCityList();
        
      } else {
        console.log(this.propertyDetailsData.msg);
      }
      this.spinner.hide();

    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

  }

  onUpdate() {
    var selectedCountryData = this.phone.getNumber();
    // var dialCode = selectedCountryData.dialCode;
     var phone = this.phone.getSelectedCountryData().dialCode;
     var codeLength= phone.length+1;
       console.log(selectedCountryData);
     console.log(phone)
     console.log('******',)
     this.propertyContact = selectedCountryData.substring(codeLength);
   console.log('******',this.propertyContact)
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var rem = /(^[0-9]{10}$)/;

    if (!re.test(this.email)) {
      this.emailValidation = true;
    }
    else if (!rem.test(this.propertyContact)) {
      this.toastr.error('Phone number should contain 10 digits. Please enter valid phone number. ')
    }
    
    else {
      var params = {
        property_name: this.propertyName,
        property_address: this.propertyAddress,
        property_contact_no: this.propertyContact,
        property_email: this.email,
        user_types: this.UserData.user_type,
        property_id: this.edit_property,
        latitude: this.lat,
        longitude: this.long,
        country_id: this.country,
        state_id: this.state,
        city_id: this.city,
        postal_code: this.postalCode,
        property_management_company: this.property_management_company,
        property_country_code:'+' + phone
      }
      this.spinner.show();
      this.webservice.adminPostData('updateProperty', params).then((result) => {
        this.data = result;
        if(this.data.status == 1) {
          this.toastr.success(this.data.msg);
        } else {
          this.toastr.error(this.data.msg);
        }
        //tost
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }

  }

}
