<app-innerbanner>
</app-innerbanner>

<div class="inner_cont">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="error-template">
                    <h3>Oops!</h3>
                    <h2>404 Not Found</h2>
                    <div class="error-details mb-3">
                        Sorry, Requested page not found!
                    </div>
                    <div class="error-actions">
                        <a routerLink="/main/dashboard" class="btn btn-danger btn-lg">
                Go Back to Home
            </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
