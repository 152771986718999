import { Component, OnInit } from '@angular/core';
import { WebserviceService } from 'src/app/webservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-cmslist',
  templateUrl: './cmslist.component.html',
  styleUrls: ['./cmslist.component.scss']
})
export class CmslistComponent implements OnInit {
  data: any;
  cmsList: any;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.spinner.show();
    this.webservice.adminGetData('cmsList').then((result) => {
      this.data = result;
      console.log("result", this.data);
      if (this.data.status == 1) {
        this.cmsList = this.data.data;
        console.log("result", this.cmsList);
      } else {
        console.log(this.data.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

}
