import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';

@Component({
  selector: 'app-vendor-add',
  templateUrl: './vendor-add.component.html',
  styleUrls: ['./vendor-add.component.scss']
})
export class VendorAddComponent implements OnInit {


  country: any = '';
  city: any = '';
  state: any = '';
  name: any = '';
  email: any = '';
  Password: any = '';
  cPassword: any = '';
  condoName: any = '';
  countryList: any = [];
  stateList: any = [];
  citylist: any = [];
  consoNameList: any = [];
  phn_no: any;
  // countryCode = '1';
  phn_noValidation: boolean = false;
  business_name: any = '';
business_address: any = '';
business_phone_number:any ='';
business_phone_number_country_code:any='';
business_alternate_phone_number:any='';
business_alternate_phone_number_country_code:any='';
business_email:any='';
vendor_alternate_phone_number:any='';
vendor_alternate_phone_number_country_code:any='';
business_phone_numberValidation: boolean = false;
business_alternate_phone_numberValidation: boolean = false;
vendor_alternate_phone_numberValidation: boolean = false;
suite_no: any = '';
postal_code: any = '';
country_code:any='';

visible:boolean = true;
changetype:boolean =true;

visible1:boolean = true;
changetype1:boolean =true;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.ongetAllCountry();
  }
  
  ongetAllCountry() {
    this.spinner.show();
    this.webservice.adminGetData('countryList').then((result: any) => {
      console.log(result)
      if(result.status == 1) {
        this.countryList = result.data;
      } else {
        console.log(result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  onCountryChange(val) {
    this.state = ''; // Reset state
  this.city = ''; // Reset city
  this.stateList = []; // Reset state list
  this.condoName ='';// Reset property
  this.consoNameList = []; // Reset property list
    this.country = val;
    console.log(val)
    // this.countryName = name;
    if(this.country != '') {
      this.spinner.show();
      this.webservice.adminGetData('stateList/'+this.country).then((result: any) => {
        console.log(result)
        if(result.status == 1) {
          this.stateList = result.data;
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }
  }
  onStateChange(val) {
    this.state = val;
    this.city = ''; // Reset city
    this.condoName ='';// Reset property
    this.consoNameList = []; // Reset property list


    // this.stateName = name
    if(this.state != '') {
      this.spinner.show();
      this.webservice.adminGetData('cityList/'+this.state).then((result: any) => {
        console.log(result)
        if(result.status == 1) {
          this.citylist = result.data;
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

  onCityChange(val) {
    this.city = val;
    this.condoName ='';// Reset property
  this.consoNameList = []; // Reset property list
    console.log(this.city)
    if (this.city != '') {
      this.webservice.adminGetData('propertyListByCity/' + this.country+'/' +this.state + '/'+ this.city).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          if(result.data.length > 0){
            this.consoNameList = result.data;
          }
          else{
            this.consoNameList = result.data;
            this.condoName = 'No property found';
            console.log(result.msg);
          }
          
        } 
      }, (err) => {
        console.log(err);
      });
    }
  }
 
  viewpass(){
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }
  viewpass1(){
    this.visible1 = !this.visible1;
    this.changetype1 = !this.changetype1;
  }

  onChangeCondoName(val) {
    this.condoName = val;
  }
  hasErrors(obj) {
    console.log(obj);
  }

  onCountryChanges(obj) {
    console.log(obj);
    this.business_phone_number_country_code = '+' + obj.dialCode
    console.log(this.business_phone_number_country_code );
  }
  onCountryChanges1(obj) {
    console.log(obj);
    this.business_alternate_phone_number_country_code = '+' + obj.dialCode
    console.log(this.business_alternate_phone_number_country_code );
  }
  onCountryChanges2(obj) {
    console.log(obj);
    this.country_code = '+' + obj.dialCode
    console.log(this.country_code);
  }
  onCountryChanges3(obj) {
    console.log(obj);
    this.vendor_alternate_phone_number_country_code= '+' + obj.dialCode
    console.log(this. vendor_alternate_phone_number_country_code);
  }
 
  getNumbers(obj) {
    console.log(obj);
  }

  telInputObjects(obj) {
    console.log(obj);
    obj.setCountry('ca');
  }

  onAdd() {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var rem = /(^[0-9]{10}$)/;
    var pass = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9@$!%*?&]{8,})$/;

    if (!rem.test(this.business_phone_number)) {
      this.toastr.error('Business phone number should contain 10 digits. Please enter valid phone number.');
    }
    else if (this.business_alternate_phone_number!= '' && !rem.test(this.business_alternate_phone_number)) {
      this.toastr.error('Business whatsapp number should contain 10 digits. Please enter valid number.');
    }
    else if (!re.test(this.business_email)) {
      this.toastr.error('Please enter valid business email');
      // this.emailValidation = true;
    }
    else if (!rem.test(this.phn_no)) {
      this.toastr.error('Vendor phone number should contain 10 digits.Please enter valid phone number. ');
    }
    else if (this.vendor_alternate_phone_number!= '' && !rem.test(this.vendor_alternate_phone_number)) {
      this.toastr.error('Vendor whatsapp number should contain 10 digits. Please enter valid number. ');
    }
    else if (!re.test(this.email)) {
      this.toastr.error('Please enter valid vendor email');
      // this.emailValidation = true;
    }
     else if (!pass.test(this.Password)) {
      this.toastr.error('Passwords must be atleast 8 characters long with a combination of uppercase and lowercase letters and atleast one number');
    } 
    
      else if(this.Password != this.cPassword) {
      this.toastr.error("Confirm password doesn't match")
    } 
    
   
   
    else {
      var params = {
        name: this.name,
        email: this.email,
        password: this.Password,
        country_id: this.country,
        state_id: this.state,
        city_id: this.city,
        property_id: this.condoName,
        contact_no: this.phn_no,
        business_name: this.business_name,
business_address: this.business_address,
business_phone_number:this.business_phone_number,
business_phone_number_country_code:this.business_phone_number_country_code,
business_alternate_phone_number:this.business_alternate_phone_number,
business_alternate_phone_number_country_code:this.business_alternate_phone_number_country_code,
business_email:this.business_email,
vendor_alternate_phone_number:this.vendor_alternate_phone_number,
vendor_alternate_phone_number_country_code:this. vendor_alternate_phone_number_country_code,
suite_no: this.suite_no,
postal_code:this.postal_code,
country_code:this.country_code
      }
      this.spinner.show();
      this.webservice.adminPostData('saveVendor', params).then((result: any) => {
        if (result.status == 1) {
          this.toastr.success(result.msg);
          this.name = '';
          this.email = '';
          this.Password = '';
          this.cPassword = '';
          this.country = '';
          this.city = '';
          this.state = '';
          this.condoName = '';
          this.phn_no = '';
          this.business_name = '';
         this.business_address = '';
         this.business_phone_number = '';
         this.business_phone_number_country_code='';
         this.business_alternate_phone_number='';
         this.business_alternate_phone_number_country_code='';
         this.business_email='';
         this.vendor_alternate_phone_number='';
         this. vendor_alternate_phone_number_country_code='';
         this.suite_no='';
         this.country_code='';
         this.router.navigate(['/main/vendor-list']);
        } else {
          this.toastr.error(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  
  }

  hasError(event) {
    console.log("Getting error ", event);
  }

  getNumber(event) {
    console.log("Show number ", event);
  }

  telInputObject(event) {
    console.log("Input object ", event);
  }

  onInputCountryChange(event) {
    console.log("Country change ", event);
  }
}
