<app-innerbanner></app-innerbanner>
<div class="partner_section">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h2>Our <strong> Partners</strong></h2>
                <p>Curabitur nunc neque, mollis viverra ex in, auctor elementum mi. Donec non purus felis. Duis interdum mi id purus feugiat, eu sagittis, auctor elementum Duiipsucuorb.eget nibh finibuodunt.</p>


                <div class="client_logo">
                    <ul>
                        <li>
                            <img src="assets/images/partners_pic5.png" alt="">
                        </li>
                        <li>
                            <img src="assets/images/partners_pic2.png" alt="">
                        </li>
                        <li>
                            <img src="assets/images/partners_pic3.png" alt="">
                        </li>
                        <li>
                            <img src="assets/images/partners_pic4.png" alt="">
                        </li>
                        <li>
                            <img src="assets/images/partners_pic5.png" alt="">
                        </li>
                        <li>
                            <img src="assets/images/partners_pic6.png" alt="">
                        </li>
                        <li>
                            <img src="assets/images/partners_pic7.png" alt="">
                        </li>
                        <li>
                            <img src="assets/images/partners_pic8.png" alt="">
                        </li>
                        <li>
                            <img src="assets/images/partners_pic9.png" alt="">
                        </li>
                        <li>
                            <img src="assets/images/partners_pic10.png" alt="">
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</div>
