// import { Component, OnInit } from '@angular/core';


// @Component({
//   selector: 'app-pay',
//   templateUrl: './pay.component.html',
//   styleUrls: ['./pay.component.scss']
// })
// export class PayComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// import { StripeService, StripeCardComponent } from 'ngx-stripe';
// import {
//   StripeCardElementOptions,
//   StripeElementsOptions
// } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';
import { Router} from '@angular/router';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss']
})
export class PayComponent implements OnInit {

  plan: any = '';
  planList: any = '';
  name: any = '';
  email: any;
  vendor_id: any = ''
  stripe_token: any = ''
  amount: any = ''
  subscription_id: any = ''
  UserData: any;
  val: any;
  save: any;
  saved:any ={};
  //@ViewChild(StripeCardComponent) card!: StripeCardComponent;

  // cardOptions: StripeCardElementOptions = {
  //   style: {
  //     base: {
  //       iconColor: '#666EE8',
  //       color: '#31325F',
  //       fontWeight: '300',
  //       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //       fontSize: '18px',
  //       '::placeholder': {
  //         color: '#CFD7E0'
  //       }
  //     }
  //   }
  // };

  // elementsOptions: StripeElementsOptions = {
  //   locale: 'en'
  // };

  stripeTest!: FormGroup;
  currency:any;


  constructor(private fb: FormBuilder, 
    // private stripeService: StripeService, 
    public webservice: WebserviceService,

    private spinner: NgxSpinnerService,
    private toastr: ToastrService, private router: Router,) { }

  ngOnInit(): void {
    this.ongetAllPlan();
    this.getCurrencySymbol();
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData);
    this.email = this.UserData.email;
    this.vendor_id = this.UserData._id
    console.log(this.UserData);

  }

  getCurrencySymbol(){
    this.webservice.getIP().subscribe((res:any)=>{  
      //this.ipAddress=res.ip;  
      console.log('ip1======',res)
      this.getCurrencySymbol_op(res.ip)
    }); 
    
  }



  getCurrencySymbol_op(ip:any){
    this.webservice.getcurrency(ip).subscribe((res:any)=>{  
      //this.ipAddress=res.ip;  
      this.currency = res
      console.log('ip=======',this.currency)
    }); 
  }

  createToken(): void {
    
  }
  onPlanChange(option) {
    this.plan = option;

    if (option.split('_')[0] == '') {
      this.spinner.show();
      this.webservice.adminGetData('getVendorSubscriptionList/').then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.planList = result.data;
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }
    else {
      this.subscription_id = option.split('_')[0];
      this.amount = option.split('_')[1];
      console.log("Amount", this.amount)
    }
  }
  ongetAllPlan() {
    this.spinner.show();
    this.webservice.adminGetData('getVendorSubscriptionList/').then((result: any) => {
      console.log(result)
      if (result.status == 1) {
       
    console.log(this.UserData);
        this.planList = result.data;
      } else {
        console.log(result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }


  oncard() {
    var params = {
      name: this.name,
      email: this.email,
      vendor_id: this.vendor_id,
      stripe_token: this.stripe_token,
      amount: this.amount,
      subscription_id: this.subscription_id


    }
    console.log("params", params);

    this.spinner.show();
    this.webservice.adminPostData('saveCardDetails', params).then((result: any) => {
      console.log(result)
      if (result.success == 1) {

        this.name = '';
        this.email = '';
        this.stripe_token = '';
        this.amount = '';
        this.subscription_id = '';
        this.toastr.success(result.message);
  
        this.router.navigate(['/main/dailydeals/add-dailydeals']);



      } 
      else if(result.status ==0){
        this.toastr.error(result.msg);
      }
      
      
      
      else {
        this.toastr.error(result.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  

  subscription(type:any){
    var params
    if(this.currency != undefined){
      params = {
        user_id:this.UserData._id,
        price_id:type,
        currency: this.currency
  
      }
    }else{
      params = {
        user_id:this.UserData._id,
        price_id:type,
        currency: 'INR'
  
      }
    }
    console.log("params", params);

    this.spinner.show();
    this.webservice.WebpostData('subscribe/web', params).then((result: any) => {
      console.log(result)
      if (result.status) {

        window.open(result.url, "_self");
  
        //this.router.navigate(['/main/dailydeals/add-dailydeals']);



      } 
      else if(result.status ==0){
        this.toastr.error(result.msg);
      }
      
      
      
      else {
        this.toastr.error(result.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  resourcesubscription(type:any){
    var params = {
      user_id:this.UserData._id,
      price_id:type,
      currency: this.currency

    }
    console.log("params", params);

    this.spinner.show();
    this.webservice.WebpostData('subscribe/web', params).then((result: any) => {
      console.log(result)
      if (result.status) {

        window.open(result.url, "_self");
  
        //this.router.navigate(['/main/dailydeals/add-dailydeals']);



      } 
      else if(result.status ==0){
        this.toastr.error(result.msg);
      }
      
      
      
      else {
        this.toastr.error(result.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
}

