<!-- Button to Open the Modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#modalregistration">
    Open modal
  </button> -->

<!-- The Modal -->
<div class="modal" id="modalregistration" *ngIf="hide == false">
    <div class="modal-dialog">
        <div class="modal-content">

            <!-- Modal body -->
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal">&times;</button>

                <div class="form_content_details">
                    <div class="forminfo">
                        <h2 class="modal-title">Signup as a Property Managery
                        </h2>

                        <div class="formcont" *ngIf="hidecontent == false">
                            <!-- <div class="typeof_registration">
                                <span>Manager</span> <span>Resident </span>
                            </div> -->
                            <!-- <form class="fromdetails"> -->
                                <div class="form-group">
                                    <input type="text" class="form-control" [(ngModel)]="name" placeholder="Name">
                                </div>
                                <p class="error"></p>
                                <div class="form-group">
                                    <input type="email" class="form-control" [(ngModel)]="email" placeholder="Email">
                                </div>
                                <p class="error" *ngIf="validMailError == true">Enter valid email</p>
                                <div class="form-group">
                                    <input type="number" class="form-control" [(ngModel)]="phoneNumber" placeholder="Phone Number">
                                </div>

                                <div class="form-group">
                                    <input type="text" class="form-control" [(ngModel)]="streetNumber" placeholder="Street Number">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" [(ngModel)]="suiteNumber" placeholder="Suite Number">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" [(ngModel)]="streetName" placeholder="Street Name">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" [(ngModel)]="postalCode" placeholder="Postal Code">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" [(ngModel)]="City" placeholder="City">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" [(ngModel)]="Province" placeholder="Province">
                                </div>
                                <div class="form-group">
                                  <select  class="form-control" placeholder="County" [(ngModel)]="County">
                                    <!-- <option disabled >Please select Country</option> -->
                                    <option *ngFor="let country of countryList" [value]="country">{{country}}</option>
                                </select>
                                    <!-- <input type="text" class="form-control" [(ngModel)]="County" placeholder="County"> -->
                                </div>

                                <div class="form-group">
                                    <input type="password" class="form-control" [(ngModel)]="password" placeholder="Password">
                                </div>

                                <div class="form-group">
                                  <input type="password" class="form-control" [(ngModel)]="cPassword" placeholder="Confirm Password">
                                </div>
                                <p class="error" *ngIf="passwordnotmatcherror == true">confirm password does not match </p>
                                <!-- <div class="form-group">
                                    <textarea class="form-control controltextarea" placeholder="message"></textarea>
                                </div> -->
                                <div class="inpbtn_wrap">
                                  <!-- [disabled] = "name == '' || email == '' || phoneNumber == '' || address == '' || password == '' || cPassword == ''" -->
                                    <button *ngIf="name == '' || email == '' || phoneNumber == ''
                                    || password == '' || cPassword == '' || streetNumber == '' ||
                                    suiteNumber == '' || streetName =='' || postalCode == '' || City == '' ||
                                    Province == '' || County == 'Please scelet country'"

                                     type="submit" class="btn defbtn" (click)="onRegister();"
                                     [disabled] = "name == '' || email == '' || phoneNumber == '' || password == '' || cPassword == '' || streetNumber == '' ||
                                      suiteNumber == '' || streetName =='' || postalCode == '' || City == '' ||
                                      Province == '' || County == 'Please scelet country'"
                                      style="cursor: no-drop"
                                      >Submit</button>

                                      <button *ngIf="name != '' && email != '' && phoneNumber != '' &&
                                       password != '' && cPassword != '' && streetNumber != '' &&
                                    suiteNumber != '' && streetName !='' && postalCode != '' && City != '' &&
                                    Province != '' && County != 'Please scelet country'"
                                     type="submit" class="btn defbtn" (click)="onRegister();"
                                      >Submit</button>
                                </div>


                            <!-- </form> -->
                        </div>

                        <div class="formcont" *ngIf="hidecontent != false">
                          {{data.msg}}
                          <div class="inpbtn_wrap">
                            <!-- [disabled] = "name == '' || email == '' || phoneNumber == '' || address == '' || password == '' || cPassword == ''" -->
                              <button  type="submit" class="btn defbtn" (click)="onOk();">Ok</button>

                          </div>
                        </div>
                    </div>
                    <div class="addpic">
                        <img src="assets/images/registration_pic.png" alt="">
                    </div>
                </div>
            </div>

            <!-- Modal footer -->
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            </div> -->

        </div>
    </div>
</div>
<!-- <div class="modal" *ngIf="sucessmessage == true">
  {{data.msg}}
</div> -->
