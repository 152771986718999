<div class="clientinfo" style="background: url(assets/images/someclientbg.png) no-repeat center top ; background-size: cover; ">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="residentinfo">
                    <h2>some of <strong>  our clients</strong></h2>
                    <p>We are committed to providing our customers with exceptional ourees the eploybest trainingommitted customers
                    </p>
                </div>
                <div class="client_logo">
                    <ul>
                        <li>
                            <img src="assets/images/clientpic1.png" alt="">
                        </li>
                        <li>
                            <img src="assets/images/clientpic2.png" alt="">
                        </li>
                        <li>
                            <img src="assets/images/clientpic3.png" alt="">
                        </li>
                        <li>
                            <img src="assets/images/clientpic1.png" alt="">
                        </li>
                        <li>
                            <img src="assets/images/clientpic2.png" alt="">
                        </li>
                    </ul>
                </div>
            </div>


        </div>
    </div>
</div>
