<div class="content_box">
    <h2>Add Vendor</h2>

    <div class="inpurwraper">
      <label>Vendor / Business Name<span class="required">*</span></label>
      <input [(ngModel)]="business_name" type="text" class="form-control"/>
    </div>

    <!-- <div class="inpurwraper">
      <label>Suite Number</label>
      <input [(ngModel)]="suite_no" type="text" class="form-control"/>
    </div> -->

    <div class="inpurwraper">
      <label>Vendor / Business Address<span class="required">*</span></label>
      <input [(ngModel)]="business_address" type="text" class="form-control"/>
    </div>

    <div class="inpurwraper">
        <label>Select Country<span class="required">*</span></label>
        <select class="form-control" [(ngModel)]="country"
          (change)="onCountryChange(country)">
          <option *ngFor="let option of countryList" [value]="option.id">{{option.name}}</option>
        </select>
      </div>

      <div class="inpurwraper">
        <label>Select State<span class="required">*</span></label>
        <select [disabled]="country == ''" class="form-control" [(ngModel)]="state"
          (change)="onStateChange(state)">
          <option disabled></option>
      <option *ngIf="!stateList || stateList.length === 0" value="no-state-found">No state found</option>
          <option *ngFor="let option of stateList" [value]="option.id">{{option.name}}</option>
        </select>
      </div>

      <div class="inpurwraper">
        <label>Select City<span class="required">*</span></label>
        <select [disabled]="state == ''" class="form-control" [(ngModel)]="city" (change)="onCityChange(city)">
          <option disabled></option>
          <option *ngIf="!citylist || citylist.length === 0" value="no-city-found">No city found</option>
            <option *ngFor="let option of citylist" [value]="option.id">{{option.name}}</option>
         
        </select>
      </div>

      <div class="inpurwraper" >
        <label>Select Property<span class="required">*</span></label>
        <select class="form-control" [disabled]="city == ''" [(ngModel)]="condoName" (ngModelChange)="onChangeCondoName(condoName)" *ngIf="consoNameList.length > 0">
            <option *ngFor="let list of consoNameList" [value]="list._id">{{list.property_name}},
              {{list.property_address}}, {{list.postal_code}}</option>
        </select>
        
        <input [(ngModel)]="condoName" type="text" class="form-control" *ngIf="consoNameList.length == 0" readonly/>
      </div>


      <!-- <input  type="text" ng2TelInput (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event)" (intlTelInputObject)="telInputObject($event)" 
      (countryChange)="onInputCountryChange($event)" /> -->

      <!-- <div class="col-lg-6">
        <div class="input-field-box">
            <label>Select Building(s)</label>
            <select class="input-field" [disabled]="city == ''" [(ngModel)]="condoName" (ngModelChange)="onChangeCondoName(condoName)">
                <option *ngFor="let list of consoNameList" [value]="list._id">{{list.property_name}}</option>
            </select>
        </div>
    </div> -->

    <div class="inpurwraper">
      <label>Postal Code</label>
      <input [(ngModel)]="postal_code" type="text" class="form-control"/>
    </div>

    <div class="inpurwraper">
      <label>Vendor / Business Phone Number<span class="required">*</span></label><br>
      <!-- <input [(ngModel)]="phn_no" type="number" class="form-control"/> -->
      <input type="text" class="form-control form-input" onwheel="this.blur()" ng2TelInput
      [ng2TelInputOptions]="{initialCountry: 'ca'}" (hasError)="hasErrors($event)"
      (ng2TelOutput)="getNumbers($event)" (intlTelInputObject)="telInputObjects($event)"
      [(ngModel)]="business_phone_number"
      (countryChange)="onCountryChanges($event)" />
      <p *ngIf="business_phone_numberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p>

    </div>

    <div class="inpurwraper">
      <label>Vendor / Business Whatsapp Number</label><br>
      <!-- <input [(ngModel)]="phn_no" type="number" class="form-control"/> -->
      <input type="text" class="form-control form-input" onwheel="this.blur()" ng2TelInput
      [ng2TelInputOptions]="{initialCountry: 'ca'}" (hasError)="hasErrors($event)"
      (ng2TelOutput)="getNumbers($event)" (intlTelInputObject)="telInputObjects($event)"
      [(ngModel)]="business_alternate_phone_number"
      (countryChange)="onCountryChanges1($event)" />
      <p *ngIf="business_alternate_phone_numberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p>

    </div>

    <div class="inpurwraper">
      <label>Vendor / Business Email<span class="required">*</span></label>
      <input  [(ngModel)]="business_email" type="text" class="form-control"/>
    </div>

      <div class="inpurwraper">
        <label>Vendor Contact Name<span class="required">*</span></label>
        <input [(ngModel)]="name" type="text" class="form-control" />
      </div>

     

      <div class="inpurwraper">
        <label>Vendor Contact Number<span class="required">*</span></label><br>
        <!-- <input [(ngModel)]="phn_no" type="number" class="form-control"/> -->
        <input type="text" class="form-control form-input" onwheel="this.blur()" ng2TelInput
        [ng2TelInputOptions]="{initialCountry: 'ca'}" (hasError)="hasErrors($event)"
        (ng2TelOutput)="getNumbers($event)" (intlTelInputObject)="telInputObjects($event)"
        [(ngModel)]="phn_no" (countryChange)="onCountryChanges2($event)" />
        <p *ngIf="phn_noValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p>

      </div>

      <div class="inpurwraper">
        <label>Vendor Whatsapp Number</label><br>
        <!-- <input [(ngModel)]="phn_no" type="number" class="form-control"/> -->
        <input type="text" class="form-control form-input" onwheel="this.blur()" ng2TelInput
        [ng2TelInputOptions]="{initialCountry: 'ca'}" (hasError)="hasErrors($event)"
        (ng2TelOutput)="getNumbers($event)" (intlTelInputObject)="telInputObjects($event)"
        [(ngModel)]="vendor_alternate_phone_number" (countryChange)="onCountryChanges3($event)" />
        <p *ngIf="vendor_alternate_phone_numberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p>

      </div>

      <div class="inpurwraper">
        <label>Vendor Email<span class="required">*</span></label>
        <input [(ngModel)]="email" type="text" class="form-control"/>
      </div>

      <div class="inpurwraper vendorField">
        <label>Password<span class="required">*</span></label>
        <input [type]="changetype?'password':'text'" [(ngModel)]="Password" type="text" class="form-control"/>
        <span class="eyeicon" (click)="viewpass()"><i [ngClass]="visible?'bi bi-eye-slash-fill':'bi bi-eye-fill'"></i>
        </span>
      </div>

      <div class="inpurwraper vendorField">
        <label>Confirm Password<span class="required">*</span></label>
        <input [type]="changetype1?'password':'text'" [(ngModel)]="cPassword" type="text" class="form-control"/>
        <span class="eyeicon" (click)="viewpass1()"><i [ngClass]="visible1?'bi bi-eye-slash-fill':'bi bi-eye-fill'"></i></span>
      </div>

      <div class="inpbtn_wrap">
        <button type="button" class="btn defbtn" *ngIf="country != '' && state != '' &&
        city != '' && name != '' && business_name !='' && business_address !='' && business_phone_number != '' && business_email !='' && 
          email != '' && Password != '' && cPassword != '' && condoName != 'No property found'  && condoName != '' && phn_no != null" type="submit" class="btn defbtn"
          (click)="onAdd();">
          Save
        </button>
    
        <button type="button" class="btn defbtn"
          *ngIf="country == '' || state == '' || city == '' || name == '' || email == '' || Password == '' || cPassword == '' || condoName == '' || condoName == 'No property found' || phn_no == null || business_name == '' || business_address == '' || business_phone_number == '' || business_email == ''"
          [disabled]="country == '' || state == '' || city == '' || name == '' || email == '' || Password == '' || cPassword == '' || condoName == '' || condoName == 'No property found'  || phn_no == null || business_name == '' || business_address == '' || business_phone_number == '' || business_email == ''" type="submit"
          class="btn defbtn" style="cursor: no-drop;">
          Save
        </button>
      </div>

</div>
