<div class="content_box">
  <h2>Listing List</h2>
  <div class="responsive_table">
  <table mat-table class="mat-elevation-z8">
    <thead>
      <tr>
          <th style="width: 5%;">Sl#</th>
          <th style="width: 15%;">Listing</th>
          <th style="width: 45%;">Description</th>
          <th style="width: 15%;">Image</th>
          <th style="width: 10%;">Created By</th>
          <th *ngIf="user_type == 1" style="width: 10%;">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let listingdata of listingList let i=index">
          <td data-label="Sl#">{{i+1}}</td>
          <td data-label="Listing">{{listingdata.listing}}</td>
          <td data-label="Description">{{listingdata.description}}</td>
          <td data-label="Image"><img style="height: 100px;" [src]="'https://nodeserver.mydevfactory.com:4779/uploads/listing_image/'+listingdata.listing_image"></td>
          <td data-label="Created By">{{listingdata.created_by}}</td>
          <td data-label="Action" *ngIf="user_type == 1"><i style="cursor: pointer;" class="material-icons" [routerLink]="['/main/listing-edit/'+listingdata._id]">create</i></td>
      </tr>
      </tbody>
  </table>
  </div>
</div>
<div *ngIf="user_type == 1">
  <div class="btn btn-primary btn-round default_btn mat-raised-button" [routerLink]="['/main/listing']" style="float:right">
      <i class="material-icons float-icon">add</i>
  </div>
</div>
