import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { WebserviceService } from 'src/app/webservice.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  POST:any;
  page:number = 1;
  count:number = 0;
  tableSize:number = 4;
  tableSizes:any = [5,10,15,20];
  data: any;

  search: any = '';
  user_type: any;

  UserData: any;
  public list: any = [];
  logintype: any;
  paymentstaus:boolean;
  cancelClicked: true;
  popoverTitle = 'Delete!';
  popoverMessage = 'If you want to delete this property, it will automatically delete all the data related this property. Are you sure you want to delete?';
  confirmClicked = false;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,private toastr: ToastrService) { }

  ngOnInit(): void {
    // this.postList();
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData.user_type)
    let logintype = '';
    if(this.UserData.user_type == 0) {
      logintype = 'admin'
      this.paymentstaus = true; //for payment
    } else {
      logintype = this.UserData._id;
      this.checkpaymentstatus();
    }
    this.user_type = this.UserData.user_type;
    this.spinner.show();
    this.webservice.adminGetData('resourceList/'+ this.UserData._id + '/undefined').then((result: any) => {
      console.log(result);
      if(result.status == 1) {
        this.list = result.data;
      } else {
        console.log(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  // postList():void{
  //   this.webservice.adminGetData('resourceList/'+this.logintype).then((result)=>{
  //     this.POST = result;
  //     console.log("pagination:",this.POST);
  //   })
  // }
  onTableDataChange(event:any){
    this.page = event ;
    // this.postList();
  }
  onTableSizeChange(event:any): void{
    this.tableSize = event.target.value;
    this.page = 1 ;
    // this.postList();
  }
  onSearch(value) {
    this.page = 1;
    let logintype = '';
    if (this.UserData.user_type == 0) {
      logintype = 'admin';
    } else {
      logintype = this.UserData._id;
    }
    this.search = value;
    if (this.search != '') {
      this.spinner.show();
      this.webservice
        .adminGetData('resourceList/' + logintype + '/' + value)
        .then(
          (result) => {
            this.data = result;
            console.log(this.data);
            if (this.data.status == 1) {
              this.list = this.data.data;
            } else {
              console.log(this.data.msg);
            }
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            console.log(err);
          }
        );
    } else {
      this.ngOnInit();
    }
  }
  getresourcelist(){
  
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
      console.log(this.UserData.user_type)
      let logintype = '';
      if(this.UserData.user_type == 0) {
        logintype = 'admin'
      } else {
        logintype = this.UserData._id
      }
     
      this.user_type = this.UserData.user_type;
      this.spinner.show();
      this.webservice.adminGetData('resourceList/'+ logintype + '/undefined').then((result) => {
        this.data = result;
        console.log(this.data);
        if(this.data.status == 1) {
          this.list = this.data.data;
          console.log(this.list)
        } else {
          console.log(this.data.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    
  
  }
  onDelete(_id) {
    var params = {
      resource_id: _id
    }
    this.webservice.adminPostData('deleteResource', params).then((result: any) => {
      if (result.status == 1) {
        this.toastr.success(result.msg);
        this.ngOnInit();
        // this.getresourcelist();
      } else {
        this.toastr.error(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });



  }
  checkpaymentstatus(){
    let data={
      'userId' : this.UserData._id
    }
    // this.webservice.adminGetData('chkVendorPayment/' + this.UserData._id).then((response:any)=>{
      this.webservice.WebgetData('getUserSubscriptionDetails/' + this.UserData._id).then((response:any)=>{
        console.log(response['data'].length)
      if(response['data'].length == 0){
        this.paymentstaus = false;
        console.log('no payment',this.paymentstaus)
      }else if(response['data'].length == 1){
        
        for (let i = 0; i < response['data'].length; i++) {
          console.log(response['data'][i].productName)
            if(response['data'][i].productName=='resource'){
            this.paymentstaus = true;
            console.log('payment done1111',this.paymentstaus)
          }else{
            this.paymentstaus = false;
            console.log('payment done22',this.paymentstaus)
          }
        }
      }else if(response['data'].length == 2){
        this.paymentstaus = true;
      }
      console.log(response.data)

      // if(response.data=='Not Subscribed'){
      //   this.paymentstaus = false;
      // }else{
      //   this.paymentstaus = true;
      // }
    })
  }

}
