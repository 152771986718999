import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';
import { environment } from '../../../../environments/environment'
@Component({
  selector: 'app-edit-advatisment',
  templateUrl: './edit-advatisment.component.html',
  styleUrls: ['./edit-advatisment.component.scss']
})
export class EditAdvatismentComponent implements OnInit {
  currentDate:any =new Date();
  id: any;
  title: any = '';
    description: any = '';
    web_link: any = '';
    advertise_image = '';
    UserData: any;
    file:any;
    data: any;
    user_type: any;
    item: any;

    country: any = '';
    city: any = '';
    state: any = '';
    countryList: any = [];
    stateList: any = [];
    citylist: any = [];
    consoNameList: any = [];
    property: any = '';
    merchant_name: any = '';
    merchant_phone_number: any;
    startDate: any = '';
    endDate: any = '';
    address: any = '';
    // countryCode = '1';
    merchant_phone_numberValidation: boolean = false;
    suite_no:any = '';
    postal_code:any = '';
    merchant_phone_no_country_code:any = '';
    merchant_alternate_phone_number:any = '';
    merchant_alternate_phone_number_country_code:any = '';
    email:any='';
    youtube_link:any='';
    advertise_deal_pdf = '';
    PDFfile:any;
    image_file_type:any;
  pdf_file_type:any;
    // imageUrl:any=environment.url+'/uploads/advertise_image/'
    // pdfUrl:any=environment.url+'/uploads/advertise_deal_pdf/'
    newAddImage:any ='';
  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) {
      this.route.params.subscribe(params => {
        this.id = params['advertise']
        console.log(JSON.parse(JSON.stringify(this.id)));
      });
     }

  ngOnInit(): void {
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log('user_type--'+this.UserData);
   
    this.user_type = this.UserData.user_type;
    this.spinner.show();
    this.webservice.adminGetData('advertiseById/'+this.id).then((result) => {
      this.data = result;
      let image = this.data.data.advertise_image.substring(this.data.data.advertise_image.lastIndexOf('/')+1);
      let pdf = this.data.data.advertise_deal_pdf.substring(this.data.data.advertise_deal_pdf.lastIndexOf('/')+1);
      //console.log(this.data);\
      console.log('image======',pdf)
      if(this.data.status == 1) {
        this.item = this.data.data;
        this.web_link = this.item.web_link;
        this.title = this.item.title;
        this.description = this.item.description;
        this.advertise_image = this.item.advertise_image;//image
        this.file = image; //image
        this.country = this.item.country_id;
        this.state = this.item.state_id;
        this.city = this.item.city_id;
        this.property = this.item.property_id;
        this.merchant_name = this.item.merchant_name;
        this.merchant_phone_number = this.item.merchant_phone_no;
        this.address = this.item.address;
        this.startDate = this.item.start_date;
        this.endDate = this.item.end_date;
        this.suite_no = this.item.suite_no;
        this.postal_code = this.item.postal_code;
        this.merchant_phone_no_country_code =  this.item.merchant_phone_no_country_code;
        this.merchant_alternate_phone_number= this.item.merchant_alternate_phone_number;
        this.merchant_alternate_phone_number_country_code = this.item.merchant_alternate_phone_number_country_code;
        this.email = this.item.email;
        this.youtube_link = this.item.youtube_link;
        this.advertise_deal_pdf = this.item.advertise_deal_pdf;//pdf  
        this.PDFfile = pdf;//pdf
       
       
console.log("image pre",this.advertise_image)



        this.ongetAllCountry();
        this.allStateList();
        this.allCityList();
        this. onAllProperty(); 
      } else {
        console.log(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  hasError(obj) {
    console.log(obj);
  }

  // onCountryCodeChange(obj) {
  //   console.log(obj);
  //   this.countryCode = obj.dialCode
  //   console.log(this.countryCode );
  // }
  getNumber(obj) {
    console.log(obj);
  }


telInputObject(obj) {
    console.log(obj);
    obj.setCountry('ca');
  }



  onFileChange(event) {
    console.log('image==============',event.target.files[0])
     if(!event.target.files.length) return;{
     this.file = event.target.files[0];
     if (this.file.type == "image/gif" || this.file.type == "image/jpeg" || this.file.type == "image/jpg" || this.file.type == "image/png") {
 
   const reader = new FileReader();
   reader.onload = e =>{
     console.log(e.target.result)
     this.advertise_image = reader.result.toString();
   };
   reader.readAsDataURL(this.file);
   }else {
    this.toastr.error("Invalid Image Format. Please Upload JPEG,JPG or PNG Format.")
  }
  }
}

  onFileChangePDF(event) {

    if(!event.target.files.length) return;{
  this.PDFfile = event.target.files[0];
  if (this.PDFfile.type == "application/pdf") {
console.log(this.PDFfile)
this.pdf_file_type= event.target.files[0].type.split('/')[0]
  const reader = new FileReader();
  reader.onload = e =>{
    console.log(e.target.result)
    this.advertise_deal_pdf = reader.result.toString();
  };
  reader.readAsDataURL(this.PDFfile);
  } else{
    this.toastr.error("Invalid Format. Please Upload PDF.")
  }
} }



  allStateList() {
    this.webservice.adminGetData('stateList/' + this.country).then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.stateList = result.data;
      } else {
        console.log(result.msg)
      }

    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  allCityList() {
    this.webservice.adminGetData('cityList/' + this.state).then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.citylist = result.data;
      } else {
        console.log(result.msg)
      }

    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

  }


  ongetAllCountry() {
    this.spinner.show();
    this.webservice.adminGetData('countryList').then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.countryList = result.data;
      } else {
        console.log(result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  onAllProperty() {
    this.webservice.adminGetData('propertyListByCity/' + this.city).then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.consoNameList = result.data;
      } else {
        console.log(result.msg);
      }
    }, (err) => {
      console.log(err);
    });
  }

  onCountryChange(val) {
    this.country = val;
    console.log(val)
    // this.countryName = name;
    if (this.country != '') {
      this.spinner.show();
      this.webservice.adminGetData('stateList/' + this.country).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.stateList = result.data;
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }
  }
  onStateChange(val) {
    this.state = val;
    // this.stateName = name
    if (this.state != '') {
      this.spinner.show();
      this.webservice.adminGetData('cityList/' + this.state).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.citylist = result.data;
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

  onCityChange(val) {
    this.city = val;
    console.log(this.city)
    if (this.city != '') {
      this.webservice.adminGetData('propertyListByCity/' + this.city).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.consoNameList = result.data;
        } else {
          console.log(result.msg);
        }
      }, (err) => {
        console.log(err);
      });

    }
  }

  onChangeCondoName(val) {
    this.property = val;
  }

  onUpdate() {
    var rem = /(^[0-9]{10}$)/;
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.advertise_image == this.item.advertise_image) {
      // var params = {
      //   advertise_id: this.id,
      //   created_by_id: this.UserData._id,
      //   created_by: this.UserData.name,
      //   title: this.title,
      //   // description: this.description,
      //   web_link: this.web_link,
      //   property_id: this.property,
      //   country_id: this.country,
      //   state_id: this.state,
      //   city_id: this.city,
      //   start_date: this.startDate,
      //   end_date: this.endDate,
      //   merchant_name: this.merchant_name,
      //   merchant_phone_no: this.merchant_phone_number,
      //   address: this.address,
      //   suite_no:this.suite_no,
      //   postal_code:this.postal_code,
      //   merchant_phone_no_country_code:this.merchant_phone_no_country_code,
      //   merchant_alternate_phone_number:this.merchant_alternate_phone_number,
      //   merchant_alternate_phone_number_country_code:this.merchant_alternate_phone_number_country_code,
      //   email:this.email,
      //   youtube_link:this.youtube_link,
      //  advertise_deal_pdf : this.PDFfile,




      // }
      let fd=new FormData();
      fd.append('advertise_id',this.id);
      fd.append('created_by_id',this.UserData._id);
      fd.append('created_by',this.UserData.name);
      fd.append('title',this.title);
      // fd.append('description',this.description);
      fd.append('web_link',this.web_link);
      fd.append('advertise_image',this.file);
      fd.append('property_id',this.property);
      fd.append('country_id',this.country);
      fd.append('state_id',this.state);
      fd.append('city_id',this.city);
      fd.append('start_date',this.startDate);
      fd.append('end_date',this.endDate);
      fd.append('merchant_name',this.merchant_name);
      fd.append('merchant_phone_no',this.merchant_phone_number);
      fd.append('address',this.address);
      fd.append('suite_no',this.suite_no);
      fd.append('postal_code',this.postal_code);
      fd.append('merchant_phone_no_country_code',this.merchant_phone_no_country_code);
      fd.append('merchant_alternate_phone_number',this.merchant_alternate_phone_number);
      fd.append('merchant_alternate_phone_number_country_code',this.merchant_alternate_phone_number_country_code);
      fd.append('email',this.email);
      fd.append('youtube_link',this.youtube_link);
      fd.append('advertise_deal_pdf',this.PDFfile);
      if (this.title == '') {
        this.toastr.error('Please enter title');
      }
        else if(!rem.test(this.merchant_phone_number)) {
          this.toastr.error('Merchant Phone Number Should contain 10 digits.Please enter Valid Phone No.')
    } 

    else if(this.merchant_alternate_phone_number!= '' && !rem.test(this.merchant_alternate_phone_number)) {
      this.toastr.error('Merchant Whatsapp No Should contain 10 digits.Please enter Valid Number. ')
} 
else if (!re.test(this.email)) {
  this.toastr.error('Please enter Valid Email');
  // this.emailValidation = true;
}
else if (this.startDate > this.endDate ) {
  this.toastr.error('Choose Proper End Date');
} 
// else if (this.description == '') {
      //   this.toastr.error('Please enter Description');
      // } 
      // else if (this.web_link == '') 
      // {
      //   this.toastr.error('Please enter weblink');
      // }
       else if (this.advertise_image == '') {
        this.toastr.error('Please upload image');
      } 
      // else if ( this.image_file_type != "image"){
      //   console.log('file======',this.image_file_type)
    
      //       this.toastr.error('Please upload correct Image Format');
      //     } 
          // else if ( this.pdf_file_type != "application"){
          //   console.log('file======',this.pdf_file_type)
        
          //       this.toastr.error('Please upload correct File Format');
          //     }
      else {

      this.spinner.show();
      this.webservice.adminPostData('updateAdvertiseWithoutImage', fd).then((result) => {
       
        this.data = result;
      console.log(this.data);
      if(this.data.status == 1) {
        this.toastr.success(this.data.msg)
      } else {
        this.toastr.error(this.data.msg)
        console.log(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

    }
  } else {
    if (this.title == '') {
      this.toastr.error('Please enter title');
    } 
    // else if (this.description == '') {
    //   this.toastr.error('Please enter Description');
    // }
    //  else if (this.web_link == '') {
    //   this.toastr.error('Please enter weblink');
    // } 
    else if (this.advertise_image == '') {
      this.toastr.error('Please upload image');
    } 
    // else if ( this.image_file_type != "image"){
    //   console.log('file======',this.image_file_type)
  
    //       this.toastr.error('Please upload correct Image Format');
    //     } 
    // //     else if ( this.pdf_file_type != "application"){
    //       console.log('file======',this.pdf_file_type)
      
    //           this.toastr.error('Please upload correct File Format');
    //         }
            else {

      console.log(this.UserData)
      console.log(this.file)
      this.spinner.show();
      let fd=new FormData();
      fd.append('advertise_id',this.id);
      fd.append('created_by_id',this.UserData._id);
      fd.append('created_by',this.UserData.name);
      fd.append('title',this.title);
      // fd.append('description',this.description);
      fd.append('web_link',this.web_link);
      fd.append('advertise_image',this.file);
      fd.append('property_id',this.property);
      fd.append('country_id',this.country);
      fd.append('state_id',this.state);
      fd.append('city_id',this.city);
      fd.append('start_date',this.startDate);
      fd.append('end_date',this.endDate);
      fd.append('merchant_name',this.merchant_name);
      fd.append('merchant_phone_no',this.merchant_phone_number);
      fd.append('address',this.address);
      fd.append('suite_no',this.suite_no);
      fd.append('postal_code',this.postal_code);
      fd.append('merchant_phone_no_country_code',this.merchant_phone_no_country_code);
      fd.append('merchant_alternate_phone_number',this.merchant_alternate_phone_number);
      fd.append('merchant_alternate_phone_number_country_code',this.merchant_alternate_phone_number_country_code);
      fd.append('email',this.email);
      fd.append('youtube_link',this.youtube_link);
      fd.append('advertise_deal_pdf',this.PDFfile);

  
  
      this.webservice.adminPostData('updateAdvertiseWithImage', fd).then((result) => {
        console.log(result);
        this.data = result;
        if (this.data.status == 1) {
          this.toastr.success(this.data.msg);
        } else {
          this.toastr.error(this.data.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }
  }

  }

}
