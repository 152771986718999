<div class="header_top animated fadeInDown" id="navbar">
    <div class="container">
        <div class="row headercont">
            <div class="col-md-12 col-lg-2">
                <div class="logo">
                    <img src="assets/images/eqnewlogo.png" routerLink="/main/dashboard" style="cursor: pointer;">
                </div>
            </div>
            <div class="col-md-12 col-lg-10">

                <nav class="navbar navbar-expand-lg navbar-light ">
                    <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
                    <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
                        <ul class="navbar-nav ml-auto">
                            <!-- <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/home" href="#">Home</a></li>
                            <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/manageresident" href="#">Manage Resident</a></li>
                            <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/aboutus"> About Us</a></li>
                            <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/partners">Partners</a></li> -->
                            <li *ngIf="UserData != null" class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/main/change-password">Change Password</a></li>

                            <li class="nav-item">
                                <a class="nav-link usericon"  *ngIf="UserData != null">{{UserData.name}}</a>
                            </li>

                            <li class="nav-item ml-auto" *ngIf="UserData == null">

                                <a class="nav-linklogin nav-link" href="" onclick="return false;" data-toggle="modal" data-target="#modallogin">
                                    <strong> Login</strong>
                                </a>
                                <!-- <a class="nav-linklogin nav-link" href="" onclick="return false;" data-toggle="modal" data-target="#modalregistration">
                                    <strong> Registration</strong>
                                </a> -->

                                <!-- <button class="btn btn-lg btn-outline-primary" (click)="open(mymodal)">Open My Modal</button> -->


                            </li>

                            <li class="nav-item ml-auto" *ngIf="UserData != null">

                              <a class="nav-linklogin nav-link" style="cursor: pointer;" (click)="onLOgout();">
                                  <strong> Logout</strong>
                              </a>
                              <!-- <a class="nav-linklogin nav-link" href="" onclick="return false;" data-toggle="modal" data-target="#modalregistration">
                                  <strong> Registration</strong>
                              </a> -->

                              <!-- <button class="btn btn-lg btn-outline-primary" (click)="open(mymodal)">Open My Modal</button> -->


                          </li>
                        </ul>
                    </div>
                </nav>

            </div>

        </div>
    </div>
</div>
