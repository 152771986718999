import { Component, OnInit } from '@angular/core';
import { WebserviceService } from 'src/app/webservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-addmemo',
  templateUrl: './addmemo.component.html',
  styleUrls: ['./addmemo.component.scss']
})
export class AddmemoComponent implements OnInit {
  date: any = '';
  memo: any = '';
  subject: any = '';
  data: any;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute, ) { }

  ngOnInit(): void {
  }

  onAdd() {
    let logtoken = JSON.parse(localStorage.getItem('LoginToken'));
    var params = {
      created_by_id: logtoken._id,
      created_by: logtoken.name,
      subject: this.subject,
      message: this.memo,
      date_required: this.date
    }

    this.spinner.show();
    this.webservice.adminPostData('saveMemo', params).then((result) => {
      this.data = result;
      if (this.data.status == 1) {
        this.toastr.success(this.data.msg);
        this.date = '';
        this.subject = '';
        this.memo = '';
      } else {
        this.toastr.error(this.data.msg);
      }
      //tost
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

  }

}
