<div class="subcontent">
    <h3>Dashboard</h3>

    <div><span class="titel">Name:</span><span class="value">  {{userData.name}}</span></div>
    <div><span class="titel">Email:</span><span class="value">  {{userData.email}}</span></div>
    <div class="search"><p>Search by Country, State, City</p></div>

    <div class="row">
       
        <div class="col-lg-4">
           
            <div class="input-field-box def_mar_bottom">
                
                <label>Country</label>
                <select class="input-field" [(ngModel)]="country" (change)="OnChangeCountry(country)">
                    <option value="" selected>Select Country</option>
                    <option *ngFor="let list of countryList" [value]="list.id">{{list.name}}</option>
    
                </select>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="input-field-box def_mar_bottom">
                <label>State</label>
                <select class="input-field" [disabled]="country == ''" [(ngModel)]="state"
                    (ngModelChange)="OnChangeState(state)">
                    <option value="" selected>Select State</option>
                    <option *ngIf="!stateList || stateList.length === 0" value="no-state-found">No state found</option>
                    <option *ngFor="let list of stateList" [value]="list.id">{{list.name}}</option>
                </select>
            </div>
        </div>
        
    <div class="col-lg-4">
        <div class="input-field-box def_mar_bottom">
            <label>City</label>
            <select class="input-field" [disabled]="state == ''" [(ngModel)]="city"
                (ngModelChange)="onChangeCity(city)">
                <option value=""selected>Select City</option>
                <option *ngIf="!cityList || cityList.length === 0" value="no-city-found">No city found</option>
                <option *ngFor="let list of cityList" [value]="list.id">{{list.name}}</option>
            </select>
            <!-- <div  [(ngModel)]="city"
            (ngModelChange)="onChangeCity(city)" >
            <select class="input-field"  class="input-field" [disabled]="state == 'Select City'" >
                <option value="" selected>Select City</option>
                <option *ngFor="let list of cityList" [value]="list.id">{{list.name}}</option>
    
            </select> -->
            
  
        </div>
    </div>



    </div>

    <div class="row mb-3">
        <div class="col-lg-6">
            <div class="inpurwraper def_mar_bottom">
                <label for="input"><strong>Search By Date</strong></label>
                <input type="date" id="input" class="form-control" [(ngModel)]="Date" mdbInput />
              </div>
        </div>
        <div class="col-lg-6">
            <!-- <label for="input">&nbsp;</label> -->
            <button type="button" (click)="graphsearch();" class="btn btn-primary def_searchbtn">Search</button>&nbsp;
            <button type="button" class="btn btn-primary def_searchbtn" (click)="resetForm()">Reset search</button>
            

        </div>
    </div>   





    <div *ngIf="show" class="">
            <div class="search_item_res" routerLink="/main/property" *ngIf="userData.user_type == 0">
                <div class="search_itm_text">Properties</div>
                 <div class="search_itm_text">{{dashboardData.property}}</div>
                <!-- <p class="text">Buildings</p> -->
               
            </div> 
       
            <div class="search_item_res" routerLink="/main/property-manager" *ngIf="userData.user_type == 0">
                <div class="search_itm_text">Property managers</div>
                    <div class="search_itm_text">{{dashboardData.property_manager_count}}</div>
               
            </div>
            <div class="search_item_res" routerLink="/main/resident-list" *ngIf="userData.user_type == 0">
                <div class="search_itm_text">Residents</div>
                <div class="search_itm_text">{{dashboardData.resident_count}}</div>
                
            </div>
            <div class="search_item_res" routerLink="/main/staff" *ngIf="userData.user_type == 0">
                <div class="search_itm_text">Staffs</div>
                    <div class="search_itm_text">{{dashboardData.staff_count}}</div>
                
            </div>

            <div class="search_item_res" routerLink="/main/dailydeals" *ngIf="userData.user_type == 0 || userData.user_type == 5">
                <div class="search_itm_text">Flash Deals</div>
                <div class="search_itm_text">{{dashboardData.flash_deal}}</div>
               
            </div> 

            <!-- <div class="search_item_res" routerLink="/main/advertise" *ngIf="userData.user_type == 0 || userData.user_type == 5">
                <div class="search_itm_text">Advertise Local Business</div>
                <div class="search_itm_text">{{dashboardData.local_advertiser}}</div>
                
            </div>  -->

            <div class="search_item_res" routerLink="/main/resources" *ngIf="userData.user_type == 0 || userData.user_type == 5">
                <div class="search_itm_text">Resources</div>
                    <div class="search_itm_text">{{dashboardData.resource}}</div>
               
            </div> 

            <div class="search_item_res" routerLink="/main/vendor-list" *ngIf="userData.user_type == 0">
                <div class="search_itm_text">Vendors</div>
                    <div class="search_itm_text">{{dashboardData.vendor}}</div>
               
            </div> 
            

          
         

            <!-- <div class="column">
                <p class="number">202</p>
                <p class="text">Buildings</p>
            </div>  -->

          
    </div>
</div>
