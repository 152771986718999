import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-managedservices',
  templateUrl: './managedservices.component.html',
  styleUrls: ['./managedservices.component.scss']
})
export class ManagedservicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
