import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';
declare var intlTelInput: any;

@Component({
  selector: 'app-vendor-edit',
  templateUrl: './vendor-edit.component.html',
  styleUrls: ['./vendor-edit.component.scss']
})
export class VendorEditComponent implements OnInit {



  country: any = '';
  city: any = '';
  state: any = '';
  name: any = '';
  email: any = '';
  Password: any = '';
  cPassword: any = '';
  condoName: any = '';
  countryList: any = [];
  stateList: any = [];
  citylist: any = [];
  UserData: any;
  vendorId: any;
  consoNameList: any = [];
  phn_no: any;
  // countryCode = '1';
  phn_noValidation: boolean = false;
  business_name: any = '';
  business_address: any = '';
  business_phone_number:any ='';
  business_phone_number_country_code:any='';
  business_alternate_phone_number:any='';
  business_alternate_phone_number_country_code:any='';
  business_email:any='';
  vendor_alternate_phone_number:any='';
  vendor_alternate_phone_number_country_code:any='';
  business_phone_numberValidation: boolean = false;
business_alternate_phone_numberValidation: boolean = false;
vendor_alternate_phone_numberValidation: boolean = false;
suite_no: any = '';
postal_code: any = '';
country_code:any='';

resphone: any;
resalno: any;
phone:any;
alphn: any;
shortcountrycode:any;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) {
      setTimeout(() => {
        this.getcountry();
      },500);
     }

  ngOnInit(): void {
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    this.route.params.subscribe(params => {
      this.vendorId = params['vendor_id'];
      console.log('eeeeeeeeeee' + this.vendorId);
      this.onVendorDetails();
    });
    setTimeout(() => {
      this.resphone = intlTelInput(document.querySelector("#business_phone_number"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });
     
      this.resalno = intlTelInput(document.querySelector("#business_alternate_phone_number"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });
      this.phone = intlTelInput(document.querySelector("#phn_no"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });
      this.alphn = intlTelInput(document.querySelector("#vendor_alternate_phone_number"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });

    },100);
  

}
getcountry(){
  this.webservice.getcountry().subscribe((res:any)=>{  
    //this.ipAddress=res.ip;  
    var short_code= res.countryCode
    this.shortcountrycode = (short_code).toLowerCase();
    console.log('country===========',this.shortcountrycode)
  }); 
}
  hasErrors(obj) {
    console.log(obj);
  }

  // onCountryChanges(obj) {
  //   console.log(obj);
  //   this.countryCode = obj.dialCode
  //   console.log(this.countryCode );
  // }
  // getNumbers(obj) {
  //   console.log(obj);
  // }

  // telInputObjects(obj) {
  //   console.log(obj);
  //   obj.setCountry('ca');
  // }


  onVendorDetails() {
    this.spinner.show();
    this.webservice.adminGetData('vendorById/' + this.vendorId).then((result: any) => {
      console.log(result);
      if (result.status == 1) {
        this.name = result.data.name;
        this.email = result.data.email;
        this.country = result.data.country_id;
        this.state = result.data.state_id;
        this.city = result.data.city_id;
        this.condoName = result.data.property_id;
        this.phn_no = result.data.contact_no;
        this.business_name =result.data.business_name ;
        this.business_address = result.data.business_address;
        this.business_phone_number =result.data.business_phone_number;
        this.business_phone_number_country_code = result.data.business_phone_number_country_code;
        this.business_alternate_phone_number =result.data.business_alternate_phone_number;
        this.business_alternate_phone_number_country_code = result.data.business_alternate_phone_number_country_code;
        this.business_email=result.data.business_email;
        this.vendor_alternate_phone_number=result.data.vendor_alternate_phone_number;
        this.vendor_alternate_phone_number_country_code =  result.data.vendor_alternate_phone_number_country_code;
        this.suite_no=result.data.suite_no;
        this.postal_code=result.data.postal_code;
        this.country_code =  result.data.country_code;


        this.resphone.setNumber(result.data.business_phone_number_country_code+result.data.business_phone_number);
        this.resalno.setNumber(result.data.business_alternate_phone_number_country_code+result.data.business_alternate_phone_number);
        this.phone.setNumber(result.data.country_code+result.data.contact_no);
        this.alphn.setNumber(result.data.vendor_alternate_phone_number_country_code+result.data.vendor_alternate_phone_number);


        console.log(this.state)
        console.log(this.city)
        console.log(this.country)
        this.ongetAllCountry();
        this.allStateList();
        this.allCityList();
        this. onAllProperty();
      } else {
        console.log(result.message)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  allStateList() {
    this.webservice.adminGetData('stateList/' + this.country).then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.stateList = result.data;
      } else {
        console.log(result.msg)
      }

    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  allCityList() {
    this.webservice.adminGetData('cityList/' + this.state).then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.citylist = result.data;
      } else {
        console.log(result.msg)
      }

    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

  }


  ongetAllCountry() {
    this.spinner.show();
    this.webservice.adminGetData('countryList').then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.countryList = result.data;
      } else {
        console.log(result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  onCountryChange(val) {
    this.country = val;
    this.state = ''; // Reset state
  this.city = ''; // Reset city
  this.stateList = []; // Reset state list
  this.condoName ='';// Reset property
  this.consoNameList = []; // Reset property list
    console.log(val)
    // this.countryName = name;
    if (this.country != '') {
      this.spinner.show();
      this.webservice.adminGetData('stateList/' + this.country).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.stateList = result.data;
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }
  }
  onStateChange(val) {
    this.state = val;
    this.city = ''; // Reset city
    this.condoName ='';// Reset property
    this.consoNameList = []; // Reset property list
    // this.stateName = name
    if (this.state != '') {
      this.spinner.show();
      this.webservice.adminGetData('cityList/' + this.state).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.citylist = result.data;
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

  onCityChange(val) {
    this.condoName ='';// Reset property
    this.consoNameList = []; // Reset property list
    this.city = val;
    console.log(this.city)
    if (this.city != '') {
      this.webservice.adminGetData('propertyListByCity/' + this.country + '/' + this.state + '/'+ this.city).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          if(result.data.length > 0){
            this.consoNameList = result.data;
          }
          else{
            this.consoNameList = result.data;
            this.condoName = 'No property found';
            console.log(result.msg);
          }
          
        } 
      }, (err) => {
        console.log(err);
      });
    }
  }
 

  onAllProperty() {
      this.webservice.adminGetData('propertyListByCity/' + this.city).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.consoNameList = result.data;
        } else {
          console.log(result.msg);
        }
      }, (err) => {
        console.log(err);
      });
    }

  onUpdate() {
    var selectedCountryData = this.resphone.getNumber();
    // var dialCode = selectedCountryData.dialCode;
     var resphone = this.resphone.getSelectedCountryData().dialCode;
     var codeLength= resphone.length+1;
       console.log(selectedCountryData);
     console.log(resphone)
     console.log('******',)
     this.business_phone_number = selectedCountryData.substring(codeLength);
   console.log('******',this.business_phone_number)
   /////////////////////////////////
   var selectedCountryData1 = this.resalno.getNumber();
   // var dialCode = selectedCountryData.dialCode;
    var resalno = this.resalno.getSelectedCountryData().dialCode;
    var codeLength= resalno.length+1;
      console.log(selectedCountryData1);
    console.log(resalno)
    console.log('******',)
    this.business_alternate_phone_number = selectedCountryData1.substring(codeLength);
  console.log('******',this.business_alternate_phone_number)
  //////////////////////////////////
  var selectedCountryData2 = this.phone.getNumber();
  // var dialCode = selectedCountryData.dialCode;
   var phone = this.phone.getSelectedCountryData().dialCode;
   var codeLength= phone.length+1;
     console.log(selectedCountryData2);
   console.log(phone)
   console.log('******',)
   this.phn_no = selectedCountryData2.substring(codeLength);
 console.log('******',this.phn_no)
 /////////////////////////
 var selectedCountryData2 = this.alphn.getNumber();
  // var dialCode = selectedCountryData.dialCode;
   var alphn = this.alphn.getSelectedCountryData().dialCode;
   var codeLength= alphn.length+1;
     console.log(selectedCountryData2);
   console.log(alphn)
   console.log('******',)
   this.vendor_alternate_phone_number = selectedCountryData2.substring(codeLength);
 console.log('******',this.vendor_alternate_phone_number)

    //////////////////////////////////////////////
    var params = {
      vendor_id: this.vendorId,
      name: this.name,
      email: this.email,
      country_id: this.country,
      state_id: this.state,
      city_id: this.city,
      property_id: this.condoName,
      contact_no: this.phn_no,
      business_name : this.business_name,
      business_address : this.business_address,
      business_phone_number : this.business_phone_number,
      business_phone_number_country_code : '+' + resphone,
      business_alternate_phone_number : this.business_alternate_phone_number,
      business_alternate_phone_number_country_code: '+' + resalno,
      business_email: this.business_email,
      vendor_alternate_phone_number: this.vendor_alternate_phone_number,
      vendor_alternate_phone_number_country_code: '+' + alphn,
      suite_no:this.suite_no,
      postal_code:this.postal_code,
      country_code: '+' + phone
    }
    
    



    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var rem = /(^[0-9]{10}$)/;
    if (!rem.test(this.business_phone_number)) {
      this.toastr.error('Business phone number should contain 10 digits.Please enter valid phone no.');
    }
    else if (this.business_alternate_phone_number!= '' && !rem.test(this.business_alternate_phone_number)) {
      this.toastr.error('Business whatsapp number should contain 10 digits. Please enter valid number.');
    }
    else if (!re.test(this.business_email)) {
      this.toastr.error('Please enter valid business email');
      // this.emailValidation = true;
    }
    else if (!rem.test(this.phn_no)) {
      this.toastr.error('Vendor phone number should contain 10 digits. Please enter valid phone no. ');
    }
    else if (this.vendor_alternate_phone_number!= '' && !rem.test(this.vendor_alternate_phone_number)) {
      this.toastr.error('Vendor whatsapp number should contain 10 digits. Please enter valid number. ');
    }
    else if (!re.test(this.email)) {
      this.toastr.error('Please enter valid vendor email');
      // this.emailValidation = true;
    }
    else {
      this.spinner.show();
      this.webservice.adminPostData('updateVendor', params).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.toastr.success(result.msg);
        } else {
          this.toastr.error(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

  onChangeCondoName(val) {
    this.condoName = val;
  }
}


