import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../webservice.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  UserData: any;
  edit_feedback: any;
  email:any=''
  _id:any=''
  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      // this.edit_feedback = params['id']; //for edit we need to define in routinh and the same name in params
      // console.log("Data:",this.edit_flashdeal);
    });
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData);

    this.detail();
  }
  detail() {
    this.spinner.show();
    this.webservice.adminGetData('getAdminEmail').then((result: any) => {
      // console.log("data:",result);
      if (result.status == 1) {
      
        this.email= result.data.email //for update needed id to be define
      
      } else {
        console.log(result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  onUpdate() {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.email == '') {
      this.toastr.error('Please enter email');
    } 
    else if (!re.test(this.email)) {
      this.toastr.error('Please enter valid email')
    } 
    // else if (this.amount == '') {
    //   this.toastr.error('Please enter Amount');
    // } 
    else {
      var params = {
        email: this.email,

      }
      this.spinner.show();
      this.webservice.adminPostData('updateAdminEmail', params).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.toastr.success(result.msg);
          // this.email= '';

        } else {
          this.toastr.error(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

}


