import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { WebserviceService } from 'src/app/webservice.service';

@Component({
  selector: 'app-manageservice',
  templateUrl: './manageservice.component.html',
  styleUrls: ['./manageservice.component.scss']
})
export class ManageserviceComponent implements OnInit {
  public serviceList: any;
  public user_type: any;
  UserData: any;
  data: any;
  property_manager_id: any;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData);
    this.user_type = this.UserData.user_type;
    if (this.UserData.user_type == 0) {
      this.property_manager_id = "no_property_manager";
    } else {
      this.property_manager_id = this.UserData._id;
    }
    this.spinner.show();
    this.webservice.adminGetData('serviceList/'+ this.property_manager_id).then((result) => {
      this.data = result;
      console.log(this.data);
      if(this.data.status == 1) {
        this.serviceList = this.data.data;
        // this.residentList = this.data.data;
      } else {
        console.log(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  }


