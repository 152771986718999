import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebserviceService } from 'src/app/webservice.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
declare var intlTelInput: any;
@Component({
  selector: 'app-residentedit',
  templateUrl: './residentedit.component.html',
  styleUrls: ['./residentedit.component.scss']
})
export class ResidenteditComponent implements OnInit {
  edit_resident: any;
  UserData: any;
  residentStatus: any = '';

  fName: any = '';
  lName: any = '';
  bDate: any = '';
  bMonth: any = '';
  bYear: any = '';
  gender: any = '';
  email: any = '';
  cellNumber: any = '';
  country: any = '';
  state: any = '';
  city: any = '';
  condoName: any = '';
  suiteNo: any = '';
  address: any = '';
  postalCode: any = '';
  password: any = '';
  cPassword: any = '';
  eName: any = '';
  eEmail: any = '';
  eCellNo: any = '';
  eRelation: any = '';
  countryList: any = [];
  cityList: any = [];
  stateList: any = [];
  consoNameList: any = [];
  birthDate = [{ date: '1' }, { date: '2' }, { date: '3' }, { date: '4' }, { date: '5' }, { date: '6' }, { date: '7' }, { date: '8' }, { date: '9' }, { date: '10' }, { date: '11' }, { date: '12' }, { date: '13' }, { date: '14' }, { date: '15' }, { date: '16' }, { date: '17' }, { date: '18' }, { date: '19' }, { date: '20' }, { date: '1' }, { date: '21' }, { date: '22' }, { date: '23' }, { date: '24' }, { date: '25' }, { date: '26' }, { date: '27' }, { date: '28' }, { date: '29' }, { date: '30' }, { date: '31' }];
  birthMonth = [{ month: 'Jan' }, { month: 'Feb' }, { month: 'Mar' }, { month: 'Apr' }, { month: 'May' }, { month: 'Jun' }, { month: 'Jul' }, { month: 'Aug' }, { month: 'Sep' }, { month: 'Oct' }, { month: 'Nov' }, { month: 'Dec' },];
  birthYear: any = [];
  eReletation: any;
  homeno: any = '';
  
  cellNumberValidation: boolean = false;
  homenoValidation: boolean = false;
  eCellNoValidation: boolean = false;
  adult_status:any='';
  country_code:any = '';
  homeCountryCode:any = '';
  contactCountryCode:any = '';


  phone: any;
  whatsapp: any;
  emergency: any;
  shortcountrycode:any;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute, ) {
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log("this.UserData", this.UserData);
    setTimeout(() => {
      this.getcountry();
    },500);
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.edit_resident = params['resident_id'];
      console.log(this.edit_resident);
    });
    for (var i = (new Date()).getFullYear() - 100; i <= (new Date()).getFullYear(); i++) {
      this.birthYear.push({ year: i });
    }
    this.residentDetails();
    setTimeout(() => {
      this.phone = intlTelInput(document.querySelector("#Phone"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });
     
      this.whatsapp = intlTelInput(document.querySelector("#whatsapp"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });
      this.emergency = intlTelInput(document.querySelector("#emergency"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });
  
      

    },100);
    

}
getcountry(){
  this.webservice.getcountry().subscribe((res:any)=>{  
    //this.ipAddress=res.ip;  
    var short_code= res.countryCode
    this.shortcountrycode = (short_code).toLowerCase();
    console.log('country===========',this.shortcountrycode)
  }); 
}
  hasError(obj) {
    console.log(obj);
  }

  onCountryChange(obj) {
    console.log(obj);
    this.country_code= obj.dialCode;
    console.log(this.country_code);
  }
  onCountryChange1(obj) {
    console.log(obj);
    this.homeCountryCode= obj.dialCode;
    console.log(this.homeCountryCode);
  }
  onCountryChange2(obj) {
    console.log(obj);
     this.contactCountryCode= obj.dialCode;
    console.log(this.contactCountryCode);
  }
  getNumber(obj) {
    console.log(obj);
  }

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry('ca');
  }


  residentDetails() {
    this.spinner.show();
    this.webservice.adminGetData('residentById/' + this.edit_resident).then((result: any) => {
      if (result.status == 1) {
        this.country = result.data.country_id;
        this.state = result.data.state_id;
        this.city = result.data.city_id;
        this.email = result.data.email;
        this.condoName = result.data.property_id;
        this.fName = result.data.name.split(' ').slice(0, -1).join(' ');
        this.lName = result.data.name.split(' ').slice(-1).join(' ');
        this.cellNumber = result.data.contact_no;
        this.password = result.data.password;
        this.address = result.data.address;
        this.suiteNo = result.data.suite_no;
        this.postalCode = result.data.postal_code;
        this.homeno = result.data.home_no;
        this.gender = result.data.gender;
        this.bDate = result.data.b_date;
        this.bMonth = result.data.birth_month;
        this.bYear = result.data.b_year;
        this.eReletation = result.data.emergency_contact_relationship;
        this.eCellNo = result.data.emergency_contact_phone_no;
        this.eEmail = result.data.emergency_contact_email;
        this.eName = result.data.emergency_contact_name;
        this.bMonth = result.data.birth_month;
        this.residentStatus = result.data.resident_type;
        this.adult_status= result.data.adult_status;
        this.country_code = result.data.country_code;
        this.homeCountryCode = result.data.homeCountryCode;
        this.contactCountryCode = result.data.contactCountryCode;

        this.phone.setNumber(result.data.country_code+result.data.contact_no);
        this.whatsapp.setNumber(result.data.homeCountryCode+result.data.home_no);
        this.emergency.setNumber(result.data.contactCountryCode+result.data.emergency_contact_phone_no);

        this.allCountryList();
        this.onAllState();
        this.onAllCity();
        this.onAllProperty();
      } else {
        console.log(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

  }




  onUpdate() {
    var selectedCountryData = this.phone.getNumber();
    // var dialCode = selectedCountryData.dialCode;
     var phone = this.phone.getSelectedCountryData().dialCode;
     var codeLength= phone.length+1;
       console.log(selectedCountryData);
     console.log(phone)
     console.log('******',)
     this.cellNumber = selectedCountryData.substring(codeLength);
   console.log('******',this.cellNumber)
   /////////////////////////////////
   var selectedCountryData1 = this.whatsapp.getNumber();
   // var dialCode = selectedCountryData.dialCode;
    var whatsapp = this.whatsapp.getSelectedCountryData().dialCode;
    var codeLength= whatsapp.length+1;
      console.log(selectedCountryData1);
    console.log(whatsapp)
    console.log('******',)
    this.homeno = selectedCountryData1.substring(codeLength);
  console.log('******',this.homeno)
  //////////////////////////////////
  var selectedCountryData2 = this.emergency.getNumber();
  // var dialCode = selectedCountryData.dialCode;
   var emergency = this.emergency.getSelectedCountryData().dialCode;
   var codeLength= emergency.length+1;
     console.log(selectedCountryData2);
   console.log(emergency)
   console.log('******',)
   this.eCellNo = selectedCountryData2.substring(codeLength);
 console.log('******',this.eCellNo)

  let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var rem = /(^[0-9]{10}$)/;
    if (this.fName == '') {
      this.toastr.error('Please enter resident first name');
    } else if (this.lName == '') {
      this.toastr.error('Please enter resident last name');
    } 
    else if (this.country == '') {
      this.toastr.error('Please select country');
    } else if (this.state == '') {
      this.toastr.error('Please select state');
    } else if (this.city == '') {
      this.toastr.error('Please select city');
    }
     else if (this.condoName == '') {
      this.toastr.error('Please select property name');
    }
    else if (this.address == '') {
      this.toastr.error('Please enter address');
    } 
    // else if (this.postalCode == '') {
    //   this.toastr.error('Please enter postal code');
    // }
    else if (this.cellNumber == '') {
      this.toastr.error('Please enter phone number');
    } 
    else if (!rem.test(this.cellNumber)) {
      this.toastr.error('Phone number should contain 10 digits. Please enter valid phone number. ');
    } 
    else if (this.homeno !== '' && !rem.test(this.homeno))  {
      this.toastr.error('Whatsapp number should contain 10 digits. Please enter valid phone number. ');
  } 
    // else if (this.bDate == '' || this.bMonth == '' || this.bYear == '') {
    //   this.toastr.error('Please select birthday');
    // } 
    // else if (this.gender == '') {
    //   this.toastr.error('Please select gender');
    // }
     else if (this.email == '') {
      this.toastr.error('Please enter email');
    } else if (!re.test(this.email)) {
      this.toastr.error('Please enter valid email')
    } 
 
 
    //  else if (this.suiteNo == '') {
    //   this.toastr.error('Please enter suite number');
    // } 
   
   
    

    else if (this.eCellNo !== '' && !rem.test(this.eCellNo)) {
      
        this.toastr.error('Emergency number should contain 10 digits.Please enter valid phone number. ');
      
    } 
    else if (this.eEmail !== '' && !re.test(this.eEmail)){
      this.toastr.error('Enter valid emergency email. ');
    
      
  }
    else if (this.residentStatus == '') {
      this.toastr.error('Please select status');
    }
    
    else {
      var params = {
        country_id: this.country,
        state_id: this.state,
        city_id: this.city,
        email: this.email,
        property_id: this.condoName,
        name: this.fName + ' ' + this.lName,
        contact_no: this.cellNumber,
        address: this.address,
        street_no: '',
        suite_no: this.suiteNo,
        street_name: '',
        postal_code: this.postalCode,
        home_no: this.homeno,
        gender: this.gender,
        birthday: this.bMonth + '-' + this.bDate + '-' + this.bYear,
        emergency_contact_relationship: this.eReletation,
        emergency_contact_phone_no: this.eCellNo,
        emergency_contact_email: this.eEmail,
        emergency_contact_name: this.eName,
        birth_month: this.bMonth,
        resident_type: this.residentStatus,
        resident_id: this.edit_resident,
        adult_status:this. adult_status,
        country_code : '+' + phone,        
        homeCountryCode: '+' + whatsapp,
        contactCountryCode: '+' + emergency,

      }
      console.log(params);
      this.spinner.show();
          this.webservice.adminPostData('updateResident', params).then((result: any) => {
            if (result.status == 1) {
              this.toastr.success(result.msg);
            } else {
              this.toastr.error(result.msg);
            }
            this.spinner.hide();
          }, (err) => {
            this.spinner.hide();
            console.log(err);
          });
    }

  }

  allCountryList() {
    this.webservice.adminGetData('countryList').then((result: any) => {
      if (result.status == 1) {
        this.countryList = result.data;
        console.log('countryList: ', this.countryList);
      } else {
        console.log(result.msg);
      }
    }, (err) => {
      console.log(err);
    });

  }

  onAllState() {
    this.webservice.adminGetData('stateList/' + this.country).then((result: any) => {
      if (result.status == 1) {
        this.stateList = result.data;
      } else {
        console.log(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  onAllCity() {
    if (this.state != '') {
      this.webservice.adminGetData('cityList/' + this.state).then((result: any) => {
        if (result.status == 1) {
          this.cityList = result.data;
        } else {
          console.log(result.msg);
        }
      }, (err) => {
        console.log(err);
      });
    }

  }
  onAllProperty() {
    if (this.city != '') {
      this.webservice.adminGetData('propertyListByCity/' + this.city).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.consoNameList = result.data;
        } else {
          console.log(result.msg);
        }
      }, (err) => {
        console.log(err);
      });

    }

  }

  onChangeDate(val) {
    this.bDate = val;
  }
  onChangeMonth(val) {
    this.bMonth = val;
  }
  onChangeYear(val) {
    this.bYear = val;
  }
  onChangeGender(val) {
    this.gender = val;
  }

  onAdult(val){
    this.adult_status = val;
  }

  OnChangeCountry(val) {
    this.country = val;
    console.log(this.country)
    if (this.country != '') {
      this.webservice.adminGetData('stateList/' + this.country).then((result: any) => {
        if (result.status == 1) {
          this.stateList = result.data;
        } else {
          console.log(result.msg);
        }
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

  OnChangeState(val) {
    this.state = val;
    console.log(this.state)
    if (this.state != '') {
      this.webservice.adminGetData('cityList/' + this.state).then((result: any) => {
        if (result.status == 1) {
          this.cityList = result.data;
        } else {
          console.log(result.msg);
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

  onChangeCity(val) {
    this.city = val;
    console.log(this.city)
    if (this.city != '') {
      this.webservice.adminGetData('propertyListByCity/' + this.country + '/' + this.state + '/' +  this.city).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.consoNameList = result.data;
        } else {
          console.log(result.msg);
        }
      }, (err) => {
        console.log(err);
      });

    }
  }

  onChangeCondoName(val) {
    this.condoName = val
  }

  onChangeReletation(val) {
    console.log(val)
    if (val == 'friend') {
      this.eReletation = 'friend';
    } else {
      this.eReletation = 'family';
    }
  }


  onChangeResidentStatus(val) {
    this.residentStatus = val;
  }

}
