import { Component, OnInit } from '@angular/core';
import { WebserviceService } from 'src/app/webservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-propertymanageradd',
  templateUrl: './propertymanageradd.component.html',
  styleUrls: ['./propertymanageradd.component.scss'],
})
export class PropertymanageraddComponent implements OnInit {
  UserData: any;
  fName: any = '';
  lName: any = '';
  bDate: any = '';
  bMonth: any = '';
  bYear: any = '';
  gender: any = '';
  email: any = '';
  cellNumber: any = '';
  country: any = '';
  state: any = '';
  city: any = '';
  condoName: any = '';
  suiteNo: any = '';
  address: any = '';
  postalCode: any = '';
  password: any = '';
  cPassword: any = '';
  eName: any = '';
  eEmail: any = '';
  eCellNo: any = '';
  eRelation: any = '';
  countryList: any = [];
  cityList: any = [];
  stateList: any = [];
  consoNameList: any = [];
  birthDate = [
    { date: '1' },
    { date: '2' },
    { date: '3' },
    { date: '4' },
    { date: '5' },
    { date: '6' },
    { date: '7' },
    { date: '8' },
    { date: '9' },
    { date: '10' },
    { date: '11' },
    { date: '12' },
    { date: '13' },
    { date: '14' },
    { date: '15' },
    { date: '16' },
    { date: '17' },
    { date: '18' },
    { date: '19' },
    { date: '20' },
    { date: '1' },
    { date: '21' },
    { date: '22' },
    { date: '23' },
    { date: '24' },
    { date: '25' },
    { date: '26' },
    { date: '27' },
    { date: '28' },
    { date: '29' },
    { date: '30' },
    { date: '31' },
  ];
  birthMonth = [
    { month: 'Jan' },
    { month: 'Feb' },
    { month: 'Mar' },
    { month: 'Apr' },
    { month: 'May' },
    { month: 'Jun' },
    { month: 'Jul' },
    { month: 'Aug' },
    { month: 'Sep' },
    { month: 'Oct' },
    { month: 'Nov' },
    { month: 'Dec' },
  ];
  birthYear: any = [];
  eReletation: any;

  // subDate: any = '';
  subAmount: any = '';
  homeno: any = '';

  propertyManagementCompany: any = '';
  cellNumberValidation: boolean = false;
  homenoValidation: boolean = false;
  eCellNoValidation: boolean = false;
  adult_status: any = '';
  country_code: any = '';
  homeCountryCode: any = '';
  contactCountryCode: any = '';

  visible: boolean = true;
  changetype: boolean = true;

  visible1: boolean = true;
  changetype1: boolean = true;

  constructor(
    public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) {
    for (
      var i = new Date().getFullYear() - 100;
      i <= new Date().getFullYear();
      i++
    ) {
      this.birthYear.push({ year: i });
    }
    this.allCountryList();
  }

  ngOnInit(): void {
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData);
  }
  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }
  viewpass1() {
    this.visible1 = !this.visible1;
    this.changetype1 = !this.changetype1;
  }

  hasError(obj) {
    console.log(obj);
  }

  onCountryChange(obj) {
    console.log(obj);
    this.country_code = '+' + obj.dialCode;
    console.log(this.country_code);
  }
  onCountryChange1(obj) {
    console.log(obj);

    this.homeCountryCode = '+' + obj.dialCode;

    console.log(this.homeCountryCode);
  }
  onCountryChange2(obj) {
    console.log(obj);

    this.contactCountryCode = '+' + obj.dialCode;
    console.log(this.contactCountryCode);
  }
  getNumber(obj) {
    console.log(obj);
  }

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry('ca');
  }

  allCountryList() {
    this.webservice.adminGetData('countryList').then(
      (result: any) => {
        if (result.status == 1) {
          this.countryList = result.data;
          console.log('countryList: ', this.countryList);
        } else {
          console.log(result.msg);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onAdd() {
    let re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var rem = /(^[0-9]{10}$)/;
    var pass = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9@$!%*?&]{8,})$/;

    console.log('code', this.country_code);

    if (this.fName == '') {
      this.toastr.error('Please enter property manager first name');
    } else if (this.lName == '') {
      this.toastr.error('Please enter property manager last name');
    }
    // else if (this.bDate == '' || this.bMonth == '' || this.bYear == '') {
    //   this.toastr.error('Please select birthday');
    // }
    // else if (this.gender == '') {
    //   this.toastr.error('Please select gender');
    // }
    else if (this.country == '') {
      this.toastr.error('Please select country');
    } else if (this.state == '') {
      this.toastr.error('Please select state');
    } else if (this.city == '') {
      this.toastr.error('Please select city');
    } else if (this.condoName == '') {
      this.toastr.error('Please select property name');
    }
    //  else if (this.suiteNo == '') {
    //   this.toastr.error('Please enter suite number');
    // }
    else if (this.address == '') {
      this.toastr.error('Please enter address');
    }
    // else if (this.postalCode == '') {
    //   this.toastr.error('Please enter postal code');
    // }
    else if (this.cellNumber == '') {
      this.toastr.error('Please enter phone number');
    } else if (!rem.test(this.cellNumber)) {
      this.toastr.error(
        'Phone number should contain 10 digits. Please enter valid phone number. '
      );
    } else if (this.homeno !== '' && !rem.test(this.homeno)) {
      this.toastr.error(
        'Whatsapp number should contain 10 digits. Please enter valid number. '
      );
    } else if (this.email == '') {
      this.toastr.error('Please enter email');
    } else if (!re.test(this.email)) {
      this.toastr.error('Please enter valid email');
    }

    //  else if (this.homeno !== '') {
    //   if (!rem.test(this.homeno)) {
    //     this.toastr.error('Alternate Number Should contain 10 digits. Please enter Valid Phone No. ');
    //   }
    // }
    else if (this.password == '') {
      this.toastr.error('Please enter password');
    } else if (!pass.test(this.password)) {
      this.toastr.error(
        'Passwords must be atleast 8 characters long with a combination of uppercase and lowercase letters and atleast one number'
      );
    } else if (this.cPassword == '') {
      this.toastr.error('Please enter confirm password');
    } else if (this.password != this.cPassword) {
      this.toastr.error('Confirm password does not match the password');
    } 
    // else if (this.subAmount == '') {
    //   this.toastr.error('Please enter subscription amount');
    // }
    // else if (this.subDate == '') {
    //   this.toastr.error('Please enter subscription date');
    // }
    //   else if (this.propertyManagementCompany == '') {
    //     this.toastr.error('Please enter Property Management Company ');
    // }
    else if (this.eCellNo !== '' && !rem.test(this.eCellNo)) {
      this.toastr.error(
        'Emergency number should contain 10 digits. Please enter valid phone number. '
      );
    } else if (this.eEmail !== '' && !re.test(this.eEmail)) {
      this.toastr.error('Enter valid emergency email. ');
    } else {
      var params = {
        country_id: this.country,
        state_id: this.state,
        city_id: this.city,
        email: this.email,
        property_id: this.condoName,
        name: this.fName + ' ' + this.lName,
        contact_no: this.cellNumber,
        password: this.password,
        address: this.address,
        street_no: '',
        suite_no: this.suiteNo,
        street_name: '',
        postal_code: this.postalCode,
        home_no: this.homeno,
        gender: this.gender,
        // birthday: this.bMonth + '-' + this.bDate + '-' + this.bYear,
        emergency_contact_relationship: this.eReletation,
        emergency_contact_phone_no: this.eCellNo,
        emergency_contact_email: this.eEmail,

        emergency_contact_name: this.eName,
        // birth_month: this.bMonth,
        subscription_amount: this.subAmount,
        // subscription_due_date: this.subDate,
        property_management_company: this.propertyManagementCompany,
        adult_status: this.adult_status,
        country_code: this.country_code,
        homeCountryCode: this.homeCountryCode,
        contactCountryCode: this.contactCountryCode,
      };
      console.log(params);
      this.spinner.show();
      this.webservice.adminPostData('savePropertyManager', params).then(
        (result: any) => {
          if (result.status == 1) {
            this.toastr.success(result.msg);
            this.country = '';
            this.state = '';
            this.city = '';
            this.email = '';
            this.condoName = '';
            this.fName = '';
            this.lName = '';
            this.cellNumber = '';
            this.password = '';
            this.address = '';
            this.suiteNo = '';
            this.postalCode = '';
            this.homeno = '';
            this.gender = '';
            // this.bMonth = '';
            // this.bDate = '';
            // this.bYear = '';
            this.eReletation = '';
            this.cellNumber = '';
            this.eEmail = '';
            this.eName = '';
            this.subAmount = '';
            // this.subDate = '';
            this.eCellNo = '';
            this.cPassword = '';
            this.propertyManagementCompany = '';
            this.country_code = '';
            this.homeCountryCode = '';
            this.contactCountryCode = '';
            this.adult_status = '';
            this.router.navigate(['/main/property-manager']);
          } else {
            this.toastr.error(result.msg);
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
        }
      );
    }
  }

  // onChangeDate(val) {
  //   this.bDate = val;
  // }
  // onChangeMonth(val) {
  //   this.bMonth = val;
  // }
  // onChangeYear(val) {
  //   this.bYear = val;
  // }
  onChangeGender(val) {
    this.gender = val;
  }

  onAdult(val) {
    this.adult_status = val;
  }

  OnChangeCountry(val) {
    this.state = ''; // Reset state
  this.city = ''; // Reset city
  this.stateList = []; // Reset state list
  this.cityList = []; // Reset city list
  this.consoNameList = []; // Reset consoName list
    this.country = val;
    console.log(this.country);
    if (this.country != '') {
      this.spinner.show();
      this.webservice.adminGetData('stateList/' + this.country).then(
        (result: any) => {
          if (result.status == 1) {
            this.stateList = result.data;
          } else {
            console.log(result.msg);
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
        }
      );
    }
  }

  OnChangeState(val) {
    this.city = ''; // Reset city
  this.cityList = []; // Reset city list
  this.consoNameList = []; // Reset consoName list
    this.state = val;
    console.log(this.state);
    if (this.state != '') {
      this.webservice.adminGetData('cityList/' + this.state).then(
        (result: any) => {
          if (result.status == 1) {
            this.cityList = result.data;
          } else {
            console.log(result.msg);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  onChangeCity(val) {
   
    this.consoNameList = []; // Reset consoName list
    this.city = val;
    console.log(this.city);
    if (this.city != '') {
      this.webservice.adminGetData('propertyListByCity/' + this.country+'/' +this.state + '/'+ this.city) .then(
        (result: any) => {
          console.log(result);
          if (result.status == 1) {
            this.consoNameList = result.data;
          } else {
            console.log(result.msg);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  onChangeCondoName(val) {
    this.condoName = val;
  }

  onChangeReletation(val) {
    console.log(val);
    if (val == 'friend') {
      this.eReletation = 'friend';
    } else {
      this.eReletation = 'family';
    }
  }
}
