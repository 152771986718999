<div class="content">
  <div class="body">
    <h1>Admin</h1>
  </div>
  <div>
    <input
      [(ngModel)]="email"
      type="email"
      class="form-control"
      placeholder="Email Address"
    />
    <p *ngIf="validationemailerror == true">Enter valid email</p>
  </div>

  <div>
    <input
      [(ngModel)]="password"
      type="text"
      [type]="changetype?'password':'text'"
      class="form-control"
      placeholder="Password"

    />
    <span class="eyeicon" (click)="viewpass()"><i [ngClass]="visible?'bi bi-eye-slash-fill':'bi bi-eye-fill'"></i>
    </span>
  </div>
  <!-- <p *ngIf="error">
    Password does not match with the given email
  </p> -->
  <div class="inpbtn_wrap">
    <button
      type="button"
      class="btn defbtn"
      *ngIf="email == '' || password == ''"
      [disabled]="email == '' || password == ''"
      type="submit"
      class="btn defbtn"
      style="cursor: no-drop;"
    >
      Login
    </button>
  </div>
  <div class="inpbtn_wrap">
    <button
      type="button"
      class="btn defbtn"
      *ngIf="email != '' &&
      password != ''"
      type="submit"
      class="btn defbtn"
      (click)="onLogin();"
      (keydown.enter)="onLogin()"
    >
      Login
    </button>
  </div>
</div>
