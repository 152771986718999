import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../webservice.service';

@Component({
  selector: 'app-edit-flash-deal-charges',
  templateUrl: './edit-flash-deal-charges.component.html',
  styleUrls: ['./edit-flash-deal-charges.component.scss']
})
export class EditFlashDealChargesComponent implements OnInit {
  UserData: any;
  title: any = '';
  amount: any = '';
  subscription_id: any = '';
  edit_flashdeal: any;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.edit_flashdeal = params['id']; //for edit we need to define in routinh and the same name in params
      // console.log("Data:",this.edit_flashdeal);
    });
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData);

    this.detail();
  }
  detail() {
    this.spinner.show();
    this.webservice.adminGetData('subscriptionById/' + this.edit_flashdeal).then((result: any) => {
      // console.log("data:",result);
      if (result.status == 1) {
        this.title = result.data.title;
        this.amount = result.data.amount;
        this.subscription_id = result.data._id //for update needed id to be define
      } else {
        console.log(result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  onUpdate() {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.title == '') {
      this.toastr.error('Please enter first name');
    } else if (this.amount == '') {
      this.toastr.error('Please enter Amount');
    } else {
      var params = {
        title: this.title,
        amount: this.amount,
        subscription_id: this.subscription_id //for update id is call

      }
      this.spinner.show();
      this.webservice.adminPostData('updateVendorSubscription', params).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.toastr.success(result.msg);
          this.router.navigate(['/main/flash-deal-charges']);
          this.title = '';
          this.amount = '';
          this.subscription_id = ''; //for update id is call

        } else {
          this.toastr.error(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

}









