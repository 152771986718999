import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';
declare var $: any;

@Component({
  selector: 'app-dailydeals',
  templateUrl: './dailydeals.component.html',
  styleUrls: ['./dailydeals.component.scss'],
})
export class DailydealsComponent implements OnInit {


  page:number = 1;
  count:number = 0;
  tableSize:number = 4;
  tableSizes:any = [5,10,15,20];

  data: any;
  public list: any = [];
  user_type: any;
  UserData: any;
  search: any = '';
  logintype: any = '';
  paymentstaus:boolean;

  cancelClicked: true;
  popoverTitle = 'Delete!';
  popoverMessage = 'If you want to delete this property, it will automatically delete all the data related this property. Are you sure you want to delete?';
  confirmClicked = false;
  
  constructor(
    public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    // this.postList();

    
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData);
    let logintype = '';
    if (this.UserData.user_type == 0 ) {
      this.logintype = 'admin';
      this.paymentstaus = true; //for payment
     
      
    } 
    else {
      this.logintype = this.UserData._id;
      // console.log("you are a vendor")
      this.checkpaymentstatus();
  
      
    }
    this.user_type = this.UserData.user_type;
    this.spinner.show();
    this.webservice
      .adminGetData('dailydealList/' + this.UserData._id + '/undefined')
      .then(
        (result) => {
          this.data = result;
          console.log(this.data);

          if (this.data.status == 1) {
            this.list = this.data.data;
            // if (this.data.data.length == 0) {
            //   this.router.navigate(['/main/pay']);
            // }
            // modal
            // if(this.data.length == 0){
            //   this.router.navigate(['/main/pay']);

            // }
            // else(this.data.data!= " ");
            // {
            //   console.log("hh",this.data.data)
            //   // this.router.navigate(['/main/add-dailydeals']);
            // }
          } else {
            console.log(this.data.msg);
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
        }
      );
  }

 

  checkpaymentstatus(){
    let data={
      'userId' : this.UserData._id
    }
    // this.webservice.adminGetData('chkVendorPayment/' + this.UserData._id).then((response:any)=>{
      this.webservice.WebgetData('getUserSubscriptionDetails/' + this.UserData._id).then((response:any)=>{
        console.log(response['data'].length)
      if(response['data'].length == 0){
        this.paymentstaus = false;
        console.log('no payment',this.paymentstaus)
      }else if(response['data'].length == 1){
        
        for (let i = 0; i < response['data'].length; i++) {
          console.log(response['data'][i].productName)
            if(response['data'][i].productName=='flash_deal'){
            this.paymentstaus = true;
            console.log('payment done1111',this.paymentstaus)
          }else{
            this.paymentstaus = false;
            console.log('payment done22',this.paymentstaus)
          }
        }
      }else if(response['data'].length == 2){
        this.paymentstaus = true;
      }
      console.log(response.data)

      // if(response.data=='Not Subscribed'){
      //   this.paymentstaus = false;
      // }else{
      //   this.paymentstaus = true;
      // }
    })
  }


  onSearch(value) {
    this.page = 1;
    let logintype = '';
    if (this.UserData.user_type == 0) {
      logintype = 'admin';
    } else {
      logintype = this.UserData._id;
    }
    this.search = value;
    if (this.search != '') {
      this.spinner.show();
      this.webservice
        .adminGetData('dailydealList/' + this.logintype + '/' + value)
        .then(
          (result) => {
            this.data = result;
            console.log(this.data);
            if (this.data.status == 1) {
              this.list = this.data.data;
            } else {
              console.log(this.data.msg);
            }
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            console.log(err);
          }
        );
    } else {
      this.ngOnInit();
    }
  }

  // postList():void{
  //   this.webservice.adminGetData('dailydealList/undefined').then((result)=>{
  //    this.data = result;
  //    console.log("pagination:",this.data);
  //   })
  // }
  onTableDataChange(event:any){
    this.page = event ;
    // this.postList();
  }
  onTableSizeChange(event:any): void{
    this.tableSize = event.target.value;
    this.page = 1 ;
    // this.postList();
  }

  onEdit() {
    // this.router.navigate(['/main/test'])
  }
  // openModal(){
  //   $('#exampleModal').modal('show');
  // }
  // closeModal(){
  //   $('#exampleModal').modal('hide');
  // }
  onDelete(_id) {
    var params = {
      daily_deal_id: _id
    }
    this.webservice.adminPostData('deleteDailyDeals', params).then((result: any) => {
      if (result.status == 1) {
        this.toastr.success(result.msg);
        this.ngOnInit();
        
      } else {
        this.toastr.error(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
}

