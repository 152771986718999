import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-dailydealsa-add',
  templateUrl: './dailydealsa-add.component.html',
  styleUrls: ['./dailydealsa-add.component.scss']
})
export class DailydealsaAddComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('fileInputs') fileInputs!: ElementRef;
  currentDate: any = new Date();
  UserData: any;
  data: any;
  propertymanagetlist: any;
  property: any = [];
  fromTime: any = '';
  toTime: any = '';
  title: any = '';
  description: any = '';
  image: any = '';
  file: any;
  startDate: any = '';
  endDate: any = '';
  PDFfile: any;
  Pdf_prev: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings;
  website: any = '';
  merchant_name: any = '';
  subject: any = '';
  merchant_address: any = '';
  phone_no: any = '';
  deal_price: any = '';
  discount: any = '';
  currency: any = '';
  countryList: any = [];
  stateList: any = [];
  citylist: any = [];
  country: any = '';
  city: any = '';
  state: any = '';
  discount_type: any = '';
  discount_percentage: any = '';
  // countryCode = '1';
  video_link: any = '';
  phone_noValidation: boolean = false;
  suite_no: any = '';
  postal_code: any = '';
  alternate_phone_no: any = '';
  alternate_phone_noValidation: boolean = false;
  country_code: any = '';
  alternate_phone_no_country_code: any = '';
  image_file_type: any;
  pdf_file_type: any;
  ecom_link: any = '';
  shortcountrycode: any = '';
  imageRemoved: boolean = false;
  pdf_enable: boolean = true;

  currencyArray = [
    { name: 'USD', value: '$' }, // US Dollar
    { name: 'CAD', value: 'C$' },// canada
    { name: 'EUR', value: '€' }, //Euro
    { name: 'CRC', value: '₡' }, //Costa Rican Colón
    { name: 'GBP', value: '£' },//British Pound Sterling
    { name: 'ILS', value: '₪' }, //// Israeli New Sheqel
    { name: 'INR', value: '₹' }, //Indian Rupee
    { name: 'JPY', value: '¥' }, // Japanese Yen
    { name: 'KRW', value: '₩' }, // South Korean Won
    { name: 'NGN', value: '₦' }, // Nigerian Naira
    { name: 'PHP', value: '₱' }, // Philippine Peso
    { name: 'PLN', value: 'zł' }, // Polish Zloty
    { name: 'PYG', value: '₲' }, // Paraguayan Guarani
    { name: 'THB', value: '฿' }, // Thai Baht
    { name: 'UAH', value: '₴' }, // Ukrainian Hryvnia
    { name: 'VND', value: '₫' }, // Vietnamese Don
    { name: 'AED', value: 'د.إ' },//dubai

  ];


  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.getcountry();
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData);
    this.ongetAllCountry();
    this.currencyArray.sort(function (a, b) {
      if (a.name > b.name)
        return 1
      if (a.name < b.name)
        return -1
      return 0;
    });




  }


  onItemSelect(item: any) {
    console.log(this.selectedItems);

  }
  onSelectAll(items: any) {
    console.log(this.selectedItems);
  }




  onFileChange(event) {

    if (!event.target.files.length) return; {
      this.file = event.target.files[0];
      if (this.file.type == "image/gif" || this.file.type == "image/jpeg" || this.file.type == "image/jpg" || this.file.type == "image/png") {
        this.image_file_type = event.target.files[0].type.split('/')[0]
        const reader = new FileReader();
        reader.onload = e => {
          //console.log(e.target.result)
          this.image = reader.result.toString();
          this.imageRemoved = false;
        };
        reader.readAsDataURL(this.file);
      } else {
        this.toastr.error("Invalid image format. Please upload jpeg,jpg or png format.")
      }
    }
  }

  onFileChangePDF(event) {

    if (!event.target.files.length) return; {
      this.PDFfile = event.target.files[0];
      //if (this.PDFfile.type == "application/pdf") {
        console.log(this.PDFfile)
        this.pdf_file_type = event.target.files[0].type.split('/')[0]
        let fd = new FormData();
        fd.append('deal_pdf', this.PDFfile);
        this.webservice.adminPostData('saveDailyDealsPDF', fd).then((result: any) => {
          console.log('pdf', result)
          //this.Pdf_prev=result.data;
          if (result.status == 1) {
            this.Pdf_prev = result.data;
            this.pdf_enable = true;
          } else {
            this.pdf_enable = false;
            this.toastr.error("Invalid file format. Please upload PDF.")

          }
        });


        const reader = new FileReader();
        reader.onload = e => {
          //console.log(e.target.result)
          // this.Pdf_prev = reader.result.toString();
        };
        reader.readAsDataURL(this.PDFfile);
        // }else{
        //   this.toastr.error("Invalid format. Please upload PDF.")
      //}
    }
  }

  ongetAllCountry() {
    this.spinner.show();
    this.webservice.adminGetData('countryList').then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.countryList = result.data;
      } else {
        console.log(result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  onCountryChange(val) {
    this.state = ''; // Reset state dropdown
    this.city = ''; // Reset city dropdown
    this.selectedItems = []; // Reset selected items in property dropdown


    this.country = val;
    console.log(val)
    // this.countryName = name;
    if (this.country != '') {
      this.spinner.show();
      this.webservice.adminGetData('stateList/' + this.country).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.stateList = result.data;
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }
  }
  onStateChange(val) {
    this.city = ''; // Reset city dropdown
    this.selectedItems = []; // Reset selected items in property dropdown


    this.state = val;
    // this.stateName = name
    if (this.state != '') {
      this.spinner.show();
      this.webservice.adminGetData('cityList/' + this.state).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.citylist = result.data;
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

  onCityChange(val) {
    this.selectedItems = []; // Reset selected items in property dropdown


    this.city = val;
    console.log('city: ', this.city)
    if (this.city != '') {
      this.webservice.adminGetData('propertyList/' + this.country + '/' + this.state + '/' + this.city).then((result) => {
        this.data = result;
        console.log("result", this.data);
        if (this.data.status == 1) {
          this.propertymanagetlist = this.data.data;
          this.dropdownList = this.propertymanagetlist;
          console.log("result", this.propertymanagetlist);
        } else {
          this.propertymanagetlist = this.data.data;
          this.dropdownList = this.propertymanagetlist;
          console.log(this.data.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });


      this.dropdownSettings = {
        singleSelection: false,
        idField: '_id',
        textField: 'property_details',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true,

      };

    }
  }
  onCurrencyChange(val) {
    this.currency = val
    console.log('===========', val)
  }
  changeDiscountType(discountType) {
    // console.log('discount_type===================', discountType)
    if (discountType === '1') {
      this.discount_percentage = ''
    } else {
      this.discount = ''
    }

  }

  hasError(obj) {
    console.log(obj);
  }

  onCountryCodeChange(obj) {
    console.log(obj);
    this.country_code = '+' + obj.dialCode
    console.log(this.country_code);
  }
  onCountryCodeChange1(obj) {
    console.log(obj);
    this.alternate_phone_no_country_code = '+' + obj.dialCode
    console.log(this.alternate_phone_no_country_code);
  }
  getNumber(obj) {
    console.log(obj);
  }


  telInputObject(obj) {
    console.log(obj);
    obj.setCountry('ca');
  }

  onAdd() {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var rem = /(^[0-9]{10}$)/;

    this.property = []
    for (var i = 0; i < this.selectedItems.length; i++) {
      this.property.push(this.selectedItems[i]._id)
    }
    if (this.property == null) {
      this.toastr.error('Please select property');
    }
    else if (!rem.test(this.phone_no)) {
      this.toastr.error('Phone number should contain 10 digits.Please enter valid phone number. ');
    }
    else if (this.alternate_phone_no != '' && !rem.test(this.alternate_phone_no)) {
      this.toastr.error('Whatsapp number should contain 10 digits.Please enter valid number. ');
    }

    else if (!re.test(this.subject)) {
      this.toastr.error('Please enter valid email');
      // this.emailValidation = true;
    }

    else if (this.title == '') {
      this.toastr.error('Please enter title');
    } else if (this.description == '') {
      this.toastr.error('Please enter description');
    } else if (this.startDate == '') {
      this.toastr.error('Please enter start date');
    } else if (this.endDate == '') {
      this.toastr.error('Please enter end date');
    }
    else if (this.startDate > this.endDate) {
      this.toastr.error('Choose proper end date');
    }
    else if (this.fromTime == '') {
      this.toastr.error('Please enter from time');
    }
    else if (this.toTime == '') {
      this.toastr.error('Please enter to time');
    }

    // else if (this.website == '') {
    //   this.toastr.error('Please enter website link');
    // } 
    else if (this.image == '') {
      this.toastr.error('Please select image');
    }
    else if (this.image_file_type != "image") {
      console.log('file======', this.image_file_type)

      this.toastr.error('Please upload correct image format');
    }
    // else if (this.pdf_file_type && this.pdf_file_type !== "application/pdf") {
    //   this.toastr.error('Please upload correct PDF file format');
    // }
    else {
      this.spinner.show();
      let fd = new FormData();
      fd.append('created_by_id', this.UserData._id);
      fd.append('created_by', this.UserData.name);
      fd.append('start_date', this.startDate);
      fd.append('end_date', this.endDate);
      fd.append('property_id', this.property);
      fd.append('title', this.title);
      fd.append('description', this.description);
      fd.append('from_time', this.fromTime);
      fd.append('to_time', this.toTime);
      fd.append('deal_image', this.file);
      fd.append('website_link', this.website);
      fd.append('pdf_file', this.Pdf_prev);
      fd.append('subject', this.subject);
      fd.append('merchant_name', this.merchant_name);
      fd.append('merchant_address', this.merchant_address);
      fd.append('phone_no', this.phone_no);
      fd.append('deal_price', this.deal_price);
      fd.append('currency', this.currency);
      fd.append('video_link', this.video_link);
      fd.append('suite_no', this.suite_no);
      fd.append('postal_code', this.postal_code);
      fd.append('alternate_phone_no', this.alternate_phone_no);
      fd.append('country_code', this.country_code);
      fd.append('alternate_phone_no_country_code', this.alternate_phone_no_country_code);
      fd.append('ecom_link', this.ecom_link);

      if (this.discount) {
        fd.append('discount', this.discount);
      } else {
        fd.append('discount_percentage', this.discount_percentage);
      }
      fd.append('discount_type', this.discount_type);
      fd.append('country_id', this.country);
      fd.append('state_id', this.state);
      fd.append('city_id', this.city);

      this.webservice.adminPostData('saveDailyDeals', fd).then((result) => {
        console.log(result);
        this.data = result;
        if (this.data.status == 1) {
          this.toastr.success(this.data.msg);
          this.title = '';
          this.description = '';
          this.property = [];
          this.fromTime = '';
          this.toTime = '';
          this.file = '';
          this.image = '';
          this.startDate = '';
          this.endDate = '';
          this.website = '';
          this.subject = '';
          this.merchant_address = '';
          this.merchant_name = '';
          this.phone_no = '';
          this.discount = '';
          this.deal_price = '';
          this.selectedItems = [];
          this.country = '';
          this.state = '';
          this.city = '';
          this.discount_type = '';
          this.discount_percentage = '';
          this.suite_no = '';
          this.postal_code = '';
          this.alternate_phone_no = '';
          this.country_code = '';
          this.alternate_phone_no_country_code = '';
          this.ecom_link = '';
          this.video_link = '';
          this.router.navigate(['/main/dailydeals']);

        }
        else if (this.data.status == 0) {
          this.toastr.error(this.data.msg);
        }

        else {
          this.toastr.error(this.data.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }

  }
  getcountry() {
    this.webservice.getcountry().subscribe((res: any) => {
      //this.ipAddress=res.ip;  
      this.shortcountrycode = res.countryCode;
      console.log('country===========', res)
    });
  }


  removeImage() {
    this.image = null;
    this.imageRemoved = true;
    this.clearFileInput();
  }

  private clearFileInput() {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
    this.file = null;
    this.image_file_type = null;
  }
 removePDF() {
    // Logic to remove the uploaded PDF
    this.Pdf_prev = null;
    this.PDFfile = null; // Optionally clear the selected file as well
    this.Pdf_prev = '';
    this.PDFfile = '';
    // Reset the input file element to clear the selected file
    if (this.fileInputs) {
      this.fileInputs.nativeElement.value = '';
    }
  }

}
