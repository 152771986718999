import { Component, OnInit } from '@angular/core';
import { WebserviceService } from 'src/app/webservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  currentPassword: any = '';
  newPassword: any = '';
  confirmPassword: any = '';
  data: any;
  visible:boolean = true;
  changetype:boolean =true;
  visible1:boolean = true;
  changetype1:boolean =true;
  visible2:boolean = true;
  changetype2:boolean =true;
  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
  }

  onChange() {
    let logtoken = JSON.parse(localStorage.getItem('LoginToken'));
    var user_id = logtoken._id;
    var pass = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9@$!%*?&]{8,})$/;
    var params = {
      currentpassword: this.currentPassword,
      newpassword: this.newPassword,
      confirmnewpassword: this.confirmPassword
    };
    if (this.newPassword != this.confirmPassword) {
      this.toastr.error('Confirm new password does not match');
    } 
    else if (!pass.test(this.newPassword)) {
      this.toastr.error('New password must be atleast 8 characters long with a combination of at least one uppercase letter, one lowercase letter, and one special character');
    } 
    else {
      this.spinner.show();
      this.webservice.adminPostData('changePassword/'+user_id, params).then((result) => {
        this.data = result;
        if (this.data.status == 1) {
          this.currentPassword = '';
          this.newPassword = '';
          this.confirmPassword = '';
          this.toastr.success(this.data.msg);
        } else {
          this.toastr.error(this.data.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }
  viewpass(){
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }
  viewpass1(){
    this.visible1 = !this.visible1;
    this.changetype1 = !this.changetype1;
  }
  viewpass2(){
    this.visible2 = !this.visible2;
    this.changetype2 = !this.changetype2;
  }

}
