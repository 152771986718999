import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { WebserviceService } from '../webservice.service';

@Component({
  selector: 'app-flashdealcharges',
  templateUrl: './flashdealcharges.component.html',
  styleUrls: ['./flashdealcharges.component.scss']
})
export class FlashdealchargesComponent implements OnInit {


  page:number = 1;
  count:number = 0;
  tableSize:number = 10;
  tableSizes:any = [5,10,15,20];

  public list: any = [];
  data:any;
  
  user_type: any;
  UserData: any;
  
  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute){}

    ngOnInit(): void {
      this.postList();
      this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData)
    let logintype = '';
    if(this.UserData.user_type == 0) {
      logintype = 'admin'
    } else {
      logintype = this.UserData._id
    }
     
      this.user_type = this.UserData.user_type;
      this.spinner.show();
      this.webservice.adminGetData('getVendorSubscriptionList').then((result) => {
        this.data = result;
        console.log(this.data);
        if(this.data.status == 1) {
          this.list = this.data.data;
          console.log("data:", this.list)
        } else {
          console.log(this.data.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
    postList():void{
      this.webservice.adminGetData('getVendorSubscriptionList').then((result)=>{
        this.data = result;
        console.log("pagination:",this.data);
      })
    }
    onTableDataChange(event:any){
      this.page = event ;
      this.postList();
    }
    onTableSizeChange(event:any): void{
      this.tableSize = event.target.value;
      this.page = 1 ;
      this.postList();
    }
    


}
