<div class="scroll_bown">
    <img src="assets/images/titleopt.png" alt="">

</div>

<div class="banner_section" style="background: url(assets/images/banner.png) no-repeat center top ; background-size: cover; ">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-7 ">
                <h2>Offer <strong> smart building </strong><span>technology to your residents</span></h2>
                <p>Developers and property managers who use EQ11 can provide a superior and simpler living experience to their residents. Our very goal is to do so by offering smart building technology.</p>
                <div class="storeinfo">
                    <a href="#">
                        For Managers
                    </a>
                    <!-- <a href="#" class="btn2">
                        Developers
                    </a> -->

                </div>
            </div>
        </div>
    </div>
</div>
