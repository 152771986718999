<!-- <p>dailydealsa-add works!</p> -->
<div class="content_box">
    <h2>Edit Resource</h2>

    <!-- <label for="input">Property</label>
    <ng-multiselect-dropdown [placeholder]="'Property'" [data]="dropdownList" [(ngModel)]="selectedItems"
      [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
    </ng-multiselect-dropdown> -->
  
  
    <!-- <div class="inpurwraper">
      <label for="input">Property</label>
      <select class="form-control" [(ngModel)]="property" (change)="changeproperty(property)">
        <option [ngValue]="''">Property</option>
        <option *ngFor="let list of propertymanagetlist" [value]="list._id">{{list.property_name}}</option>
  
      </select>
    </div> -->

    <div class="inpurwraper">
      <label for="input">Resources Organization Name<span class="required">*</span></label>
      <input [(ngModel)]="orgName" type="text" class="form-control"/>
    </div>

    <!-- <div class="inpurwraper">
      <label for="input">Suite Number</label>
      <input  [(ngModel)]="suite_no" type="text" class="form-control"/>
    </div> -->

    <div class="inpurwraper">
      <label for="input">Resources Organization Address<span class="required">*</span></label>
      <input  [(ngModel)]="address" type="text" class="form-control"/>
    </div>

    <div class="inpurwraper">
      <label>Select Country<span class="required">*</span></label>
      <select class="form-control" [(ngModel)]="country"
        (change)="onCountryChange(country)">
        <option *ngFor="let option of countryList" [value]="option.id">{{option.name}}</option>
      </select>
    </div>

    <div class="inpurwraper" >
      <label>Select State<span class="required">*</span></label>
      <select [disabled]="country == ''" class="form-control" [(ngModel)]="state"
        (change)="onStateChange(state)">
        <option disabled></option>
        <option *ngIf="!stateList || stateList.length === 0" value="no-state-found">No state found</option>
        <option *ngFor="let option of stateList" [value]="option.id">{{option.name}}</option>
      </select>
    </div>

    <!-- <div class="inpurwraper" *ngIf="stateList.length == 0 ">
      <label>Select State<span class="required">*</span></label>
      <input [(ngModel)]="state" type="text" class="form-control"/>
    </div> -->


       <div class="inputwrapper">
      <label>Select City<span class="required">*</span></label>
      <select [disabled]="state == ''" class="form-control" [(ngModel)]="city" (change)="onCityChange(city)">
          <option disabled></option>
          <option *ngIf="!citylist || citylist.length === 0" value="no-city-found">No city found</option>
          <option *ngFor="let option of citylist" [value]="option.id">{{option.name}}</option>
      
      </select>
  </div>

    <div class="inpurwraper" >
      <label>Select Property<span class="required">*</span></label>
      <select class="form-control"  [disabled]="city == ''" [(ngModel)]="property" (ngModelChange)="onChangeCondoName(property)" *ngIf="consoNameList.length > 0">
        <option disabled></option>
          <option *ngFor="let list of consoNameList" [value]="list._id">{{list.property_details}}</option>
      </select>
      <input [(ngModel)]="property" type="text" class="form-control" *ngIf="consoNameList.length == 0" readonly/>
    </div>

    <div class="inpurwraper">
      <label for="input">Postal Code</label>
      <input type="text" [(ngModel)]="postal_code" class="form-control"/>
    </div>

    <div class="inpurwraper">
      <label for="input">Resources Organization Phone Number<span class="required">*</span></label><br>
      <input id="resource_phone_no" class="form-control" type="text" minlength="10" maxlength="15" onwheel="this.blur()" >
      <!-- <div class="flexFld phnumber"><input class="form-control phonecode" [(ngModel)]="resource_phone_no_country_code"  readonly/>
        <input type="number" class="form-control phone form-input " onwheel="this.blur()"
        [(ngModel)]="resource_phone_no" /></div>
       -->
      <!-- <input [(ngModel)]="phone_number" type="number" class="form-control"/> -->
      <!-- <input type="text"
        class="form-control"
        style="width: 253%;"
            ng2TelInput
            [ng2TelInputOptions]="{initialCountry: 'ca'}"
            (hasError)="hasError($event)"
            (ng2TelOutput)="getNumber($event)"
            (intlTelInputObject)="telInputObject($event)"
            [(ngModel)]="resource_phone_no"
            (countryChange)="onCountryCodeChange($event)" />
            <p *ngIf="phone_numberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->
      

    </div>

    <div class="inpurwraper">
      <label for="input">Resources Organization Whatsapp Number</label><br>
      <!-- <div class="flexFld phnumber"><input class="form-control phonecode" [(ngModel)]="resource_alternate_phone_no_country_code" readonly/>
        <input type="number" class="form-control phone form-input " onwheel="this.blur()"
        [(ngModel)]="resource_alternate_phone_no" /></div>
       -->
       <input id="resource_alternate_phone_no" class="form-control" type="text" minlength="10" maxlength="15" onwheel="this.blur()" >
      <!-- <input [(ngModel)]="phone_number" type="number" class="form-control"/> -->
      <!-- <input type="text"
        class="form-control"
        style="width: 253%;"
            ng2TelInput
            [ng2TelInputOptions]="{initialCountry: 'ca'}"
            (hasError)="hasError($event)"
            (ng2TelOutput)="getNumber($event)"
            (intlTelInputObject)="telInputObject($event)"
            [(ngModel)]="alternate_phone_no"
            (countryChange)="onCountryCodeChange($event)" /> -->
            <!-- <p *ngIf="phone_numberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->
      

    </div>

    <div class="inpurwraper">
      <label for="input">Resources Organization Email<span class="required">*</span> </label>
      <input type="text" [(ngModel)]="email" class="form-control"/>
    </div>


    <div class="inpurwraper">
      <label for="input">Resources Contact Person Name<span class="required">*</span></label>
      <input type="text" [(ngModel)]="resource_contact_name" class="form-control"/>
    </div>

    <div class="inpurwraper">
      <label for="input">Primary Contact Number<span class="required">*</span></label><br>
      <!-- <div class="flexFld phnumber">  <input class="form-control phonecode" [(ngModel)]="country_code"  readonly/>
        <input type="number" class="form-control phone form-input" onwheel="this.blur()"
        [(ngModel)]="phone_number" /></div> -->
        <input id="phone_number" class="form-control" type="text" minlength="10" maxlength="15" onwheel="this.blur()" >
    
      <!-- <input [(ngModel)]="phone_number" type="number" class="form-control"/> -->
      <!-- <input type="text"
        class="form-control"
        style="width: 253%;"
            ng2TelInput
            [ng2TelInputOptions]="{initialCountry: 'ca'}"
            (hasError)="hasError($event)"
            (ng2TelOutput)="getNumber($event)"
            (intlTelInputObject)="telInputObject($event)"
            [(ngModel)]="phone_number"
            (countryChange)="onCountryCodeChange($event)" /> -->
            <!-- <p *ngIf="phone_numberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->
      

    </div>

    <div class="inpurwraper">
      <label for="input">Contact Person Whatsapp Number</label><br>
      <!-- <div class="flexFld phnumber"><input class="form-control phonecode"  [(ngModel)]="alternate_phone_no_country_code"  readonly/>
        <input type="number" class="form-control phone form-input " onwheel="this.blur()" 
         [(ngModel)]="alternate_phone_no"/></div> -->
         <input id="alternate_phone_no" class="form-control" type="text" minlength="10" maxlength="15" onwheel="this.blur()" >
    
      <!-- <input [(ngModel)]="phone_number" type="number" class="form-control"/> -->
      <!-- <input type="text"
        class="form-control"
        style="width: 253%;"
            ng2TelInput
            [ng2TelInputOptions]="{initialCountry: 'ca'}"
            (hasError)="hasError($event)"
            (ng2TelOutput)="getNumber($event)"
            (intlTelInputObject)="telInputObject($event)"
            [(ngModel)]="resource_alternate_phone_no"
            (countryChange)="onCountryCodeChange($event)" /> -->
            <!-- <p *ngIf="phone_numberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->
      

    </div>

    <div class="inpurwraper">
      <label for="input">Contact Person Email<span class="required">*</span> </label>
      <input type="text" [(ngModel)]="resource_email" class="form-control"/>
    </div>

    <div class="inpurwraper">
      <label for="input">Title/Subject Of Announcement<span class="required">*</span> </label>
      <input [(ngModel)]="title" type="text" class="form-control"/>
    </div>
  
  
    <div class="inpurwraper">
      <label for="input">Brief Description Of Announcement<span class="required">*</span></label>
      <input [(ngModel)]="description" type="text" class="form-control" />
    </div>

    <div class="inpurwraper">
      <label for="input">Posting Start Date<span class="required">*</span></label>
      <input type="date" id="input" class="form-control" [(ngModel)]="startDate" class="form-control" mdbInput  min="{{ currentDate | date: 'yyyy-MM-dd' }}"/>
    </div>
  
    <div class="inpurwraper">
      <label for="input">Posting End Date<span class="required">*</span></label>
      <input type="date" id="input" class="form-control" [(ngModel)]="endDate" class="form-control" mdbInput  min="{{ currentDate | date: 'yyyy-MM-dd' }}"/>
    </div>
  
  
    <div class="inpurwraper">
      <label for="input">From Time<span class="required">*</span></label>
      <input type="time" id="input" class="form-control" [(ngModel)]="fromTime" class="form-control" mdbInput />
    </div>
  
  
    <div class="inpurwraper">
      <label for="input">To Time<span class="required">*</span></label>
      <input type="time" id="input" class="form-control" [(ngModel)]="toTime" class="form-control" mdbInput />
    </div>

    <div class="inpurwraper">
      <label for="input">Website Link or Mobile App Link</label>
      <input
        [(ngModel)]="website"
        type="text"
        class="form-control"
        placeholder="Website Link"
      />
    </div>
    
    <div class="inpurwraper">
      <label for="input">Ecommerce App Link / Weblink</label>
      <input [(ngModel)]="ecom_link" type="text" class="form-control" placeholder="Ecommerce Link" />
    </div>

    <div class="inpurwraper">
      <label for="input">Youtube Video Link</label>
      <input
      [(ngModel)]="video_link"
        type="text"
        class="form-control"
        placeholder="Video Link"
      />
    </div>


      <div class="inpurwraper">
        <label for="input">Resources Image<span class="required">*</span></label>
        <input
          type="file"
          #fileInput
          accept="image/gif,image/jpeg,image/jpg,image/png"
          class="form-control"
          (change)="onFileChange($event)"
        />
      </div>
      <div *ngIf="image" class="uploadImg">
        <img [src]="image"/>
        <button type="button" (click)="removeImage()">
          <i class="bi bi-x-circle"></i>
        </button>
      </div>

    <!-- <div class="inputwrapper">
      <label for="input">Resources PDF</label>
      <input 
        type="file" 
        accept="application/pdf" 
        class="form-control" 
        (change)="onFileChangePDF($event)" 
      />
    </div>
    <div >
      <p class="pdf" >Preview File:
        <a 
          *ngIf="checkPdf(Pdf_prev) !== 'No pdf file found'" 
          [href]="checkPdf(Pdf_prev)" 
          target="_blank"
          [class.disabled]="checkPdf(Pdf_prev) === 'No pdf file found'"
        >
          Click here to view PDF
        </a>
       <span *ngIf="checkPdf(Pdf_prev) === 'No pdf file found'">No PDF file found</span>
      </p>
    </div> -->
</div>
    <div class="inputwrapper">
      <label for="input">Resources PDF</label>
      <input 
        type="file" 
        accept="application/pdf" 
        class="form-control"
        #fileInputs 
        (change)="onFileChangePDF($event)" 
      />
    </div>
    
    <!-- Conditionally show the PDF preview section -->
    <div >
      <div class="pt-2">
          <span *ngIf="checkPdf(Pdf_prev) !== 'No pdf file found'  && PDFfile === undefined">Preview File:
          
          <a             
            [href]="checkPdf(Pdf_prev)" 
            target="_blank"
            [class.disabled]="checkPdf(Pdf_prev) === 'No pdf file found'"
          >
           Click here to view PDF
          </a>
        </span>
      </div>
      
      <!-- <div *ngIf="checkPdf(Pdf_prev) !=='' && PDFfile === undefined">
        <p class="pdf">Preview File:
          <a [href]="checkPdf(Pdf_prev)"  target="_blank">Click to view PDF</a>
        </p>
      </div>
    </div> -->


    
      <!-- Section with PDF icon and remove button -->   

      <div *ngIf="fileSelected && pdf_enable==true" class="uploadImg mt-1">
        <img src="assets/images/pdf.png" alt="PDF Icon" style="height: 50px; width:50px;">
        <button type="button" (click)="removePDF()">
          <i class="bi bi-x-circle"></i>
        </button>
      </div>


      <span *ngIf="!fileSelected && checkPdf(Pdf_prev) === 'No pdf file found'">Preview File: No PDF file found</span>
    
    
   
  
    <div class="inpbtn_wrap">
      <button
        type="button"
        class="btn defbtn"
        *ngIf="property != '' && property!= 'No property found' && title != '' && description != '' && image != '' && country != '' && city != '' && state != '' && orgName != '' && phone_number != null && startDate != '' && endDate != '' && fromTime != '' && toTime != '' && image && pdf_enable == true"
        type="submit"
        class="btn defbtn"
        (click)="onEdit();"
      >
        Save
      </button>
  
      <button
        type="button"
        class="btn defbtn"
        *ngIf="property == '' || property == 'No property found' || title == '' || description == '' || !image || imageRemoved ||
         country == '' || city == '' || state == '' || orgName == '' || phone_number == null || startDate == '' || endDate == '' || fromTime == '' || toTime == '' || address =='' || pdf_enable == false"
  
        [disabled]="property == '' || property == 'No property found' || title == '' || description == '' ||!image || imageRemoved 
        || country == '' || city == '' || state == '' || orgName == '' || phone_number == null || startDate == '' || endDate == '' || fromTime == '' || toTime == ''  || address =='' || pdf_enable == false"
        type="submit"
        class="btn defbtn"
        style="cursor: no-drop;"
      >
        Save
      </button>
    </div>
  