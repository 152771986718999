<div class="content_box">
    <h2>Add Property Manager</h2>

    <div class="register-page">
        <div class="add-swimming-pool-form">
            <div class="row">
                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Property Manager First Name<span class="required">*</span></label>
                        <input [(ngModel)]="fName" type="text" class="input-field">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Property Manager Last Name<span class="required">*</span></label>
                        <input [(ngModel)]="lName" type="text" class="input-field">
                    </div>
                </div>
                


                
                <!-- <div class="col-lg-6">
                <div class="input-field-box">
                    <label>Cell No</label>
                    <input [(ngModel)]="cellNumber" type="number" class="input-field">
                </div>
            </div> -->
                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Country<span class="required">*</span></label>
                        <select class="input-field" [(ngModel)]="country" (ngModelChange)="OnChangeCountry(country)">
                            <option *ngFor="let list of countryList" [value]="list.id">{{list.name}}</option>

                        </select>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>State<span class="required">*</span></label>
                        <select class="input-field" [disabled]="country == ''" [(ngModel)]="state"
                            (ngModelChange)="OnChangeState(state)">
                            <option disabled></option>
                            <option *ngIf="!stateList || stateList.length === 0" value="no-state-found">No state found</option>
                            <option *ngFor="let list of stateList" [value]="list.id">{{list.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>City<span class="required">*</span></label>
                        <select class="input-field" [disabled]="state == ''" [(ngModel)]="city"
                            (ngModelChange)="onChangeCity(city)">
                            <option disabled></option>
                            <option *ngIf="!cityList || cityList.length === 0" value="no-city-found">No city found</option>
                                <option *ngFor="let list of cityList" [value]="list.id">{{list.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Assigned Property Name<span class="required">*</span></label>
                        <select class="input-field" [disabled]="city == ''" [(ngModel)]="condoName"
                            (ngModelChange)="onChangeCondoName(condoName)">
                            <option disabled></option>
                            <option *ngIf="consoNameList.length === 0" disabled>No properties found</option>
                            <option *ngFor="let list of consoNameList" [value]="list._id">{{list.property_name}},
                                {{list.property_address}},{{list.postal_code}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Suite Number</label>
                        <input [(ngModel)]="suiteNo" type="text" class="input-field">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Property Manager Address<span class="required">*</span></label>
                        <input [(ngModel)]="address" type="text" class="input-field">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Postal Code</label>
                        <input [(ngModel)]="postalCode" type="text" class="input-field">
                    </div>
                </div>


                
                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Phone Number<span class="required">*</span></label>
                        <input type="text" class="input-field form-input" onwheel="this.blur()" ng2TelInput
                            [ng2TelInputOptions]="{initialCountry: 'ca'}" (hasError)="hasError($event)"
                            (ng2TelOutput)="getNumber($event)" (intlTelInputObject)="telInputObject($event)"
                            [(ngModel)]="cellNumber" (countryChange)="onCountryChange($event)" />
                            <p *ngIf="cellNumberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p>
                    </div>
                </div>






                <div class="col-lg-6">
                    <div class="input-field-box">
                        <!-- <label>Home No</label>
                        <input [(ngModel)]="homeno" type="number" class="input-field"> -->
                        <label>Whatsapp Number</label>
                        <input type="text" class="input-field form-input" onwheel="this.blur()" ng2TelInput
                            [ng2TelInputOptions]="{initialCountry: 'ca'}" (hasError)="hasError($event)"
                            (ng2TelOutput)="getNumber($event)" (intlTelInputObject)="telInputObject($event)"
                            [(ngModel)]="homeno" (countryChange)="onCountryChange1($event)" />
                            <p *ngIf="homenoValidation == true">Alternate Number Should contain 10 digits.Please enter Valid Phone No. </p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Email<span class="required">*</span></label>
                        <input [(ngModel)]="email" type="email" class="input-field">
                    </div>
                </div>


                <!-- <div class="col-lg-12">
                    <div class="input-field-box">
                        <label>Are You an Adult?</label>

                        <div class="birthday">
                            <select class="input-field" [(ngModel)]="bDate" (ngModelChange)="onChangeDate(bDate)">
                                <option [ngValue]="null" [disabled]="true">Date</option>
                                <option *ngFor="let item of birthDate" [value]="item.date">{{item.date}}</option>
                            </select>

                            <select class="input-field" [(ngModel)]="bMonth" (ngModelChange)="onChangeMonth(bMonth)">
                                <option [ngValue]="null" [disabled]="true">Month</option>
                                <option *ngFor="let item of birthMonth" [value]="item.month">{{item.month}}</option>
                            </select>

                            <select class="input-field" [(ngModel)]="bYear" (ngModelChange)="onChangeYear(bYear)">
                                <option [ngValue]="null" [disabled]="true">Year</option>
                                <option *ngFor="let item of birthYear" [value]="item.year">{{item.year}}</option>
                            </select>
                        </div>
                    </div>
                </div> -->
                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Are You An Adult?</label>
                        <select class="input-field" [(ngModel)]="adult_status" (ngModelChange)="onAdult(adult_status)">
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
            
                        </select>
                    </div>
                </div>



                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Gender</label>
                        <select class="input-field" [(ngModel)]="gender" (ngModelChange)="onChangeGender(gender)">
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                </div>

              
                
             
                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Password<span class="required">*</span></label>
                        <input [type]="changetype?'password':'text'" [(ngModel)]="password" type="text" class="input-field">
                        <span class="eyeicon" (click)="viewpass()"><i [ngClass]="visible?'bi bi-eye-slash-fill':'bi bi-eye-fill'"></i>
                        </span>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Confirm Password<span class="required">*</span></label>
                        <input  [type]="changetype1?'password':'text'" [(ngModel)]="cPassword" type="text" class="input-field">
                        <span class="eyeicon" (click)="viewpass1()"><i [ngClass]="visible1?'bi bi-eye-slash-fill':'bi bi-eye-fill'"></i></span>
                    </div>
                </div>

                <!-- <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Subscription Amount<span class="required">*</span></label>
                        <input [(ngModel)]="subAmount" type="number" onwheel="this.blur()" class="input-field">
                    </div>
                </div> -->

                <!-- <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Subscription Date</label>
                        <input type="date" id="input" [(ngModel)]="subDate" class="input-field" mdbInput />
                    </div>
                </div> -->



                <div class="col-lg-12">
                    <h2 class="page-sub-title">Emergency Contact</h2>
                </div>

                <div class="col-lg-12">
                    <div class="input-field-box">
                        <label>Name</label>
                        <input [(ngModel)]="eName" type="text" class="input-field">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Contact Number</label>
                        <!-- <input [(ngModel)]="eCellNo" type="number" class="input-field"> -->
                        <input type="text" class="input-field form-input" onwheel="this.blur()" ng2TelInput
                            [ng2TelInputOptions]="{initialCountry: 'ca'}" (hasError)="hasError($event)"
                            (ng2TelOutput)="getNumber($event)" (intlTelInputObject)="telInputObject($event)"
                            [(ngModel)]="eCellNo" (countryChange)="onCountryChange2($event)"/>
                            <p *ngIf="eCellNoValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p>
 
                            
                        
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Email</label>
                        <input [(ngModel)]="eEmail" type="email" class="input-field">
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="input-field-box">
                        <label>Property Management Company</label>
                        <input [(ngModel)]="propertyManagementCompany" type="text" class="input-field">
                    </div>
                </div>

               
                <div class="col-lg-6">
                    <div class="input-field-box">
                        <label>Relationship</label>
                        <div class="relation_inner">
                            <div class="relation">
                                <input type="radio" name="relation" [checked]="eReletation == 'family'"
                                    ng-model="eReletation" (click)="onChangeReletation('family')">
                                <span>Family</span>
                            </div>
                            <div class="relation">
                                <input type="radio" name="relation" [checked]="eReletation == 'friend'"
                                    ng-model="eReletation" (click)="onChangeReletation('friend')">
                                <span>Friend</span>
                            </div>
                        </div>
                    </div>
                </div>

               
                <div class="col-lg-12">
                    <div class="button-set">
                        <button class="default-btn" (click)="onAdd();">Add</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>