import { Component, OnInit, HostListener  } from '@angular/core';
import { WebserviceService } from 'src/app/webservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-adminlogin',
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./adminlogin.component.scss']
})
export class AdminloginComponent implements OnInit {
  email: any = '';
  password: any = '';
  validationemailerror: boolean = false;
  error: boolean = false;
  data: any;
  user_extension: any;
  user_type: any;

  visible:boolean = true;
changetype:boolean =true;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,private toastr: ToastrService,) {
    console.log("adminlogin");
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.user_extension = params['user_extension'];
      console.log('ext-'+this.user_extension);
      this.webservice.sendheaderfooter(this.user_extension);
    });
  }
  onLogin() {
   
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(this.email)) {
      this.validationemailerror = true;
      this.spinner.hide();
    } else {
      
      this.validationemailerror = false;
      this.spinner.show();
      if(this.user_extension == undefined) {
        this.user_type = 0;
        this.user_extension = '';
      } else {
        this.user_type = 0;
        this.user_extension = this.user_extension;
      }
      var params = {
        email: this.email,
        password: this.password,
        user_type: this.user_type,
        user_extension: this.user_extension
      };

      this.webservice.adminPostData('login', params).then((result) => {
       
        console.log("result: ",result);
        this.data = result;
        if (this.data.status == 1) {
          if (this.data.data.user_type == 0 || this.data.data.user_type == 5) {
            this.error = false;
            this.webservice.sendheaderfooter(undefined);
            this.webservice.sendLoggedData(this.data.data);
            localStorage.setItem('LoginToken', JSON.stringify(this.data.data));
            this.router.navigateByUrl('/main/dashboard');
            this.toastr.success("Login successful");
          } else if (this.data.data.user_type == 1) {
            this.error = false;
            this.toastr.error('Only admin and vendors are allowed to login; property managers are not allowed.');
          } else if (this.data.data.user_type == 2) {
            this.error = false;
            this.toastr.error('Only admin and vendors are allowed to login; residents are not allowed.');
          } else if (this.data.data.user_type == 3) {
            this.error = false;
            this.toastr.error('Only admin and vendors are allowed to login; staffs are not allowed.');
          } else {
            this.error = true; // Handle any other user types not explicitly mentioned
          }
        }
         else {
          this.error = true;
          this.toastr.error(this.data.msg);
        }
        this.spinner.hide();

      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }


  }

  // onLogin() {
   
  //   var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   if (!re.test(this.email)) {
  //     this.validationemailerror = true;
  //     this.spinner.hide();
  //   } else {
      
  //     this.validationemailerror = false;
  //     this.spinner.show();
  //     if(this.user_extension == undefined) {
  //       this.user_type = 0;
  //       this.user_extension = '';
  //     } else {
  //       this.user_type = 0;
  //       this.user_extension = this.user_extension;
  //     }
  //     var params = {
  //       email: this.email,
  //       password: this.password,
  //       user_type: this.user_type,
  //       user_extension: this.user_extension
  //     };

  //     this.webservice.adminPostData('login', params).then((result) => {
       
  //       console.log("result: ",result);
  //       this.data = result;
  //       if(this.data.status == 1){
          
  //         if (this.data.data.user_type == 0 || this.data.data.user_type == 5) {
  //           this.error = false;
  //           this.webservice.sendheaderfooter(undefined);
  //           this.webservice.sendLoggedData(this.data.data);
  //           localStorage.setItem('LoginToken', JSON.stringify(this.data.data));
           
  //             this.router.navigateByUrl('/main/dashboard')
  //             this.toastr.success("Login successful");
  //         } else {
            
  //           this.error = true
            
  //         }
          
  //       } else {
  //         this.error = true
  //       }
  //       this.spinner.hide();

  //     }, (err) => {
  //       this.spinner.hide();
  //       console.log(err);
  //     });

  //   }


  // }
  viewpass(){
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }
  @HostListener('window:keydown.enter', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.email !== '' && this.password !== '') {
      this.onLogin();
    }
  }

}
