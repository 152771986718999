<div class="container">
  <h2>Vendor Profile</h2>

  <div class="content_box">
    <div class="row">
      <div class="col-md-6 mb-3">
        <label>Vendor / Business Name<span class="required"></span></label>
        <input
          [(ngModel)]="business_name"
          type="text"
          class="form-control"
          readonly
        />
      </div>
      <div class="col-md-6 mb-3">
        <label>Vendor / Business Address<span class="required"></span></label>
        <input
          [(ngModel)]="business_address"
          type="text"
          class="form-control"
          readonly
        />
      </div>
      <div class="col-md-6 mb-3">
        <label>Country<span class="required"></span></label>
        <select class="form-control" [(ngModel)]="country" disabled>
          <option *ngFor="let option of countryList" [value]="option.id">
            {{ option.name }}
          </option>
        </select>
      </div>
      <div class="col-md-6 mb-3">
        <label>State<span class="required"></span></label>
        <select class="form-control" [(ngModel)]="state" disabled *ngIf="state != 'no-state-found'">
          <option *ngFor="let option of stateList" [value]="option.id" >
            {{ option.name }}
          </option>
        </select>
        <input [(ngModel)]="state" type="text" class="form-control" *ngIf="state == 'no-state-found'" readonly/>
      </div>
      <div class="col-md-6 mb-3">
        <label>City<span class="required"></span></label>
        <select class="form-control" [(ngModel)]="city" disabled *ngIf="city != 'no-city-found'">
          <option *ngFor="let option of citylist" [value]="option.id">
            {{ option.name }}
          </option>
        </select>
        <input [(ngModel)]="city" type="text" class="form-control" *ngIf="city == 'no-city-found'"readonly/>
      </div>
      <div class="col-md-6 mb-3">
        <label>Property Name</label><br />
        <input
          [(ngModel)]="property_name"
          type="text"
          class="form-control"
          readonly
        />
      </div>
      <div class="col-md-6 mb-3" *ngIf="postal_code">
        <label>Postal Code</label>
        <ng-container>
          <input
            [(ngModel)]="postal_code"
            type="text"
            class="form-control"
            readonly
          />
        </ng-container>
      </div>
      <div class="col-md-6 mb-3">
        <label
          >Vendor / Business Phone Number<span class="required"></span></label
        ><br />
        <input
          class="form-control"
          [value]="
            business_phone_number_country_code + ' ' + business_phone_number
          "
          readonly
        />
      </div>
      <div
        class="col-md-6 mb-3"
        *ngIf="
          business_alternate_phone_number &&
          business_alternate_phone_number.trim() !== ''
        "
      >
        <label>Vendor / Business Whatsapp Number</label><br />
        <ng-container>
          <input
            class="form-control"
            [value]="
              business_alternate_phone_number_country_code +
              ' ' +
              business_alternate_phone_number
            "
            readonly
          />
        </ng-container>
      </div>

      <div class="col-md-6 mb-3">
        <label>Vendor / Business Email<span class="required"></span></label>
        <a [href]="'mailto:' + business_email">
          <input
            [(ngModel)]="business_email"
            type="text"
            class="form-control"
            style="cursor: pointer"
            readonly
          />
        </a>
      </div>
      <div class="col-md-6 mb-3">
        <label>Vendor Contact Name<span class="required"></span></label>
        <input [(ngModel)]="name" type="text" class="form-control" readonly />
      </div>
      <div class="col-md-6 mb-3">
        <label>Vendor Contact Number<span class="required"></span></label><br />

        <input
          class="form-control"
          [value]="country_code + ' ' + phn_no"
          readonly
        />
      </div>
      <div
        class="col-md-6 mb-3"
        *ngIf="
          vendor_alternate_phone_number &&
          vendor_alternate_phone_number.trim() !== ''
        "
      >
        <label>Vendor Whatsapp Number</label><br />
        <ng-container>
          <input
            class="form-control"
            [value]="
              vendor_alternate_phone_number_country_code +
              ' ' +
              vendor_alternate_phone_number
            "
            readonly
          />
        </ng-container>
      </div>

      <div class="col-md-6 mb-3">
        <label>Vendor Email<span class="required"></span></label>
        <a [href]="'mailto:' + email">
          <input
            [(ngModel)]="email"
            type="text"
            style="cursor: pointer"
            class="form-control"
            readonly
          />
        </a>
      </div>
    </div>
  </div>
</div>
