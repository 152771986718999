<div class="body">
  <div class="card">
    <div
      style="
        border-radius: 200px;
        height: 200px;
        width: 200px;
        background: #f8faf5;
        margin: 0 auto;
      "
    >
      <i class="checkmark">✓</i>
    </div>
    <h1>Payment Successful!!</h1>
    <!-- <p>We received your purchase request;<br/> we'll be in touch shortly!</p> -->
  </div>
</div>
