<div class="content_box">
    <h2>Resource List</h2>
    <div style="margin-bottom: 10px;">
      <mdb-icon fas icon="search" aria-hidden="true"></mdb-icon>
    <input style="min-height: 40px;" class="form-control form-control-sm w-60 srchIcon" type="text" placeholder="Search by Country, State, City, Property name" aria-label="Search"
      mdbInput [(ngModel)]="search" (ngModelChange)="onSearch(search)">
    </div>
    <div class="responsive_table">
    <table mat-table class="mat-elevation-z8">
      <thead>
        <tr>
            <th>Sl#</th>
            <th>Property Name</th>
            <th>Title</th>
            <th>Description</th>
            <th>Action</th>
        </tr>
      </thead>
          <tbody>
        <tr *ngFor="let item of list  | paginate:{itemsPerPage: tableSize, currentPage: page, totalItems:count}; let i=index">
            <td data-label="Sl#">{{ (page - 1) * tableSize + i +1 }}</td>
            <td data-label="Property Name">{{item.property_name}}</td>
            <td data-label="Title">{{item.title}}</td>
            <td data-label="Description">{{item.description}}</td>
            <!-- <td><i style="cursor: pointer;" class="material-icons" [routerLink]="['/main/add-dailydeals]">create</i></td> -->
            <td data-label="Action"><div class="d-flex edtBt"><i style="cursor: pointer;" class="material-icons" [routerLink]="['./edit-resources/'+item._id]">create</i>&nbsp;
              <i style="padding-left:10%" class="btn btn-outline-secondary" mwlConfirmationPopover [popoverTitle]="popoverTitle"
              [popoverMessage]="popoverMessage" placement="left" (confirm)="onDelete(item._id)"
              (cancel)="cancelClicked = true" style="cursor: pointer;" class="material-icons">delete</i></div>
              </td>
         
        </tr>
        </tbody> 
    </table>
    </div>
    <div class="justify-content-end d-flex">
        <pagination-controls previousLabel="Prev" nextLabel="Next" maxSize="5" (pageChange)="onTableDataChange($event) " ></pagination-controls>
      </div>
    <div *ngIf="list.length == 0" class="Nodatafound">No data found!</div>
  </div>
  <div class="btn btn-primary btn-round default_btn mat-raised-button" [routerLink]="['./payresource']" style="float:right" *ngIf="paymentstaus == false">
    <i class="material-icons float-icon">add</i>
  </div> 
  
  <div class="btn btn-primary btn-round default_btn mat-raised-button" [routerLink]="['./add-resources']" style="float:right" *ngIf="paymentstaus == true">
    <i class="material-icons float-icon">add</i>
  </div>

  <!-- <div class="btn btn-primary btn-round default_btn mat-raised-button" [routerLink]="['./add-resources']" style="float:right">
    <i class="material-icons float-icon">add</i>
  </div> -->