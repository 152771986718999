<div class="serviceinfo_section">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h2>EQ11 on-demand <strong>  curated services</strong></h2>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. <span class="sep1"></span></p>
                <div class="service_content">
                    <div class="service_info">
                        <div class="service_item">
                            <h3><img src="assets/images/serviceicon1.png" alt=""> Personal training</h3>
                            <p>Offer more services to your residents with Walter. Your residents can </p>
                        </div>
                        <div class="service_item">
                            <h3><img src="assets/images/serviceicon2.png" alt=""> Spa-like massage</h3>
                            <p>Offer more services to your residents with Walter. Your residents can </p>
                        </div>
                        <div class="service_item">
                            <h3><img src="assets/images/serviceicon3.png" alt=""> House cleaning</h3>
                            <p>Offer more services to your residents with Walter. Your residents can </p>
                        </div>
                        <div class="service_item">
                            <h3><img src="assets/images/serviceicon4.png" alt=""> Dog sitting</h3>
                            <p>Offer more services to your residents with Walter. Your residents can </p>
                        </div>
                        <div class="service_item">
                            <h3><img src="assets/images/serviceicon5.png" alt=""> Car detailing Service.</h3>
                            <p>Offer more services to your residents with Walter. Your residents can </p>
                        </div>
                        <div class="service_item">
                            <h3><img src="assets/images/serviceicon6.png" alt=""> On-demand handyman</h3>
                            <p>Offer more services to your residents with Walter. Your residents can </p>
                        </div>

                    </div>

                </div>
            </div>

        </div>

    </div>
</div>
