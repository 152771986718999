<div class="content_box">
  <h2>Resident List</h2>
  <div style="margin-bottom: 10px;">
    <mdb-icon fas icon="search" aria-hidden="true"></mdb-icon>
  <input style="min-height: 40px;" class="form-control form-control-sm w-60 srchIcon" type="text" placeholder="Search by Suite No, Country, State, City, Property building by Name & Address" aria-label="Search"
    mdbInput [(ngModel)]="search" (ngModelChange)="onSearch(search)">
  </div>
  <div class="responsive_table">
    <table mat-table class="mat-elevation-z8">
      <thead>
        <tr>
            <th>Sl#</th>
            <th>Resident Name</th>
            <th>Property Manager</th>
            <th>Property Name</th>
            <th>Email</th>
            <th>Contact Phone No</th>
            <th>Address</th>
            <th>Family Members</th>
            <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let residentdata of residentList | paginate:{itemsPerPage: tableSize, currentPage: page, totalItems:count}; let i=index">
            <td data-label="Sl#" >{{ (page - 1) * tableSize + i +1 }}</td>
            <td data-label="Resident Name">{{residentdata.name}}</td>
            <td data-label="Property Manager">{{residentdata.parent_name}}</td>
            <td data-label="Property Name">{{residentdata.property_name}}</td>
            <td data-label="Email">{{residentdata.email}}</td>
            <td data-label="Contact Phone No">{{residentdata.country_code}} {{residentdata.contact_no}}</td>
            <td data-label="Address">{{residentdata.address}}</td>

            <td data-label="Family Members">
              <button 
                *ngIf="residentdata.family_id"
                style="font-weight: 600; cursor: pointer;"
                [routerLink]="['./family-member/'+residentdata._id]"
                class="view-button"
              >
                View
              </button>
              <button 
                *ngIf="!residentdata.family_id"
                style="font-weight: 600; color: gray;"
                class="view-button disabled-button"
                disabled
              >
                View
              </button>
            </td>
            
            
            <!-- ['/main/resident-edit/'+residentdata._id]" -->
            <td data-label="Action"><i style="cursor: pointer;" class="material-icons" [routerLink]="['./resident-edit/'+residentdata._id]">create</i></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="justify-content-end d-flex" >
  <pagination-controls previousLabel="Prev" nextLabel="Next"  maxSize="5" (pageChange)="onTableDataChange($event) " ></pagination-controls>
</div>
<div *ngIf="residentList.length == 0" class="Nodatafound">No result found!</div>
<div class="btn btn-primary btn-round default_btn mat-raised-button" [routerLink]="['./resident-add']" style="float:right">
  <i class="material-icons float-icon">add</i>
</div>
