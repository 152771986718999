<div class="content_box">
    <h2>Advertise Local Businesses</h2>
    <div style="margin-bottom: 10px;">
      <mdb-icon fas icon="search" aria-hidden="true"></mdb-icon>
    <input style="min-height: 40px;" class="form-control form-control-sm  w-60" type="text" placeholder="Search by Country, State, City, Property name" aria-label="Search"
      mdbInput [(ngModel)]="search" (ngModelChange)="onSearch(search)">
    </div>
    <div class="responsive_table">

    <table mat-table class="mat-elevation-z8">
      <thead>
        <tr>
            <th>Sl#</th>
            <th>Created By</th>
            <th>Advertise Title</th>
            <!-- <th>Description</th> -->
            <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of list | paginate:{itemsPerPage: tableSize, currentPage: page, totalItems:count}; let i=index">
            <td data-label="Sl#">{{ (page - 1) * tableSize + i +1 }}</td>
            <td data-label="Created By">{{item.created_by}}</td>
            <td data-label="Advertise Title">{{item.title}}</td>
            <!-- <td>{{item.description}}</td> -->
            <td data-label="Action"><i style="cursor: pointer;" class="material-icons" [routerLink]="['./edit-advertise/'+item._id]">create</i>&nbsp;&nbsp;
              <i style="padding-left:10%" class="btn btn-outline-secondary" mwlConfirmationPopover [popoverTitle]="popoverTitle"
              [popoverMessage]="popoverMessage" placement="left" (confirm)="onDelete(item._id)"
              (cancel)="cancelClicked = true" style="cursor: pointer;" class="material-icons">delete</i></td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
  <div class="justify-content-end d-flex">
    <pagination-controls previousLabel="Prev" nextLabel="Next" maxSize="5" (pageChange)="onTableDataChange($event) " ></pagination-controls>
  </div>
  <div class="Nodatafound" *ngIf="list.length == 0">No advertise found</div>
  <div class="btn btn-primary btn-round default_btn mat-raised-button" [routerLink]="['./add-advertise']" style="float:right">
    <i class="material-icons float-icon">add</i>
  </div>