<div class="content_box">
    <h2>Edit Advertise Local Businesses</h2>

    <div class="inpurwraper">
      <label for="input">Merchant Name<span class="required">*</span></label>
      <input [(ngModel)]="merchant_name" type="text" class="form-control"  />
    </div>

    <div class="inpurwraper">
      <label for="input">Suite Number</label>
      <input [(ngModel)]="suite_no" type="text" class="form-control"  />
    </div>

    <div class="inpurwraper">
      <label>Merchant Address<span class="required">*</span></label>
      <input
        [(ngModel)]="address"
        type="text"
        class="form-control"
      />
    </div>


    <div class="inpurwraper">
      <label>Select country<span class="required">*</span></label>
      <select class="form-control" [(ngModel)]="country"
        (change)="onCountryChange(country)">
        <option *ngFor="let option of countryList" [value]="option.id">{{option.name}}</option>
      </select>
    </div>
  
    <div class="inpurwraper">
      <label>Select State<span class="required">*</span></label>
      <select [disabled]="country == ''" class="form-control" [(ngModel)]="state"
        (change)="onStateChange(state)">
        <option *ngFor="let option of stateList" [value]="option.id">{{option.name}}</option>
      </select>
    </div>
  
    <div class="inpurwraper">
      <label>Select city<span class="required">*</span></label>
      <select [disabled]="state == ''" class="form-control" [(ngModel)]="city"
        (change)="onCityChange(city)">
        <option *ngFor="let option of citylist" [value]="option.id">{{option.name}}</option>
      </select>
    </div>
  
    <div class="inpurwraper">
      <label>Select Property Name<span class="required">*</span></label>
      <select class="form-control" [disabled]="city == ''" [(ngModel)]="property" (ngModelChange)="onChangeCondoName(property)">
          <option *ngFor="let list of consoNameList" [value]="list._id">{{list.property_details}}</option>
      </select>
    </div>

    <div class="inpurwraper">
      <label>Postal Code</label>
      <input
      [(ngModel)]="postal_code"
        type="text"
        class="form-control"
      />
    </div>

 
    <div class="inpurwraper">
      <label for="input">Merchant Phone Number<span class="required">*</span></label><br>
      <input class="form-control phonecode" [(ngModel)]="merchant_phone_no_country_code" readonly/>
    <input type="number" onwheel="this.blur()" class="form-control phone " 
      [(ngModel)]="merchant_phone_number"/>
      <!-- <input [(ngModel)]="merchant_phone_number" type="number" class="form-control"  />
       <p *ngIf="merchant_phone_numberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p>
       -->
       <!-- <input type="text"
       class="form-control"
       style="width: 253%;"
           ng2TelInput
           [ng2TelInputOptions]="{initialCountry: 'ca'}"
           (hasError)="hasError($event)"
           (ng2TelOutput)="getNumber($event)"
           (intlTelInputObject)="telInputObject($event)"
           [(ngModel)]="merchant_phone_number"
           (countryChange)="onCountryCodeChange($event)" /> -->
           <!-- <p *ngIf="merchant_phone_numberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->

    </div>

    <div class="inpurwraper">
      <label for="input">Merchant Whatsapp No</label><br>
      <input class="form-control phonecode" [(ngModel)]="merchant_alternate_phone_number_country_code" readonly/>
      <input type="number" onwheel="this.blur()" class="form-control phone " 
        [(ngModel)]="merchant_alternate_phone_number"/>
      <!-- <input [(ngModel)]="merchant_phone_number" type="number" class="form-control"  />
       <p *ngIf="merchant_phone_numberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p>
       -->
       <!-- <input type="text"
       class="form-control"
       style="width: 253%;"
           ng2TelInput
           [ng2TelInputOptions]="{initialCountry: 'ca'}"
           (hasError)="hasError($event)"
           (ng2TelOutput)="getNumber($event)"
           (intlTelInputObject)="telInputObject($event)"
          
           (countryChange)="onCountryCodeChange($event)" /> -->
           <!-- <p *ngIf="merchant_phone_numberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->

    </div>

    <div class="inpurwraper">
      <label for="input">Email<span class="required">*</span></label>
      <input
      [(ngModel)]="email"
        type="text"
        class="form-control"
      />
    </div>

    <div class="inpurwraper">
      <label for="input">Advertise Title<span class="required">*</span></label>
      <input
        [(ngModel)]="title"
        type="text"
        class="form-control"
      />
    </div>

    <div class="inpurwraper">
      <label for="input">Posting Start Date<span class="required">*</span></label>
      <input type="date" id="input" class="form-control" [(ngModel)]="startDate" class="form-control" min="{{ currentDate | date: 'yyyy-MM-dd' }}" mdbInput />
    </div>
  
    <div class="inpurwraper">
      <label for="input">Posting End Date<span class="required">*</span></label>
      <input type="date" id="input" class="form-control" [(ngModel)]="endDate" class="form-control" min="{{ currentDate | date: 'yyyy-MM-dd' }}" mdbInput />
    </div>

   
  
    
  
    <!-- <div class="inpurwraper">
      <label for="input">Description</label>
      <input
        [(ngModel)]="description"
        type="text"
        class="form-control"
      />
    </div> -->
  
  
    <div class="inpurwraper">
      <label for="input">Website Link or Mobile App Link</label>
      <input
        [(ngModel)]="web_link"
        type="text"
        class="form-control"
      />
    </div>

    <div class="inpurwraper">
      <label for="input">Youtube Video Link</label>
      <input
      [(ngModel)]="youtube_link"
        type="text"
        class="form-control"
      />
    </div>
  
    <div class="inpurwraper">
      <label>Deals Image<span class="required">*</span></label>
      <input
        type="file"
        accept="image/gif,image/jpeg,image/jpg,image/png"
        class="form-control"
        (change)="onFileChange($event)"
      />
    </div>
    <div class="image">
      <img src="{{advertise_image}}" style="width: 113px; margin-top: 10px; margin-left: 32px;"/>
      <p></p>
  </div>
 

  <div class="inpurwraper">
    <label for="input">Deals PDF</label>
    <input type="file"  class="form-control" 
    accept="application/pdf"
      (change)="onFileChangePDF($event)" />
  </div>
  <div>
    <p class="pdf">Preview File <a href="{{advertise_deal_pdf}}">{{advertise_deal_pdf}}</a></p>

  </div>

  

  
  

  
  
  
    <div class="inpbtn_wrap" *ngIf="UserData.user_type == 0 || UserData.user_type == 2">
      <button
        type="button"
        class="btn defbtn"
        *ngIf="title != '' && advertise_image != '' && country != '' && city != '' && state != '' && property != '' && merchant_name != '' && merchant_phone_number != null && startDate != '' && endDate != '' && address != '' && suite_no !='' && email != ''"
        type="submit"
        class="btn defbtn"
        (click)="onUpdate();"
      >
        Update
      </button>
  
      <button
        type="button"
        class="btn defbtn"
        *ngIf="title == '' || advertise_image == ''  || country == '' || city == '' || state == '' || property == '' || merchant_name == '' || merchant_phone_number == null || startDate == '' || endDate == '' || address == '' || suite_no == '' || email ==''"
        [disabled]="title == '' || advertise_image == ''  || country == '' || city == '' || state == '' || property == '' || merchant_name == '' || merchant_phone_number == null || startDate == '' || endDate == '' || address == '' || suite_no == '' || email == ''"
        type="submit"
        class="btn defbtn"
        style="cursor: no-drop;"
      >
        Update
      </button>
    </div>
  
    <div class="inpbtn_wrap" *ngIf="UserData.user_type == 1">
      <button
        type="button"
        class="btn defbtn"
        *ngIf="title != '' && advertise_image != ''"
        type="submit"
        class="btn defbtn"
        (click)="onUpdate();"
      >
        Update
      </button>
  
      <button
        type="button"
        class="btn defbtn"
        *ngIf="title == ''  || advertise_image == ''"
        [disabled]="title == '' || advertise_image == ''"
        type="submit"
        class="btn defbtn"
        style="cursor: no-drop;"
      >
        Update
      </button>
    </div>
  </div>
  

  