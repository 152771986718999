<div class="content_box">
  <h2>Service List</h2>
  <div class="responsive_table">
  <table mat-table class="mat-elevation-z8">
    <thead>
      <tr>
          <th style="width: 5%;">Sl#</th>
          <th style="width: 15%;">Service</th>
          <th style="width: 40%;">Description</th>
          <th style="width: 20%;">Service Provider</th>
          <th style="width: 10%;">Service Provider Address</th>
          <th *ngIf="user_type == 1" style="width: 10%;">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let servicedata of serviceList let i=index">
          <td data-label="Sl#">{{ i+1 }}</td>
          <td data-label="Service">{{ servicedata.service }}</td>
          <td data-label="Description">{{ servicedata.description }}</td>
          <td data-label="Service Provider">{{ servicedata.service_provider_name }} <br>
              <span class="sub-td">Contact No - {{ servicedata.contact_no }}</span>
          </td>
          <td data-label="Service Provider Address">{{ servicedata.address }}</td>
          <td data-label="Action" *ngIf="user_type == 1"><i style="cursor: pointer;" class="material-icons" [routerLink]="['/main/service-edit/'+servicedata._id]">create</i></td>
      </tr>
      </tbody>
  </table>
  </div>
</div>
<div *ngIf="user_type == 1">
  <div class="btn btn-primary btn-round default_btn mat-raised-button" [routerLink]="['/main/service']" style="float:right">
      <i class="material-icons float-icon">add</i>
  </div>
</div>
