<div class="content_box">
    <h2>Add Advertise Local Businesses</h2>

    <div class="inpurwraper">
      <label for="input">Merchant Name<span class="required">*</span></label>
      <input [(ngModel)]="merchant_name" type="text" class="form-control"  />
    </div>

    <div class="inpurwraper">
      <label for="input">Suite Number</label>
      <input type="text" [(ngModel)]="suite_no" class="form-control"  />
    </div>

    <div class="inpurwraper">
      <label>Merchant Address<span class="required">*</span></label>
      <input
        [(ngModel)]="address"
        type="text"
        class="form-control"
      />
    </div>

    <div class="inpurwraper">
      <label>Select country<span class="required">*</span></label>
      <select class="form-control" [(ngModel)]="country"
        (change)="onCountryChange(country)">
        <option *ngFor="let option of countryList" [value]="option.id">{{option.name}}</option>
      </select>
    </div>
  
    <div class="inpurwraper">
      <label>Select State<span class="required">*</span></label>
      <select [disabled]="country == ''" class="form-control" [(ngModel)]="state"
        (change)="onStateChange(state)">
        <option *ngFor="let option of stateList" [value]="option.id">{{option.name}}</option>
      </select>
    </div>
  
    <div class="inpurwraper">
      <label>Select city<span class="required">*</span></label>
      <select [disabled]="state == ''" class="form-control" [(ngModel)]="city"
        (change)="onCityChange(city)">
        <option *ngFor="let option of citylist" [value]="option.id">{{option.name}}</option>
      </select>
    </div>
  
    <label for="input">Select Property Name<span class="required">*</span></label>
    <ng-multiselect-dropdown [data]="dropdownList" [(ngModel)]="selectedItems"
      [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
    </ng-multiselect-dropdown>

    <div class="inpurwraper">
      <label for="input">Postal Code</label>
      <input type="text" [(ngModel)]="postal_code" class="form-control"  />
    </div>

    <div class="inpurwraper">
      <label for="input">Merchant Phone Number<span class="required">*</span></label>
      <!-- <input [(ngModel)]="merchant_phone_number" type="number" class="form-control"  /> -->
      <div>
      <input type="number"
        class="form-control form-input"
            ng2TelInput
            [ng2TelInputOptions]="{initialCountry: 'ca'}"
            (hasError)="hasError($event)"
            (ng2TelOutput)="getNumber($event)"
            (intlTelInputObject)="telInputObject($event)"
            [(ngModel)]="merchant_phone_number"
            (countryChange)="onCountryCodeChange($event)" />
            <p *ngIf="merchant_phone_numberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p>
            
    </div>
    </div>

    <div class="inpurwraper">
      <label for="input">Merchant Whatsapp No</label>
      <!-- <input [(ngModel)]="merchant_phone_number" type="number" class="form-control"  /> -->
      <div>
      <input type="number"
        class="form-control form-input"
            ng2TelInput
            [ng2TelInputOptions]="{initialCountry: 'ca'}"
            (hasError)="hasError($event)"
            (ng2TelOutput)="getNumber($event)"
            (intlTelInputObject)="telInputObject($event)"
            [(ngModel)]="merchant_alternate_phone_number"
            (countryChange)="onCountryCodeChange1($event)" />
            <p *ngIf="merchant_alternate_phone_number_Validation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p>
            
    </div>
    </div>

    <div class="inpurwraper">
      <label>Email<span class="required">*</span></label>
      <input
      [(ngModel)]="email"
        type="text"
        class="form-control"
      />
    </div>

    <div class="inpurwraper">
      <label>Advertise Title<span class="required">*</span></label>
      <input
        [(ngModel)]="title"
        type="text"
        class="form-control"
      />
    </div>

    <div class="inpurwraper">
      <label for="input">Posting Start Date<span class="required">*</span></label>
      <input type="date" id="input" class="form-control" [(ngModel)]="startDate" class="form-control" min="{{ currentDate | date: 'yyyy-MM-dd' }}" mdbInput />
    </div>
  
    <div class="inpurwraper">
      <label for="input">Posting End Date<span class="required">*</span></label>
      <input type="date" id="input" class="form-control" [(ngModel)]="endDate" class="form-control" min="{{ currentDate | date: 'yyyy-MM-dd' }}" mdbInput />
    </div>

    
  
   
  
    <!-- <div class="inpurwraper">
      <label>Description</label>
      <input
        [(ngModel)]="description"
        type="text"
        class="form-control"
      />
    </div> -->
  
  
    <div class="inpurwraper">
      <label>Website link or Mobile App Link</label>
      <input
        [(ngModel)]="web_link"
        type="text"
        class="form-control"
      />
    </div>

    <div class="inpurwraper">
      <label>Youtube Video Link</label>
      <input
      [(ngModel)]="youtube_link"
        type="text"
        class="form-control"
      />
    </div>
  
    <div class="inpurwraper">
      <label>Deals Image<span class="required">*</span></label>
      <input
        type="file"
        accept="image/gif,image/jpeg,image/jpg,image/png"
        class="form-control"
        (change)="onFileChange($event)"
      />
    </div>
    <div>
      <img [src]="advertise_image" style="width: 113px; margin-top: 10px; margin-left: 32px;"/>
  </div>

  <div class="inpurwraper">
    <label for="input">Deals PDF</label>
    <input type="file" class="form-control" 
    accept="application/pdf"
      (change)="onFileChangePDF($event)" />
  </div>
  
  
  
    <div class="inpbtn_wrap" *ngIf="UserData.user_type == 0  || UserData.user_type == 2">
      <button
        type="button"
        class="btn defbtn"
        *ngIf="title != '' && advertise_image != '' && country != '' && city != '' && state != '' && property != [] && merchant_name != '' && merchant_phone_number != null && startDate != '' && endDate != '' && address != ''"
        type="submit"
        class="btn defbtn"
        (click)="onAdd();"
      >
        Save
      </button>
  
      <button
        type="button"
        class="btn defbtn"
        *ngIf="title == '' || advertise_image == '' || country == '' || city == '' || state == '' || property == [] || merchant_name == '' || merchant_phone_number == null || startDate == '' || endDate == '' || address == ''"
        [disabled]="title == ''  || advertise_image == '' || country == '' || city == '' || state == '' || property == [] || merchant_name == '' || merchant_phone_number == null || startDate == '' || endDate == '' || address == ''"
        type="submit"
        class="btn defbtn"
        style="cursor: no-drop;"
      >
        Save
      </button>
    </div>
  
    <div class="inpbtn_wrap" *ngIf="UserData.user_type == 1 || UserData.user_type == 5">
      <button
        type="button"
        class="btn defbtn"
        *ngIf="title != '' "
        type="submit"
        class="btn defbtn"
        (click)="onAdd();"
      >
        Save
      </button>
  
      <button
        type="button"
        class="btn defbtn"
        *ngIf="title == '' "
        [disabled]="title == '' "
        type="submit"
        class="btn defbtn"
        style="cursor: no-drop;"
      >
        Save
      </button>
    </div>
  </div>
  