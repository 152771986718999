import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../webservice.service';

@Component({
  selector: 'app-addflashdealcharges',
  templateUrl: './addflashdealcharges.component.html',
  styleUrls: ['./addflashdealcharges.component.scss']
})
export class AddflashdealchargesComponent implements OnInit {
  title: any = '';
  amount: any = '';
  UserData: any;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) {
  }

  ngOnInit(): void {
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData);


  }

  onAdd() {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.title == '') {
      this.toastr.error('Please enter first name');
    } else if (this.amount == '') {
      this.toastr.error('Please enter Amount');
    } else {
      var params = {
        title: this.title,
        amount: this.amount,
      }
      this.spinner.show();
      this.webservice.adminPostData('saveVendorSubscription', params).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.toastr.success(result.msg);
          this.router.navigate(['/main/flash-deal-charges']);
          this.title = '';
          this.amount = '';

        } else {
          this.toastr.error(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }



}










