import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  userData: any;
  show:boolean =  false;
  dashboardData: any;
  country: any = '';
  state: any = '';
  city: any = '';
  condoName: any = '';
  countryList: any = [];
  cityList: any = [];
  stateList: any = [];
  consoNameList: any = [];
  country_id: any = '';
  state_id: any = '';
  city_id: any = '';
  countryCode:any='';
  stateCode:any='';
  cityCode:any='';
  Date:any='';
  constructor(public webservice: WebserviceService,private spinner: NgxSpinnerService,private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('LoginToken'));

    console.log('userData: ',this.userData)
     this.allCountryList();
     this.onDashBoardall();
  }
  allCountryList() {
    this.webservice.adminGetData('countryList').then(
      (result: any) => {
        if (result.status == 1) {
          this.countryList = result.data;
          console.log('countryList: ', this.countryList);
        } else {
          console.log(result.msg);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  OnChangeCountry(val) {
    this.country = val;
    console.log(this.country);
    if (this.country != '') {
      this.spinner.show();
      this.webservice.adminGetData('stateList/' + this.country).then(
        (result: any) => {
          if (result.status == 1) {
            this.stateList = result.data;
          } else {
            console.log(result.msg);
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
        }
      );
    }
  }

  OnChangeState(val) {
    this.state = val;
    console.log(this.state);
    if (this.state != '') {
      this.webservice.adminGetData('cityList/' + this.state).then(
        (result: any) => {
          if (result.status == 1) {
            this.cityList = result.data;
          } else {
            console.log(result.msg);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  onChangeCity(val) {
    this.city = val;
    console.log(this.city);
    if (this.city != '') {
      this.webservice.adminGetData('propertyListByCity/' + this.city).then(
        (result: any) => {
          console.log(result);
          if (result.status == 1) {
            this.consoNameList = result.data;
          } else {
            console.log(result.msg);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  onChangeCondoName(val) {
    this.condoName = val;
  }
  // OnChangeCountry(val) {
  //   this.countryCode = val.split('_')[0];
  //   this.country_id = val.split('_')[1];
  //   console.log(this.countryCode);
  //   console.log(this.country_id);
  //   if (this.countryCode != '') {
  //     this.spinner.show();
  //     this.webservice.adminGetData('stateList/' + this.countryCode).then(
  //       (result: any) => {
  //         if (result.status == 1) {
  //           this.stateList = result.data;
  //         } else {
  //           console.log(result.msg);
  //         }
  //         this.spinner.hide();
  //       },
  //       (err) => {
  //         this.spinner.hide();
  //         console.log(err);
  //       }
  //     );
  //   }
  // }

  // OnChangeState(val) {
  //   this.stateCode = val.split('_')[0];
  //   this.state_id = val.split('_')[1];
  //   console.log(this.state);
  //   if (this.stateCode != '') {
  //     this.webservice.adminGetData('cityList/' + this.stateCode).then(
  //       (result: any) => {
  //         if (result.status == 1) {
  //           this.cityList = result.data;
  //         } else {
  //           console.log(result.msg);
  //         }
  //       },
  //       (err) => {
  //         console.log(err);
  //       }
  //     );
  //   }
  // }

  // onChangeCity(val) {
  //   this.cityCode = val.split('_')[0];
  //   this.city_id = val.split('_')[1];
  //   console.log(this.city);
  //   if (this.cityCode!= '') {
  //     this.webservice.adminGetData('propertyListByCity/' + this.city).then(
  //       (result: any) => {
  //         console.log(result);
  //         if (result.status == 1) {
  //           this.consoNameList = result.data;
  //         } else {
  //           console.log(result.msg);
  //         }
  //       },
  //       (err) => {
  //         console.log(err);
  //       }
  //     );
  //   }
  // }

  // onChangeCondoName(val) {
  //   this.condoName = val;
  // }
  onDashBoardall(){
    if(this.userData.user_type == 0) {
      this.spinner.show();
      console.log("date=======",this.Date)
      if(this.country==""||this.country==null||this.country == undefined){
        this.country= undefined;
        this.state= undefined;
        this.city= undefined;
        if(this.Date){
          this.Date=this.Date;
        }else{
          this.Date=undefined;
        }
        this.webservice.adminGetData('getAdmindashboard/'+ this.userData._id + '/' + this.country+'/'+this.state+'/'+ this.city+'/'+this.Date).then((result: any) => {
          console.log("result", result);
          if (result.status == 1) {
            this.show = true;
            this.dashboardData = result;
          } else {
            this.show = false;
            this.toastr.error('Internal server error');
            console.log(result.msg)
          }
          this.spinner.hide();
        }, (err) => {
          this.toastr.error('Internal server error');
          this.show = false;
          this.spinner.hide();
          console.log(err);
        });
      }else{

        if(this.Date){
          this.Date=this.Date;

        }else{
          this.Date=undefined;
        }
        this.webservice.adminGetData('getAdmindashboard/'+ this.userData._id+ '/' + this.country+'/'+this.state+'/'+this.city+'/'+this.Date).then((result: any) => {
          console.log("result", result);
          if (result.status == 1) {
            this.show = true;
            this.dashboardData = result;
          } else {
            this.show = false;
            this.toastr.error('Internal server error');
            console.log(result.msg)
          }
          this.spinner.hide();
        }, (err) => {
          this.toastr.error('Internal server error');
          this.show = false;
          this.spinner.hide();
          console.log(err);
        });
      }
     
  
    } else if (this.userData.user_type == 5) {
  
      this.spinner.show();
      this.webservice.adminGetData('getVendordashboard/'+this.userData._id).then((result: any) => {
        console.log("result", result);
        if (result.status == 1) {
          this.show = true;
          this.dashboardData = result;
        } else {
          this.show = false;
          this.toastr.error('Internal server error');
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.toastr.error('Internal server error');
        this.show = false;
        this.spinner.hide();
        console.log(err);
      });
  
    } else {
      this.toastr.error('Internal server error');
    }
  }
  graphsearch(){
    console.log("country=====",this.country);
    console.log("state=====",this.state);
    console.log("city=====",this.city)
    console.log("date=====",this.Date)
    
    this.onDashBoardall();
  //   var params = {
  //     country_id: this.country,
  //     state_id: this.state,
  //     city_id: this.city,

  // }
  
}
resetForm() {
  this.country = '';
  this.state = '';
  this.city = '';
  this.condoName = '';
  this.Date = '';
  this.onDashBoardall();
}

}
