<div class="banner_section" style="background: url(assets/images/innerbanner.png) no-repeat center top ; background-size: cover; ">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-7 ">
                <h2>Offer <strong> smart building </strong><span>technology to your residents</span></h2>

            </div>
        </div>
    </div>
</div>
