import { Component, OnInit } from '@angular/core';
import { WebserviceService } from '../webservice.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vendor-subscription-list',
  templateUrl: './vendor-subscription-list.component.html',
  styleUrls: ['./vendor-subscription-list.component.scss'],
})
export class VendorSubscriptionListComponent implements OnInit {
  subscription_name: any = '';
  subscription_amount: any = '';
  subscription_start_date: any = '';
  UserData: any;
  subscription_end_date: any;
  vendor_id: any;

  constructor(
    public webservice: WebserviceService,

    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    // this.vendor_id = this.UserData._id
    console.log(this.UserData);
    this.subscriptionList();
  }
  subscriptionList() {
    this.spinner.show();
    this.webservice
      .adminGetData('vendorSubscription/' + this.UserData._id)
      .then(
        (result: any) => {
          console.log('subscription List', result);

          if (result.success === 1) {
            this.subscription_name = result.data.subscription_name;
            this.subscription_amount = result.data.subscription_amount;
            this.subscription_start_date = result.data.subscription_start_date;
            this.subscription_end_date = result.data.subscription_end_date;
          } 
          else {
            console.log(result.message);
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
        }
      );
  }

  cancel() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Cancel subscription!',
    }).then(
      (result) => {
        if (result.isConfirmed) {
          this.webservice
            .adminGetData('cancelSubscription/' + this.UserData._id)
            .then((result: any) => {
              console.log('Cancel Result', result);
              if (result.success == 1) {
                console.log(result.message);

                this.subscription_name = '';
                this.subscription_amount = '';
                this.subscription_start_date = '';
                this.subscription_end_date = '';
                this.subscriptionList();
                this.toastr.success(result.message);
              }
              else {
                this.toastr.error(result.message);
              }
              this.spinner.hide();
            },
              (err) => {
                this.spinner.hide();
                console.log(err);
              }
            );

        }
      },
    )
  }
}

