import { Component, OnInit } from '@angular/core';
import { WebserviceService } from 'src/app/webservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-cmsedit',
  templateUrl: './cmsedit.component.html',
  styleUrls: ['./cmsedit.component.scss']
})
export class CmseditComponent implements OnInit {
  title: any = '';
  content: any = '';
  edit_cms: any = '';
  data: any;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute, ) { }

  // CMSById/'+id
  // /admin/updateCMS', updatecms

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.edit_cms = params['cms_id'];
    });
    this.spinner.show();
    this.webservice.adminGetData('CMSById/' + this.edit_cms).then((result) => {
      this.data = result;
      console.log("result", this.data);
      if (this.data.status == 1) {
        this.title = this.data.data.title,
          this.content = this.data.data.cms_content;
      } else {
        console.log(this.data.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  omUpdate() {
    var params = {
      cms_id: this.edit_cms,
      title: this.title,
      cms_content: this.content
    };
    this.spinner.show();
    this.webservice.adminPostData('updateCMS', params).then((result) => {
      this.data = result;
      if (this.data.status == 1) {
        this.toastr.success(this.data.msg);
      } else {
        this.toastr.error(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

}
