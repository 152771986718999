import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';

// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HometopbannerComponent } from './components/hometopbanner/hometopbanner.component';
import { HomeComponent } from './pages/home/home.component';
import { PlaystoreaddComponent } from './layout/playstoreadd/playstoreadd.component';
import { WhatmatterComponent } from './components/whatmatter/whatmatter.component';
import { ResidentsinfoComponent } from './components/residentsinfo/residentsinfo.component';
import { ServicesComponent } from './components/services/services.component';
import { SmarttechnologyComponent } from './components/smarttechnology/smarttechnology.component';
import { ManagementtoolsComponent } from './components/managementtools/managementtools.component';

import { LoginComponent } from './pages/login/login.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { InnerbannerComponent } from './components/innerbanner/innerbanner.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { NoPageFoundComponent } from './pages/no-page-found/no-page-found.component';
import { SomeclientsComponent } from './components/someclients/someclients.component';
import { ManageResidentComponent } from './features/manage-resident/manage-resident.component';
import { DashboardComponent } from './features/manage-resident/dashboard/dashboard.component';
import { ManageprofileComponent } from './features/manage-resident/manageprofile/manageprofile.component';
import { ManageresidentsComponent } from './features/manage-resident/manageresidents/manageresidents.component';
import { ManageconciergeComponent } from './features/manage-resident/manageconcierge/manageconcierge.component';
import { ManagelistingsComponent } from './features/manage-resident/managelistings/managelistings.component';
import { ViewmessageComponent } from './features/manage-resident/viewmessage/viewmessage.component';
import { ManagememoComponent } from './features/manage-resident/managememo/managememo.component';
import { ManagedservicesComponent } from './features/manage-resident/managedservices/managedservices.component';
import { WebserviceService } from './webservice.service';
import { HttpClientModule } from 'node_modules/@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AdminloginComponent } from './pages/adminlogin/adminlogin.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PropertyComponent } from './features/manage-resident/property/property.component';
import { AddpropertyComponent } from './features/manage-resident/addproperty/addproperty.component';
import { EditpropertyComponent } from './features/manage-resident/editproperty/editproperty.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { ResidentComponent } from './features/manage-resident/resident/resident.component';
import { ResidentaddComponent } from './features/manage-resident/residentadd/residentadd.component';
import { ResidenteditComponent } from './features/manage-resident/residentedit/residentedit.component';
import { PropertymanagerComponent } from './features/manage-resident/propertymanager/propertymanager.component';
import { PropertymanageraddComponent } from './features/manage-resident/propertymanageradd/propertymanageradd.component';
import { PropertymanagereditComponent } from './features/manage-resident/propertymanageredit/propertymanageredit.component';
import { StaffComponent } from './features/manage-resident/staff/staff.component';
import { StaffaddComponent } from './features/manage-resident/staffadd/staffadd.component';
import { StaffeditComponent } from './features/manage-resident/staffedit/staffedit.component';
import { ManageserviceComponent } from './features/manage-resident/manageservice/manageservice.component';
import { CmslistComponent } from './features/manage-resident/cmslist/cmslist.component';
import { CmseditComponent } from './features/manage-resident/cmsedit/cmsedit.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { MatDatepickerModule } from '@angular/material/datepicker/';
import { MatNativeDateModule } from '@angular/material/core';
import { AddmemoComponent } from './features/manage-resident/addmemo/addmemo.component';
import { EditmemoComponent } from './features/manage-resident/editmemo/editmemo.component';
import { ChangepasswordComponent } from './features/manage-resident/changepassword/changepassword.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { FamilymemberComponent } from './features/manage-resident/familymember/familymember.component';
import { AdvatismentComponent } from './features/manage-resident/advatisment/advatisment.component';
import { AddAdvatismentComponent } from './features/manage-resident/add-advatisment/add-advatisment.component';
import { EditAdvatismentComponent } from './features/manage-resident/edit-advatisment/edit-advatisment.component';
import { DailydealsComponent } from './features/manage-resident/dailydeals/dailydeals.component';
import { DailydealsaAddComponent } from './features/manage-resident/dailydealsa-add/dailydealsa-add.component';
import { DailydealseditComponent } from './features/manage-resident/dailydealsedit/dailydealsedit.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ResourcesComponent } from './features/manage-resident/resources/resources.component';
import { ResourcesAddComponent } from './features/manage-resident/resources-add/resources-add.component';
import { ResourcesEditComponent } from './features/manage-resident/resources-edit/resources-edit.component';
import { VendarComponent } from './features/manage-resident/vendar/vendar.component';
import { VendorAddComponent } from './features/manage-resident/vendor-add/vendor-add.component';
import { VendorEditComponent } from './features/manage-resident/vendor-edit/vendor-edit.component';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { TestComponent } from './features/manage-resident/test/test.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FlashdealchargesComponent } from './flashdealcharges/flashdealcharges.component';
import { AddflashdealchargesComponent } from './addflashdealcharges/addflashdealcharges.component';
import { EditFlashDealChargesComponent } from './edit-flash-deal-charges/edit-flash-deal-charges.component';
import { PayComponent } from './pay/pay.component';
//import { NgxStripeModule } from 'ngx-stripe';
import { NgxPaginationModule } from 'ngx-pagination';
import { SavedcardsComponent } from './savedcards/savedcards.component';
import { VendorSubscriptionListComponent } from './vendor-subscription-list/vendor-subscription-list.component';
import { OverViewComponent } from './over-view/over-view.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { PayresourceComponent } from './payresource/payresource.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SuccessComponent } from './success/success.component';
import { AdminsubscriptionlistComponent } from './adminsubscriptionlist/adminsubscriptionlist.component';
import { VendorProfileComponent } from './vendor-profile/vendor-profile.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HometopbannerComponent,
    HomeComponent,
    PlaystoreaddComponent,
    WhatmatterComponent,
    ResidentsinfoComponent,
    ServicesComponent,
    SmarttechnologyComponent,
    ManagementtoolsComponent,
    LoginComponent,
    RegistrationComponent,
    AboutusComponent,
    ContactusComponent,
    PartnersComponent,
    InnerbannerComponent,
    NoPageFoundComponent,
    SomeclientsComponent,
    ManageResidentComponent,
    DashboardComponent,
    ManageprofileComponent,
    ManageresidentsComponent,
    ManageconciergeComponent,
    ManagelistingsComponent,
    ViewmessageComponent,
    ManagememoComponent,
    ManagedservicesComponent,
    AdminloginComponent,
    PropertyComponent,
    AddpropertyComponent,
    EditpropertyComponent,
    ResidentComponent,
    ResidentaddComponent,
    ResidenteditComponent,
    PropertymanagerComponent,
    PropertymanageraddComponent,
    PropertymanagereditComponent,
    StaffComponent,
    StaffaddComponent,
    StaffeditComponent,
    ManageserviceComponent,
    CmslistComponent,
    CmseditComponent,
    // ChangepasswordComponent,
    EditmemoComponent,
    AddmemoComponent,
    ChangepasswordComponent,
    FamilymemberComponent,
    AdvatismentComponent,
    AddAdvatismentComponent,
    EditAdvatismentComponent,
    DailydealsComponent,
    DailydealsaAddComponent,
    DailydealseditComponent,
    ResourcesComponent,
    ResourcesAddComponent,
    ResourcesEditComponent,
    VendarComponent,
    VendorAddComponent,
    VendorEditComponent,
    TestComponent,
    FlashdealchargesComponent,
    AddflashdealchargesComponent,
    EditFlashDealChargesComponent,
    PayComponent,
    SavedcardsComponent,
    VendorSubscriptionListComponent,
    OverViewComponent,
    FeedbackComponent,
    PayresourceComponent,
    SubscriptionComponent,
    SuccessComponent,
    AdminsubscriptionlistComponent,
    VendorProfileComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    HttpClientModule,
    FormsModule,
    CKEditorModule,
    MatDatepickerModule,
    NgxSpinnerModule,
    MatNativeDateModule,
    GooglePlaceModule,
    Ng2TelInputModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    NgxPaginationModule,

    //NgxStripeModule.forRoot('pk_test_51HqwWhIvNyzSTLksrxJLQTSrBtx6uc3TF8v8SfNh2j4cJiq6c5mBjxAWMYxruuZ9TSBh3DA9y3AkqNpL0NvaWmsw000w6mvNC5'),
    ToastrModule.forRoot({
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
      easing: 'ease-in-out',
      easeTime: 1000,
      progressAnimation: 'increasing',
      maxOpened: 1,
      autoDismiss: true,
    }),
    // ToastrModule.forRoot({
    //   timeOut: 10000,
    //   positionClass: 'toast-top-right',
    //   preventDuplicates: false,
    //maxOpened:1,
    // }),
    NgMultiSelectDropDownModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger', // set defaults here
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    WebserviceService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
