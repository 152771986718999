import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';

@Component({
  selector: 'app-vendar',
  templateUrl: './vendar.component.html',
  styleUrls: ['./vendar.component.scss']
})
export class VendarComponent implements OnInit {


  POST:any;
  page:number = 1;
  count:number = 0;
  tableSize:number = 4;
  tableSizes:any = [5,10,15,20];

  public VendorList: any = [];
  user_type: any;
  UserData: any
  search: any = '';
  data:any;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    // this.postList();
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log('user_type--'+this.UserData);
    if (this.UserData.user_type == 0) {
      var parantId = 'no_parent'
    } else {
      parantId = this.UserData._id;
    }
    this.user_type = this.UserData.user_type;
    this.spinner.show();
    this.webservice.adminGetData('vendorList/undefined').then((result: any) => {
      console.log(result);
      if(result.status == 1) {
        this.VendorList = result.data;
      } else {
        console.log(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  onSearch(val) {
    this.search = val;
    this.page = 1;
    if (this.search != '') {
      this.spinner.show();
      this.webservice.adminGetData('vendorList/'+this.search).then((result: any) => {
        console.log(result);
        if(result.status == 1) {
          this.VendorList = result.data;
        } else {
          console.log(result.msg);
        }
        this.spinner.hide()
      }, (err) => {
        this.spinner.hide()
        console.log(err);
      });

    } else {
      this.spinner.show();
      this.webservice.adminGetData('vendorList/undefined').then((result: any) => {
        console.log(result);
        if(result.status == 1) {
          this.VendorList = result.data;
        } else {
          console.log(result.msg);
        }
        this.spinner.hide()
      }, (err) => {
        this.spinner.hide()
        console.log(err);
      });

    }

  }

  // postList():void{
  //   this.webservice.adminGetData('vendorList/undefined').then((result)=>{
  //     this.POST = result;
  //     console.log("pagination:",this.POST);
  //   })
  // }
  onTableDataChange(event:any){
    this.page = event ;
    // this.postList();
  }
  onTableSizeChange(event:any): void{
    this.tableSize = event.target.value;
    this.page = 1 ;
    // this.postList();
  }
  onstatus(_id,status){
    console.log(_id)
    var params = {
      vendor_id: _id,
      status:status
    }
    this.spinner.show();
    this.webservice.adminPostData('blockVendor',params).then((result) => {
      this.data = result;
      console.log(this.data);
      if(this.data.status == 1) {
        this.toastr.success(this.data.msg);
        this.getvendorlist();
      } else {
        console.log(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  getvendorlist(){
    if (this.search != '') {
      this.spinner.show();
      this.webservice.adminGetData('vendorList/'+this.search).then((result: any) => {
        console.log(result);
        if(result.status == 1) {
          this.VendorList = result.data;
        } else {
          console.log(result.msg);
        }
        this.spinner.hide()
      }, (err) => {
        this.spinner.hide()
        console.log(err);
      });

    } else {
      this.spinner.show();
      this.webservice.adminGetData('vendorList/undefined').then((result: any) => {
        console.log(result);
        if(result.status == 1) {
          this.VendorList = result.data;
        } else {
          console.log(result.msg);
        }
        this.spinner.hide()
      }, (err) => {
        this.spinner.hide()
        console.log(err);
      });

    }
  }

}
