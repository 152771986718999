<div class="content_box">
    <h2>Flash Deal List</h2>
    <div style="margin-bottom: 10px;">
        <mdb-icon fas icon="search" aria-hidden="true"></mdb-icon>
      <input style="min-height: 40px;" class="form-control form-control-sm w-60 srchIcon" type="text" placeholder="Search by Country, State, City, Property name" aria-label="Search"
        mdbInput [(ngModel)]="search" (ngModelChange)="onSearch(search)">
      </div>
      <div class="responsive_table">

    <table mat-table class="mat-elevation-z8">
      <thead>
        <tr>
            <th>Sl#</th>
            <th>Property Name</th>
            <th>Country</th>
            <th>State</th>
            <th>City</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>From Time</th>
            <th>To Time</th>
            <th>Title</th>
            <th >Description</th>
            <!-- <th style="padding: 18px 18px;">Title</th>
            <th style="padding: 18px 18px;">Description</th> -->
            <th>Action</th>
        </tr>
      </thead>
        <tbody>
        <tr *ngFor="let item of list | paginate:{itemsPerPage: tableSize, currentPage: page, totalItems:count}; let i=index">
            <td data-label="Sl#" >{{ (page - 1) * tableSize + i +1 }}</td>
            <td data-label="Property Name" >{{item.property_name}}</td>
            <td data-label="Country" >{{item.country_name}}</td>
            <td data-label="State" >{{ item.state_name === 'xxxx' ? 'No state found' : item.state_name }}</td>
            <td data-label="City">{{ item.city_name === 'xxxx' ? 'No city found' : item.city_name }}</td>
            <td data-label="Start Date" >{{item.start_date | date: 'dd/MM/yyyy'}}</td>
            <td data-label="End Date" >{{item.end_date | date: 'dd/MM/yyyy'}}</td>
            <td data-label="From Time" >{{item.from_time}}</td>
            <td data-label="To Time"  class="linebreak">{{item.to_time}}</td>
            <td data-label="Title"  class="linebreak">{{item.title}}</td>
            <td data-label="Description" class="linebreak">{{item.description}}</td>
            <!-- <td><i style="cursor: pointer;" class="material-icons" [routerLink]="['/main/add-dailydeals]">create</i></td> -->
            <td data-label="Action" >
              <div class="d-flex floatr"><i style="cursor: pointer;" class="material-icons" [routerLink]="['./edit-dailydeals/'+item._id]">create</i>
                <i style="padding-left:10%" class="btn btn-outline-secondary" mwlConfirmationPopover [popoverTitle]="popoverTitle"
                [popoverMessage]="popoverMessage" placement="left" (confirm)="onDelete(item._id)"
                (cancel)="cancelClicked = true" style="cursor: pointer;" class="material-icons">delete</i></div></td>
        </tr>
        </tbody>
    </table>
    </div>
  </div>
  <div class="justify-content-end d-flex">
    <pagination-controls previousLabel="Prev" nextLabel="Next" maxSize="5" (pageChange)="onTableDataChange($event) " ></pagination-controls>
  </div>
  <div class="Nodatafound" *ngIf="list.length == 0">No result found!</div>
  
  <div class="btn btn-primary btn-round default_btn mat-raised-button" [routerLink]="['./pay']" style="float:right" *ngIf="paymentstaus == false">
    <i class="material-icons float-icon">add</i>
  </div> 
  
  <div class="btn btn-primary btn-round default_btn mat-raised-button" [routerLink]="['./add-dailydeals']" style="float:right" *ngIf="paymentstaus == true">
    <i class="material-icons float-icon">add</i>
  </div>

  <!-- <div class="btn btn-primary btn-round default_btn mat-raised-button" [routerLink]="['./add-dailydeals']" style="float:right">
    <i class="material-icons float-icon">add</i>
  </div> -->



<!-- Modal -->
<!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content"> -->
      <!-- <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
      <!-- <div class="modal-body">
        <h1>You are not an subscriber.</h1>
        <h2>Please Pay to Add Flash Deal.</h2>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal"[routerLink]="['/main/pay']">Pay Now</button>
      </div>
    </div>
  </div>
</div> -->