import { Component, OnInit, ViewEncapsulation, AfterViewInit, Output, EventEmitter, HostListener, Inject } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { WebserviceService } from 'src/app/webservice.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('fade',
      [
        state('void', style({ opacity: 0 })),
        transition(':enter', [animate(300)]),
        transition(':leave', [animate(500)]),
      ]
    )]
})
export class HeaderComponent implements OnInit {

  navbarOpen = false;
  public clicked = false;
  _el: any;
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  UserData: any = '';


  constructor(@Inject(DOCUMENT) document, public webservice: WebserviceService,public router: Router,private toastr: ToastrService,) {
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));

    webservice.getLoggedInData.subscribe((data) => {
      this.UserData = data;
    })
  }

  ngOnInit(): void {
  }


  onClick(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicked = true;
  }


  @HostListener('document:click', ['event'])

  private clickedOutside(event): void {
    if (this.clicked) {
      this._el.nativeElement.querySelector('.dropdown-menu').classList.toggle('show');
    }

  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 10) {
      let element = document.getElementById('navbar');
      element.classList.add('sticky');
    } else {
      let element = document.getElementById('navbar');
      element.classList.remove('sticky');
    }
  }

  onLOgout() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to Logout?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Logout!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/admin-login/admin']);
        // localStorage.removeItem('LoginToken');
        // this.webservice.sendLoggedData(null);
        localStorage.removeItem('LoginToken');
        this.webservice.sendLoggedData(null);
        this.toastr.success("Logout successful");
        // location.reload();
        // Swal.fire(
          
        //   'LogOut Successfull!!',
         
        // )
      }
    })




   
  };

//   onLOgout() {
//     Swal.fire({
//       title: 'Are you sure?',
//       text: "You won't be able to revert this!",
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes, delete it!'
//     }).then((result) => {
//       if (result.isConfirmed) {
//         this.router.navigate(['login']);
//         localStorage.removeItem('LoginToken');
//         this.webservice.sendLoggedData(null);
//         location.reload();
//         Swal.fire(
//           'Deleted!',
//           'Your file has been deleted.',
//           'success'
//         )
//       }
//     })
      
  

// }
}