<div class="content_box">
    <h2>Vendor List</h2>
    <div style="margin-bottom: 10px;">
        <mdb-icon fas icon="search" aria-hidden="true"></mdb-icon>
      <input style="min-height: 40px;" class="form-control form-control-sm w-60 srchIcon" type="text" placeholder="Search by Country, State, City, Staff name" aria-label="Search"
        mdbInput [(ngModel)]="search" (ngModelChange)="onSearch(search)">
      </div>
      <div class="responsive_table">

    <table mat-table class="mat-elevation-z8">
      <thead>
        <tr>
            <th>Sl#</th>
            <th>Staff Name</th>
            <th>Email</th>
            <th>Country</th>
            <th>State</th>
            <th>City</th>
            <th>Action</th>
            <th>Status </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of VendorList | paginate:{itemsPerPage: tableSize, currentPage: page, totalItems:count}; let i=index">
            <td data-label="Sl#">{{ (page - 1) * tableSize + i +1 }}</td>
            <td data-label="Staff Name">{{item.name}}</td>
            <td data-label="Email">{{item.email}}</td>
            <td data-label="Country">{{item.country}}</td>
            <td data-label="State">{{ item.province === 'xxxx' ? 'No state found' : item.province }}</td>
            <td data-label="City">{{ item.city === 'xxxx' ? 'No city found' : item.city }}</td>
            <td data-label="Action"><i style="cursor: pointer;" class="material-icons" [routerLink]="['./edit-vendor/'+item._id]">create</i></td>
            <td data-label="Status">              
              <button class="blockBtn" *ngIf="item.status == 2"><i style="cursor: pointer;" (click)="onstatus(item._id,1);">Unblock</i></button>                        
              <button class="blockBtn" *ngIf="item.status == 1"><i style="cursor: pointer;" (click)="onstatus(item._id,2);">Block</i></button>
            </td>
        </tr>
      </tbody>
    </table>
    </div>
    <div class="justify-content-end d-flex">
      <pagination-controls previousLabel="Prev" nextLabel="Next" maxSize="5"  (pageChange)="onTableDataChange($event) " ></pagination-controls>
    </div>
    <div *ngIf="VendorList.length == 0" class="Nodatafound">No result found!</div>
  </div>
  <div class="btn btn-primary btn-round default_btn mat-raised-button" [routerLink]="['./add-vendor']" style="float:right">
    <i class="material-icons float-icon">add</i>
  </div>