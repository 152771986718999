<div class="managementtools_section">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h2><strong> EQ11</strong> management tools</h2>
                <div class="management_slider_area">
                    <!-- <owl-carousel [options]="{items: 3, dots: false, navigation: false}" [items]="images" [carouselClasses]="['owl-theme', 'row', 'sliding']">
                            <div class="item">
                                <span>
                                    
                                </span>
                                <h3>Smart Door Lock</h3>
                                <p>There are many variations of passages of Lorem Ipsum. available</p>

                            </div>
                        </owl-carousel> -->

                    <owl-carousel-o [options]="customOptions" class="management_slider">
                        <ng-template class="slide_opt" carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
                            <div class="slide_item">
                                <span>
                                    <img [src]="slide.src" [alt]="slide.alt" class="managment_porf_icon" >
                                    <img [src]="slide.srchover" [alt]="slide.alt" class="hovepic" >
                                </span>
                                <h3>{{slide.title}}</h3>
                                <p>{{slide.descroption}}</p>
                            </div>
                        </ng-template>
                    </owl-carousel-o>

                </div>
            </div>
        </div>
    </div>
</div>