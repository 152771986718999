import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from "../app/pages/home/home.component";
import { AboutusComponent } from "../app/pages/aboutus/aboutus.component";
import { ContactusComponent } from "../app/pages/contactus/contactus.component";
import { PartnersComponent } from "../app/pages/partners/partners.component";
import { ManageResidentComponent } from './features/manage-resident/manage-resident.component';

import { DashboardComponent } from './features/manage-resident/dashboard/dashboard.component';
import { ManageprofileComponent } from './features/manage-resident/manageprofile/manageprofile.component';
import { ManageresidentsComponent } from './features/manage-resident/manageresidents/manageresidents.component';
import { ManageconciergeComponent } from './features/manage-resident/manageconcierge/manageconcierge.component';
import { ManagelistingsComponent } from './features/manage-resident/managelistings/managelistings.component';
import { ViewmessageComponent } from './features/manage-resident/viewmessage/viewmessage.component';
import { ManagememoComponent } from './features/manage-resident/managememo/managememo.component';
import { ManagedservicesComponent } from './features/manage-resident/managedservices/managedservices.component';

import { NoPageFoundComponent } from "../app/pages/no-page-found/no-page-found.component";
import { AdminloginComponent } from './pages/adminlogin/adminlogin.component';
import { PropertyComponent } from './features/manage-resident/property/property.component';
import { EditpropertyComponent } from './features/manage-resident/editproperty/editproperty.component';
import { AddpropertyComponent } from './features/manage-resident/addproperty/addproperty.component';
import { ResidentComponent } from './features/manage-resident/resident/resident.component';
import { ResidenteditComponent } from './features/manage-resident/residentedit/residentedit.component';
import { ResidentaddComponent } from './features/manage-resident/residentadd/residentadd.component';
import { PropertymanagerComponent } from './features/manage-resident/propertymanager/propertymanager.component';
import { PropertymanageraddComponent } from './features/manage-resident/propertymanageradd/propertymanageradd.component';
import { PropertymanagereditComponent } from './features/manage-resident/propertymanageredit/propertymanageredit.component';
import { StaffComponent } from './features/manage-resident/staff/staff.component';
import { StaffaddComponent } from './features/manage-resident/staffadd/staffadd.component';
import { StaffeditComponent } from './features/manage-resident/staffedit/staffedit.component';
import { ManageserviceComponent } from './features/manage-resident/manageservice/manageservice.component';
import { CmslistComponent } from './features/manage-resident/cmslist/cmslist.component';
import { CmseditComponent } from './features/manage-resident/cmsedit/cmsedit.component';
// import { ChangepasswordComponent } from './features/manage-resident/changepassword/changepassword.component';
import { AddmemoComponent } from './features/manage-resident/addmemo/addmemo.component';
import { EditmemoComponent } from './features/manage-resident/editmemo/editmemo.component';
import { ChangepasswordComponent } from './features/manage-resident/changepassword/changepassword.component';
import { FamilymemberComponent } from './features/manage-resident/familymember/familymember.component';
import { AdvatismentComponent } from './features/manage-resident/advatisment/advatisment.component';
import { AddAdvatismentComponent } from './features/manage-resident/add-advatisment/add-advatisment.component';
import { EditAdvatismentComponent } from './features/manage-resident/edit-advatisment/edit-advatisment.component';
import { DailydealsComponent } from './features/manage-resident/dailydeals/dailydeals.component';
import { DailydealsaAddComponent } from './features/manage-resident/dailydealsa-add/dailydealsa-add.component';
import { DailydealseditComponent } from './features/manage-resident/dailydealsedit/dailydealsedit.component';
import { ResourcesComponent } from './features/manage-resident/resources/resources.component';
import { ResourcesEditComponent } from './features/manage-resident/resources-edit/resources-edit.component';
import { ResourcesAddComponent } from './features/manage-resident/resources-add/resources-add.component';
import { VendarComponent } from './features/manage-resident/vendar/vendar.component';
import { VendorAddComponent } from './features/manage-resident/vendor-add/vendor-add.component';
import { VendorEditComponent } from './features/manage-resident/vendor-edit/vendor-edit.component';
import { TestComponent } from './features/manage-resident/test/test.component';
import { FlashdealchargesComponent } from './flashdealcharges/flashdealcharges.component';
import { AddflashdealchargesComponent } from './addflashdealcharges/addflashdealcharges.component';
import { EditFlashDealChargesComponent } from './edit-flash-deal-charges/edit-flash-deal-charges.component';
import { PayComponent } from './pay/pay.component';
import { SavedcardsComponent } from './savedcards/savedcards.component';
import { VendorSubscriptionListComponent } from './vendor-subscription-list/vendor-subscription-list.component';
import { OverViewComponent } from './over-view/over-view.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { PayresourceComponent } from './payresource/payresource.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SuccessComponent } from './success/success.component';
import { AdminsubscriptionlistComponent } from './adminsubscriptionlist/adminsubscriptionlist.component';
import { VendorProfileComponent } from './vendor-profile/vendor-profile.component';

const routes: Routes = [
  
  { path: '', redirectTo: '/admin-login/admin', pathMatch: 'full' },
  { path: 'admin-login/:user_extension', component: AdminloginComponent },
  { path: 'home', component: HomeComponent },
  // { path: 'product-detail/:id', component: ProductDetailComponent },
  { path: 'aboutus', pathMatch: 'prefix', component: AboutusComponent },
  { path: 'contactus', pathMatch: 'prefix', component: ContactusComponent },
  { path: 'partners', pathMatch: 'prefix', component: PartnersComponent },
  {
    path: 'main', component: ManageResidentComponent,
    children: [
     
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'property',
        component: PropertyComponent
      },
      {
        path: 'property/property-edit/:property_id',
        component: EditpropertyComponent
      },
      {
        path: 'resident-list/family-member/:resident_id',
        component: FamilymemberComponent
      },
      {
        path: 'property/property-add',
        component: AddpropertyComponent
      },
      {
        path: 'resident-list',
        component: ResidentComponent
      },
      {
        path: 'resident-list/resident-edit/:resident_id',
        component: ResidenteditComponent
      }, {
        path: 'resident-list/resident-add',
        component: ResidentaddComponent
      },
      {
        path: 'property-manager',
        component: PropertymanagerComponent
      },
      {
        path: 'property-manager/property-manager-add',
        component: PropertymanageraddComponent
      },
      {
        path: 'property-manager/property-manager-edit/:property_manager_id',
        component: PropertymanagereditComponent
      },
      {
        path: 'staff',
        component: StaffComponent
      },
      {
        path: 'staff/staff-add',
        component: StaffaddComponent
      },
      {
        path: 'staff/staff-edit/:staff_id',
        component: StaffeditComponent
      },
      {
        path: 'change-password',
        component: ChangepasswordComponent
      },
      {
        path: 'manageprofile',
        component: ManageprofileComponent
      },
      {
        path: 'manageresidents',
        component: ManageresidentsComponent
      },
      {
        path: 'manageconcierge',
        component: ManageconciergeComponent
      },
      {
        path: 'viewmessage',
        component: ViewmessageComponent
      },
      {
        path: 'cms-list',
        component: CmslistComponent
      },
      {
        path: 'cms-list/cms-edit/:cms_id',
        component: CmseditComponent
      },

      {
        path: 'manage-memo',
        component: ManagememoComponent
      },
      {
        path: 'add-memo',
        component: AddmemoComponent
      },
      {
        path: 'edit-memo/:memo_id',
        component: EditmemoComponent
      },
      {
        path: 'manage-service',
        component: ManageserviceComponent
      },
      {
        path: 'manage-listing',
        component: ManagelistingsComponent
      },
      {
        path: 'managedservices',
        component: ManagedservicesComponent
      },
      {
        path: 'advertise',
        component: AdvatismentComponent
      },
      {
        path: 'advertise/add-advertise',
        component: AddAdvatismentComponent
      },
      {
        path: 'dailydeals',
        component: DailydealsComponent
      },
      {
        path: 'dailydeals/add-dailydeals',
        component: DailydealsaAddComponent
      },
      {
        path: 'dailydeals/edit-dailydeals/:daily_deal_id',
        component: DailydealseditComponent
      },
      {
        path: 'advertise/edit-advertise/:advertise',
        component: EditAdvatismentComponent
      },
      {
        path: 'resources',
        component: ResourcesComponent
      },
      {
        path: 'resources/edit-resources/:resources_id',
        component: ResourcesEditComponent
      },
      {
        path: 'resources/add-resources',
        component: ResourcesAddComponent
      },
      {
        path: 'vendor-list',
        component: VendarComponent
      },
      {
        path: 'vendor-list/add-vendor',
        component: VendorAddComponent
      },
      {
        path: 'vendor-list/edit-vendor/:vendor_id',
        component: VendorEditComponent
      },
      {
        path: 'test',
        component: TestComponent
      },
      {
        path: 'flash-deal-charges',
        component: FlashdealchargesComponent
      },
      {
        path: 'flash-deal-charges/add-flash-deal-charges',
        component: AddflashdealchargesComponent
      },
      {
        path: 'flash-deal-charges/edit-flash-deal-charges/:id',
        component: EditFlashDealChargesComponent
      },
      {
        path: 'dailydeals/pay',
        component: PayComponent
      },
      {
        path: 'resources/payresource',
        component: PayresourceComponent
      },
      {
        path: 'saveCards',
        component: SavedcardsComponent
      },
      {
        path: 'vendorSubscriptionList',
        component:VendorSubscriptionListComponent
      },
      {
        path: 'overView',
        component:OverViewComponent
      },
      {
        path: 'Support',
        component:FeedbackComponent
      },
      {
        path: 'subscription',
        component:SubscriptionComponent
      },
      {
        path: 'subscription/sucess',
        component: SuccessComponent
      },
      {
        path: 'adminsubscription',
        component: AdminsubscriptionlistComponent
      },
      {
        path: 'adminsubscription/vendor-profile/:profile_id',
        component: VendorProfileComponent
      },
    ]
  },
  { path: '**', component: NoPageFoundComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
