<div class="content_box">
  <h2>Edit CMS</h2>
          <input matInput placeholder="Title" [(ngModel)]="title">

      <ckeditor [(ngModel)]="content"></ckeditor>
      <br>
      <button type="button"
      class="btn defbtn" (click)="omUpdate();"
       *ngIf="title != '' && content != ''">Update</button>

       <button type="button"
       class="btn defbtn" (click)="omUpdate();"
        *ngIf="title == '' || content == ''"
        [disabled]="title == '' || content == ''"
        style="cursor: no-drop;">Update</button>
</div>
