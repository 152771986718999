<div class="banner_section" style="background: url(assets/images/innerbanner.png) no-repeat center top ; background-size: cover; ">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-7 ">
                <h2>Get in <strong> touch with us </strong><span></span></h2>
                <p>see how our company can help you grow your business online.</p>
            </div>
        </div>
    </div>
</div>

<div class="inner_cont">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="inncont">
                    <div class="addpic">
                        <img src="assets/images/map.png" alt="">
                    </div>
                    <div class="residentinfo">
                        <div class="form_content_details">
                            <div class="forminfo">
                                <h2><strong>contact us</strong>
                                </h2>

                                <div class="formcont">
                                    <div class="typeof_registration">
                                        <p>please fill this from in a decent manner</p>
                                    </div>
                                    <form class="fromdetails">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Full name">
                                        </div>
                                        <p class="error"></p>
                                        <div class="form-group">
                                            <input type="email" class="form-control" placeholder="Email">
                                        </div>

                                        <div class="form-group">
                                            <textarea class="form-control controltextarea" placeholder="message"></textarea>
                                        </div>
                                        <div class="inpbtn_wrap">
                                            <button type="submit" class="btn defbtn">Submit</button>

                                        </div>


                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
