<div class="playstoresection" style="background:#222522 url(assets/images/backgroundpic4.png) no-repeat center top ; ">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h2>Offer smart building <strong>technology to your residents</strong></h2>
                <p>Developers and property managers who use Walter can provide a superior and simpler living experience to their residents. Our very goal is to do so by offering smart building technology.</p>
                <div class="storeinfo">
                    <a href="#">
                        <img src="assets/images/googlestore.png" alt="">
                    </a>
                    <a href="#">
                        <img src="assets/images/appstore.png" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
