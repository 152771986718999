<div class="content_box">
  <h2>Add Resource</h2>

  <!-- <label for="input">Property</label>
    <ng-multiselect-dropdown [placeholder]="'Property'" [data]="dropdownList" [(ngModel)]="selectedItems"
      [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
    </ng-multiselect-dropdown> -->

  <div class="inpurwraper">
    <label for="input"
      >Resources Organization Name<span class="required">*</span></label
    >
    <input [(ngModel)]="orgName" type="text" class="form-control" />
  </div>

  <!-- <div class="inpurwraper">
    <label for="input">Suite Number</label>
    <input [(ngModel)]="suite_no" type="text" class="form-control" />
  </div> -->

  <div class="inpurwraper">
    <label for="input"
      >Resources Organization Address<span class="required">*</span></label
    >
    <input [(ngModel)]="address" type="text" class="form-control" />
  </div>

  <div class="inpurwraper">
    <label>Select Country<span class="required">*</span></label>
    <select
      class="form-control"
      [(ngModel)]="country"
      (change)="onCountryChange(country)"
    >
      <option *ngFor="let option of countryList" [value]="option.id">
        {{ option.name }}
      </option>
    </select>
  </div>

  <div class="inpurwraper">
    <label>Select State<span class="required">*</span></label>
    <select
      [disabled]="country == ''"
      class="form-control"
      [(ngModel)]="state"
      (change)="onStateChange(state)"
    >
    <option disabled></option>
    <option *ngIf="!stateList || stateList.length === 0" value="no-state-found">No state found</option>
      <option *ngFor="let option of stateList" [value]="option.id">
        {{ option.name }}
      </option>
    </select>
  </div>

  <div class="inpurwraper">
    <label>Select City<span class="required">*</span></label>
    <select [disabled]="state == ''" class="form-control" [(ngModel)]="city" (change)="onCityChange(city)">
      <option disabled></option>
        <option *ngIf="!citylist || citylist.length === 0" value="no-city-found">No city found</option>
        <option *ngFor="let option of citylist" [value]="option.id">{{option.name}}</option>
    </select>
  </div>

  <label for="input">Select Property<span class="required">*</span></label>
  <ng-multiselect-dropdown 
    class="mulDrop"
    [data]="dropdownList"
    [(ngModel)]="selectedItems"
    [settings]="dropdownSettings"
    (onSelect)="onItemSelect($event)"
    (onSelectAll)="onSelectAll($event)"
  >
  </ng-multiselect-dropdown>

  <div class="inpurwraper">
    <label for="input">Postal Code</label>
    <input type="text" [(ngModel)]="postal_code" class="form-control" />
  </div>

  <div class="inpurwraper">
    <label for="input"
      >Resources Organization Phone Number<span class="required">*</span></label
    >
    <!-- <input [(ngModel)]="phone_number" type="number" class="form-control"/> -->
    <br />
    <input
      type="text"
      class="form-control form-input"
      onwheel="this.blur()"
      ng2TelInput
      [ng2TelInputOptions]="{ initialCountry: 'ca' }"
      (hasError)="hasError($event)"
      (ng2TelOutput)="getNumber($event)"
      (intlTelInputObject)="telInputObject($event)"
      [(ngModel)]="resource_phone_no"
      (countryChange)="onCountryCodeChange($event)"
    />
    <p *ngIf="resource_phone_noValidation == true">
      Phone Number Should contain 10 digits.Please enter Valid Phone No.
    </p>
  </div>

  <div class="inpurwraper">
    <label for="input">Resources Organization Whatsapp Number</label>
    <!-- <input [(ngModel)]="phone_number" type="number" class="form-control"/> -->
    <br />
    <input
      type="text"
      class="form-control form-input"
      onwheel="this.blur()"
      ng2TelInput
      [ng2TelInputOptions]="{ initialCountry: 'ca' }"
      (hasError)="hasError($event)"
      (ng2TelOutput)="getNumber($event)"
      (intlTelInputObject)="telInputObject($event)"
      [(ngModel)]="resource_alternate_phone_no"
      (countryChange)="onCountryCodeChange1($event)"
    />
    <p *ngIf="alternate_phone_noValidation == true">
      Phone Number Should contain 10 digits.Please enter Valid Phone No.
    </p>
  </div>

  <div class="inpurwraper">
    <label for="input"
      >Resources Organization Email<span class="required">*</span></label
    >
    <input [(ngModel)]="email" type="text" class="form-control" />
  </div>

  <div class="inpurwraper">
    <label for="input"
      >Resources Contact Person Name<span class="required">*</span></label
    >
    <input
      [(ngModel)]="resource_contact_name"
      type="text"
      class="form-control"
    />
  </div>

  <div class="inpurwraper">
    <label for="input"
      >Primary Contact Number<span class="required">*</span></label
    >
    <!-- <input [(ngModel)]="phone_number" type="number" class="form-control"/> -->
    <br />
    <input
      type="text"
      class="form-control form-input"
      onwheel="this.blur()"
      ng2TelInput
      [ng2TelInputOptions]="{ initialCountry: 'ca' }"
      (hasError)="hasError($event)"
      (ng2TelOutput)="getNumber($event)"
      (intlTelInputObject)="telInputObject($event)"
      [(ngModel)]="phone_number"
      (countryChange)="onCountryCodeChange2($event)"
    />
    <p *ngIf="phone_numberValidation == true">
      Phone Number Should contain 10 digits.Please enter Valid Phone No.
    </p>
  </div>

  <div class="inpurwraper">
    <label for="input">Contact Person Whatsapp Number</label>
    <!-- <input [(ngModel)]="phone_number" type="number" class="form-control"/> -->
    <br />
    <input
      type="text"
      class="form-control form-input"
      onwheel="this.blur()"
      ng2TelInput
      [ng2TelInputOptions]="{ initialCountry: 'ca' }"
      (hasError)="hasError($event)"
      (ng2TelOutput)="getNumber($event)"
      (intlTelInputObject)="telInputObject($event)"
      [(ngModel)]="alternate_phone_no"
      (countryChange)="onCountryCodeChange3($event)"
    />
    <p *ngIf="resource_alternate_phone_noValidation == true">
      Phone Number Should contain 10 digits.Please enter Valid Phone No.
    </p>
  </div>

  <div class="inpurwraper">
    <label for="input">Contact Person Email<span class="required">*</span></label>
    <input [(ngModel)]="resource_email" type="text" class="form-control" />
  </div>

  <div class="inpurwraper">
    <label for="input"
      >Title/Subject Of Announcement<span class="required">*</span>
    </label>
    <input [(ngModel)]="title" type="text" class="form-control" />
  </div>

  <div class="inpurwraper">
    <label for="input"
      >Brief Description Of Announcement<span class="required">*</span></label
    >
    <input [(ngModel)]="description" type="text" class="form-control" />
  </div>

  <div class="inpurwraper">
    <label for="input">Posting Start Date<span class="required">*</span></label>
    <input
      type="date"
      id="input"
      class="form-control"
      [(ngModel)]="startDate"
      class="form-control"
      mdbInput
      min="{{ currentDate | date : 'yyyy-MM-dd' }}"
      mdbInput
    />
  </div>

  <div class="inpurwraper">
    <label for="input">Posting End Date<span class="required">*</span></label>
    <input
      type="date"
      id="input"
      class="form-control"
      [(ngModel)]="endDate"
      class="form-control"
      mdbInput
      min="{{ currentDate | date : 'yyyy-MM-dd' }}"
    />
  </div>

  <div class="inpurwraper">
    <label for="input">From Time<span class="required">*</span></label>
    <!-- <input type="time" id="input" class="form-control" [(ngModel)]="fromTime" class="form-control" mdbInput /> -->
    <input type="time" class="form-control" [(ngModel)]="fromTime" />
    <!-- <input [ngxTimepicker]="toggleTimepicker" [format]="24" [disableClick]="false"  [(ngModel)]="fromTime" readonly class="form-control" >
      <ngx-material-timepicker-toggle [for]="toggleTimepicker"></ngx-material-timepicker-toggle>
      <ngx-material-timepicker #toggleTimepicker></ngx-material-timepicker> -->
  </div>

  <div class="inpurwraper">
    <label for="input">To Time<span class="required">*</span></label>
    <!-- <input type="time" id="input" class="form-control" [(ngModel)]="toTime" class="form-control" mdbInput /> -->
    <input type="time" class="form-control" [(ngModel)]="toTime" />
    <!-- <input [ngxTimepicker]="toggleTimepicker1" [format]="24" [disableClick]="false"  [(ngModel)]="toTime" readonly class="form-control">
    <ngx-material-timepicker-toggle [for]="toggleTimepicker1"></ngx-material-timepicker-toggle>
      <ngx-material-timepicker #toggleTimepicker1></ngx-material-timepicker> -->
  </div>

  <div class="inpurwraper">
    <label for="input">Website Link or Mobile App Link</label>
    <input [(ngModel)]="website" type="text" class="form-control" />
  </div>
  <div class="inpurwraper">
    <label for="input">Ecommerce App Link / Weblink</label>
    <input [(ngModel)]="ecom_link" type="text" class="form-control" />
  </div>

  <div class="inpurwraper">
    <label for="input">Youtube Video Link</label>
    <input [(ngModel)]="video_link" type="text" class="form-control" />
  </div>

  <!-- <div class="inpurwraper">
      <label for="input">Start Date</label>
      <input type="date" id="input" class="form-control" [(ngModel)]="startDate" class="form-control" mdbInput />
    </div> -->

  <!-- <div class="inpurwraper">
      <label for="input">End Date</label>
      <input type="date" id="input" class="form-control" [(ngModel)]="endDate" class="form-control" mdbInput />
    </div> -->

  <!-- <div class="inpurwraper">
      <label for="input">From Time</label>
      <input type="time" id="input" class="form-control" [(ngModel)]="fromTime" class="form-control" mdbInput />
    </div> -->

  <!-- <div class="inpurwraper">
      <label for="input">To Time</label>
      <input type="time" id="input" class="form-control" [(ngModel)]="toTime" class="form-control" mdbInput />
    </div> -->

  <div class="inpurwraper">
    <label for="input">Resources Image<span class="required">*</span></label>
    <input
      type="file"
      #fileInput
      accept="image/gif,image/jpeg,image/jpg,image/png"
      class="form-control"
      (change)="onFileChange($event)"
    />
  </div>
  <div>
    <div *ngIf="image" class="uploadImg">
      <img [src]="image"/>
      <button type="button" (click)="removeImage()">
        <i class="bi bi-x-circle"></i>
      </button>
    </div>

  <div class="inpurwraper">
    <label for="input">Resources PDF</label>
    <input
      type="file"
      #fileInputs
      accept="application/pdf"
     
      class="form-control"
      (change)="onFileChangePDF($event)"
    />
  </div>

  <div *ngIf="Pdf_prev" class="uploadImg">
    <img src="assets/images/pdf.png" alt="PDF Icon" style="width:60px;">
    <button type="button" (click)="removePDF()">
      <i class="bi bi-x-circle"></i>
    </button>
  </div>






</div>
  

  <div class="inpbtn_wrap">
    <button
      type="button"
      class="btn defbtn"
      *ngIf="
        property != null &&
        title != '' &&
        description != '' &&
        country != '' &&
        city != '' &&
        state != '' &&
        orgName != '' &&
        phone_number != null &&
        startDate != '' &&
        endDate != '' &&
        fromTime != '' &&
        toTime != '' &&
        email != '' &&
        resource_contact_name != '' &&
        resource_phone_no != '' &&
        resource_email != '' &&
        address != '' &&
        image
        && pdf_enable == true
      "
      type="submit"
      class="btn defbtn"
      (click)="onAdd()"
    >
      Save
    </button>

    <button
      type="button"
      class="btn defbtn"
      *ngIf="
        property == null ||
        title == '' ||
        description == '' ||
        country == '' ||
        city == '' ||
        state == '' ||
        orgName == '' ||
        phone_number == null ||
        startDate == '' ||
        endDate == '' ||
        fromTime == '' ||
        toTime == '' ||
        email == '' ||
        resource_contact_name == '' ||
        resource_phone_no == '' ||
        resource_email == '' ||
        address == '' ||
        !image ||
        imageRemoved || pdf_enable == false
      "
      [disabled]="
        property == null ||
        title == '' ||
        description == '' ||
        country == '' ||
        city == '' ||
        state == '' ||
        orgName == '' ||
        phone_number == null ||
        startDate == '' ||
        endDate == '' ||
        fromTime == '' ||
        toTime == '' ||
        email == '' ||
        resource_contact_name == '' ||
        resource_phone_no == '' ||
        resource_email == '' ||
        address == '' ||
        !image ||
        imageRemoved || pdf_enable == false
      "
      type="submit"
      class="btn defbtn"
      style="cursor: no-drop"
    >
      Save
    </button>
  </div>
</div>
