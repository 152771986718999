import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';
@Component({
  selector: 'app-adminsubscriptionlist',
  templateUrl: './adminsubscriptionlist.component.html',
  styleUrls: ['./adminsubscriptionlist.component.scss']
})
export class AdminsubscriptionlistComponent implements OnInit {

  page:number = 1;
  count:number = 0;
  tableSize:number = 4;
  tableSizes:any = [5,10,15,20];

  data: any;
  public list: any = [];
  user_type: any;
  UserData: any;
  search: any = '';
  sort:any = '';
  logintype: any = '';
  paymentstaus:boolean;

  cancelClicked: true;
  popoverTitle = 'Cancel subscription!!';
  popoverMessage = 'Are you sure you want to cancel your subscription plan?';
  confirmClicked = false;
  
  constructor(
    public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData);
    this.user_type = this.UserData.user_type;
    this.spinner.show();
    this.webservice.adminGetData('getUserSubscriptionDetails')
      .then(
        (result) => {
          this.data = result;
          console.log(this.data);

          if (this.data.status == 1) {
            this.list = this.data.data;
          } else {
            console.log(this.data.msg);
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
        }
      );
  }
  onTableDataChange(event:any){
    this.page = event ;
    // this.postList();
  }
  onTableSizeChange(event:any): void{
    this.tableSize = event.target.value;
    this.page = 1 ;
    // this.postList();
  }
  onSearch(value) {
    this.page = 1 ;
    let logintype = '';
    if (this.UserData.user_type == 0) {
      logintype = 'admin';
    } else {
      logintype = this.UserData._id;
    }
    this.search = value;
    if (this.search != '') {
      this.spinner.show();
      this.webservice
        .adminGetData(`getUserSubscriptionDetails/?search=${this.search}&sort=${this.sort}`)
        .then(
          (result) => {
            this.data = result;
            console.log(this.data);
            if (this.data.status == 1) {
              this.list = this.data.data;
            } else {
              console.log(this.data.msg);
            }
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            console.log(err);
          }
        );
    } else if(this.search == ''&& this.sort !=''){
      this.spinner.show();
      this.webservice
        .adminGetData(`getUserSubscriptionDetails/?search=${this.search}&sort=${this.sort}`)
        .then(
          (result) => {
            this.data = result;
            console.log(this.data);
            if (this.data.status == 1) {
              this.list = this.data.data;
            } else {
              console.log(this.data.msg);
            }
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            console.log(err);
          }
        );
    }
    else {
      this.ngOnInit();
    }
  }

 // Inside your component class
 onSelectChange(selectedOption: string): void {
  this.page = 1 ;
  let apiEndpoint: string;
  this.sort = selectedOption;
  switch (selectedOption) {
    case 'All':
      this.webservice.adminGetData(`getUserSubscriptionDetails/?search=${this.search}&sort=${this.sort}`)
      .then(
        (result) => {
          
          this.data = result;
          console.log(this.data);

          if (this.data.status == 1) {
            this.list = this.data.data;
          } else {
            console.log(this.data.msg);
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
        }
      );
      break;
    case 'flash_deal':
      this.webservice.adminGetData(`getUserSubscriptionDetails/?search=${this.search}&sort=flash_deal`)
      .then(
        (result) => {
          this.data = result;
          console.log(this.data);

          if (this.data.status == 1) {
            
            this.list = this.data.data;
          } else {
            console.log(this.data.msg);
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
        }
      );
      break;
    case 'resource':
      this.webservice.adminGetData(`getUserSubscriptionDetails/?search=${this.search}&sort=resource`)
      .then(
        (result) => {
          this.data = result;
          console.log(this.data);

          if (this.data.status == 1) {
            this.list = this.data.data;
          } else {
            console.log(this.data.msg);
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
        }
      );
  }
 }


}
