// import { Chart } from 'chart.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';
import { Component, ViewChild, OnInit } from '@angular/core';

// import {
//   ChartComponent,
//   ApexAxisChartSeries,
//   ApexChart,
//   ApexXAxis,
//   ApexTitleSubtitle,
//   ApexYAxis
// } from "ng-apexcharts";

export type ChartOptions = {
  // series: ApexAxisChartSeries;
  // chart: ApexChart;
  // xaxis: ApexXAxis;
  // yaxis: ApexYAxis;
  // title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-over-view',
  templateUrl: './over-view.component.html',
  styleUrls: ['./over-view.component.scss'],
})
export class OverViewComponent implements OnInit {
  // @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  userData: any;
  show: boolean = false;
  dashboardData: any;
  // public charts : any;

  country: any = '';
  state: any = '';
  city: any = '';
  condoName: any = '';
  countryList: any = [];
  cityList: any = [];
  stateList: any = [];
  consoNameList: any = [];
  country_id: any = '';
  state_id: any = '';
  city_id: any = '';
  countryCode: any = '';
  stateCode: any = '';
  cityCode: any = '';
  Date: any = '';
  series: any;
  title: any;
  property: number;

  constructor(
    public webservice: WebserviceService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log('userData: ', this.userData);

    this.getUserList();
    this.allCountryList();
  }
  allCountryList() {
    this.webservice.adminGetData('countryList').then(
      (result: any) => {
        if (result.status == 1) {
          this.countryList = result.data;
          console.log('countryList: ', this.countryList);
        } else {
          console.log(result.msg);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  OnChangeCountry(val) {
    this.country = val;
    console.log(this.country);
    if (this.country != '') {
      this.spinner.show();
      this.webservice.adminGetData('stateList/' + this.country).then(
        (result: any) => {
          if (result.status == 1) {
            this.stateList = result.data;
          } else {
            console.log(result.msg);
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
        }
      );
    }
  }

  OnChangeState(val) {
    this.state = val;
    console.log(this.state);
    if (this.state != '') {
      this.webservice.adminGetData('cityList/' + this.state).then(
        (result: any) => {
          if (result.status == 1) {
            this.cityList = result.data;
          } else {
            console.log(result.msg);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  onChangeCity(val) {
    this.city = val;
    console.log(this.city);
    if (this.city != '') {
      this.webservice.adminGetData('propertyListByCity/' + this.city).then(
        (result: any) => {
          console.log(result);
          if (result.status == 1) {
            this.consoNameList = result.data;
          } else {
            console.log(result.msg);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  onChangeCondoName(val) {
    this.condoName = val;
  }
  graphsearch() {
    console.log('country=====', this.country);
    console.log('state=====', this.state);
    console.log('city=====', this.property);
    this.getUserList();
    // this.graph()
  }

  getUserList() {
    if (
      this.country == '' ||
      this.country == null ||
      this.country == undefined
    ) {
      this.country = undefined;
      this.state = undefined;
      this.city = undefined;
      if (this.Date) {
        this.Date = this.Date;
      } else {
        this.Date = undefined;
      }
      this.webservice
        .adminGetData(
          'getAdmindashboard/' +
            this.userData._id +
            '/' +
            this.country +
            '/' +
            this.state +
            '/' +
            this.city +
            '/' +
            this.Date
        )
        .then(
          (result: any) => {
            console.log('result', result);
            if (result.status == 1) {
              this.show = true;
              this.dashboardData = result;
              this.property = this.dashboardData.property;
              this.graph();
              console.log('dashboard data=====', this.property);
            } else {
              this.show = false;
              this.toastr.error('Internal server error');
              console.log(result.msg);
            }
            this.spinner.hide();
          },
          (err) => {
            this.toastr.error('Internal server error');
            this.show = false;
            this.spinner.hide();
            console.log(err);
          }
        );
    } else {
      if (this.Date) {
        this.Date = this.Date;
      } else {
        this.Date = undefined;
      }
      this.webservice
        .adminGetData(
          'getAdmindashboard/' +
            this.userData._id +
            '/' +
            this.country +
            '/' +
            this.state +
            '/' +
            this.city +
            '/' +
            this.Date
        )
        .then(
          (result: any) => {
            console.log('result', result);
            if (result.status == 1) {
              this.show = true;
              this.dashboardData = result;
              this.graph();
            } else {
              this.show = false;
              this.toastr.error('Internal server error');
              console.log(result.msg);
            }
            this.spinner.hide();
          },
          (err) => {
            this.toastr.error('Internal server error');
            this.show = false;
            this.spinner.hide();
            console.log(err);
          }
        );
    }
  }
  // graph(){
  //   this.charts=new Chart('MyChartpie', {

  //     type: 'line',
  //     data: {
  //       labels: [
  //         'Property',
  //         'Property manager',
  //         'Resident',
  //         'Staff',
  //         'Advertise',
  //         'Flash Deal',
  //         'Resource',
  //         'Vendor',
  // 'CMS'

  //   ],
  //    datasets: [{
  //     label: 'Dashboard',
  //     data: [this.dashboardData.property,this.dashboardData.property_manager_count,this.dashboardData.resident_count,this.dashboardData.staff_count,this.dashboardData.local_advertiser,this.dashboardData.flash_deal,this.dashboardData.resource,this.dashboardData.vendor],
  //     // backgroundColor:"#0196FD",
  //     borderColor: "#0196FD",
  //     borderWidth: 1
  // },

  //   {
  //     label: 'Property Manager',
  //     data: [this.dashboardData.property_manager_count],
  //     backgroundColor:"#0196FD",
  //     borderColor: "#0196FD",
  //     borderWidth: 1
  // },

  // {
  //   label: 'Resident',
  //   data: [this.dashboardData.resident_count],
  //   backgroundColor: [
  //     'rgb(255, 99, 132)',
  //     'rgb(54, 162, 235)',

  //   ],

  // },
  // {
  //   label: 'Staff',
  //   data: [this.dashboardData.staff_count],
  //   backgroundColor: [
  //     'rgb(255, 99, 132)',
  //     'rgb(54, 162, 235)',

  //   ],

  // },
  // {
  //   label: 'Advertise',
  //   data: [this.dashboardData.local_advertiser],
  //   backgroundColor: [
  //     'rgb(255, 99, 132)',
  //     'rgb(54, 162, 235)',

  //   ],

  // },
  // {
  //   label: 'Flash Deal',
  //   data: [this.dashboardData.flash_deal],
  //   backgroundColor: [
  //     'rgb(255, 99, 132)',
  //     'rgb(54, 162, 235)',

  //   ],

  // },
  // {
  //   label: 'Resource',
  //   data: [this.dashboardData.resource],
  //   backgroundColor: [
  //     'rgb(255, 99, 132)',
  //     'rgb(54, 162, 235)',

  //   ],

  // },
  // {
  //   label: 'Vendor',
  //   data: [this.dashboardData.vendor
  //   ],
  //   backgroundColor: [
  //     'rgb(255, 99, 132)',
  //     'rgb(54, 162, 235)',

  //   ],

  // },
  // {
  //   label: 'CMS',
  //   data: [this.dashboardData.cms_count],
  //   backgroundColor: [
  //     'rgb(255, 99, 132)',
  //     'rgb(54, 162, 235)',

  //   ],

  // }

  //     ],

  //     },

  //   });

  // }

  graph() {
    this.chartOptions = {
      series: [
        {
          name: 'DashBoard',
          data: [
            this.dashboardData.property,
            this.dashboardData.property_manager_count,
            this.dashboardData.resident_count,
            this.dashboardData.staff_count,
            this.dashboardData.flash_deal,
            this.dashboardData.resource,
            this.dashboardData.vendor,
          ],
        },
      ],

      chart: {
        height: 350,

        type: 'line',
      },

      // title: {
      //   text: "My First Angular Chart"
      // },
      xaxis: {
        categories: [
          'Property',
          'Property manager',
          'Resident',
          'Staff',
          'Flash Deal',
          'Resource',
          'Vendor',
        ],
      },
      yaxis: {
        // show: false,
      },
    };
  }
  resetForm() {
    this.country = '';
    this.state = '';
    this.city = '';
    this.condoName = '';
    this.Date = '';
    this.getUserList();
  }
}
