import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-savedcards',
  templateUrl: './savedcards.component.html',
  styleUrls: ['./savedcards.component.scss'],
})
export class SavedcardsComponent implements OnInit {
  save: any = '';
  brand: any = '';
  last4: any = '';
  UserData: any;
  vendor_id: any;
  // card_id: any;
  // cards: any = [];

  constructor(
    public webservice: WebserviceService,

    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    this.vendor_id = this.UserData._id;
    console.log(this.UserData);
    this.savedCard();
  }
  savedCard() {
    this.spinner.show();
    
    this.webservice.adminGetData('vendorSavedCard/' + this.UserData._id).then(
      (result: any) => {
        console.log('Card Save', result);

        if (result.success == 1) {
          //   this.save = result.data;
          // console.log("ghdujfghd",this.save);
          this.brand = result.data[0].brand;
          // console.log("brand",this.brand);
          this.last4 = 'xxxx-xxxx-xxxx-' + result.data[0].last4;

          // this.card_id = result.data[0].id;
          // this.cards = result.data;//for multiple
        } else {
          this.toastr.error(result.message);
          console.log(result.message);
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        console.log(err);
      }
    );
  }

  saveDeleteCard() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete Card!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.webservice
          .adminGetData('vendorDeleteCard/' + this.UserData._id)
          .then(
            (result: any) => {
              console.log('Card Result', result);

              if (result.success == true) {
                this.toastr.success(result.message);
                console.log(result.message);

                this.brand = '';
                this.last4 = '';
                // this.savedCard();
              } else {
                this.toastr.error(result.message);

                console.log(result.message);
              }
              this.spinner.hide();
            },
            (err) => {
              this.spinner.hide();
              console.log(err);
            }
          );
      }
    });
  }
}

// {
//   this.spinner.show();
//   this.webservice.adminGetData('vendorDeleteCard/' + this.UserData._id).then((result: any) => {

//     console.log("Card Result", result)

//     if (result.success == true) {
//       this.toastr.success(result.message);
//       console.log(result.message)

//       this.brand = '';
//       this.last4 = '';
//       // this.savedCard();

//     }
//     else {
//       this.toastr.error(result.message);

//       console.log(result.message)
//     }
//     this.spinner.hide();
//   }, (err) => {
//     this.spinner.hide();
//     console.log(err);
//   });
// }
