import { Component, OnInit } from '@angular/core';
import { WebserviceService } from 'src/app/webservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-propertymanager',
  templateUrl: './propertymanager.component.html',
  styleUrls: ['./propertymanager.component.scss']
})
export class PropertymanagerComponent implements OnInit {
  
  page:number = 1;
  count:number = 0;
  tableSize:number = 4;
  tableSizes:any = [];





  data: any;
  propertyManagerList: any = [];
  search: any = '';

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.postList();
    this.spinner.show();
    this.webservice.adminGetData('propertyManagerList/undefined').then((result) => {
      this.data = result;
      console.log(this.data);
      if(this.data.status == 1) {
        this.propertyManagerList = this.data.data;
      } else {
        console.log(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  onSearch(val) {
    this.search = val;
    this.page = 1;
    if (this.search != '') {
      this.spinner.show();
      this.webservice.adminGetData('propertyManagerList/'+this.search).then((result) => {
        this.data = result;
        console.log(this.data);
        if(this.data.status == 1) {
          this.propertyManagerList = this.data.data;
        } else {
          console.log(this.data.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    } else {
      this.spinner.show();
      this.webservice.adminGetData('propertyManagerList/undefined').then((result) => {
        this.data = result;
        console.log(this.data);
        if(this.data.status == 1) {
          this.propertyManagerList = this.data.data;
        } else {
          console.log(this.data.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }

  }
  postList():void{
    this.webservice.adminGetData('propertyManagerList/undefined').then((result)=>{
      this.data = result;
      console.log("pagination:",this.data);
    })
  }
  onTableDataChange(event:any){
    this.page = event ;
    this.postList();
  }
  onTableSizeChange(event:any): void{
    this.tableSize = event.target.value;
    this.page = 1 ;
    this.postList();
  }

}
