<!-- <p>dailydealsa-add works!</p> -->
<div class="content_box">
  <h2>Add Flash Deal</h2>

  
  <div class="inpurwraper">
    <label for="input">Merchant Name<span class="required">*</span></label>
    <input [(ngModel)]="merchant_name" type="text" class="form-control"  />
  </div>

  <div class="inpurwraper">
    <label for="input">Suite Number</label>
    <input [(ngModel)]="suite_no" type="text" class="form-control"  />
  </div>

  <div class="inpurwraper">
    <label for="input">Merchant Address<span class="required">*</span></label>
    <input [(ngModel)]="merchant_address" type="text" class="form-control" />
  </div>

  <div class="inpurwraper">
    <label>Select Country<span class="required">*</span></label>
    <select class="form-control" [(ngModel)]="country"
      (change)="onCountryChange(country)">
      <option *ngFor="let option of countryList" [value]="option.id">{{option.name}}</option>
    </select>
  </div>

  <div class="inpurwraper">
    <label>Select State<span class="required">*</span></label>
    <select [disabled]="country == ''" class="form-control" [(ngModel)]="state"
      (change)="onStateChange(state)">
      <option disabled></option>
    <option *ngIf="!stateList || stateList.length === 0" value="no-state-found">No state found</option>
      <option *ngFor="let option of stateList" [value]="option.id">{{option.name}}</option>
    </select>
  </div>

  <div class="inpurwraper">
    <label>Select City<span class="required">*</span></label>
    <select [disabled]="state == ''" class="form-control" [(ngModel)]="city" (change)="onCityChange(city)">
        <option disabled></option>
        <option *ngIf="!citylist || citylist.length === 0" value="no-city-found">No city found</option>
        <option *ngFor="let option of citylist" [value]="option.id">{{option.name}}</option>
    </select>
  </div>

  <label for="input">Select Property Name<span class="required">*</span></label>
<ng-multiselect-dropdown class="multiselectoption mulDrop" [data]="dropdownList" [(ngModel)]="selectedItems"
  [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
</ng-multiselect-dropdown>

  <div class="inpurwraper">
    <label for="input">Postal Code</label>
    <input [(ngModel)]="postal_code" type="text" class="form-control" />
  </div>

  
  <div class="inpurwraper">
    <label for="input">Phone Number<span class="required">*</span></label>
    <!-- <input [(ngModel)]="phone_no" type="number" class="form-control" /> -->
    <br>
    <input type="text"
      class="form-control form-input"
      onwheel="this.blur()"
      ng2TelInput
      [ng2TelInputOptions]="{initialCountry: 'ca'}"
      (hasError)="hasError($event)"
      (ng2TelOutput)="getNumber($event)"
      (intlTelInputObject)="telInputObject($event)"
      [(ngModel)]="phone_no"
      (countryChange)="onCountryCodeChange($event)" />
      

  <p *ngIf="phone_noValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p>

  </div>

  
  <div class="inpurwraper">
    <label for="input">Whatsapp Number</label>
    <!-- <input [(ngModel)]="phone_no" type="number" class="form-control" /> -->
    <br>
    <input type="text"
      class="form-control form-input"
      onwheel="this.blur()"
      ng2TelInput
      [ng2TelInputOptions]="{initialCountry: 'ca'}"
      (hasError)="hasError($event)"
      (ng2TelOutput)="getNumber($event)"
      (intlTelInputObject)="telInputObject($event)"
      [(ngModel)]="alternate_phone_no"
      (countryChange)="onCountryCodeChange1($event)" />
      

  <p *ngIf="alternate_phone_noValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p>

  </div>
  <div class="inpurwraper">
    <label for="input">Email<span class="required">*</span></label>
    <input [(ngModel)]="subject" type="text" class="form-control" />
    <!-- <input type="text" class="form-control" /> -->
  </div>

  <div class="inpurwraper">
    <label for="input">Advertise Title<span class="required">*</span></label>
    <input [(ngModel)]="title" type="text" class="form-control" />
  </div>

  <div class="inpurwraper">
    <label for="input">Description<span class="required">*</span></label>
    <input [(ngModel)]="description" type="text" class="form-control"  />
  </div>
<!-- 
  <div class="inpurwraper">
    <label for="input">Store Name</label>
    <input [(ngModel)]="merchant_name" type="text" class="form-control"  />
  </div>

  <div class="inpurwraper">
    <label for="input">Subject</label>
    <input [(ngModel)]="subject" type="text" class="form-control" />
  </div> -->
  <div class="inpurwraper">
    <label for="input">Original Price($)<span class="required">*</span></label>
    <input [(ngModel)]="deal_price" type="number" onwheel="this.blur()" class="form-control" />
  </div>

  <div class="inpurwraper">
    <label for="input">Discount($/%)<span class="required">*</span></label>
    <select   [(ngModel)]="discount_type" (change)="changeDiscountType(discount_type)"  placeholder="Discount Type" class="form-control">
      <option value="" disabled>
        Select Discount Type
      </option>
      <option  value="2" >
        Discount Percentage
      </option>
      <option  value="1" >
       Discount Amount
      </option>

    </select>
  </div>

 

  <div class="inpurwraper">
    <label for="input">Currency<span class="required">*</span></label>
    <!-- <input [(ngModel)]="currency" type="text" class="form-control" /> -->
    <select class="form-control" [(ngModel)]="currency" placeholder="Choose Currency"
      (change)="onCurrencyChange(currency)">
      <option *ngFor="let option of currencyArray" [value]="option.value">{{option.name}}({{option.value}})</option>
    </select>
  </div>
  

  <div class="inpurwraper">
    <!-- <label for="input">Discount($)</label> -->
    <!-- <input [(ngModel)]="discount" type="number" class="form-control"  /> -->
    <input type="text" *ngIf="discount_type == '1' "oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="form-control"  [(ngModel)]="discount"   placeholder="Flat Amount">
    <input type="text" *ngIf="discount_type == '2'" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="form-control"  [(ngModel)]="discount_percentage"   placeholder="Percentage (%)">
  
  </div>


  <div class="inpurwraper">
    <label for="input">Posting Start Date<span class="required">*</span></label>
    <input type="date" id="input" class="form-control" [(ngModel)]="startDate" min="{{ currentDate | date: 'yyyy-MM-dd' }}"  mdbInput />
  </div>

  <div class="inpurwraper">
    <label for="input">Posting End Date<span class="required">*</span></label>
    <input type="date" id="input" class="form-control" [(ngModel)]="endDate" min="{{ currentDate | date: 'yyyy-MM-dd' }}" mdbInput />
  </div>


  <div class="inpurwraper">
    <label for="input">From Time<span class="required">*</span></label>
    <input type="time" class="form-control" [(ngModel)]="fromTime">
    <!-- <input type="time" id="input" class="form-control" [(ngModel)]="fromTime" class="form-control" mdbInput /> -->
    <!-- <input [ngxTimepicker]="toggleTimepicker" [format]="24" [disableClick]="false"  class="form-control" [(ngModel)]="fromTime">
    <ngx-material-timepicker-toggle [for]="toggleTimepicker"></ngx-material-timepicker-toggle>
    <ngx-material-timepicker #toggleTimepicker></ngx-material-timepicker> -->

  </div>


  <!-- <div class="inpurwraper">
    <label for="input">To Time</label>
     <input type="time" id="input" class="form-control" [(ngModel)]="toTime" class="form-control" mdbInput /> 
    
     <input [ngxTimepicker]="toggleTimepicker1" [format]="24" [disableClick]="false"  [(ngModel)]="toTime" class="form-control">
    <ngx-material-timepicker-toggle [for]="toggleTimepicker1"></ngx-material-timepicker-toggle>
    <ngx-material-timepicker #toggleTimepicker1></ngx-material-timepicker>
     {{toTime}} 
    <input [ngxTimepicker]="toggleTimepicker1" [format]="24" [disableClick]="false"  class="form-control" [(ngModel)]="toTime">
    <ngx-material-timepicker-toggle [for]="toggleTimepicker1"></ngx-material-timepicker-toggle>
    <ngx-material-timepicker #toggleTimepicker1></ngx-material-timepicker>

  </div> -->

  <div class="inpurwraper">
    <label for="input">To Time<span class="required">*</span></label>
    <input type="time" class="form-control" [(ngModel)]="toTime">
    <!-- <input [ngxTimepicker]="toggleTimepicker1" [format]="24" [disableClick]="false"  class="form-control" [(ngModel)]="toTime">
    <ngx-material-timepicker-toggle [for]="toggleTimepicker1"></ngx-material-timepicker-toggle>
    <ngx-material-timepicker #toggleTimepicker1></ngx-material-timepicker> -->
</div>

  <div class="inpurwraper">
    <label for="input">Website Link or Mobile App Link</label>
    <input [(ngModel)]="website" type="text" class="form-control" />
  </div>

  <div class="inpurwraper">
    <label for="input">Ecommerce App Link / Weblink</label>
    <input [(ngModel)]="ecom_link" type="text" class="form-control" />
  </div>

  <div class="inpurwraper">
    <label for="input">Youtube Video Link</label>
    <input [(ngModel)]="video_link" type="text" class="form-control"/>
  </div>


  <div class="inputwrapper">
    <label for="input">Deals Image<span class="required">*</span></label>
    <input #fileInput type="file" class="form-control"
           accept="image/gif,image/jpeg,image/jpg,image/png" 
           (change)="onFileChange($event)" />
  </div>
  <div *ngIf="image" class="uploadImg">
    <img [src]="image"/>
    <button type="button" (click)="removeImage()">
      <i class="bi bi-x-circle"></i>
    </button>
  </div>
  

  <div class="inpurwraper">
    <label for="input">Deals PDF</label>
    <input type="file" #fileInputs class="form-control" 
    accept="application/pdf" 
      (change)="onFileChangePDF($event)" />
  </div>
  <div *ngIf="Pdf_prev" class="uploadImg">
    <img src="assets/images/pdf.png" alt="PDF Icon" style="width:60px;">
    <button type="button" (click)="removePDF()">
      <i class="bi bi-x-circle"></i>
    </button>
  </div>

 



  <div class="inpbtn_wrap">
  <button type="button" class="btn defbtn"
          *ngIf="property != null && fromTime != '' && toTime != '' &&
                title != '' && description != '' && image && startDate != '' &&
                endDate != '' && merchant_name != '' && subject != '' &&
                merchant_address != '' && phone_no != '' && deal_price != '' && deal_price != null &&
                discount_type != '' && country != '' && city != '' && state != '' && pdf_enable == true"
          (click)="onAdd()">
    Save
  </button>

  <button type="button" class="btn defbtn"
          *ngIf="!image || imageRemoved || property == null || fromTime == '' ||
                toTime == '' || title == '' || description == '' || !image ||
                startDate == '' || endDate == '' || merchant_name == '' ||
                subject == '' || merchant_address == '' || phone_no == '' ||
                deal_price == '' || deal_price == null || discount_type == '' || country == '' ||
                city == '' || state == ''  || pdf_enable == false"
          [disabled]="!image || imageRemoved || property == null || fromTime == '' ||
                      toTime == '' || title == '' || description == '' || !image ||
                      startDate == '' || endDate == '' || merchant_name == '' ||
                      subject == '' || merchant_address == '' || phone_no == '' ||
                      deal_price == '' ||  deal_price == null || discount_type == '' || country == '' ||
                      city == '' || state == '' || pdf_enable == false"
          style="cursor: no-drop;"
          (click)="onAdd()">
    Save
  </button>
</div>





  <!-- <div class="inpbtn_wrap">
    <button type="button" class="btn defbtn" *ngIf="property != null && fromTime != '' && toTime != '' &&
        title != '' && description != '' && image != '' && startDate != '' && endDate != '' && website != '' && merchant_name != '' && 
        subject != '' && merchant_address != '' && phone_no != '' && currency !='' && deal_price != '' && discount_type != '' && country != '' && city != '' && state != '' " type="submit"
      class="btn defbtn" (click)="onAdd();">
      Save
    </button>


 

    <button type="button" class="btn defbtn" *ngIf="property == null || fromTime == '' || toTime == '' ||
        title == '' || description == ''  || startDate == '' || endDate == '' ||  merchant_name == '' || 
        subject == '' || merchant_address == '' || phone_no == '' || deal_price == '' || discount_type == ''  || country == '' || city == '' || state == '' " [disabled]="property == null || fromTime == '' || toTime == '' ||
        title == '' || description == '' || startDate == '' || endDate == '' || merchant_name == '' || 
        subject == '' || merchant_address == '' || phone_no == '' || deal_price == '' || discount_type == ''  || country == '' || city == '' || state == '' " type="submit"
      class="btn defbtn" style="cursor: no-drop;">
      Save
    </button>
  </div>
</div> -->






