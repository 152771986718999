import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
// import { EventEmitter } from 'protractor';
import { environment } from '../environments/environment'
import { from, Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { catchError, tap } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class WebserviceService {
  // BaseUrl = 'https://nodeserver.mydevfactory.com:4779/website/';

  // AdminApi = 'https://nodeserver.mydevfactory.com:4779/admin/';
  BaseUrl = environment.url+'website/';
  AdminApi = environment.url+ 'admin/';
  webAPI = environment.url+ 'webservice/';


   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':'application/json',
      'Authorization':'',
    })
  };
  @Output() getLoggedInData: EventEmitter<any> = new EventEmitter();
  @Output() getActiveClassData: EventEmitter<any> = new EventEmitter();
  @Output() getPaypalFlagData: EventEmitter<any> = new EventEmitter();
  @Output() headerfooter: EventEmitter<any> = new EventEmitter();

  constructor(public http: HttpClient,private toastr: ToastrService) {
    this.getIP();
   }

  getData(url) {
    return new Promise((resolve, reject) => {
      this.http.get(this.BaseUrl + url).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });

  }
  adminGetData(url) {
    return new Promise((resolve, reject) => {
      this.http.get(this.AdminApi + url).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });

  }

  postData(url, data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.BaseUrl + url, data).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  WebpostData(url, data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.webAPI + url, data).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }
  WebgetData(url) {
    return new Promise((resolve, reject) => {
      this.http.get(this.webAPI + url).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });

  }

  adminPostData(url, data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.AdminApi + url, data).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  // currency(){
  //   return new Promise((resolve, reject) => {
  //     this.http.post(this.AdminApi + url, data).subscribe(res => {
  //       resolve(res);
  //     }, (err) => {
  //       reject(err);
  //     });
  //   });
  // }

  getIP() {
    return this.http.get("https://api.ipify.org/?format=json");
  }

  getcurrency(ip){
    return this.http.get(
      'https://ipapi.co/'+ip+'/currency/',
      {responseType: 'text'})
    
  }
  getcountry(){
    return this.http.get("https://ip-api.com/json");
  }

  //add flash deal subcription
  // saveVendorSubscription(data: any): Observable<any> {
  //   const API_URL = `${this.AdminApi}/saveVendorSubscription`;
  //   return this.http.post(API_URL, data)
  //     .pipe(
  //       map(res => {
  //         return res;
  //       }),

  //     );
  // }
  // saveVendorSubscription(url, data) {
  //   return new Promise((resolve, reject) => {
  //     this.http.post(this.AdminApi + url, data).subscribe(res => {
  //       resolve(res);
  //     }, (err) => {
  //       reject(err);
  //     });
  //   });
  // }

  showSuccess(message, title){
    this.toastr.success(message, title)
}

showError(message, title){
    this.toastr.error(message, title)
}

showInfo(message, title){
    this.toastr.info(message, title)
}

showWarning(message, title){
    this.toastr.warning(message, title)
}

sendLoggedData(token) {
  this.getLoggedInData.emit(token);
}

sendheaderfooter(token) {
  this.headerfooter.emit(token);
}
}
