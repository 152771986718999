<div class="content_box">
    <h2>Change Password</h2>
    <div class="inpurwraper vendorField">
        <input
          [(ngModel)]="currentPassword"
          type="password"
          [type]="changetype?'password':'text'"
          class="form-control"
          placeholder="Current Password"
        />
        <span class="eyeicon" (click)="viewpass()"><i [ngClass]="visible?'bi bi-eye-slash-fill':'bi bi-eye-fill'"></i>
        </span>
      </div>

      <div class="inpurwraper vendorField">
        <input
          [(ngModel)]="newPassword"
          type="password"
          [type]="changetype1?'password':'text'"
          class="form-control"
          placeholder="New Password"
        />
        <span class="eyeicon" (click)="viewpass1()"><i [ngClass]="visible1?'bi bi-eye-slash-fill':'bi bi-eye-fill'"></i>
        </span>
      </div>

      <div class="inpurwraper vendorField">
        <input
          [(ngModel)]="confirmPassword"
          type="password"
          [type]="changetype2?'password':'text'"
          class="form-control"
          placeholder="Confirm Password"
        />
        <span class="eyeicon" (click)="viewpass2()"><i [ngClass]="visible2?'bi bi-eye-slash-fill':'bi bi-eye-fill'"></i>
        </span>
      </div>


      <div class="inpbtn_wrap">
        <button
          type="button"
          class="btn defbtn"
          *ngIf="currentPassword != '' &&
          newPassword != '' && confirmPassword != ''"
          type="submit"
          class="btn defbtn"
          (click)="onChange();"
        >
          Change
        </button>
    
        <button
          type="button"
          class="btn defbtn"
          *ngIf="currentPassword == '' || newPassword == '' || confirmPassword == ''"
          [disabled]="currentPassword == '' || newPassword == '' || confirmPassword == ''"
          type="submit"
          class="btn defbtn"
          style="cursor: no-drop;"
        >
          Change
        </button>
      </div>
</div>