<!-- <div class="card">
    <div class="rightside">
        <form action="">
            <h3>Not A Member!! Proceed to pay.</h3>
            <p>Cardholder Name</p>
            <input type="text" class="inputbox" name="name" required />
            <p>Card Number</p>
            <input type="number" class="inputbox" name="card_number" id="card_number" required /> -->

<!-- <p>Card Type</p>
                <select class="inputbox" name="card_type" id="card_type" required>
                  <option value="">--Select a Card Type--</option>
                  <option value="Visa">Visa</option>
                  <option value="RuPay">RuPay</option>
                  <option value="MasterCard">MasterCard</option>
                </select> -->
<!-- <div class="expcvv">
                <p class="expcvv_text">Expiry</p>
                <input type="text" class="inputbox" name="exp_date" id="exp_date" required />

                <p class="expcvv_text2">CVV</p>
                <input type="password" class="inputbox" name="cvv" id="cvv" required />
            </div>
            <p></p>
            <button type="submit" class="button" [routerLink]="['/main/add-dailydeals']" >CheckOut</button>
        </form>
    </div>
</div> -->


 <div class="card">
    <div class="rightside">
      <button type="submit" class="button" (click)="subscription('flash_deal')">PROCEED TO SUBSCRIBE</button>
      <!-- <form novalidate (ngSubmit)="createToken();" [formGroup]="stripeTest">
        <h3>Not A Member!! Proceed to pay.</h3>
        <div class="input-box">
          <span class="details">Plan Type<span class="required">*</span></span>
          <select class="form-control" [(ngModel)]="plan"
          (change)="onPlanChange($event.target.value)">
          <option *ngFor="let option of planList" value="{{option._id}}_{{option.amount}}">{{option.title}}</option>
        </select>
        <p>Amount<span class="required">*</span></p>
        <input type="number" class="inputbox"  value="{{amount}}" readonly >

        </div>
        <p>Cardholder Name<span class="required">*</span></p>
        <input type="text" class="inputbox" formControlName="name" placeholder="Enter Name" [(ngModel)]="name">
        <p>Card Number<span class="required">*</span></p>
        <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
        <button type="submit" class="button">PROCEED TO PAY</button>
      </form> -->
    </div>
  </div>
 