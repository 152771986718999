<div class="card">
    <div class="rightside">
        <form>
            <h3>Saved Card Details</h3>
            <p>Card Type</p>
            <input type="text" class="inputbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="brand" readonly />
        
        <p>Card Number</p>
        <input type="text" class="inputbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="last4" readonly />
    
            <button type="submit" class="button" (click)="saveDeleteCard();"  [disabled]="brand == '' || last4 == '' ">Delete Card</button>
        </form>
    </div>
</div>
<!-- <p *ngFor="let cardsDetails of cards">
    <input type="text" class="inputbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="cardsDetails.brand" readonly/>
    <input type="text" class="inputbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="cardsDetails.last4" readonly/>
</p> //multiple payment show.-->