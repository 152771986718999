import { Component, OnInit, ViewChild } from '@angular/core';
import { WebserviceService } from 'src/app/webservice.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  email: any = '';
  password: any = '';
  validMailError: boolean = false;
  error: boolean = false;
  data: any;
hide: boolean = false;
  constructor(public webservice: WebserviceService,) { }

  ngOnInit(): void {
  }

  onLogin() {
    console.log('123123')
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(this.email)) {
      this.validMailError = true;
    }
     else {
      var params = {
        email: this.email,
        password: this.password
      };
      this.webservice.postData('propertyManagerLogin', params).then((result) => {
        this.data = result;
        console.log(this.data);
        if (this.data.status == 1) {
          this.validMailError = false;
          this.error = false;
          this.webservice.sendLoggedData(this.data.data);
          localStorage.setItem('LoginToken', JSON.stringify(this.data.data));
        } else {
          this.validMailError = false;
          this.error = true;
          this.hide = false;
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

}
