<div class="smarttechnology" style="background: url(assets/images/backgroundpic2.png) no-repeat center top ; background-size: cover; ">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="residentinfo">
                    <h2>Increase your residents' living experience with <strong>  smart building technology</strong></h2>
                    <p>Offer more services to your residents with Walter. Your residents can order on-demand at home services curated by the Walter team.</p>
                    <div class="storeinfo">
                        <a href="#">For Managers </a>
                    </div>
                </div>
                <div class="add_pic">
                    <img src="assets/images/door1.png" alt="">
                </div>
            </div>

        </div>
    </div>
</div>
