import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';

@Component({
  selector: 'app-add-advatisment',
  templateUrl: './add-advatisment.component.html',
  styleUrls: ['./add-advatisment.component.scss']
})
export class AddAdvatismentComponent implements OnInit {

    currentDate:any =new Date();
    title: any = '';
    description: any = '';
    web_link: any = '';
    advertise_image = '';
    Pdf_prev:any;
    UserData: any;
    file:any;
    
   
    data: any;

    countryList: any = [];
    stateList: any = [];
    citylist: any = [];
    country: any = '';
    city: any = '';
    state: any = '';

    dropdownList = [];
    selectedItems = [];
    propertymanagetlist: any = [];
    dropdownSettings:IDropdownSettings;
    merchant_name: any = '';
    merchant_phone_number: any;
    startDate: any = '';
    endDate: any = '';
    property: any = [];
    address: any = '';
    merchant_phone_numberValidation: boolean = false;
    merchant_alternate_phone_number_Validation: boolean = false;
    postal_code: any = '';
suite_no: any = '';
youtube_link: any = '';
merchant_alternate_phone_number: any = '';
merchant_alternate_phone_number_country_code: any = '';
merchant_phone_no_country_code: any = '';
advertise_deal_pdf: any = '';
PDFfile:any;
email:any = '';
image_file_type:any;
pdf_file_type:any;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData);
    
    this.ongetAllCountry();
  }

  
  hasError(obj) {
    console.log(obj);
  }

  onCountryCodeChange(obj) {
    console.log(obj);
    this.merchant_phone_no_country_code = '+' + obj.dialCode
    console.log(this.merchant_phone_no_country_code );
  }
  onCountryCodeChange1(obj) {
    console.log(obj);
    this.merchant_alternate_phone_number_country_code = '+' + obj.dialCode
    console.log(this.merchant_alternate_phone_number_country_code );
  }
  getNumber(obj) {
    console.log(obj);
  }


telInputObject(obj) {
    console.log(obj);
    obj.setCountry('ca');
  }



  onFileChange(event) {
   console.log('image==============',event.target.files[0])
    if(!event.target.files.length) return;
  this.file = event.target.files[0];
  if (this.file.type == "image/gif" || this.file.type == "image/jpeg" || this.file.type == "image/jpg" || this.file.type == "image/png") {
    this.image_file_type= event.target.files[0].type.split('/')[0]
  const reader = new FileReader();
  reader.onload = e =>{
    console.log(e.target.result)
    this.advertise_image = reader.result.toString();
  };
  reader.readAsDataURL(this.file);
  }else {
    this.toastr.error("Invalid Image Format. Please Upload JPEG,JPG or PNG Format.")
  }
}

  onFileChangePDF(event) {

    if(!event.target.files.length) return;
  this.PDFfile = event.target.files[0];
  if (this.PDFfile.type == "application/pdf") {
console.log(this.PDFfile)
this.pdf_file_type= event.target.files[0].type.split('/')[0]
  const reader = new FileReader();
  reader.onload = e =>{
    console.log(e.target.result)
    this.advertise_deal_pdf = reader.result.toString();
  };
  reader.readAsDataURL(this.PDFfile);
  } else{
    this.toastr.error("Invalid Format. Please Upload PDF.")
  }
}

  onItemSelect(item: any) {
    console.log(this.selectedItems);
    
  }
  onSelectAll(items: any) {
 
    console.log(this.selectedItems);
  }
  ongetAllCountry() {
    this.spinner.show();
    this.webservice.adminGetData('countryList').then((result: any) => {
      console.log(result)
      if(result.status == 1) {
        this.countryList = result.data;
      } else {
        console.log(result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  onCountryChange(val) {
    this.country = val;
    console.log(val)
    // this.countryName = name;
    if(this.country != '') {
      this.spinner.show();
      this.webservice.adminGetData('stateList/'+this.country).then((result: any) => {
        console.log(result)
        if(result.status == 1) {
          this.stateList = result.data;
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }
  }

  onStateChange(val) {
    this.state = val;
    // this.stateName = name
    if(this.state != '') {
      this.spinner.show();
      this.webservice.adminGetData('cityList/'+this.state).then((result: any) => {
        console.log(result)
        if(result.status == 1) {
          this.citylist = result.data;
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }


  onCityChange(val) {
    this.city = val;
    console.log('city: ',this.city)
    if (this.city != '') {
      this.webservice.adminGetData('propertyListByCity/'+this.city).then((result: any) => {
        console.log("result",result);
        if (result.status == 1) {
          this.propertymanagetlist = result.data;
          this.dropdownList = this.propertymanagetlist;
          console.log("result", this.propertymanagetlist);
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
  
  
      this.dropdownSettings = {
        singleSelection: false,
        idField: '_id',
        textField: 'property_details',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true
      };

    }
  }


  onAdd() {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
 var rem = /(^[0-9]{10}$)/;

    this.property = []
    for (var i=0; i<this.selectedItems.length; i++) {
      this.property.push(this.selectedItems[i]._id)
    }
    if (this.title == '') {
      this.toastr.error('Please enter Merchant Name');
    } 
    else if(this.merchant_phone_number!= '' && !rem.test(this.merchant_phone_number)) {
          this.toastr.error('Merchant Phone Number Should contain 10 digits.Please enter Valid Phone No.')
    } 

    else if(this.merchant_alternate_phone_number!= '' && !rem.test(this.merchant_alternate_phone_number)) {
      this.toastr.error('Merchant Whatsapp No Should contain 10 digits.Please enter Valid Number. ')
} 
else if (!re.test(this.email)) {
  this.toastr.error('Please enter Valid Email');
  // this.emailValidation = true;
} 
else if (this.startDate > this.endDate ) {
  this.toastr.error('Choose Proper End Date');
} 
  //   else if (this.description == '') {
  //     this.toastr.error('Please enter Description');
  //  } 
    // else if (this.web_link == '') {
    //   this.toastr.error('Please enter weblink');
    // } 
    else if (this.advertise_image == '') {
      this.toastr.error('Please upload image');
    } 
    else if ( this.image_file_type != "image"){
      console.log('file======',this.image_file_type)
  
          this.toastr.error('Please upload correct Image Format');
        } 
        // else if (this.file!= undefined &&  this.pdf_file_type != "application"){
        //   console.log('file======',this.pdf_file_type)
      
        //       this.toastr.error('Please upload correct File Format');
        //     }
    else {
      
    console.log("userdata",this.UserData)
    console.log(this.file)
    this.spinner.show();
    let fd=new FormData();
    fd.append('created_by_id',this.UserData._id);
    fd.append('created_by',this.UserData.name);
    fd.append('title',this.title);
    // fd.append('description',this.description);
    fd.append('web_link',this.web_link);
    fd.append('advertise_image',this.file);
    fd.append('property_id',this.property);
    fd.append('country_id',this.country);
    fd.append('state_id',this.state);
    fd.append('city_id',this.city);
    fd.append('start_date',this.startDate);
    fd.append('end_date',this.endDate);
    fd.append('merchant_name',this.merchant_name);
    fd.append('merchant_phone_no',this.merchant_phone_number);
    fd.append('address',this.address);
    fd.append('suite_no',this.suite_no);
    fd.append('postal_code',this.postal_code);
    fd.append('youtube_link',this.youtube_link);
    fd.append('merchant_alternate_phone_number',this.merchant_alternate_phone_number);
    fd.append('merchant_phone_no_country_code',this.merchant_phone_no_country_code);
    fd.append('advertise_deal_pdf',this.PDFfile);
    fd.append('merchant_alternate_phone_number_country_code',this.merchant_alternate_phone_number_country_code);
    fd.append ('email',this.email)
    console.log("fd",fd)
  ;

    this.webservice.adminPostData('saveAdvertise', fd).then((result) => {
      console.log("deddsd",result);
      this.data = result;
      if (this.data.status == 1) {
        this.toastr.success(this.data.msg);
        this.title = '';
        // this.description = '';
        this.web_link = '';
        this.advertise_image = '';
        this.file = '';
        this.country = '';
        this.state = '';
        this.city = '';
        this.selectedItems = [];
        this.property = [];
        this.merchant_name = '';
        this.merchant_phone_number = '';
        this.startDate = '';
        this.endDate = '';
        this.address = '';
        this.suite_no = '';
        this.postal_code='';
        this.youtube_link='';
        this.merchant_alternate_phone_number='';
        this.merchant_phone_no_country_code='';
        this.advertise_deal_pdf='';
        this.merchant_alternate_phone_number_country_code='';
        this.email = '';
      } else {
        this.toastr.error(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
}

}
