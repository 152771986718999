
<div class="content_box">
    <h2>Edit Flash Deals Charges</h2>
  
    <div class="inpurwraper">
      <label for="input">Plan Type</label>
      <input [(ngModel)]="title" readonly type="text" class="form-control" />
    </div>
  
    <div class="inpurwraper">
      <label for="input">Amount</label>
      <input [(ngModel)]="amount" type="number" class="form-control" />
    </div>
  
    <!-- <div class="inpurwraper">
      <label>Type</label>
      <select class="input-field" [(ngModel)]="gender" (ngModelChange)="onChangeGender(gender)"> 
      <select class="input-field">
        <option value="Select Type">Select Type</option>
        <option value="Monthly">Monthly</option>
        <option value="Yearly">Yearly</option>
      </select>
    </div> -->
  
    <div class="col-lg-12">
      <div class="button-set">
        <button class="default-btn" (click)="onUpdate()">Update</button>
   
      </div>
    </div>
  </div>
  