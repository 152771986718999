<div class="content_box">
  <h2>Memo List</h2>
  <div class="responsive_table">
  <table mat-table class="mat-elevation-z8">
    <thead>
      <tr>
          <th style="width: 5%;">Sl#</th>
          <th style="width: 15%;">Subject</th>
          <th style="width: 45%;">Memo</th>
          <th style="width: 15%;">Date</th>
          <th style="width: 10%;">Created By</th>
          <th *ngIf="user_type == 1" style="width: 10%;">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let memodata of memoList let i=index">
          <td data-label="Sl#">{{i+1}}</td>
          <td data-label="Subject">{{memodata.subject}}</td>
          <td data-label="Memo">{{memodata.message}}</td>
          <td data-label="Date">{{memodata.date_required | date}}</td>
          <td data-label="Created By">{{memodata.created_by}}</td>
          <td data-label="Action" *ngIf="user_type == 1"><i style="cursor: pointer;" class="material-icons" [routerLink]="['/main/edit-memo/'+memodata._id]">create</i></td>
      </tr>
    </tbody>
  </table>
  </div>
</div>
<div *ngIf="user_type == 1">
  <div class="btn btn-primary btn-round default_btn mat-raised-button" [routerLink]="['/main/add-memo']" style="float:right">
      <i class="material-icons float-icon">add</i>
  </div>
</div>
