<!-- The Modal -->
<div class="modal" id="modallogin">
  <!-- <div class="modal" data-toggle="modal" data-target="#myModal"> -->
    <div class="modal-dialog">
        <div class="modal-content">

            <!-- Modal body -->
            <div class="modal-body">
              <!-- #closebutton -->
                <button type="button" class="close" data-dismiss="modal">&times;</button>

                <div class="form_content_details">
                    <div class="forminfo">
                        <h2 class="modal-title">Get more things done with Loggin platform.</h2>

                        <p class="addpadding">Access to the most powerfull tool in the entire</p>
                        <div class="formcont">
                            <!-- <form action="test.php" method="post" id="users"> -->

                                <!-- <div class="form-group">
                                    <select class="form-control selectopt">
                                          <option>Login Type</option>
                                          <option>Manager</option>
                                          <option>Resident</option>
                                        </select>
                                </div> -->
                                <div class="form-group">
                                    <input [(ngModel)]="email" type="email" class="form-control" placeholder="Email Address">
                                </div>
                                <p class="error" *ngIf="validMailError == true">Enter valid email</p>
                                <div class="form-group">
                                    <input [(ngModel)]="password" type="password" class="form-control" placeholder="Password">
                                </div>
                                <p class="error" *ngIf="error == true">{{data.msg}}</p>
                                <div class="form-check checkcaption">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                    <label class="form-check-label" for="exampleCheck1">keep me logged in on this computer</label>
                                </div>
                                <div class="inpbtn_wrap">
                                    <button id="modallogin" (click)="onLogin();"
                                    *ngIf="email == '' ||
                                    password == ''"
                                    [disabled] = "email == '' ||
                                    password == ''"
                                    type="submit" class="btn defbtn"
                                    style="cursor: no-drop">Login</button>

                                    <button id="modallogin" (click)="onLogin();"
                                    *ngIf="email != '' &&
                                    password != ''"
                                    type="submit" class="btn defbtn"
                                    >Login</button>
                                </div>
                                <p class="forpass">I forgot my password</p>
                                <div class="inpbtn_wrap">
                                    <button type="button" class="btn defbtn" data-toggle="modal" data-target="#modalregistration">Register</button>

                                </div>



                            <!-- </form> -->
                        </div>
                    </div>
                    <div class="addpic">
                        <img src="assets/images/registration_pic.png" alt="">
                    </div>
                </div>
            </div>

            <!-- Modal footer -->
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            </div> -->

        </div>
    </div>
</div>
