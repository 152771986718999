<!-- <app-home></app-home>
<app-aboutus></app-aboutus>
<app-partners></app-partners>
<app-contactus></app-contactus>

<router-outlet></router-outlet> -->
<app-header *ngIf="user_extension == undefined"></app-header>
<div class="maincontent_area">
    <router-outlet></router-outlet>
    <ngx-spinner size="large" color="#393999" type="ball-scale-multiple" fullScreen="true">
    </ngx-spinner>
</div>
<!-- <app-footer *ngIf="user_extension == undefined"></app-footer> -->
<app-login></app-login>
<app-registration></app-registration>
<!-- <app-adminlogin></app-adminlogin> -->
