<div class="content_box">
    <h2>Family Member List</h2>
    <div class="responsive_table">

    <table mat-table class="mat-elevation-z8">
        <thead>
        <tr>
            <th>Sl#</th>
            <th>Associate Name</th>
            <th>Resident Name</th>
            <th>Property Name</th>
            <th>Email</th>
            <th>Contact No</th>
            <th>Relation</th>
            <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let residentdata of residentList let i=index">
            <td data-label="Sl#">{{i+1}}</td>
            <td data-label="Resident Name">{{residentdata.name}}</td>
            <td data-label="Property Manager">{{residentdata.parent_name}}</td>
            <td data-label="Property Name">{{residentdata.property_name}}</td>
            <td data-label="Email">{{residentdata.email}}</td>
            <td data-label="Contact No">{{residentdata.country_code}} {{residentdata.contact_no}}</td>
            <td data-label="Relation">{{residentdata.relationship}}</td>
            <td data-label="Action" style="cursor: pointer;">
               <div class="flexIbtn">
                <i class="respdn rRed" *ngIf="residentdata.status == 0" (click)="onReject(residentdata._id);">Reject</i>&nbsp;
                <i class="respdn rRed" *ngIf="residentdata.status == 2" >Rejected</i>
                <i class="respdn rGreen"  *ngIf="residentdata.status == 0" (click)="onAccept(residentdata._id);">Accept</i>
                <i class="respdn rGreen" *ngIf="residentdata.status == 1" >Accepted</i>
               </div>
            </td>
            <!-- <td *ngIf="residentdata.status == 0" style="background: red;border-radius: 12px;"><i style="cursor: pointer;" (click)="onReject(residentdata._id);">Reject</i></td>
            <td *ngIf="residentdata.status == 2" disabled style="background: red;border-radius: 12px;"><i style="cursor: pointer;">Rejected</i></td>
            <td *ngIf="residentdata.status == 0" style="background: #1ebb0a;border-radius: 12px;"><i style="cursor: pointer;" (click)="onAccept(residentdata._id);">Accept</i></td>
            <td *ngIf="residentdata.status == 1" disabled style="background: #1ebb0a;border-radius: 12px;"><i style="cursor: pointer;">Accepted</i></td> -->
        </tr>
        </tbody>
    </table>
    </div>
    <div  class="Nodatafound" *ngIf="NodataFound">No family member found</div>
  </div>
