<div class="content_box">
  <h2>Flash Deals Charges</h2>
  <div style="margin-bottom: 10px">

  </div>
  <div class="responsive_table">
  <table mat-table class="mat-elevation-z8">
    <thead>
    <tr>
      <th>Sl#</th>
      <th>Plan Type</th>
      <th>Amount</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of list | paginate:{itemsPerPage: tableSize, currentPage: page, totalItems:count}; let i=index" >
      <td data-label="Sl#">{{ (page - 1) * tableSize + i +1 }}</td>
      <td data-label="Plan Type">{{item.title}}</td>
      <td data-label="Amount">{{item.amount}}</td>
      <td data-label="Action"><i style="cursor: pointer;" class="material-icons"
          [routerLink]="['./edit-flash-deal-charges/'+item._id]">create</i></td>
    </tbody>      
  </table>
  </div>
  <div class="justify-content-end d-flex">
    <pagination-controls previousLabel="Prev" nextLabel="Next" maxSize="5" (pageChange)="onTableDataChange($event) " ></pagination-controls>
  </div>
  
  <div class="Nodatafound" *ngIf="list.length == 0">No result found!</div>
  <!-- <div class="btn btn-primary btn-round default_btn mat-raised-button" [routerLink]="['/main/add-flash-deal-charges']"
    style="float:right">
    <i class="material-icons float-icon">add</i>
  </div> -->
</div>