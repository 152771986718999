<div class="matters_section">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h2>Keep your <strong> staff focused on what matters</strong></h2>
                <p>Intuitive software your entire team will actually want to use. Walter automatically alerts your staff about important events, and streamlines the flow of information between shifts, departments, and systems.</p>
                <div class="storeinfo">
                    <a href="#">
                        Read More
                    </a>

                </div>
            </div>
        </div>
    </div>
</div>