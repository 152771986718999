<div class="content_box">
  <h2>Overview</h2></div>

<div class="search"><p>Search by Country, State, City</p></div>

<div class="row">
   
    <div class="col-lg-4">
       
        <div class="input-field-box def_mar_bottom">
            
            <label>Country</label>
            <select class="input-field" [(ngModel)]="country" (change)="OnChangeCountry(country)">
                <option>Select Country</option>
                <option *ngFor="let list of countryList" [value]="list.id">{{list.name}}</option>

            </select>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="input-field-box def_mar_bottom">
            <label>State</label>
            <select class="input-field" [disabled]="country == ''" [(ngModel)]="state"
                (ngModelChange)="OnChangeState(state)">
                <option>Select State</option>
                <option *ngIf="!stateList || stateList.length === 0" value="no-state-found">No state found</option>
                <option *ngFor="let list of stateList" [value]="list.id">{{list.name}}</option>
            </select>
        </div>
    </div>
    
<div class="col-lg-4">
    <div class="input-field-box def_mar_bottom">
        <label>City</label>
        <select class="input-field" [disabled]="state == ''" [(ngModel)]="city"
            (ngModelChange)="onChangeCity(city)">
            <option>Select City</option>
            <option *ngIf="!cityList || cityList.length === 0" value="no-city-found">No city found</option>
            <option *ngFor="let list of cityList" [value]="list.id">{{list.name}}</option>
        </select>
        <!-- <div  [(ngModel)]="city"
        (ngModelChange)="onChangeCity(city)" >
        <select class="input-field"  class="input-field" [disabled]="state == 'Select City'" >
            <option value="" selected>Select City</option>
            <option *ngFor="let list of cityList" [value]="list.id">{{list.name}}</option>

        </select> -->
        

    </div>
</div>



</div>

<div class="row mb-3">
    <div class="col-lg-6">
        <div class="inpurwraper def_mar_bottom">
            <label for="input"><strong>Search By Date</strong></label>
            <input type="date" id="input" class="form-control" [(ngModel)]="Date" mdbInput />
          </div>
    </div>
    <div class="col-lg-6">
        <!-- <label for="input">&nbsp;</label> -->
        <button type="button" (click)="graphsearch();" class="btn btn-primary def_searchbtn">Search</button>&nbsp;
        <button type="button" class="btn btn-primary def_searchbtn" (click)="resetForm()">Reset search</button>
        
        
    </div>
</div>   
<!-- <div>
    <canvas id="MyChartpie"></canvas>
  </div> -->


  <div style="text-align:center">
    <!-- <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [title]="chartOptions.title"
    ></apx-chart> -->
  </div>
<!-- <apx-chart [series]="series" [chart]="chart" [title]="title"></apx-chart> -->
  
