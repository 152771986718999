<app-innerbanner></app-innerbanner>

<div class="inner_cont">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="inncont">
                    <div class="addpic">
                        <img src="assets/images/aboutpic.png" alt="">
                    </div>
                    <div class="residentinfo">

                        <h2>Our <strong> Story</strong></h2>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo Nemo enimjq
                            Letraset sheets.
                        </p>
                        <p>Omnis iste natus error sit voluptatem accusantium doloremque laudantium, but the majority suffered.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-someclients></app-someclients>

<!-- /var/www/html/nodeserver.brainiuminfotech.com/public_html/rahul/eq11_admin/v2 -->
