<!-- <p>dailydealsa-add works!</p> -->
<div class="content_box">
    <h2>Edit Flash Deal</h2>

    <div class="inpurwraper">
      <label for="input">Merchant Name<span class="required">*</span></label>
      <input [(ngModel)]="merchant_name" type="text" class="form-control"  />
    </div>

    <div class="inpurwraper">
      <label for="input">Suite Number</label>
      <input [(ngModel)]="suite_no" type="text" class="form-control"  />
    </div>

    <div class="inpurwraper">
      <label for="input">Merchant Address<span class="required">*</span></label>
      <input [(ngModel)]="merchant_address" type="text" class="form-control" />
    </div>
  
  
    <!-- <div class="inpurwraper">
      <label for="input">Property</label>
      <select class="form-control" [(ngModel)]="property" (change)="changeproperty(property)">
        <option [ngValue]="''">Property</option>
        <option *ngFor="let list of propertymanagetlist" [value]="list._id">{{list.property_name}}</option>
  
      </select>
    </div> -->

    <div class="inpurwraper">
      <label>Select Country<span class="required">*</span></label>
      <select class="form-control" [(ngModel)]="country"
        (change)="onCountryChange(country)">
        <option *ngFor="let option of countryList" [value]="option.id">{{option.name}}</option>
      </select>
    </div>

    <div class="inpurwraper">
      <label>Select State<span class="required">*</span></label>
      <select [disabled]="country == ''" class="form-control" [(ngModel)]="state"
        (change)="onStateChange(state)">
        <option disabled></option>
    <option *ngIf="!stateList || stateList.length === 0" value="no-state-found">No state found</option>
        <option *ngFor="let option of stateList" [value]="option.id">{{option.name}}</option>
      </select>
    </div>

    <div class="inputwrapper">
      <label>Select City<span class="required">*</span></label>
      <select [disabled]="state == ''" class="form-control" [(ngModel)]="city" (change)="onCityChange(city)">
        <option disabled></option>
        <option *ngIf="!citylist || citylist.length === 0" value="no-city-found">No city found</option>
          <option *ngFor="let option of citylist" [value]="option.id">{{option.name}}</option>
        
      </select>
  </div>

    <div class="inpurwraper">
      <label>Select Property Name<span class="required">*</span></label>
      <select class="form-control" [disabled]="city == ''" [(ngModel)]="property" (ngModelChange)="onChangeCondoName(property)"  *ngIf="consoNameList.length > 0">
        <option disabled></option>
          <option *ngFor="let list of consoNameList" [value]="list._id">{{list.property_details}}</option>
      </select>
      <input [(ngModel)]="property" type="text" class="form-control" *ngIf="consoNameList.length == 0" readonly/>
    </div>

    <div class="inpurwraper">
      <label for="input">Postal Code</label>
      <input
      [(ngModel)]="postal_code"
        type="text"
        class="form-control"
     
      />
    </div>

    
    <div class="inpurwraper">
      <!-- <label for="input">Phone Number</label>
      <input [(ngModel)]="phone_no" type="text" class="form-control" />
      <p *ngIf="phone_noValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p>
     -->
     <label for="input">Phone Number<span class="required">*</span></label>
    <!-- <input [(ngModel)]="phone_no" type="number" class="form-control" /> -->
    <br>
    <!-- <div class="flexFld phnumber">
    <input
    class="form-control phonecode"
    [(ngModel)]="country_code"
    readonly
  />
  <input
    type="number"
    class="form-control phone form-input "
    onwheel="this.blur()"
    [(ngModel)]="phone_no"
  /> -->
</div>
    <!-- <input type="text"
      class="form-control"
      style="width: 253%;"
      ng2TelInput
      [ng2TelInputOptions]="{initialCountry: 'ca'}"
      (hasError)="hasError($event)"
      (ng2TelOutput)="getNumber($event)"
      (intlTelInputObject)="telInputObject($event)"
      [(ngModel)]="phone_no"
      (countryChange)="onCountryCodeChange($event)" /> -->
  <!-- <p *ngIf="phone_noValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->
  <input class="form-control" id="Phone" type="text" minlength="10" maxlength="15" onwheel="this.blur()" >

    </div>

    
    <div class="inpurwraper">
      <!-- <label for="input">Phone Number</label>
      <input [(ngModel)]="phone_no" type="text" class="form-control" />
      <p *ngIf="phone_noValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p>
     -->
     <label for="input">Whatsapp Number</label>
    <!-- <input [(ngModel)]="phone_no" type="number" class="form-control" /> -->
    <br>
    <!-- <div class="flexFld phnumber">
      <input
    class="form-control phonecode"
    [(ngModel)]="alternate_phone_no_country_code"
    readonly
  />
  <input
    type="number"
    class="form-control phone form-input"
    onwheel="this.blur()"
    [(ngModel)]="alternate_phone_no"
  />
    </div> -->
    <!-- <input type="text"
      class="form-control"
      style="width: 253%;"
      ng2TelInput
      [ng2TelInputOptions]="{initialCountry: 'ca'}"
      (hasError)="hasError($event)"
      (ng2TelOutput)="getNumber($event)"
      (intlTelInputObject)="telInputObject($event)"
     
      (countryChange)="onCountryCodeChange($event)" /> -->
  <!-- <p *ngIf="phone_noValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->
       
  <input id="whatsapp" class="form-control" type="text" minlength="10" maxlength="15" onwheel="this.blur()" >
    </div>

    
    <div class="inpurwraper">
      <label for="input">Email<span class="required">*</span></label>
      <input [(ngModel)]="subject" type="text" class="form-control" />
    </div>

  
    <div class="inpurwraper">
      <label for="input">Advertise Title<span class="required">*</span></label>
      <input
        [(ngModel)]="title"
        type="text"
        class="form-control"
        placeholder="Title"
      />
    </div>

    <div class="inpurwraper">
      <label for="input">Description<span class="required">*</span></label>
      <input
        [(ngModel)]="description"
        type="text"
        class="form-control"
        placeholder="Description"
      />
    </div>


  
  
   
  
  
    <div class="inpurwraper">
      <label for="input">Deal Price($)<span class="required">*</span></label>
      <input [(ngModel)]="deal_price" type="number" onwheel="this.blur()" class="form-control" />
    </div>
  
    <div class="inpurwraper" *ngIf="discount_type==1">
      <label for="input">Discount($)<span class="required">*</span></label>
      <input [(ngModel)]="discount" type="number" onwheel="this.blur()" class="form-control"  />
    </div>
    <div class="inpurwraper" *ngIf="discount_type==2">
      <label for="input">Discount(%)<span class="required">*</span></label>
      <input [(ngModel)]="discount_percentage" type="number" onwheel="this.blur()" class="form-control"  />
    </div>
  
  
  

    <!-- <div class="inpurwraper">
      <input
        [(ngModel)]="date"
        type="text"
        class="form-control"
        [matDatepicker]="picker"
        placeholder="Choose a Date"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div> -->

    <div class="inpurwraper">
      <label for="input">Posting Start Date<span class="required">*</span></label>
      <input
        type="date"
        id="input"
        class="form-control"
        [(ngModel)]="startDate"
        name="startDate"
        class="form-control" min="{{ currentDate | date: 'yyyy-MM-dd' }}"
        mdbInput
      />
    </div>

    <div class="inpurwraper">
      <label for="input">Posting End Date<span class="required">*</span></label>
      <input type="date" id="input" class="form-control" [(ngModel)]="endDate" class="form-control" min="{{ currentDate | date: 'yyyy-MM-dd' }}" mdbInput />
    </div>
  

    <div class="inpurwraper">
        <label for="input">From Time<span class="required">*</span></label>
        <input
          type="time"
          id="input"
          class="form-control"
          [(ngModel)]="fromTime"
          class="form-control"
          mdbInput
        />
      </div>
    

    <div class="inpurwraper">
        <label for="input">To Time<span class="required">*</span></label>
        <input
          type="time"
          id="input"
          class="form-control"
          [(ngModel)]="toTime"
          class="form-control"
          mdbInput
        />
      </div>

      <div class="inpurwraper">
        <label for="input">Website Link or Mobile App Link</label>
        <input
          [(ngModel)]="website"
          type="text"
          class="form-control"
          placeholder="Website Link"
        />
      </div>
      <div class="inpurwraper">
        <label for="input">Ecommerce App Link / Weblink</label>
        <input [(ngModel)]="ecom_link" type="text" class="form-control"  placeholder="Ecommerce Link" />
      </div>

      <div class="inpurwraper">
        <label for="input">YouTube Video Link</label>
        <input
        [(ngModel)]="video_link"
          type="text"
          class="form-control"
          placeholder="Video Link"
        />
      </div>


      <div class="inputwrapper">
        <label for="input">Deals Image<span class="required">*</span></label>
        <input #fileInput type="file" class="form-control"
               accept="image/gif,image/jpeg,image/jpg,image/png" 
               (change)="onFileChange($event)" />
      </div>
      <div *ngIf="image" class="uploadImg">
        <img [src]="image"/>
        <button type="button" (click)="removeImage()">
          <i class="bi bi-x-circle"></i>
        </button>
      </div>
      

   <!-- <div class="inputwrapper">
  <label for="input">Deals PDF</label>
  <input
    type="file"
    accept="application/pdf" 
    class="form-control"
    (change)="onFileChangePDF($event)"
  />
</div>
<div>
  <p class="pdf">Preview File:
    <a *ngIf="checkPdf(Pdf_prev) !== 'No pdf file found'" 
       [href]="checkPdf(Pdf_prev)" target="_blank">
      Click here to view PDF
    </a>
    <span *ngIf="checkPdf(Pdf_prev) === 'No pdf file found'">No PDF file found</span>
  </p>
</div> -->


<div class="inputwrapper">
  <label for="input">Deals PDF</label>
  <input 
    type="file" 
    accept="application/pdf" 
    class="form-control"
    #fileInputs 
    (change)="onFileChangePDF($event)" 
  />
</div>

<!-- Conditionally show the PDF preview section -->
<div >
  <div class="pt-2">
      <span *ngIf="checkPdf(Pdf_prev) !== 'No pdf file found'  && PDFfile === undefined">Preview File:
      
      <a             
        [href]="checkPdf(Pdf_prev)" 
        target="_blank"
        [class.disabled]="checkPdf(Pdf_prev) === 'No pdf file found'"
      >
       Click here to view PDF
      </a>
    </span>
  </div>
  
  <!-- <div *ngIf="checkPdf(Pdf_prev) !=='' && PDFfile === undefined">
    <p class="pdf">Preview File:
      <a [href]="checkPdf(Pdf_prev)"  target="_blank">Click to view PDF</a>
    </p>
  </div>
</div> -->



  <!-- Section with PDF icon and remove button -->   

  <div *ngIf="fileSelected && pdf_enable==true" class="uploadImg mt-1">
    <img src="assets/images/pdf.png" alt="PDF Icon" style="height: 50px; width:50px;">
    <button type="button" (click)="removePDF()">
      <i class="bi bi-x-circle"></i>
    </button>
  </div>


  <span *ngIf="!fileSelected && checkPdf(Pdf_prev) === 'No pdf file found'">Preview File: No PDF file found</span>

    
    

  
<div class="inpbtn_wrap">
  <button type="button" class="btn defbtn"
          *ngIf="property != '' && property != 'No property found' && fromTime != '' && toTime != '' &&
                title != '' && description != '' && image != '' && endDate != '' && merchant_name != '' && 
                subject != '' && merchant_address != '' && phone_no != '' && deal_price != '' && discount != '' && !imageRemoved && deal_price != null  && pdf_enable == true"
          (click)="onEdit()">
    Save
  </button>

  <button type="button" class="btn defbtn"
          *ngIf="property == '' || property == 'No property found' || fromTime == '' || toTime == '' ||
                title == '' || description == '' || image == '' || endDate == '' || merchant_name == '' || 
                subject == '' || merchant_address == '' || phone_no == '' || deal_price == '' ||  deal_price == null || discount == '' || imageRemoved || pdf_enable == false"
          [disabled]="property == '' || property == 'No property found' || fromTime == '' || toTime == '' ||
                      title == '' || description == '' || image == '' || endDate == '' || merchant_name == '' ||
                      subject == '' || merchant_address == '' || phone_no == '' || deal_price == '' ||  deal_price == null || discount == '' || imageRemoved || pdf_enable == false"
          style="cursor: no-drop;"
          (click)="onEdit()">
    Save
  </button>
</div>
