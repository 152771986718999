import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';

@Component({
  selector: 'app-familymember',
  templateUrl: './familymember.component.html',
  styleUrls: ['./familymember.component.scss']
})
export class FamilymemberComponent implements OnInit {
  residenrtId:  any;
  data: any;
  residentList: any;
  NodataFound: boolean = false;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) {
      this.route.params.subscribe(params => {
        this.residenrtId = params['resident_id'];
        console.log(this.residenrtId);
      });

     }

  ngOnInit(): void {
    

    this.spinner.show();
    this.webservice.adminGetData('getFamilyMember/'+this.residenrtId).then((result) => {
      this.data = result;
      console.log(this.data);
      if(this.data.status == 1) {
        this.residentList = this.data.data;
        this.NodataFound = false;
        console.log('residentList: ', this.residentList)
      } else {
        this.NodataFound = true;
        console.log(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.NodataFound = true;
      this.spinner.hide();
      console.log(err);
    });
  }

  onReject(id){
    console.log(id);
    var params = {
      family_member_id: id
    }
    this.spinner.show();
    this.webservice.adminPostData('rejectFamilyMember/',params).then((result) => {
      this.data = result;
      console.log(this.data);
      if(this.data.status == 1) {
        this.ngOnInit();
      } else {
        console.log(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

  }
  onAccept(id){
    console.log(id)
    var params = {
      family_member_id: id
    }
    this.spinner.show();
    this.webservice.adminPostData('approveFamilyMember/',params).then((result) => {
      this.data = result;
      console.log(this.data);
      if(this.data.status == 1) {
        this.ngOnInit();
      } else {
        console.log(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

}
