import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { WebserviceService } from 'src/app/webservice.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-advatisment',
  templateUrl: './advatisment.component.html',
  styleUrls: ['./advatisment.component.scss']
})
export class AdvatismentComponent implements OnInit {
  search: any = '';
  page:number = 1;
  count:number = 0;
  tableSize:number = 4;
  tableSizes:any = [5,10,15,20];

  data: any;
  public list: any = [];
  user_type: any;
  UserData: any;
  logintype: any;


 cancelClicked: true;
  popoverTitle = 'Delete!';
  popoverMessage = 'If you want to delete this property, it will automatically delete all the data related this property. Are you sure you want to delete?';
  confirmClicked = false;

 

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute, private toastr: ToastrService,) { }

  ngOnInit(): void {
    // this.postList();

    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData.user_type)
    let logintype = '';
    if(this.UserData.user_type == 0) {
      logintype = 'admin';
    } else {
      this.logintype = this.UserData._id
    }
   
    // this.user_type = this.UserData.user_type;
    this.spinner.show();
    this.webservice.adminGetData('advertiseList/'+ logintype + '/undefined').then((result) => {
      this.data = result;
      console.log(this.data);
      if(this.data.status == 1) {
        this.list = this.data.data;
        console.log(this.list)
      } else {
        console.log(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  // postList():void{
  //   this.webservice.adminGetData('advertiseList/'+this.logintype).then((result)=>{
  //     this.data = result;
  //     console.log("pagination:",this.data);
  //   })
  // }
  onTableDataChange(event:any){
    this.page = event ;
    // this.postList();
  }
  onTableSizeChange(event:any): void{
    this.tableSize = event.target.value;
    this.page = 1 ;
    // this.postList();
  }
  
  onDelete(id) {
    var params = {
      advertise_id: id
    }
    this.webservice.adminPostData('deleteAdvertise', params).then((result: any) => {
      if (result.status == 1) {
        this.toastr.success(result.msg);
        // this.ngOnInit();
        this.getadvertisementlist();
      } else {
        this.toastr.error(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });



  }
getadvertisementlist(){
  
  this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData.user_type)
    let logintype = '';
    if(this.UserData.user_type == 0) {
      logintype = 'admin'
    } else {
      logintype = this.UserData._id
    }
   
    this.user_type = this.UserData.user_type;
    this.spinner.show();
    this.webservice.adminGetData('advertiseList/'+ logintype + '/undefined').then((result) => {
      this.data = result;
      console.log(this.data);
      if(this.data.status == 1) {
        this.list = this.data.data;
        console.log(this.list)
      } else {
        console.log(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  

}
onSearch(value) {
  let logintype = '';
  if (this.UserData.user_type == 0) {
    logintype = 'admin';
  } else {
    logintype = this.UserData._id;
  }
  this.search = value;
  if (this.search != '') {
    this.spinner.show();
    this.webservice
      .adminGetData('advertiseList/' + logintype + '/' + value)
      .then(
        (result) => {
          this.data = result;
          console.log(this.data);
          if (this.data.status == 1) {
            this.list = this.data.data;
          } else {
            console.log(this.data.msg);
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
        }
      );
  } else {
    this.ngOnInit();
  }
}

}
