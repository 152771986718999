
<div class="content_box">
    <h2>Edit Support</h2>
  
    <div class="inpurwraper">
      <label for="input">Email<span class="required">*</span></label>
      <input [(ngModel)]="email" type="text" class="form-control" />
    </div>
    <div class="col-lg-12">
      <div class="button-set">
        <button class="default-btn" (click)="onUpdate()">Update</button>
   
      </div>
    </div>
  </div>
  