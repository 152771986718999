<div class="content_box">
    <h2>Subscription List</h2>
      <div class="responsive_table">

    <table mat-table class="mat-elevation-z8">
      <thead>
        <tr>
            <th>Sl#</th>
            <th>Creation&nbsp;Date</th>
            <th>Product Id</th>
            <th>Product Name</th>
            <th>Subscription Id</th>
            <th>Subscription Status</th>
            <th>Action</th>
        </tr>
      </thead>
        <tbody>
        <tr *ngFor="let item of list | paginate:{itemsPerPage: tableSize, currentPage: page, totalItems:count}; let i=index">
            <td data-label="Sl#" >{{ (page - 1) * tableSize + i +1 }}</td>
            <td data-label="created at" >{{item.updatedAt | date: 'dd/MM/yyyy'}}</td>
            <td data-label="productId" >{{item.productId}}</td>
            <td data-label="productname">{{ item.productName === 'resource' ? 'Resource' : item.productName === 'flash_deal' ? 'Flash Deal' : item.productName }}</td>
            <td data-label="subscriptionid" >{{item.stripe_subscription_id}}</td>
            <td data-label="status" >{{ item.stripe_subscription_status === 'trialing' ? 'Trail' : item.stripe_subscription_status }}</td>
            <td data-label="Action" ><i style="cursor: pointer;" class="material-icons"></i>
                <button 
                 
                class="btn btn-outline-secondary unsubscribe-button" 
                mwlConfirmationPopover 
                [popoverTitle]="popoverTitle"
                [popoverMessage]="popoverMessage" 
                placement="left" 
                (confirm)="onCancel(item.stripe_subscription_id)" 
                (cancel)="cancelClicked = true">
                    Unsubscribe
            </button></td>
        </tr>
        </tbody>
    </table>
    </div>
  </div>
  <!-- <button [routerLink]="['/main/subscription/sucess']">sucess</button> -->
  
  <div class="justify-content-end d-flex">
    <pagination-controls previousLabel="Prev" nextLabel="Next" maxSize="5" (pageChange)="onTableDataChange($event) " ></pagination-controls>
  </div>
  <div class="Nodatafound" *ngIf="list.length == 0">No result found!</div>
  

 