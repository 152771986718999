import { Component, OnInit } from '@angular/core';
import { WebserviceService } from 'src/app/webservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {

  page:number = 1;
  count:number = 0;
  tableSize:number = 4;
  tableSizes:any = [5,10,15,20];

  data: any;
  public staffList: any = [];
  user_type: any;
  UserData: any;
  search: any = '';
  parantId:any = '';

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) {
    }

  ngOnInit(): void {
    // this.postList();
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log('user_type--'+this.UserData);
    if (this.UserData.user_type == 0) {
      var parantId = 'no_parent'
    } else {
      parantId = this.UserData._id;
    }
    this.user_type = this.UserData.user_type;
    this.spinner.show();
    this.webservice.adminGetData('staffList/'+parantId+'/undefined').then((result) => {
      this.data = result;
      console.log(this.data);
      if(this.data.status == 1) {
        this.staffList = this.data.data;
      } else {
        console.log(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  onSearch(val) {
    this.page = 1;
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log('user_type--'+this.UserData);
    if (this.UserData.user_type == 0) {
      var parantId = 'no_parent'
    } else {
      parantId = this.UserData._id;
    }
    this.search = val;
    if (this.search == '') {
      this.spinner.show();
      this.webservice.adminGetData('staffList/'+parantId+'/undefined').then((result) => {
        this.data = result;
        console.log(this.data);
        if(this.data.status == 1) {
          this.staffList = this.data.data;
        } else {
          console.log(this.data.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    } else {
      this.spinner.show();
      this.webservice.adminGetData('staffList/'+parantId+'/'+this.search).then((result) => {
        this.data = result;
        console.log(this.data);
        if(this.data.status == 1) {
          this.staffList = this.data.data;
        } else {
          console.log(this.data.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }

  }
  //  postList():void{
  //   this.webservice.adminGetData('residentList/'+this.parantId+'/undefined').then((result)=>{
  //     this.data = result;
  //     console.log("pagination:",this.data);
  //   })
  // }
  onTableDataChange(event:any){
    this.page = event ;
    // this.postList();
  }
  onTableSizeChange(event:any): void{
    this.tableSize = event.target.value;
    this.page = 1 ;
    // this.postList();
  }

}
