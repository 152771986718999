import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';
import { Router} from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-payresource',
  templateUrl: './payresource.component.html',
  styleUrls: ['./payresource.component.scss']
})
export class PayresourceComponent implements OnInit {

  plan: any = '';
  planList: any = '';
  name: any = '';
  email: any;
  vendor_id: any = ''
  stripe_token: any = ''
  amount: any = ''
  subscription_id: any = ''
  UserData: any;
  val: any;
  save: any;
  saved:any ={};
  stripeTest!: FormGroup;
  currency:any;


  constructor(private fb: FormBuilder, 
    // private stripeService: StripeService, 
    public webservice: WebserviceService,

    private spinner: NgxSpinnerService,
    private toastr: ToastrService, private router: Router,) { }

  ngOnInit(): void {
    
    this.getCurrencySymbol();
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData);
    this.email = this.UserData.email;
    this.vendor_id = this.UserData._id
    console.log(this.UserData);

  }

  getCurrencySymbol(){
    this.webservice.getIP().subscribe((res:any)=>{  
      //this.ipAddress=res.ip;  
      
      this.getCurrencySymbol_op(res.ip)
    }); 
    
  }

  getCurrencySymbol_op(ip:any){
    this.webservice.getcurrency(ip).subscribe((res:any)=>{  
      //this.ipAddress=res.ip;  
      this.currency = res;
      console.log('ip=======',this.currency)
    }); 
  }

  createToken(): void {
    
  }
 



 
  

  subscription(type:any){
    var params
    if(this.currency != undefined){
      params = {
        user_id:this.UserData._id,
        price_id:type,
        currency: this.currency
  
      }
    }else{
      params = {
        user_id:this.UserData._id,
        price_id:type,
        currency: 'INR'
  
      }
    }
    
    console.log("params", params);

    this.spinner.show();
    this.webservice.WebpostData('subscribe/web', params).then((result: any) => {
      console.log(result)
      if (result.status) {

        window.open(result.url, "_self");
  
        



      } 
      else if(result.status ==0){
        this.toastr.error(result.msg);
      }
      
      
      
      else {
        this.toastr.error(result.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  resourcesubscription(type:any){
    var params = {
      user_id:this.UserData._id,
      price_id:type,
      currency: this.currency

    }
    console.log("params", params);

    this.spinner.show();
    this.webservice.WebpostData('subscribe/web', params).then((result: any) => {
      console.log(result)
      if (result.status) {

        window.open(result.url, "_self");
  
 



      } 
      else if(result.status ==0){
        this.toastr.error(result.msg);
      }
      
      
      
      else {
        this.toastr.error(result.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
}


