<div class="content_box">
    <h2>Subscription List</h2>

    <div class="inpurwraper">
        <label for="input">Subscription Type</label>
        <input type="text" class="form-control" [(ngModel)]="subscription_name" readonly />
    </div>

    <div class="inpurwraper">
        <label for="input">Subscription Amount</label>
        <input type="text" class="form-control"  [(ngModel)]="subscription_amount" readonly />
    </div>

    <div class="inpurwraper">
        <label for="input">Subscription Start Date </label>
        <input type="text" class="form-control"  [(ngModel)]="subscription_start_date" readonly />
    </div>

    <div class="inpurwraper">
        <label for="input">Subscription End Date</label>
        <input type="text" class="form-control"  [(ngModel)]="subscription_end_date" readonly />
    </div>

    <div class="col-lg-12">
        <div class="button-set">
          <button class="default-btn" (click)="cancel()" [disabled]="subscription_name == undefined || subscription_amount == undefined || subscription_start_date == undefined || subscription_end_date  == undefined ">Cancel</button>
          <!-- <button class="default-btn" (click)="cancel()" [disabled]="displayData">Cancel</button> -->
        </div>
      </div>
</div>