<div class="content_box">
  <h2>Subscription List</h2>
  <div style="margin-bottom: 10px">
    <div class="form-group">
      <div class="input-group">
        <input
          style="min-height: 40px"
          class="form-control form-control-sm w-60 srchIcon"
          type="text"
          placeholder="Search by Country, State, City, Business Name, User Name, Property Name"
          aria-label="Search"
          mdbInput
          [(ngModel)]="search"
          (ngModelChange)="onSearch(search)"
        />
        <div class="input-group-append" style="margin-left: 10px">
          <!-- Added margin-left -->
          <select
            class="custom-select form-control-sm"
            (change)="onSelectChange($event.target.value)"
          >
            <option value="View By" disabled selected>View By</option>
            <option value="All">All</option>
            <option value="flash_deal">Flash Deal</option>
            <option value="resource">Resource</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="responsive_table">
    <table mat-table class="mat-elevation-z8">
      <thead>
        <tr>
          <th>Sl#</th>
          <th>Subscription<br />Date</th>
          <th>Business Name</th>
          <th>User Name</th>
          <th>Property Name</th>
          <th>Country</th>
          <th>State</th>
          <th>City</th>
          <th>Product Name</th>
          <th>
            Subscription<br />
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let item of list
              | paginate
                : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                  };
            let i = index
          "
        >
          <td data-label="Sl#">{{ (page - 1) * tableSize + i + 1 }}</td>
          <td data-label="date">{{ item.updatedAt | date : "dd/MM/yyyy" }}</td>
          <td data-label="Business Name">{{ item.user_id.business_name }}</td>
          <td
            data-label="Name"
            style="cursor: pointer"
            [routerLink]="['./vendor-profile/' + item.user_id._id]"
          >
            {{ item.user_id.name }}
          </td>
          <td data-label="property name">{{ item.user_id.property_name }}</td>
          <td data-label="Country">{{ item.user_id.country }}</td>
          <td data-label="State">{{ item.user_id.province === 'xxxx' ? 'No state found' : item.user_id.province }}</td>
          <td data-label="City">{{ item.user_id.city === 'xxxx' ? 'No city found' : item.user_id.city }}</td>
          <td data-label="productname">
            {{
              item.productName === "resource"
                ? "Resource"
                : item.productName === "flash_deal"
                ? "Flash Deal"
                : item.productName
            }}
          </td>
          <td data-label="status" class="capitalize">
            {{
              item.stripe_subscription_status === "trialing"
                ? "Trail"
                : item.stripe_subscription_status
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- <button [routerLink]="['/main/subscription/sucess']">sucess</button> -->

<div class="justify-content-end d-flex">
  <pagination-controls
    previousLabel="Prev"
    nextLabel="Next"
    maxSize="5"
    (pageChange)="onTableDataChange($event)"
  ></pagination-controls>
</div>
<div class="Nodatafound" *ngIf="list.length == 0">No result found!</div>
