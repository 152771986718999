import { Component, OnInit } from '@angular/core';
import { WebserviceService } from 'src/app/webservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-resident',
  templateUrl: './resident.component.html',
  styleUrls: ['./resident.component.scss']
})
export class ResidentComponent implements OnInit {


  page:number = 1;
  count:number = 0;
  tableSize:number = 4;
  tableSizes:any = [5,10,15,20];

  data: any;
  residentList: any = [];
  parent_id: any = '';
  UserData: any;
  search: any = '';

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    // this.postList();
    // if(admin) {
    //   parent_id = 'no_parent'
    //   } else {
    //   parent_id = property manager id
    //   }
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData);
    if (this.UserData.user_type == 0) {
      this.parent_id = "no_parent";
    } else {
      this.parent_id = this.UserData._id;
    }
    this.spinner.show();
    this.webservice.adminGetData('residentList/'+this.parent_id+'/undefined').then((result) => {
      this.data = result;
      console.log(this.data);
      if(this.data.status == 1) {
        this.residentList = this.data.data;
      } else {
        console.log(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  onSearch(val) {
    this.page = 1;
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData);
    if (this.UserData.user_type == 0) {
      this.parent_id = "no_parent";
    } else {
      this.parent_id = this.UserData._id;
    }
    this.search = val;
    if(this.search == '') {
      this.spinner.show();
    this.webservice.adminGetData('residentList/'+this.parent_id+'/undefined').then((result) => {
      this.data = result;
      console.log(this.data);
      if(this.data.status == 1) {
        this.residentList = this.data.data;
      } else {
        console.log(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

    } else {
      this.spinner.show();
      this.webservice.adminGetData('residentList/'+this.parent_id+'/'+this.search).then((result) => {
        this.data = result;
        console.log(this.data);
        if(this.data.status == 1) {
          this.residentList = this.data.data;
        } else {
          console.log(this.data.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }

  }
  // postList():void{
  //   this.webservice.adminGetData('residentList/'+this.parent_id+'/undefined').then((result)=>{
  //     this.data = result;
  //     console.log("pagination:",this.data);
  //   })
  // }
  onTableDataChange(event:any){
    this.page = event ;
    // this.postList();
  }
  onTableSizeChange(event:any): void{
    this.tableSize = event.target.value;
    this.page = 1 ;
    // this.postList();
  }

}
