import { Component, OnInit } from '@angular/core';
import { WebserviceService } from 'src/app/webservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-addproperty',
  templateUrl: './addproperty.component.html',
  styleUrls: ['./addproperty.component.scss']
})
export class AddpropertyComponent implements OnInit {
  propertyName: any = '';
  propertyAddress: any = '';
  propertyContact: any = '';
  email: any;
  propertyDetailsData: any;
  emailValidation: boolean = false;
  propertyContactValidation: boolean = false;
  data: any;
  UserData: any;
  lat: any = '';
  long: any = '';
  country: any = '';
  city: any = '';
  state: any = '';
  countryList: any = [];
  stateList: any = [];
  citylist: any = [];
  postalCode:any='';
  title = 'rou';
  property_management_company: any = ''; 
  property_country_code: any='';
  countryCode: string = '';
  //Local Variable defined 
  formattedaddress=" "; 
  options={ 
    componentRestrictions:{ 
      // country:["AU"] 
    } 
  } 
  
  public AddressChange(address: any) { 
    //setting address from API to local variable 
     this.formattedaddress=address.formatted_address 
     this.propertyAddress = this.formattedaddress;
     this.lat = address.geometry.location.lat();
     this.long = address.geometry.location.lng();
     console.log(this.lat,this.long);
  } 

  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    this.ongetAllCountry();
    this.getUserType();
  }
  hasError(obj) {
    console.log(obj);
  }

  onCountryChanges(obj) {
    console.log(obj);
    this.countryCode = '+' + obj.dialCode
    console.log(this.countryCode );
  }
  getNumber(obj) {
    console.log("num",obj);
  }

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry('ca');
  }

  onCountryChange(val) {
    this.state = ''; // Reset state
    this.city = '';  // Reset city

    this.country = val;
    console.log(val)
    // this.countryName = name;
    if(this.country != '') {
      this.webservice.adminGetData('stateList/'+this.country).then((result: any) => {
        console.log(result)
        if(result.status == 1) {
          this.stateList = result.data;
        } else {
          console.log(result.msg)
        }
  
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }
  }
  onStateChange(val) {
    this.city = '';  // Reset city
    this.state = val;
    // this.stateName = name
    if(this.state != '') {
      this.webservice.adminGetData('cityList/'+this.state).then((result: any) => {
        console.log(result)
        if(result.status == 1) {
          this.citylist = result.data;
        } else {
          console.log(result.msg)
        }
  
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

  onCityChange(val) {
    this.city = val;
    // this.cityName = name;
  }

  ongetAllCountry() {
    this.webservice.adminGetData('countryList').then((result: any) => {
      console.log(result)
      if(result.status == 1) {
        this.countryList = result.data;
      } else {
        console.log(result.msg)
      }

    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  getUserType() {
    this.webservice.adminGetData('userTypes').then((result) => {
      console.log("userTypes: ", result);


    }, (err) => {
      console.log(err);
    });

  }

  onAdd() {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var rem = /(^[0-9]{10}$)/;
 if (!re.test(this.email)) {
      this.toastr.error('Please enter valid mail')

      // this.emailValidation = true;
    } 
    else if (!rem.test(this.propertyContact)) {
      this.toastr.error('Phone number should contain 10 digits. Please enter valid phone number. ')

      // this.emailValidation = true;
    }else {
      var params = {
        property_name: this.propertyName,
        property_address: this.propertyAddress,
        
        property_contact_no: this.propertyContact,
        property_email: this.email,
        user_types: this.UserData.user_type,
        latitude: this.lat,
        longitude: this.long,
        country_id: this.country,
        state_id: this.state,
        city_id: this.city,
        postal_code: this.postalCode,
        property_management_company: this.property_management_company,
        property_country_code:this.countryCode

      }
      console.log("params",params)
      this.spinner.show();
      this.webservice.adminPostData('saveProperty', params).then((result) => {
        this.data = result;
        if (this.data.status == 1) {
          this.toastr.success(this.data.msg);
          this.propertyName = '';
          this.propertyAddress = '';
          this.propertyContact = '';
          this.email = '';
          this.country = '';
          this.state = '';
          this.city = '';
          this.postalCode = '';
          this.property_management_company='';
          this.property_country_code='';
          this.router.navigate(['/main/property']);
        } else {
          this.toastr.error(this.data.msg);
        }
        //tost
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }

  }

}
