import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-managementtools',
  templateUrl: './managementtools.component.html',
  styleUrls: ['./managementtools.component.scss']
})
export class ManagementtoolsComponent implements OnInit {


 customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    // navText: ['Previous', 'Next'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: false
  }

  slidesStore = [
    {
      id:1,
      alt:'Smart Door Lock',
      src:'assets/images/door_lock.png',
      srchover:'assets/images/door_lockhover.png',
      title:'Smart Door Lock',
      descroption:'There are many variations ofpassages of Lorem Ipsum.'
    },
    {
      id:2,
      alt:'All Events',
      src:'assets/images/allevent.png',
      srchover:'assets/images/alleventhover.png',
      title:'All Events',
      descroption:'There are many variations ofpassages of Lorem Ipsum. '
    },
    {
      id:3,
      alt:'Important Contacts',
      src:'assets/images/contacticon.png',
      srchover:'assets/images/contacticonhover.png',
      title:'Important Contacts',
      descroption:'There are many variations ofpassages of Lorem Ipsum.'
    },
    {
      id:4,
      alt:'Smart Door Lock',
      src:'assets/images/door_lock.png',
      srchover:'assets/images/door_lockhover.png',
      title:'Smart Door Lock',
      descroption:'There are many variations ofpassages of Lorem Ipsum.'
    }

  ]




  constructor() { }

  ngOnInit(): void {
  }

}
