import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';
import * as moment from 'moment';
declare var intlTelInput: any;
@Component({
  selector: 'app-dailydealsedit',
  templateUrl: './dailydealsedit.component.html',
  styleUrls: ['./dailydealsedit.component.scss']
})
export class DailydealseditComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('fileInputs') fileInputs!: ElementRef;
  currentDate:any =new Date();
  daily_deal_id: any = '';
  UserData: any;
  data: any;
  propertyData: any;
  propertymanagetlist: any;
  property: any = '';
  fromTime: any = '';
  toTime: any = '';
  title: any = '';
  description: any = '';
  image: any = '';
  file:any;
  startDate: any = '';
  endDate: any = '';
  website: any = '';
  merchant_name: any = '';
  subject: any = '';
  merchant_address: any = '';
  phone_no: any = '';
  deal_price: any = '';
  discount: any = '';
  country: any = '';
  city: any = '';
  state: any = '';
  countryList: any = [];
  stateList: any = [];
  citylist: any = [];
  consoNameList: any = [];
  phone_noValidation: boolean = false;
  // countryCode: '1';
  video_link:any='';
  suite_no:any= '';
  postal_code:any= '';
  alternate_phone_no:any= '';
  country_code:any= '';
  alternate_phone_no_country_code:any= '';
  PDFfile:any;
  Pdf_prev:any;
   image_file_type:any;
  pdf_file_type:any;
  Pdf_prev1: any;
  ecom_link:any='';
  discount_type:any;
  discount_percentage:any;
  imageRemoved: boolean = false; 
  pdf_enable: boolean = true;
  fileSelected: boolean = false;

  phone: any;
  whatsapp: any;
  shortcountrycode:any;



  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) { 
      setTimeout(() => {
        this.getcountry();
      },500);
    }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.daily_deal_id = params['daily_deal_id'];
      console.log('this.daily_deal_id: ',this.daily_deal_id)
    });
    this.spinner.show();
    this.webservice.adminGetData('dailyDealsById/' + this.daily_deal_id).then((result) => {
      this.data = result;
      console.log("result", this.data);
       
      if (this.data.status == 1) {
      
        this.onProperty();
        let pdf = this.data.data.pdf_file.substring(this.data.data.pdf_file.lastIndexOf('/')+1);
        this.image = this.data.data.deal_image;
        this.description = this.data.data.description;
        this.title = this.data.data.title;
        this.toTime = this.data.data.to_time;
        this.fromTime = this.data.data.from_time;
        this.property = this.data.data.property_id;
        this.website = this.data.data.website_link;
        this.subject = this.data.data.subject;
        this.merchant_address = this.data.data.merchant_address;
        this.merchant_name = this.data.data.merchant_name;
        this.phone_no = this.data.data.phone_no;
        this.deal_price = this.data.data.deal_price;
        this.discount = this.data.data.discount;
        this.discount_type = this.data.data.discount_type;
        // console.log("phone=================", this.phone_no);
        // this.startDate = this.data.data.start_date;
        this.startDate = moment(this.data.data.start_date).format("YYYY-MM-DD");
        this.endDate = moment(this.data.data.end_date).format("YYYY-MM-DD")
        this.country = this.data.data.country_id;
        this.state = this.data.data.state_id;
        this.city = this.data.data.city_id;
        this.video_link =this.data.data.video_link;
        this.suite_no =this.data.data.suite_no;
        this.postal_code = this.data.data.postal_code;
        this.alternate_phone_no = this.data.data.alternate_phone_no;
        this.country_code = this.data.data.country_code;
        this.alternate_phone_no_country_code = this.data.data.alternate_phone_no_country_code;
        this.Pdf_prev = this.data.data.pdf_file;//pdf
        this.ecom_link = this.data.data.ecom_link;
        this.discount_percentage = this.data.data.discount_percentage;
        this.Pdf_prev1= pdf;//pdf
        this.phone.setNumber(this.data.data.country_code+this.data.data.phone_no);
        this.whatsapp.setNumber(this.data.data.alternate_phone_no_country_code+this.data.data.alternate_phone_no);
        console.log('city: ',this.city)
        this.ongetAllCountry();
        this.allStateList();
        this.allCityList();
        this. onAllProperty(); 
      } else {
        console.log(this.data.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });setTimeout(() => {
      this.phone = intlTelInput(document.querySelector("#Phone"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });
     
      this.whatsapp = intlTelInput(document.querySelector("#whatsapp"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });

    },100);
    



}
getcountry(){
  this.webservice.getcountry().subscribe((res:any)=>{  
    //this.ipAddress=res.ip;  
    var short_code= res.countryCode
    this.shortcountrycode = (short_code).toLowerCase();
    console.log('country===========',this.shortcountrycode)
  }); 
}
  hasError(obj) {
    console.log(obj);
  }

  // onCountryCodeChange(obj) {
  //   console.log(obj);
  //   this.countryCode = obj.dialCode
  //   console.log(this.countryCode );
  // }
  getNumber(obj) {
    console.log(obj);
  }
  

  telInputObject(obj) {
      console.log(obj);
      obj.setCountry('ca');
    }

  onProperty() {
    // this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    // console.log(this.UserData);
    // this.webservice.adminGetData('propertyList/undefined').then((result) => {
    //   this.propertyData = result;
    //   console.log("propertyData", this.propertyData);
    //   if (this.propertyData.status == 1) {
    //     this.propertymanagetlist = this.propertyData.data;
    //     console.log("result", this.propertymanagetlist);
        
    //   } else {
    //     console.log(this.propertyData.msg)
    //   }
    //   this.spinner.hide();
    // }, (err) => {
    //   this.spinner.hide();
    //   console.log(err);
    // });
  }

  allStateList() {
    this.webservice.adminGetData('stateList/' + this.country).then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.stateList = result.data;
      } else {
        console.log(result.msg)
      }

    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  allCityList() {
    this.webservice.adminGetData('cityList/' + this.state).then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.citylist = result.data;
      } else {
        console.log(result.msg)
      }

    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

  }


  ongetAllCountry() {
    this.spinner.show();
    this.webservice.adminGetData('countryList').then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.countryList = result.data;
      } else {
        console.log(result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  onAllProperty() {
    this.webservice.adminGetData('propertyListByCity/' + this.city).then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.consoNameList = result.data;
      } else {
        console.log(result.msg);
      }
    }, (err) => {
      console.log(err);
    });
  }

  onCountryChange(val) {
    this.state = ''; // Reset state
    this.city = '';  // Reset city
    this.property = ''; // Reset property

    
    this.country = val;
    console.log(val)
    // this.countryName = name;
    if (this.country != '') {
      this.spinner.show();
      this.webservice.adminGetData('stateList/' + this.country).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.stateList = result.data;
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }
  }
  onStateChange(val) {

    this.city = '';  // Reset city
    this.property = ''; // Reset property



    this.state = val;
    // this.stateName = name
    if (this.state != '') {
      this.spinner.show();
      this.webservice.adminGetData('cityList/' + this.state).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.citylist = result.data;
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

  onCityChange(val) {
    this.property = ''; // Reset property


    this.city = val;
    console.log(this.city)
    if (this.city != '') {
      this.webservice.adminGetData('propertyList/' + this.country + '/' + this.state + '/' +  this.city).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.consoNameList = result.data;
        } else {
          this.consoNameList = result.data;
          this.property = 'No property found';
          console.log(result.msg);
        }
      }, (err) => {
        console.log(err);
      });

    }
  }

  onChangeCondoName(val) {
    this.property = val;
  }

  onFileChange(event) {

    if(!event.target.files.length) return;{
  this.file = event.target.files[0];
if (this.file.type == "image/gif" || this.file.type == "image/jpeg" || this.file.type == "image/jpg" || this.file.type == "image/png") {
    this.image_file_type= event.target.files[0].type.split('/')[0]
  const reader = new FileReader();
  reader.onload = e =>{
    //console.log(e.target.result)
    this.image = reader.result.toString();
    this.imageRemoved = false;
  };
  reader.readAsDataURL(this.file);
  } else {
        this.toastr.error("Invalid image format. Please upload jpeg,jpg or png format.")
      }
}
}

onFileChangePDF(event) {
  if (!event.target.files.length) return;

  this.PDFfile = event.target.files[0];
  this.fileSelected = true;

  // Check if the file type is PDF
  //if (this.PDFfile.type === "application/pdf") {
      console.log(this.PDFfile);
      this.pdf_file_type = event.target.files[0].type.split('/')[0];
      let fd = new FormData();
      fd.append('deal_pdf', this.PDFfile);

      this.webservice.adminPostData('saveDailyDealsPDF', fd).then((result: any) => {
          console.log('pdf', result);
          this.Pdf_prev = result.data;
          this.Pdf_prev1 = result.data;
          if (result.status == 1) {
              this.pdf_enable = true;
          } else {
              this.pdf_enable = false;
              this.toastr.error("Invalid file format. Please upload PDF.");
          }
      });

      const reader = new FileReader();
      reader.onload = e => {
          // Check if result is defined before assigning to preview
          if (reader.result) {
              this.Pdf_prev = reader.result.toString();
          }
      };
      reader.readAsDataURL(this.PDFfile);
  //} else {
      //this.toastr.error("Invalid format. Please upload PDF.");
  //}
}


  changeproperty (val) {
    this.property = val;
    console.log(this.property)
  }

  onEdit () {
    var selectedCountryData = this.phone.getNumber();
    // var dialCode = selectedCountryData.dialCode;
     var phone = this.phone.getSelectedCountryData().dialCode;
     var codeLength= phone.length+1;
       console.log(selectedCountryData);
     console.log(phone)
     console.log('******',)
     this.phone_no = selectedCountryData.substring(codeLength);
   console.log('******',this.phone_no)
   /////////////////////////////////
   var selectedCountryData1 = this.whatsapp.getNumber();
   // var dialCode = selectedCountryData.dialCode;
    var whatsapp = this.whatsapp.getSelectedCountryData().dialCode;
    var codeLength= whatsapp.length+1;
      console.log(selectedCountryData1);
    console.log(whatsapp)
    console.log('******',)
    this.alternate_phone_no = selectedCountryData1.substring(codeLength);
  console.log('******',this.alternate_phone_no)

    console.log(this.file)
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var rem = /(^[0-9]{10}$)/;
    if (this.file == undefined) {
      if (this.property == '') {
        this.toastr.error('Please select property');
      } 
      else if (this.merchant_name == '') {
        this.toastr.error('Please enter merchant name');
      }
      else if (this.merchant_address == '') {
        this.toastr.error('Please merchant address');
      } 
      // else if (this.postal_code == '') {
      //   this.toastr.error('Please enter Postal Code');
      // }
      else if (this.phone_no == '') {
        this.toastr.error('Please enter Phone number');
      } 
      else if (!rem.test(this.phone_no)) {
        this.toastr.error('Phone number should contain 10 digits.Please enter valid phone number. ');
      }
      else if (this.alternate_phone_no!= '' && !rem.test(this.alternate_phone_no)) {
        this.toastr.error('Whatsapp number should contain 10 digits.Please enter valid number. ');
      }
      else if (this.subject == '') {
        this.toastr.error('Please enter email');
      }
      else if (!re.test(this.subject)) {
        this.toastr.error('Please enter valid email');
        // this.emailValidation = true;
      }
      else if (this.title == '') {
        this.toastr.error('Please enter advertise title');
      }
      else if (this.description == '') {
        this.toastr.error('Please enter description');
      } 
      else if (this.deal_price == '') {
        this.toastr.error('Please deal price');
      } 
      else if (this.discount == '') {
        this.toastr.error('Please discount');
      } 
      else if (this.startDate == '') {
        this.toastr.error('Please enter start date');
      } 
      else if (this.endDate == '') {
        this.toastr.error('Please enter end date');
      } 
      else if (this.startDate > this.endDate ) {
        this.toastr.error('Choose proper end date');
      } 
      else if (this.fromTime == '') {
        this.toastr.error('Please enter from time');
      } 
      else if (this.toTime == '') {
        this.toastr.error('Please enter to time');
      }
     
      //  else if (this.website == '') {
      //   this.toastr.error('Please enter website link');
      // }
      // else if ( this.image_file_type != "image"){
      //   console.log('file======',this.image_file_type)
    
      //       this.toastr.error('Please upload correct Image Format');
      //     } 
          // else if ( this.pdf_file_type != "application"){
          //   console.log('file======',this.pdf_file_type)
        
          //       this.toastr.error('Please upload correct File Format');
          //     } 
      else {
        
      var params = {
        daily_deal_id: this.daily_deal_id,
        property_id: this.property,
        title: this.title,
        description: this.description,
        from_time: this.fromTime,
        to_time: this.toTime,
        start_date: this.startDate,
        end_date: this.endDate,
        website_link: this.website,
        subject: this.subject,
        merchant_name: this.merchant_name,
        merchant_address: this.merchant_address,
        phone_no: this.phone_no,
        deal_price: this.deal_price,
        discount: this.discount,
        discount_percentage: this.discount_percentage,
        country_id: this.country,
        state_id: this.state,
        city_id: this.city,
        video_link:this.video_link,
        suite_no:this.suite_no,
        postal_code:this.postal_code,
        alternate_phone_no:this.alternate_phone_no,
        country_code:'+' + phone,
        alternate_phone_no_country_code:'+' + whatsapp,
        // pdf_file:this.Pdf_prev,
        pdf_file :this.Pdf_prev1,
        ecom_link:this.ecom_link,
        discount_type : this.discount_type,
      };
      this.spinner.show();
      this.webservice.adminPostData('updateDailyDealWithoutImage', params).then((result) => {
        this.data = result;
        if (this.data.status == 1) {
          this.toastr.success(this.data.msg);
        } else {
          this.toastr.error(this.data.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }

    } else {

      if (this.property == '') {
        this.toastr.error('Please select property');
      }
      else if (this.merchant_name == '') {
        this.toastr.error('Please enter merchant name');
      }
      else if (this.merchant_address == '') {
        this.toastr.error('Please merchant address');
      } 
      // else if (this.postal_code == '') {
      //   this.toastr.error('Please enter Postal Code');
      // }
      else if (this.phone_no == '') {
        this.toastr.error('Please enter phone number');
      } 
      else if (this.subject == '') {
        this.toastr.error('Please enter email');
       }
       else if (this.title == '') {
        this.toastr.error('Please enter advertise title');
      } 
      else if (this.description == '') {
        this.toastr.error('Please enter description');
      } 
      else if (this.deal_price == '') {
        this.toastr.error('Please enter deal price');
      }
      else if (this.discount == '') {
        this.toastr.error('Please enter discount');
      } 
     
      else if (this.startDate == '') {
        this.toastr.error('Please enter start date');
      } else if (this.endDate == '') {
        this.toastr.error('Please enter end date');
      } 
      else if (this.fromTime == '') {
        this.toastr.error('Please enter from time');
      } 
      else if (this.toTime == '') {
        this.toastr.error('Please enter to time');
      } 
      // else if (this.website == '') {
      //   this.toastr.error('Please enter website link');
      // } 
      else if (this.image == '') {
        this.toastr.error('Please select image');
      }
      // else if ( this.image_file_type != "image"){
      //   console.log('file======',this.image_file_type)
    
      //       this.toastr.error('Please upload correct Image Format');
      //     } 
          // else if ( this.pdf_file_type != "application"){
          //   console.log('file======',this.pdf_file_type)
        
          //       this.toastr.error('Please upload correct File Format');
          //     }  
              else {
        this.spinner.show();
      let fd=new FormData();
      fd.append('daily_deal_id',this.daily_deal_id);
      fd.append('start_date',this.startDate);
      fd.append('end_date',this.endDate);
      fd.append('property_id',this.property);
      fd.append('title',this.title);
      fd.append('description',this.description);
      fd.append('from_time',this.fromTime);
      fd.append('to_time',this.toTime);
      fd.append('deal_image',this.file);
      fd.append('website_link',this.website);
      fd.append('subject',this.subject);
      fd.append('merchant_name',this.merchant_name);
      fd.append('merchant_address',this.merchant_address);
      fd.append('phone_no',this.phone_no);
      fd.append('deal_price',this.deal_price);
      fd.append('discount',this.discount);
      fd.append('country_id',this.country);
      fd.append('state_id',this.state);
      fd.append('city_id',this.city);
      fd.append('video_link',this.video_link);
      fd.append('suite_no',this.suite_no);
      fd.append('postal_code',this.postal_code);
      fd.append('alternate_phone_no',this.alternate_phone_no);
      fd.append('country_code',this.country_code);
      fd.append('alternate_phone_no_country_code',this.alternate_phone_no_country_code);
      fd.append('pdf_file',this.Pdf_prev1);
      fd.append('ecom_link',this.ecom_link);
  
  
      this.webservice.adminPostData('updateDailyDealWithImage', fd).then((result) => {
        console.log(result);
        this.data = result;
        if (this.data.status == 1) {
          this.toastr.success(this.data.msg);
        } else {
          this.toastr.error(this.data.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
  
      }
      
    }
  }

  checkPdf(url: any) {
    if (url === undefined) {
      return "No pdf file found";
    } else {
      // Check if URL starts with "https://"
      if (url.startsWith("https://")) {
        var pdfLink: string = url.substring(url.lastIndexOf('/') + 1);
        if (pdfLink === 'undefined' || pdfLink === undefined || pdfLink === null || pdfLink === 'null' || pdfLink === '') {
          console.log('1======', pdfLink);
          return "No pdf file found";
        } else {
          console.log('2======', pdfLink);
          return url;
        }
      } else {
        return "No pdf file found";
      }
    }
  }
  
  disableLink(event: Event) {
    event.preventDefault();
  }

  removeImage() {
    this.image = null;
    this.imageRemoved = true;
    this.clearFileInput();
  }

  private clearFileInput() {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
    this.file = null;
    this.image_file_type = null;
  }
 removePDF() {
    // Logic to remove the uploaded PDF
    this.Pdf_prev = null;
    this.Pdf_prev1 = null;
    this.PDFfile = null; // Optionally clear the selected file as well
    this.Pdf_prev = '';
    this.PDFfile = '';
    this.Pdf_prev1 = '';
    this.fileSelected = false;
    // Reset the input file element to clear the selected file
    if (this.fileInputs) {
      this.fileInputs.nativeElement.value = '';
    }
  }

}
