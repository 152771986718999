<div class="residentinfo_section" style="background:#222522 url(assets/images/backgroundpic3.png) no-repeat center top ; ">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="residentinfo">
                    <h2>Keep your <strong> residents informed</strong></h2>
                    <p>Keep your residents informed of everything that goes on in their building with our real time resident notification system. Through our mobile app, they will be able to find important dates, events, memos and more. Our system automatically
                        notifies residents of package deliveries, guests, key pickups and outages — via text message, email, voice message or directly on the Walter app.</p>
                    <div class="storeinfo">
                        <a href="#">For Managers </a>
                    </div>
                </div>
                <div class="add_pic">
                    <img src="assets/images/door2.png" alt="">
                </div>
            </div>

        </div>
    </div>
</div>
