import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  page:number = 1;
  count:number = 0;
  tableSize:number = 4;
  tableSizes:any = [5,10,15,20];

  data: any;
  public list: any = [];
  user_type: any;
  UserData: any;
  search: any = '';
  logintype: any = '';
  paymentstaus:boolean;

  cancelClicked: true;
  popoverTitle = 'Cancel Subscription!!';
  popoverMessage = 'Are you sure you want to cancel your subscription plan?';
  confirmClicked = false;
  
  constructor(
    public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData);
    this.user_type = this.UserData.user_type;
    this.spinner.show();
    this.webservice.WebgetData('getUserSubscriptionDetails/' + this.UserData._id)
      .then(
        (result) => {
          this.data = result;
          console.log(this.data);

          if (this.data.status == 1) {
            this.list = this.data.data;
          } else {
            console.log(this.data.msg);
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
        }
      );
  }
  onTableDataChange(event:any){
    this.page = event ;
    // this.postList();
  }
  onTableSizeChange(event:any): void{
    this.tableSize = event.target.value;
    this.page = 1 ;
    // this.postList();
  }

 
  onCancel(id) {
    console.log("=========",id);
    let data={
      'stripe_subscription_id' : id
     
    }
    this.webservice.WebpostData('unsubscribe', data 
).then((result: any) => {
      if (result.status == 1) {
        this.toastr.success(result.msg);
        
        this.ngOnInit();
        
      } else {
        this.toastr.error(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
}