import { Component, OnInit } from '@angular/core';
import { WebserviceService } from 'src/app/webservice.service';

@Component({
  selector: 'app-playstoreadd',
  templateUrl: './playstoreadd.component.html',
  styleUrls: ['./playstoreadd.component.scss']
})
export class PlaystoreaddComponent implements OnInit {

  constructor(public webservice: WebserviceService,) { }

  ngOnInit(): void {
  }

  // opentost() {
  //   console.log("sbfgjhgfhj")
  //   this.webservice.showSuccess("successfully !!",'fbdxsvf');
  // }

}
