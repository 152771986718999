<div class="content_box">
    <h2>Edit Vendor</h2>

    
    <div class="inpurwraper">
      <label>Vendor / Business Name<span class="required">*</span></label>
      <input  [(ngModel)]="business_name" type="text" class="form-control"/>
    </div>

    <!-- <div class="inpurwraper">
      <label>Suite Number</label>
      <input [(ngModel)]="suite_no" type="text" class="form-control"/>
    </div> -->

    <div class="inpurwraper">
      <label>Vendor / Business Address<span class="required">*</span></label>
      <input [(ngModel)]="business_address" type="text" class="form-control"/>
    </div>

    <div class="inpurwraper">
        <label>Select country<span class="required">*</span></label>
        <select class="form-control" [(ngModel)]="country"
          (change)="onCountryChange(country)">
          <option *ngFor="let option of countryList" [value]="option.id">{{option.name}}</option>
        </select>
      </div>

      <div class="inpurwraper">
        <label>Select State<span class="required">*</span></label>
        <select [disabled]="country == ''" class="form-control" [(ngModel)]="state"
          (change)="onStateChange(state)">
          <option disabled></option>
      <option *ngIf="!stateList || stateList.length === 0" value="no-state-found">No state found</option>
          <option *ngFor="let option of stateList" [value]="option.id">{{option.name}}</option>
        </select>
      </div>

      <div class="inputwrapper">
        <label>Select City<span class="required">*</span></label>
        <select [disabled]="state == ''" class="form-control" [(ngModel)]="city" (change)="onCityChange(city)">
          <option disabled></option>
          <option *ngIf="!citylist || citylist.length === 0" value="no-city-found">No city found</option>
            <option *ngFor="let option of citylist" [value]="option.id">{{option.name}}</option>
        </select>
    </div>

      <div class="inpurwraper">
        <label>Select Property<span class="required">*</span></label>
        <select class="form-control" [disabled]="city == ''" [(ngModel)]="condoName" (ngModelChange)="onChangeCondoName(condoName)" *ngIf="consoNameList.length > 0">
          
            <option *ngFor="let list of consoNameList" [value]="list._id">{{list.property_name}}, 
              {{list.property_address}},{{list.postal_code}}</option>
        </select>
        <input [(ngModel)]="condoName" type="text" class="form-control" *ngIf="consoNameList.length == 0" readonly/>
      </div>
      <div class="inpurwraper">
        <label>Postal Code</label>
        <input [(ngModel)]="postal_code" type="text" class="form-control"/>
      </div>
  
      <div class="inpurwraper">
        <label>Vendor / Business Phone Number<span class="required">*</span></label><br>
        <input id="business_phone_number" class="form-control" type="text" minlength="10" maxlength="15" onwheel="this.blur()" >
        <!-- <div class="flexFld phnumber"><input class="form-control phonecode"  [(ngModel)]="business_phone_number_country_code" readonly/>
          <input type="number" class="form-control phone form-input" onwheel="this.blur()"
              [(ngModel)]="business_phone_number" /></div> -->
        
        <!-- <input [(ngModel)]="phn_no" type="number" class="form-control"/> -->
        <!-- <input type="text" class="form-control" style="width: 252%;" ng2TelInput
        [ng2TelInputOptions]="{initialCountry: 'ca'}" (hasError)="hasErrors($event)"
        (ng2TelOutput)="getNumbers($event)" (intlTelInputObject)="telInputObjects($event)"
        [(ngModel)]="business_phone_number"
        (countryChange)="onCountryChanges($event)" /> -->
        <!-- <p *ngIf="business_phone_numberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->
  
      </div>
  
      <div class="inpurwraper">
        <label>Vendor / Business Whatsapp No</label><br>
        <!-- <div class="flexFld phnumber"> <input class="form-control phonecode"  [(ngModel)]="business_alternate_phone_number_country_code" readonly/>
          <input type="number" class="form-control phone form-input" onwheel="this.blur()"
          [(ngModel)]="business_alternate_phone_number"/></div> -->
          <input id="business_alternate_phone_number" class="form-control" type="text" minlength="10" maxlength="15" onwheel="this.blur()" >
        <!-- <input [(ngModel)]="phn_no" type="number" class="form-control"/> -->
        <!-- <input type="text" class="form-control" style="width: 252%;" ng2TelInput
        [ng2TelInputOptions]="{initialCountry: 'ca'}" (hasError)="hasErrors($event)"
        (ng2TelOutput)="getNumbers($event)" (intlTelInputObject)="telInputObjects($event)"
        [(ngModel)]="business_alternate_phone_number"
        (countryChange)="onCountryChanges($event)" /> -->
        <!-- <p *ngIf="business_alternate_phone_numberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->
  
      </div>
  
      <div class="inpurwraper">
        <label>Vendor / Business Email<span class="required">*</span></label>
        <input [(ngModel)]="business_email" type="text" class="form-control"/>
      </div>

      <div class="inpurwraper">
        <label>Vendor Contact Name<span class="required">*</span></label>
        <input [(ngModel)]="name" type="text" class="form-control" />
      </div>

      <div class="inpurwraper">
        <label>Vendor Contact Number<span class="required">*</span></label><br>
        <!-- <div class="flexFld phnumber"><input class="form-control phonecode"  [(ngModel)]="country_code" readonly/>
          <input type="number" class="form-control phone form-input" onwheel="this.blur()"
          [(ngModel)]="phn_no" /></div> -->
          <input id="phn_no" class="form-control" type="text" minlength="10" maxlength="15" onwheel="this.blur()" >
        <!-- <input [(ngModel)]="phn_no" type="number" class="form-control"/> -->
        <!-- <input type="text" class="form-control" style="width: 252%;" ng2TelInput
        [ng2TelInputOptions]="{initialCountry: 'ca'}" (hasError)="hasErrors($event)"
        (ng2TelOutput)="getNumbers($event)" (intlTelInputObject)="telInputObjects($event)"
        [(ngModel)]="phn_no" (countryChange)="onCountryChanges($event)" />-->
        <!-- <p *ngIf="phn_noValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->


      </div>

      <div class="inpurwraper">
        <label>Vendor Whatsapp No</label><br>
        <!-- <div class="flexFld phnumber"><input class="form-control phonecode"  [(ngModel)]="vendor_alternate_phone_number_country_code"  readonly/>
          <input type="number" class="form-control phone form-input" onwheel="this.blur()"
          [(ngModel)]="vendor_alternate_phone_number" maxlength="10" /></div> -->
          <input id="vendor_alternate_phone_number" class="form-control" type="text" minlength="10" maxlength="15" onwheel="this.blur()" >
        <!-- <input [(ngModel)]="phn_no" type="number" class="form-control"/> -->
        <!-- <input type="text" class="form-control" style="width: 252%;" ng2TelInput
        [(ngModel)]="vendor_alternate_phone_number"
        [ng2TelInputOptions]="{initialCountry: 'ca'}" (hasError)="hasErrors($event)"
        (ng2TelOutput)="getNumbers($event)" (intlTelInputObject)="telInputObjects($event)"
        (countryChange)="onCountryChanges($event)" /> -->
        <!-- <p *ngIf="vendor_alternate_phone_numberValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->


      </div>

      <div class="inpurwraper">
        <label>Vendor Email<span class="required">*</span></label>
        <input [(ngModel)]="email" type="text" class="form-control" readonly/>
      </div>

      <div class="inpbtn_wrap">
        <button type="button" class="btn defbtn" *ngIf="country != '' && state != '' &&
        city != '' && name != '' && business_name !='' && business_address !='' && business_phone_number != '' && business_email !='' && 
          email != '' && condoName != '' && condoName != 'No property found' && phn_no != null" type="submit" class="btn defbtn"
          (click)="onUpdate();">
          Update
        </button>
    
        <button type="button" class="btn defbtn"
          *ngIf="country == '' || state == '' || city == '' || name == '' || email == '' || condoName == '' || condoName == 'No property found' || phn_no == null || business_name == '' || business_address == '' || business_phone_number == '' || business_email == ''"
          [disabled]="country == '' || state == '' || city == '' || name == '' || email == '' || condoName == '' || condoName == 'No property found' || phn_no == null || business_name == '' || business_address == '' || business_phone_number == '' || business_email == ''" type="submit"
          class="btn defbtn" style="cursor: no-drop;">
          Update
        </button>
      </div>

</div>
