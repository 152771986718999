import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-resources-add',
  templateUrl: './resources-add.component.html',
  styleUrls: ['./resources-add.component.scss']
})
export class ResourcesAddComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('fileInputs') fileInputs!: ElementRef;
  currentDate:any =new Date();
  image: any = '';
  title: any = '';
  description: any = '';
  file:any;
  ecom_link: any = '';
  dropdownList = [];
  selectedItems = [];
  dropdownSettings:IDropdownSettings;
  propertymanagetlist: any;
  UserData: any;
  property: any = [];
  website:  any = '';
  video_link:  any = '';
  PDFfile:any = '';
  Pdf_prev:any;
  countryList: any = [];
  stateList: any = [];
  citylist: any = [];
  country: any = '';
  city: any = '';
  state: any = '';
  orgName: any = '';
  phone_number: any;
  startDate: any = '';
  endDate: any = '';
  fromTime: any = '';
  toTime: any = '';
  // countryCode = '1';
  address:any='';
  phone_numberValidation: boolean = false;
  suite_no:any='';
  postal_code:any='';
  country_code:any = '';
  alternate_phone_no:any='';
  alternate_phone_no_country_code:any='';
  email:any='';
  resource_contact_name:any='';
  resource_phone_no:any='';
  resource_phone_no_country_code:any='';
  resource_alternate_phone_no :any='';
  resource_alternate_phone_no_country_code:any='';
  resource_email: any ='';
  alternate_phone_noValidation: boolean = false;
  resource_alternate_phone_noValidation: boolean = false;
  resource_phone_noValidation: boolean = false;
  image_file_type:any;
  pdf_file_type:any;
  imageRemoved: boolean = false;
  pdf_enable: boolean = true;
  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    

    this.UserData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.UserData);
    this.ongetAllCountry();
  }

  hasError(obj) {
    console.log(obj);
  }

  onCountryCodeChange(obj) {
    console.log(obj);
    this.resource_phone_no_country_code = '+' + obj.dialCode
    console.log(this.resource_phone_no_country_code);
  }
  onCountryCodeChange1(obj) {
    console.log(obj);
    this.resource_alternate_phone_no_country_code = '+' + obj.dialCode
    console.log(this.resource_alternate_phone_no_country_code );
  }
  onCountryCodeChange2(obj) {
    console.log(obj);
    this.country_code = '+' + obj.dialCode
    console.log(this.country_code );
  }
  onCountryCodeChange3(obj) {
    console.log(obj);
    this.alternate_phone_no_country_code = '+' + obj.dialCode
    console.log(this.alternate_phone_no_country_code);
  }
  getNumber(obj) {
    console.log(obj);
  }


telInputObject(obj) {
    console.log(obj);
    obj.setCountry('ca');
  }
  ongetAllCountry() {
    this.spinner.show();
    this.webservice.adminGetData('countryList').then((result: any) => {
      console.log(result)
      if(result.status == 1) {
        this.countryList = result.data;
      } else {
        console.log(result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  onCountryChange(val) {
    this.state = ''; // Reset state dropdown
    this.city = ''; // Reset city dropdown
    this.selectedItems = []; // Reset selected items in property dropdown



    this.country = val;
    console.log(val)
    // this.countryName = name;
    if(this.country != '') {
      this.spinner.show();
      this.webservice.adminGetData('stateList/'+this.country).then((result: any) => {
        console.log(result)
        if(result.status == 1) {
          this.stateList = result.data;
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }
  }
  onStateChange(val) {
    this.city = ''; // Reset city dropdown
    this.selectedItems = []; // Reset selected items in property dropdown


    this.state = val;
    // this.stateName = name
    if(this.state != '') {
      this.spinner.show();
      this.webservice.adminGetData('cityList/'+this.state).then((result: any) => {
        console.log(result)
        if(result.status == 1) {
          this.citylist = result.data;
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

  onCityChange(val) {
    this.selectedItems = []; // Reset selected items in property dropdown

    this.city = val;
    console.log('city: ',this.city)
    if (this.city != '') {
      this.webservice.adminGetData('propertyList/' + this.country + '/' + this.state + '/' +  this.city).then((result: any) => {
        console.log("result",result);
        if (result.status == 1) {
          this.propertymanagetlist = result.data;
          this.dropdownList = this.propertymanagetlist;
          console.log("result", this.propertymanagetlist);
        } else {
          this.propertymanagetlist = result.data;
          this.dropdownList = this.propertymanagetlist;
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
  
  
      this.dropdownSettings = {
        singleSelection: false,
        idField: '_id',
        textField: 'property_details',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true
      };

    }
  }

  onItemSelect(item: any) {
    console.log(this.selectedItems);
    
  }
  onSelectAll(items: any) {
    console.log(this.selectedItems);
  }

  onFileChangePDF (event) {

    if(!event.target.files.length) return;{
  this.PDFfile = event.target.files[0];
  //if (this.PDFfile.type == "application/msword" || this.PDFfile.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || this.PDFfile.type == "application/txt" || this.PDFfile.type == "application/pdf" || this.PDFfile.type == "application/rtf" || this.PDFfile.type == "application/xlsx"  || this.PDFfile.type == "application/xls" || this.PDFfile.type == "application/xml") {
  console.log("aaaaaaaa",this.PDFfile)
  this.pdf_file_type= event.target.files[0].type.split('/')[0]
  let fd=new FormData();
  fd.append('resource_pdf',this.PDFfile);
  this.webservice.adminPostData('saveResourcePDF', fd).then((result:any) => {
    console.log('pdf',result)
   // this.Pdf_prev=result.data;
   if (result.status == 1) {
    this.Pdf_prev = result.data;
    this.pdf_enable = true;
  } else {
    this.pdf_enable = false;
    this.toastr.error("Invalid file format. Please upload PDF.")

  }
  })

  const reader = new FileReader();
  reader.onload = e =>{
    //console.log(e.target.result)
    // this.Pdf_prev = reader.result.toString();
  };
  reader.readAsDataURL(this.PDFfile);
  }
  //else{
   // this.toastr.error("Invalid format. Please pdf file format.")
 // }
}


  onFileChange(event) {

    if(!event.target.files.length) return;
  this.file = event.target.files[0];
  if (this.file.type == "image/gif" || this.file.type == "image/jpeg" || this.file.type == "image/jpg" || this.file.type == "image/png") {
    this.image_file_type= event.target.files[0].type.split('/')[0]
  const reader = new FileReader();
  reader.onload = e =>{
    //console.log(e.target.result)
    this.image = reader.result.toString();
    this.imageRemoved = false;
  };
  reader.readAsDataURL(this.file);
  }else {
    this.toastr.error("Invalid image format. Please upload JPEG,JPG or PNG format.")
  }
}

  onAdd() {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var rem = /(^[0-9]{10}$)/;
    this.property = []
    for (var i=0; i<this.selectedItems.length; i++) {
      this.property.push(this.selectedItems[i]._id)
    }
    if (this.property == null) {
      this.toastr.error('Please select property');
    } 
    else if(!rem.test(this.resource_phone_no)) {
      this.toastr.error('Resources organization phone number should contain 10 digits.Please enter valid phone number. ');
    }
    else if(this.alternate_phone_no!= '' && !rem.test(this.alternate_phone_no)) {
      this.toastr.error('Resources organization whatsapp number should contain 10 digits.Please enter valid phone number. ');
    }
    else if (!re.test(this.email)) {
      this.toastr.error('Please enter valid resources organization email');
      // this.emailValidation = true;
    }
    else if(!rem.test(this.phone_number)) {
      this.toastr.error('Contact person phone number should contain 10 digits.Please enter valid phone number. ');
    }
    else if(this.resource_alternate_phone_no!= '' && !rem.test(this.resource_alternate_phone_no)) {
      this.toastr.error('Contact person whatsapp number should contain 10 digits.Please enter valid number. ');
    }
    else if (!re.test(this.resource_email)) {
      this.toastr.error('Please enter valid contact person email');
      // this.emailValidation = true;
    }
      else if(this.title == '') {
      this.toastr.error('Please enter title');
    } else if (this.description == '') {
      this.toastr.error('Please enter description');
    }
    else if (this.startDate > this.endDate ) {
      this.toastr.error('Choose proper end date');
    } 
    //  else if (this.website == '') {
    //   this.toastr.error('Please enter website link');
    // } 
    else if (this.image == '') {
      this.toastr.error('Please select image');
    } 
    else if ( this.image_file_type != "image"){
      console.log('file======',this.image_file_type)
  
          this.toastr.error('Please upload correct image format');
        } 
        // else if ( this.pdf_file_type != "application"){
        //   console.log('file======',this.pdf_file_type)
      
        //       this.toastr.error('Please upload correct File Format');
        //     }
  
    else {
      this.spinner.show();
      let fd=new FormData();
      fd.append('created_by',this.UserData.name);
      fd.append('created_by_id',this.UserData._id);
      fd.append('title',this.title);
      fd.append('description',this.description);
      fd.append('property_id',this.property);
      fd.append('website_link',this.website);
      fd.append('resource_image',this.file);
      fd.append('resource_pdf',this.Pdf_prev);
      fd.append('start_date',this.startDate);
      fd.append('end_date',this.endDate);
      fd.append('start_time',this.fromTime);
      fd.append('end_time',this.toTime);
      fd.append('organization_name',this.orgName);
      fd.append('address',this.address);
      fd.append('organization_phone_no',this.phone_number);
      fd.append('country_id',this.country);
      fd.append('state_id',this.state);
      fd.append('city_id',this.city);
      fd.append('video_link',this.video_link);
      fd.append('suite_no',this.suite_no);
      fd.append('postal_code',this.postal_code);
      fd.append('country_code',this.country_code);
      fd.append('alternate_phone_no',this.alternate_phone_no);
      fd.append('alternate_phone_no_country_code',this.alternate_phone_no_country_code);
      fd.append('email',this.email);
      fd.append('resource_contact_name',this.resource_contact_name);
      fd.append('resource_phone_no',this.resource_phone_no);
      fd.append('resource_phone_no_country_code',this.resource_phone_no_country_code);
      fd.append('resource_alternate_phone_no',this.resource_alternate_phone_no);
      fd.append('resource_alternate_phone_no_country_code',this.resource_alternate_phone_no_country_code);
      fd.append('resource_email',this.resource_email);
      fd.append('ecom_link', this.ecom_link);
      console.log('fd',fd)
      this.webservice.adminPostData('saveResource', fd).then((result: any) => {
        if(result.status == 1) {
        this.toastr.success(result.msg);
        this.title = '';
        this.description = '';
        this.property = [];
        this.dropdownList = [];
        this.selectedItems = [];
        this.image = '';
        this.PDFfile = '';
        this.website = '';
        this.startDate = '';
        this.endDate = '';
        this.fromTime = '';
        this.toTime = '';
        this.orgName = '';
        this.phone_number = '';
        this.country = '';
        this.state = '';
        this.city = '';
        this.video_link = '';
        this.suite_no = '';
        this.postal_code = '';
        this.country_code = '';
        this.alternate_phone_no = '';
        this.alternate_phone_no_country_code = '';
        this.email = '';
        this.resource_contact_name = '';
        this.resource_phone_no = '';
        this.resource_phone_no_country_code = '';
        this.resource_alternate_phone_no = '';
        this.resource_alternate_phone_no_country_code = '';
        this.resource_email = '';
        this.address= '';
        this.ecom_link = '';
        this.router.navigate(['/main/resources']);

        } else {
          this.toastr.error(result.msg);

        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }
  removeImage() {
    this.image = null;
    this.imageRemoved = true;
    this.clearFileInput();
  }

  private clearFileInput() {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
    this.file = null;
    this.image_file_type = null;
  }

  removePDF() {
    // Logic to remove the uploaded PDF
    this.Pdf_prev = null;
    this.PDFfile = null; // Optionally clear the selected file as well
    this.Pdf_prev = '';
    this.PDFfile = '';
    // Reset the input file element to clear the selected file
    if (this.fileInputs) {
      this.fileInputs.nativeElement.value = '';
    }
  }
}
