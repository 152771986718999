import { Component, OnInit,ViewChild,ElementRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from 'src/app/webservice.service';
declare var intlTelInput: any;
@Component({
  selector: 'app-resources-edit',
  templateUrl: './resources-edit.component.html',
  styleUrls: ['./resources-edit.component.scss']
})
export class ResourcesEditComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('fileInputs') fileInputs!: ElementRef;
  currentDate:any =new Date();
  resources_id: any = '';
  UserData: any;
  propertymanagetlist: any;
  file: any;
  PDFfile: any;
  Pdf_prev: any;
  image: any = '';
  property: any = '';
  description: any = '';
  title: any = '';
  website: any = '';


  countryList: any = [];
  stateList: any = [];
  citylist: any = [];
  country: any = '';
  city: any = '';
  state: any = '';
  orgName: any = '';
  phone_number: any;
  startDate: any = '';
  endDate: any = '';
  fromTime: any = '';
  toTime: any = '';
  consoNameList: any = [];
  phone_numberValidation: boolean = false;
  // countryCode = '1';
  suite_no: any = '';
  postal_code: any = '';
  country_code: any = '';
  alternate_phone_no: any = '';
  alternate_phone_no_country_code: any = '';
  email: any = '';
  resource_contact_name: any = '';
  resource_phone_no: any = '';
  resource_phone_no_country_code: any = '';
  resource_alternate_phone_no: any = '';
  resource_alternate_phone_no_country_code: any = '';
  resource_email: any = '';
  address: any = '';
  video_link: any = '';
  Pdf_prev1: any;
  image_file_type:any;
  pdf_file_type:any;
  imageRemoved: boolean = false;
  pdf_enable: boolean = true;
  ecom_link: any = '';
  fileSelected: boolean = false;


  resphone: any;
  resalno: any;
  phone:any;
  alphn: any;
  shortcountrycode:any;

  // Pdf_preview: string = "your_pdf_url_here";
  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) {
      setTimeout(() => {
        this.getcountry();
      },500);
     }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.resources_id = params['resources_id'];
      console.log('resources_id: ', this.resources_id)
    });
    this.spinner.show();
    this.webservice.adminGetData('resourceById/' + this.resources_id).then((result: any) => {
      if (result.status == 1) {
        console.log('result: ', result);
        let pdf = result.data.resource_pdf.substring(result.data.resource_pdf.lastIndexOf('/')+1);
        this.title = result.data.title;
        this.description = result.data.description;
        this.property = result.data.property_id;
        this.image = result.data.resource_image;
        this.Pdf_prev = result.data.resource_pdf;//pdf
        this.Pdf_prev1= pdf;//pdf
        this.website = result.data.website_link;
        this.startDate = result.data.start_date;
        this.endDate = result.data.end_date;
        this.fromTime = result.data.start_time;
        this.toTime = result.data.end_time;
        this.orgName = result.data.organization_name;
        this.phone_number = result.data.organization_phone_no;
        this.country = result.data.country_id;
        this.state = result.data.state_id;
        this.city = result.data.city_id;
        this.suite_no = result.data.suite_no;
        this.postal_code = result.data.postal_code;
        this.country_code = result.data.country_code;
        this.alternate_phone_no = result.data.alternate_phone_no;
        this.alternate_phone_no_country_code = result.data.alternate_phone_no_country_code;
        this.email = result.data.email;
        this.resource_contact_name = result.data.resource_contact_name;
        this.resource_phone_no = result.data.resource_phone_no;
        this.resource_phone_no_country_code = result.data.resource_phone_no_country_code;
        this.resource_alternate_phone_no = result.data.resource_alternate_phone_no;
        this.resource_alternate_phone_no_country_code = result.data.resource_alternate_phone_no_country_code;
        this.resource_email = result.data.resource_email;
        this.address = result.data.address;
        this.video_link = result.data.video_link;
        this.ecom_link = result.data.ecom_link;


        this.resphone.setNumber(result.data.resource_phone_no_country_code+result.data.resource_phone_no);
        this.resalno.setNumber(result.data.resource_alternate_phone_no_country_code+result.data.resource_alternate_phone_no);
        this.phone.setNumber(result.data.country_code+result.data.organization_phone_no);
        this.alphn.setNumber(result.data.alternate_phone_no_country_code+result.data.alternate_phone_no);
        

        this.ongetAllCountry();
        this.allStateList();
        this.allCityList();
        this.onAllProperty();

      }

    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
    setTimeout(() => {
      this.resphone = intlTelInput(document.querySelector("#resource_phone_no"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });
     
      this.resalno = intlTelInput(document.querySelector("#resource_alternate_phone_no"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });
      this.phone = intlTelInput(document.querySelector("#phone_number"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });
      this.alphn = intlTelInput(document.querySelector("#alternate_phone_no"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });

    },100);
  

}
getcountry(){
  this.webservice.getcountry().subscribe((res:any)=>{  
    //this.ipAddress=res.ip;  
    var short_code= res.countryCode
    this.shortcountrycode = (short_code).toLowerCase();
    console.log('country===========',this.shortcountrycode)
  }); 
}

  hasError(obj) {
    console.log(obj);
  }

  // onCountryCodeChange(obj) {
  //   console.log(obj);
  //   this.countryCode = obj.dialCode
  //   console.log(this.countryCode );
  // }
  getNumber(obj) {
    console.log(obj);
  }
  telInputObject(obj) {
    console.log(obj);
    obj.setCountry('ca');
  }



  allStateList() {
    this.webservice.adminGetData('stateList/' + this.country).then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.stateList = result.data;
      } else {
        console.log(result.msg)
      }

    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  allCityList() {
    this.webservice.adminGetData('cityList/' + this.state).then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.citylist = result.data;
      } else {
        console.log(result.msg)
      }

    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

  }


  ongetAllCountry() {
    this.spinner.show();
    this.webservice.adminGetData('countryList').then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.countryList = result.data;
      } else {
        console.log(result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  onAllProperty() {
    this.webservice.adminGetData('propertyListByCity/' + this.city).then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.consoNameList = result.data;
      } else {
        console.log(result.msg);
      }
    }, (err) => {
      console.log(err);
    });
  }

  onCountryChange(val) {
    this.state = ''; // Reset state
    this.city = '';  // Reset city
    this.property = ''; // Reset property


    this.country = val;
    console.log(val)
    // this.countryName = name;
    if (this.country != '') {
      this.spinner.show();
      this.webservice.adminGetData('stateList/' + this.country).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.stateList = result.data;
          // if(result.data.length == 0){
          //   this.state = 'no-state-found';
          // }
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }
  }
  onStateChange(val) {
console.log('onstate====',val)

    this.city = '';  // Reset city
    this.property = ''; // Reset property




    this.state = val;
    // this.stateName = name
    if (this.state != '') {
      this.spinner.show();
      this.webservice.adminGetData('cityList/' + this.state).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.citylist = result.data;
        } else {
          console.log(result.msg)
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

  onCityChange(val) {
    this.property = ''; // Reset property

    this.city = val;
    console.log(this.city)
    if (this.city != '') {
      this.webservice.adminGetData('propertyList/' + this.country + '/' + this.state + '/' +  this.city).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.consoNameList = result.data;
        } else {
          this.consoNameList = result.data;
          this.property = 'No property found';
          console.log(result.msg);
        }
      }, (err) => {
        console.log(err);
      });

    }
  }

  onChangeCondoName(val) {
    this.property = val;
  }

  onFileChange(event) {


    if (!event.target.files.length) return;
    {



      this.file = event.target.files[0];
      if (this.file.type == "image/gif" || this.file.type == "image/jpeg" || this.file.type == "image/jpg" || this.file.type == "image/png") {
        console.log("iiiii====", event.target.files[0].type.split('/')[0])
        this.image_file_type= event.target.files[0].type.split('/')[0]

        const reader = new FileReader();
        reader.onload = e => {
          //console.log(e.target.result)
          this.image = reader.result.toString();
          this.imageRemoved = false;
        };
        reader.readAsDataURL(this.file);
      } else {
        this.toastr.error("Invalid image format. Please upload JPEG,JPG or PNG format.")
      }
    }



  }




  onFileChangePDF(event) {

    if (!event.target.files.length) return;{
    this.PDFfile = event.target.files[0];
    this.fileSelected = true;
    //if (this.PDFfile.type == "application/pdf") {
    console.log("aaaaaaaa", this.PDFfile)
    this.pdf_file_type= event.target.files[0].type.split('/')[0]
    let fd = new FormData();
    fd.append('resource_pdf', this.PDFfile);
    this.webservice.adminPostData('saveResourcePDF', fd).then((result: any) => {
      console.log('pdf', result)
      //this.Pdf_prev = result.data;
      //this.Pdf_prev1 = result.data;
      if (result.status == 1)  {
        this.Pdf_prev = result.data;
        this.Pdf_prev1 = result.data;
        this.pdf_enable = true;
      } else {
        this.pdf_enable = false;
        this.toastr.error("Invalid file format. Please upload PDF.")

      }
    });

    const reader = new FileReader();
    reader.onload = e => {
      //console.log(e.target.result)
      // this.Pdf_prev = reader.result.toString();
    };
    reader.readAsDataURL(this.PDFfile);
  }
 // else{
    //this.toastr.error("Invalid format. Please upload pdf.")
 // }
}





 
  
  



  onEdit() {
    var selectedCountryData = this.resphone.getNumber();
    // var dialCode = selectedCountryData.dialCode;
     var resphone = this.resphone.getSelectedCountryData().dialCode;
     var codeLength= resphone.length+1;
       console.log(selectedCountryData);
     console.log(resphone)
     console.log('******',)
     this.resource_phone_no = selectedCountryData.substring(codeLength);
   console.log('******',this.resource_phone_no)
   /////////////////////////////////
   var selectedCountryData1 = this.resalno.getNumber();
   // var dialCode = selectedCountryData.dialCode;
    var resalno = this.resalno.getSelectedCountryData().dialCode;
    var codeLength= resalno.length+1;
      console.log(selectedCountryData1);
    console.log(resalno)
    console.log('******',)
    this.resource_alternate_phone_no = selectedCountryData1.substring(codeLength);
  console.log('******',this.resource_alternate_phone_no)
  //////////////////////////////////
  var selectedCountryData2 = this.phone.getNumber();
  // var dialCode = selectedCountryData.dialCode;
   var phone = this.phone.getSelectedCountryData().dialCode;
   var codeLength= phone.length+1;
     console.log(selectedCountryData2);
   console.log(phone)
   console.log('******',)
   this.phone_number = selectedCountryData2.substring(codeLength);
 console.log('******',this.phone_number)
 /////////////////////////
 var selectedCountryData2 = this.alphn.getNumber();
  // var dialCode = selectedCountryData.dialCode;
   var alphn = this.alphn.getSelectedCountryData().dialCode;
   var codeLength= alphn.length+1;
     console.log(selectedCountryData2);
   console.log(alphn)
   console.log('******',)
   this.alternate_phone_no = selectedCountryData2.substring(codeLength);
 console.log('******',this.alternate_phone_no)







    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var rem = /(^[0-9]{10}$)/;
    // var accept= /\.(gif|jpe?g|tiff?|png|webp|bmp)$/
    // var accept2= /^.+\.(?:(?:[dD][oO][cC][xX]?)|(?:[pP][dD][fF]))$/

    if (this.file == undefined) {

      if (this.property == '') {
        this.toastr.error('Please select property');
      }
      else if (!rem.test(this.resource_phone_no)) {
        this.toastr.error('Resources organization phone number should contain 10 digits.Please enter valid phone number. ');
      }
      else if (this.alternate_phone_no != '' && !rem.test(this.alternate_phone_no)) {
        this.toastr.error('Resources organization whatsapp number should contain 10 digits.Please enter valid number. ');
      }
      else if (!re.test(this.email)) {
        this.toastr.error('Please enter valid resources organization email');
        // this.emailValidation = true;
      }
      else if (!rem.test(this.phone_number)) {
        this.toastr.error('Contact person phone number should contain 10 digits.Please enter valid phone number. ');
      }
      else if (this.resource_alternate_phone_no != '' && !rem.test(this.resource_alternate_phone_no)) {
        this.toastr.error('Contact person whatsapp number should contain 10 digits.Please enter valid number. ');
      }
      else if (!re.test(this.resource_email)) {
        this.toastr.error('Please enter valid contact person email');
        // this.emailValidation = true;
      }
      else if (this.title == '') {
        this.toastr.error('Please enter title');
      } else if (this.description == '') {
        this.toastr.error('Please enter description');
      }
      else if (this.startDate > this.endDate) {
        this.toastr.error('Choose proper end date');
      }
      else {
        var params = {
          resource_id: this.resources_id,
          property_id: this.property,
          title: this.title,
          description: this.description,
          website_link: this.website,
          start_date: this.startDate,
          end_date: this.endDate,
          start_time: this.fromTime,
          end_time: this.toTime,
          organization_name: this.orgName,
          organization_phone_no: this.phone_number,
          country_id: this.country,
          state_id: this.state,
          city_id: this.city,
          suite_no: this.suite_no,
          postal_code: this.postal_code,
          country_code: '+' + phone,
          alternate_phone_no: this.alternate_phone_no,
          alternate_phone_no_country_code: '+' + alphn,
          email: this.email,
          resource_contact_name: this.resource_contact_name,
          resource_phone_no: this.resource_phone_no,
          resource_phone_no_country_code: '+' + resphone,
          resource_alternate_phone_no: this.resource_alternate_phone_no,
          resource_alternate_phone_no_country_code: '+' + resalno,
          resource_email: this.resource_email,
          address: this.address,
          video_link: this.video_link,
          image: this.image,
          resource_pdf :this.Pdf_prev1,
          //resource_pdf: this.Pdf_prev,
          ecom_link:this.ecom_link,

         
         

        }
        this.spinner.show();
        this.webservice.adminPostData('updateResourceWithoutImage', params).then((result: any) => {
          if (result.status == 1) {
            this.toastr.success(result.msg);
          } else {
            this.toastr.error(result.msg);
          }
          this.spinner.hide();

        }, (err) => {
          this.spinner.hide();
          console.log(err);
        });
      }

    } else {
      if (this.property == '') {
        this.toastr.error('Please select property');
      } else if (this.title == '') {
        this.toastr.error('Please enter title');
      } else if (this.description == '') {
        this.toastr.error('Please enter description');
      }
      //  else if (this.website == '') {
      //   this.toastr.error('Please enter website link');
      // } 
      else if (this.image == '') {
        this.toastr.error('Please select image');
      }
    //   else if (this.file.type !='image/gif' || this.file.type !='image/jpeg' || this.file.type !='image/jpg' || this.file.type != 'image/png'){
    // console.log('file======',this.file.type)

    //     this.toastr.error('Please select format');
    //   }

    else if ( this.image_file_type != "image"){
      console.log('file======',this.image_file_type)
  
          this.toastr.error('Please upload correct image format');
        } 
      
      // else if(!accept2.test(this.Pdf_prev)){
      //   this.toastr.error('Please select correct Format');
      // }
      else {
        this.spinner.show();
        let fd = new FormData();
        fd.append('resource_id', this.resources_id);
        fd.append('property_id', this.property);
        fd.append('title', this.title);
        fd.append('description', this.description);
        fd.append('resource_image', this.file);
        fd.append('resource_pdf',this.Pdf_prev1);
        fd.append('website_link', this.website);
        fd.append('start_date', this.startDate);
        fd.append('end_date', this.endDate);
        fd.append('start_time', this.fromTime);
        fd.append('end_time', this.toTime);
        fd.append('organization_name', this.orgName);
        fd.append('organization_phone_no', this.phone_number);
        fd.append('country_id', this.country);
        fd.append('state_id', this.state);
        fd.append('city_id', this.city);
        fd.append('suite_no', this.suite_no);
        fd.append('postal_code', this.postal_code);
        fd.append('country_code', this.country_code);
        fd.append('alternate_phone_no', this.city);
        fd.append('alternate_phone_no_country_code', this.alternate_phone_no);
        fd.append('email', this.email);
        fd.append('resource_contact_name', this.resource_contact_name);
        fd.append('resource_phone_no', this.resource_phone_no);
        fd.append('resource_phone_no_country_code', this.resource_phone_no_country_code);
        // fd.append('email',this.email);
        fd.append('resource_alternate_phone_no', this.resource_alternate_phone_no);
        // fd.append('resource_phone_no',this.resource_phone_no);
        fd.append('resource_alternate_phone_no_country_code', this.resource_alternate_phone_no_country_code);
        fd.append('resource_email', this.resource_email);
        fd.append('address', this.address);
        fd.append('video_link', this.video_link);
        console.log('fd==============',this.file);
        fd.append('ecom_link',this.ecom_link);
        this.webservice.adminPostData('updateResourceWithImage', fd).then((result: any) => {
          if (result.status == 1) {
            this.toastr.success(result.msg);
          } else {
            this.toastr.error(result.msg);
          }
          this.spinner.hide();

        }, (err) => {
          this.spinner.hide();
          console.log(err);
        });
      }

    }
  }

checkPdf(url: any) {
  if (url === undefined || url === null) {
    return "No pdf file found";
  } else {
    // Check if URL starts with "https://"
    if (url.startsWith("https://")) {
      var pdfLink: string = url.substring(url.lastIndexOf('/') + 1);
      if (pdfLink === 'undefined' || pdfLink === undefined || pdfLink === null || pdfLink === 'null' || pdfLink === '') {
        console.log('1======', pdfLink);
        return "No pdf file found";
      } else {
        console.log('2======', pdfLink);
        return url;
      }
    } else {
      return "No pdf file found";
    }
  }
}

  disableLink(event: Event) {
    event.preventDefault();
  }
  removeImage() {
    this.image = null;
    this.imageRemoved = true;
    this.clearFileInput();
  }

  private clearFileInput() {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
    this.file = null;
    this.image_file_type = null;
  }

  removePDF() {
    // Logic to remove the uploaded PDF
    this.Pdf_prev = null;
    this.Pdf_prev1= null;
    this.PDFfile = null; // Optionally clear the selected file as well
    this.Pdf_prev = '';
    this.PDFfile = '';
    this.Pdf_prev1= '';
    this.fileSelected = false;
  
    // Reset the input file element to clear the selected file
    if (this.fileInputs) {
      this.fileInputs.nativeElement.value = '';
    }
  }
}
