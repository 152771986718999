import { Component, OnInit } from '@angular/core';
import { WebserviceService } from 'src/app/webservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})
export class PropertyComponent implements OnInit {

page:number = 1;
count:number = 0;
tableSize:number = 4;
tableSizes:any = [5,10,15,20];






  data: any;
  propertyList: any = [];
  search: any = '';
  cancelClicked: true;

  popoverTitle = 'Delete!';
  popoverMessage = 'If you want to delete this property, it will automatically delete all the data related this property. Are you sure you want to delete?';
  confirmClicked = false;


  constructor(public webservice: WebserviceService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.postList();
    this.spinner.show();
    this.webservice.adminGetData('propertyList/undefined').then((result) => {
      this.data = result;
      console.log(this.data);
      if (this.data.status == 1) {
        this.propertyList = this.data.data;
      } else {
        console.log(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

  }

  onSearch(val) {
    this.search = val;
    this.page = 1;
    console.log(this.search);
    if (this.search != '') {
      this.spinner.show();
      this.webservice.adminGetData('propertyList/' + this.search).then((result) => {
        this.data = result;
        console.log(this.data);
        if (this.data.status == 1) {
          this.propertyList = this.data.data;
        } else {
          console.log(this.data.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    } else {
      this.spinner.show();
      this.webservice.adminGetData('propertyList/undefined').then((result) => {
        this.data = result;
        console.log(this.data);
        if (this.data.status == 1) {
          this.propertyList = this.data.data;
        } else {
          console.log(this.data.msg);
        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });

    }

  }

  onDelete(id) {
    var params = {
      property_id: id
    }
    this.webservice.adminPostData('deleteProperty', params).then((result: any) => {
      if (result.status == 1) {
        this.toastr.success(result.msg);
        this.ngOnInit();
      } else {
        this.toastr.error(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });



  }
  // sortName(property){
  //   this.isDesc - !this.isDesc;

  // }
  //   sortName(property)
  //   this.isDesc - 1this.isDesc;
  // let - 1;
  // this.showData.sort(function (а, b) {
  //   if (a[property] b[property]) {
  //   return 1 - direction;
  //      else ]if (a[property] > b[property1)
  //     return 1.direction;

  postList():void{
    this.webservice.adminGetData('propertyList/undefined').then((result)=>{
      this.data = result;
      console.log("pagination:",this.data);
    })
  }
  onTableDataChange(event:any){
    this.page = event ;
    this.postList();
  }
  onTableSizeChange(event:any): void{
    this.tableSize = event.target.value;
    this.page = 1 ;
    this.postList();
  }
}
