<div class="maincontentarea">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-lg-3">
            </div>
            <div class="col-sm-12 col-lg-9">
                <div class="innercont_top">
                    <!-- <div class="breadcrumblink ">
                        <ul>
                            <li><a class="nav-link selected" routerLinkActive="active" routerLink="/home">Home</a></li>
                            <li><a class="nav-link" href="#">Manage Profile</a></li>
                        </ul>

                    </div> -->
                    <!-- <div class="content_left">
                        <div class="left_cont">


                            <div class="menuicon">
                                <img src="assets/images/menuicon.png" alt="">
                            </div>
                            <div class="emailicon">
                                <a href="mailto:webmaster@example.com"><img src="assets/images/emailicon.png" alt=""></a>
                            </div>
                            <div class="notf">
                                <span>1</span>
                                <img src="assets/images/notefication.png" alt="">
                            </div>
                            <div class="callicon">
                                (033) 203-8919
                            </div>
                        </div>

                    </div> -->
                </div>
                <!-- <div class="searchinfo">
                    <div class="search_cont">
                        <div class="form-group has-search">
                            <input type="button" class="searchbtn">
                            <input type="text" class="form-control" placeholder="Search">
                        </div>
                    </div>
                </div> -->
            </div>

        </div>


        <div class="commonSection" *ngIf="UserData.user_type == 1">
            <div class="leftMenu sidebar">
                <div class="sidebar_cont">
                    <ul>
                        <!-- <li><a class="nav-link active " routerLinkActive="active" routerLink="/dashbord/dashboard">Dashboard</a></li> -->
                        <li><a class="nav-link active " routerLinkActive="active" routerLink="/main/dashboard">Dashboard</a></li>
                      <li><a class="nav-link" routerLinkActive="active" routerLink="/main/resident-list">Resident</a></li>
                      <li><a class="nav-link" routerLinkActive="active" routerLink="/main/staff">Staff</a></li>
                      <li><a class="nav-link" routerLinkActive="active" routerLink="/main/manage-memo">Manage Memo</a></li>
                      <li><a class="nav-link" routerLinkActive="active" routerLink="/main/manage-service">Manage Service</a></li>
                      <li><a class="nav-link" routerLinkActive="active" routerLink="/main/manage-listing">Manage Listing</a></li>
                    </ul>
                </div>
            </div>
            <div class="rightContent">
                <div class="maincontent">
                    <router-outlet></router-outlet>
                </div>
            </div>

        </div>


        <div class="commonSection" *ngIf="UserData.user_type == 0">
            <div class="leftMenu sidebar">
                <div class="sidebar_cont">
                    <ul>
                        <!-- <li><a class="nav-link active " routerLinkActive="active" routerLink="/dashbord/dashboard">Dashboard</a></li> -->
                        <li><a class="nav-link active " routerLinkActive="active" routerLink="/main/dashboard">Dashboard</a></li>
                        <li><a class="nav-link" routerLinkActive="active" routerLink="/main/property">Properties</a></li>
                        <li><a class="nav-link" routerLinkActive="active" routerLink="/main/property-manager">Property Managers</a></li>
                        <li><a class="nav-link" routerLinkActive="active" routerLink="/main/resident-list">Residents</a></li>
                        <li><a class="nav-link" routerLinkActive="active" routerLink="/main/staff">Staffs</a></li>
                        <!-- <li><a class="nav-link" routerLinkActive="active" routerLink="/main/manage-memo">Manage Memo</a></li> -->
                        <!-- <li><a class="nav-link" routerLinkActive="active" routerLink="/main/manage-service">Manage Service</a></li> -->
                        <!-- <li><a class="nav-link" routerLinkActive="active" routerLink="/main/manage-listing">Manage Listing</a></li> -->
                        <!-- <li><a class="nav-link" routerLinkActive="active" routerLink="/main/advertise">Advertise Local Businesses</a></li> -->
                        <li><a class="nav-link" routerLinkActive="active" routerLink="/main/dailydeals">Flash Deals</a></li>
                        <!-- <li><a class="nav-link" routerLinkActive="active" routerLink="/main/flash-deal-charges">Flash Deal Charge</a></li> -->
                        <li><a class="nav-link" routerLinkActive="active" routerLink="/main/resources">Resources</a></li>
                        <li><a class="nav-link" routerLinkActive="active" routerLink="/main/vendor-list">Vendors</a></li>
                        <li><a class="nav-link" routerLinkActive="active" routerLink="/main/cms-list">Manage CMS</a></li>
                        <li><a class="nav-link" routerLinkActive="active" routerLink="/main/overView">Overview</a></li>
                        <!-- <li><a class="nav-link" routerLinkActive="active" routerLink="/main/test">Test</a></li> -->
                        <li><a class="nav-link" routerLinkActive="active" routerLink="/main/Support">Support</a></li>
                        <li><a class="nav-link" routerLinkActive="active" routerLink="/main/adminsubscription">Subscription List</a></li>
                    </ul>
                </div>
            </div>
            <div class="rightContent">
                <div class="maincontent">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>




    










    <div class="commonSection" *ngIf="UserData.user_type == 5">
        <div class="leftMenu sidebar">
            <div class="sidebar_cont">
                <ul>
                    <!-- <li><a class="nav-link active " routerLinkActive="active" routerLink="/dashbord/dashboard">Dashboard</a></li> -->
                    <li><a class="nav-link active " routerLinkActive="active" routerLink="/main/dashboard">Dashboard</a></li>
                    <!-- <li><a class="nav-link" routerLinkActive="active" routerLink="/main/advertise">Advertise Local business</a></li> -->
                    <li><a class="nav-link" routerLinkActive="active" routerLink="/main/dailydeals">Flash Deals</a></li>
                    <li><a class="nav-link" routerLinkActive="active" routerLink="/main/resources">Resources</a></li>
                    <li><a class="nav-link" routerLinkActive="active" routerLink="/main/subscription">Subscriptions</a></li>
                    <!-- <li><a class="nav-link" routerLinkActive="active" routerLink="/main/vendorSubscriptionList">Subscription List</a></li>
                    <li><a class="nav-link" routerLinkActive="active" routerLink="/main/saveCards">Saved Card</a></li> -->
                    
                
                    
                    
                    <!-- <li><a class="nav-link" routerLinkActive="active" routerLink="/main/cms-list">Manage CMS</a></li> -->
                </ul>
            </div>
        </div>
        <div class="rightContent">
            <div class="maincontent">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
