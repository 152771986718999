<div class="content_box">
  <h2>CMS List</h2>
  <div class="responsive_table">

  <table mat-table class="mat-elevation-z8">
    <thead>
      <tr>
          <th style="width: 5%;">Sl#</th>
          <th style="width: 85%;">Title</th>
          <!--<th style="width: 70%;">CMS</th>-->
          <th style="width: 10%;">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let cmsdata of cmsList let i=index">
          <td data-label="Sl#" >{{i+1}}</td>
          <td data-label="Title">{{cmsdata.title}}</td>
          <!--<td>{{cmsdata.cms_content}}</td>-->
          <td data-label="Action"><i style="cursor: pointer;" class="material-icons" title="Edit" [routerLink]="['./cms-edit/'+cmsdata._id]">create</i></td>
      </tr>
    </tbody>
  </table>
  </div>
</div>
<!--<div *ngIf="user_type == 1">
  <div class="btn btn-primary btn-round default_btn mat-raised-button" [routerLink]="['/main/memo']" style="float:right">
      <i class="material-icons float-icon">add</i>
  </div>
</div>-->
