<div class="content_box">
  <h2>Add Property</h2>

  <div class="inpurwraper">
    <label>Property Name<span class="required">*</span></label>
    <input [(ngModel)]="propertyName" type="text" class="form-control"/>
  </div>

  <div class="inpurwraper">
    <label>Property Address<span class="required">*</span></label>
    <input [(ngModel)]="propertyAddress" type="text" class="form-control"
      ngx-google-places-autocomplete [options]='options' (onAddressChange)="AddressChange($event)" />
  </div>

  <div class="inpurwraper">
    <label>Select Country<span class="required">*</span></label>
    <select class="form-control" [(ngModel)]="country"
      (change)="onCountryChange(country)">
      <option *ngFor="let option of countryList" [value]="option.id">{{option.name}}</option>
    </select>
  </div>

  <div class="inputwrapper">
    <label>Select State<span class="required">*</span></label>
    <select [disabled]="country == ''" class="form-control" [(ngModel)]="state" (change)="onStateChange(state)">
      <option disabled></option>
      <option *ngIf="!stateList || stateList.length === 0" value="no-state-found">No state found</option>
      <option *ngFor="let option of stateList" [value]="option.id">{{ option.name }}</option>
    </select>
  </div>
  


  <div class="inpurwraper">
    <label>Select City<span class="required">*</span></label>
    <select [disabled]="state == ''" class="form-control" [(ngModel)]="city" (change)="onCityChange(city)">
      <option disabled></option>
        <option *ngIf="!citylist || citylist.length === 0" value="no-city-found">No city found</option>
        <option *ngFor="let option of citylist" [value]="option.id">{{option.name}}</option>
    
    </select>
  </div>

  <!-- <div class="inpurwraper">
    <label>Select Building(s)<span class="required">*</span></label>
    <input [(ngModel)]="propertyName" type="text" class="form-control"/>
  </div> -->



  <!-- <div class="inpurwraper">
    <label>Building Address<span class="required">*</span></label>
    <input [(ngModel)]="propertyAddress" type="text" class="form-control"
      ngx-google-places-autocomplete [options]='options' (onAddressChange)="AddressChange($event)" />
  </div> -->


 

  <div class="inpurwraper">
    <label>Postal Code</label>
    <input [(ngModel)]="postalCode" type="text" class="form-control" />
  </div>

  <div class="inpurwraper">
    <!-- <label>Work Tel no</label>
    <input [(ngModel)]="propertyContact" type="number" class="form-control"/>
    <p *ngIf="propertyContactValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->
    <label>Phone Number<span class="required">*</span></label><br>
    <input type="text" onwheel="this.blur()" class="form-control form-input" ng2TelInput
        [ng2TelInputOptions]="{initialCountry: 'ca'}" (hasError)="hasError($event)"
        (ng2TelOutput)="getNumber($event)" (intlTelInputObject)="telInputObject($event)"
        [(ngModel)]="propertyContact"  (countryChange)="onCountryChanges($event)" />
        <p *ngIf="propertyContactValidation == true">Phone number should contain 10 digits.Please enter valid phone number. </p>
  </div>

  <div class="inpurwraper">
    <label>Email<span class="required">*</span></label>
    <input [(ngModel)]="email" type="email" class="form-control"/>
    <p *ngIf="emailValidation == true">Please enter valid mail</p>
  </div>



  <div class="inpurwraper">
    <label>Property Management Company</label>
    <input  [(ngModel)]="property_management_company" type="text" class="form-control"/>
  
  </div>


  <!-- <div style="margin-top: 10px;">
    <label>Allowed User Roles</label>
    <div class="form-check" [(ngModel)]="reles">
      <input type="radio" class="form-check-input" id="materialUnchecked" name="materialExampleRadios" mdbInput>
      <label class="form-check-label" for="materialUnchecked">Superintendent</label>
      <input type="radio" class="form-check-input" id="materialUnchecked" name="materialExampleRadios" mdbInput>
      <label class="form-check-label" for="materialUnchecked">Staff</label>
    </div>
  </div> -->



  <div class="inpbtn_wrap">
    <button type="button" class="btn defbtn" *ngIf="country != '' && state != '' &&
    city != '' && propertyName != '' &&
      propertyAddress != ''  && propertyContact != '' && email != ''" type="submit" class="btn defbtn"
      (click)="onAdd();">
      Save
    </button>

    <button type="button" class="btn defbtn"
      *ngIf="country == '' || state == '' || city == '' || propertyName == '' || propertyAddress == '' || propertyContact == '' || email == ''"
      [disabled]="country == '' || state == '' || city == '' || propertyName == '' || propertyAddress == '' || propertyContact == '' || email == ''" type="submit"
      class="btn defbtn" style="cursor: no-drop;">
      Save
    </button>
  </div>
</div>