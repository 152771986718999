<div class="content_box">
  <h2>Edit Property</h2>

  <div class="inpurwraper">
    <label>Property Name<span class="required">*</span></label>
    <input [(ngModel)]="propertyName" type="text" class="form-control" />
  </div>

  <div class="inpurwraper">
    <label>Property Address<span class="required">*</span></label>
    <input [(ngModel)]="propertyAddress" type="text" class="form-control" ngx-google-places-autocomplete
      [options]='options' (onAddressChange)="AddressChange($event)" />
  </div>


  <div class="inpurwraper">
    <label>Select Country<span class="required">*</span></label>
    <select class="form-control" [(ngModel)]="country" (change)="onCountryChange(country)">
      <option *ngFor="let option of countryList" [value]="option.id">{{option.name}}</option>
    </select>
  </div>

  <div class="inpurwraper">
    <label>Select State<span class="required">*</span></label>
    <select [disabled]="country == ''" class="form-control" [(ngModel)]="state" (change)="onStateChange(state)">
      <option disabled></option>
      <option *ngIf="!stateList || stateList.length === 0" value="no-state-found">No state found</option>
      <option *ngFor="let option of stateList" [value]="option.id">{{option.name}}</option>
    </select>
  </div>


  <div class="inputwrapper">
    <label>Select City<span class="required">*</span></label>
    <select [disabled]="state == ''" class="form-control" [(ngModel)]="city" (change)="onCityChange(city)">
        <option disabled></option>
      <option *ngIf="!citylist || citylist.length === 0" value="no-city-found">No city found</option>
        <option *ngFor="let option of citylist" [value]="option.id">{{option.name}}</option>
    </select>
</div>


  <div class="inpurwraper">
    <label>Postal Code</label>
    <input [(ngModel)]="postalCode" type="text" class="form-control"/>
  </div>

  <div class="inpurwraper">
    <!-- <label>Work Tel no</label>
    <input [(ngModel)]="propertyContact" type="number" class="form-control" />
    <p *ngIf="propertyContactValidation == true">Phone Number Should contain 10 digits.Please enter Valid Phone No. </p> -->
    <label>Phone Number<span class="required">*</span></label><br>
    
    <div class="flexFld phnumber">
      <input class="form-control phone" id="Phone" type="text" minlength="10" maxlength="15" onwheel="this.blur()" >
      <!-- <input class="form-control phonecode" [(ngModel)]="property_country_code" readonly /> <input type="number" onwheel="this.blur()" class="form-control phone " 
        [(ngModel)]="propertyContact" />
        <p *ngIf="propertyContactValidation == true">Phone number Should contain 10 digits.Please enter valid phone number. </p></div> -->
   

    
  </div>

  <div class="inpurwraper">
    <label>Email<span class="required">*</span></label>
    <input [(ngModel)]="email" type="email" class="form-control" readonly/>
    <p *ngIf="emailValidation == true">Please enter valid mail</p>
  </div>


  <div class="inpurwraper">
    <label>Property Management Company</label>
    <input [(ngModel)]="property_management_company" type="text" class="form-control" />
    <!-- <p *ngIf="emailValidation == true">Please enter Valid mail</p> -->
  </div>

  <!-- <div style="margin-top: 10px;">
    <label>Allowed User Roles</label>
    <div class="form-check" [(ngModel)]="reles">
      <input type="radio" class="form-check-input" id="materialUnchecked" name="materialExampleRadios" mdbInput>
      <label class="form-check-label" for="materialUnchecked">Superintendent</label>
      <input type="radio" class="form-check-input" id="materialUnchecked" name="materialExampleRadios" mdbInput>
      <label class="form-check-label" for="materialUnchecked">Staff</label>
    </div>
  </div> -->



  <div class="inpbtn_wrap">
    <button type="button" class="btn defbtn" *ngIf="country != '' && state != '' &&
      city != '' && propertyName != '' &&
      propertyAddress != '' && propertyContact != '' && email != ''" type="submit" class="btn defbtn"
      (click)="onUpdate();">
      Update
    </button>

    <button type="button" class="btn defbtn"
      *ngIf="country == '' || state == '' || city == '' || propertyName == '' || propertyAddress == '' || propertyContact == '' || email == ''"
      [disabled]="country == '' || state == '' || city == '' || propertyName == '' || propertyAddress == '' || propertyContact == '' || email == ''"
      type="submit" class="btn defbtn" style="cursor: no-drop;">
      Update
    </button>
  </div>
</div>